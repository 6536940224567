import Modal from 'components/molecules/modal/modal';
import { useEffect, useState } from 'react';

import Button from 'components/atoms/button/button';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Row from 'components/utilities/row/row';
import { FormattedMessage } from 'react-intl';
import './installPWA.scss';

export const InstallPWA = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const element = document.getElementById('app-outlet')?.style;
    const navbar = (document.getElementsByClassName('main-navigation')[0] as HTMLElement).style;

    if (element) {
      element.filter = 'blur(5px)';
      navbar.filter = 'blur(5px)';
    }
  }, []);

  const handleOnClose = () => {
    setOpen(false);
    const element = document.getElementById('app-outlet')?.style;
    const navbar = (document.getElementsByClassName('main-navigation')[0] as HTMLElement).style;

    if (element) {
      element.filter = '';
      navbar.filter = '';
    }
  };

  const handleDontShowAgain = () => {
    localStorage.setItem('installPrompt', 'dont show');
    handleOnClose();
  };

  if (!open) {
    return null;
  }

  return (
    <div className="customs">
      <Modal
        showCross={false}
        closeHandler={handleOnClose}
        headline={
          <Block className="install-pwa" flex spaceBetween>
            <Button onClick={handleDontShowAgain}>
              <FormattedMessage defaultMessage="Don't show again" id="PWAPrompt.dontShowAgian" />
            </Button>

            <Button onClick={handleOnClose} version="primary" iconEnd="Cross" iconClassname="icon">
              <FormattedMessage defaultMessage="Close" id="PWAPrompt.buttonClose" />
            </Button>
          </Block>
        }
      >
        <Column className={'pwa-buttons'}>
          <Typography>
            <FormattedMessage id="pwa.clickOnTheIcon.text" defaultMessage="Click on the icon" /> (
            <Icon name={'Share'} className="icon" />){' '}
            <FormattedMessage
              id="pwa.below.text"
              defaultMessage="below and choose “Add to home screen“ to save the application on your phone"
            />
          </Typography>

          <Row className="center">
            <Icon name="ArrowDown" />
          </Row>
        </Column>

        <div className="arrow-down" />
      </Modal>
    </div>
  );
};

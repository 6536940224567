import { BusinessSalesCase, CounselingCase, RectifyCase, RentalCase, SalesCase } from 'Types';
import { matchHeroDetailsFragment$key } from '__generated__/matchHeroDetailsFragment.graphql';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { usePropertyType } from 'hooks/usePropertyCategories';
import { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import MatchHeroContentAgriculturalCase from '../match-hero-content-agriculturalcase/match-hero-content-agricultural-case';
import MatchHeroContentBusinessSalesCase from '../match-hero-content-businesssalescase/match-hero-content-business-sales-case';
import MatchHeroContentCounselingCase from '../match-hero-content-counselingcase/match-hero-content-counseling-case';
import MatchHeroContentRentalCase from '../match-hero-content-rentalcase/match-hero-content-rental-case';
import MatchHeroContentSalesCase from '../match-hero-content-salescase/match-hero-content-sales-case';
import './match-hero-details.scss';

export interface MatchHeroDetailsProps {
  fragment: matchHeroDetailsFragment$key | null;
}

const matchHeroDetailsFragment = graphql`
  fragment matchHeroDetailsFragment on BaseCase {
    address
    zipCode
    city
    ...matchHeroContentAgriculturalCase_baseCase
    ...matchHeroContentSalesCase
    ...matchHeroContentRentalCase
    ...matchHeroContentBusinessSalesCase
    baseCaseMainOffer {
      ...matchHeroContentCounselingCase
    }
    ... on SalesCase {
      propertyType
      __typename
    }
    ... on RectifyCase {
      __typename
      propertyType
    }
    ... on CounselingPropertyCase {
      __typename
      propertyType
    }
    ... on RentalCase {
      __typename
      propertyType
    }
    ... on CounselingCase {
      __typename
      propertyType
    }
    ... on BusinessSalesCase {
      __typename
      businessPropertyType: propertyType
    }
    ... on BusinessRentalCase {
      __typename
      businessPropertyType: propertyType
    }
  }
`;

const propertyTypes = [
  'HobbyAgriculture',
  'Liebhaveri',
  'Cattle',
  'Pigs',
  'Crop',
  'SpecialProduction',
  'ForestProperty',
  'FreeLand',
  'FarmLand',
];

export const MatchHeroDetails = (props: MatchHeroDetailsProps) => {
  const baseCase = useFragment(matchHeroDetailsFragment, props.fragment);
  const [isExpanded, setIsExpanded] = useState(false);
  const formatBusinessPropertyType = usePropertyType();

  const isAgricultureCase = useMemo(() => {
    const propertyType = baseCase?.businessPropertyType || baseCase?.propertyType;
    if (propertyType) {
      return propertyTypes.includes(propertyType);
    }
    return false;
  }, [baseCase?.propertyType, baseCase?.businessPropertyType]);

  const InfoComponent = () => {
    if (isAgricultureCase) {
      return <MatchHeroContentAgriculturalCase fragment={baseCase} isExpanded={isExpanded} />;
    } else if (baseCase?.__typename === SalesCase) {
      return <MatchHeroContentSalesCase fragment={baseCase} isExpanded={isExpanded} />;
    } else if (baseCase?.__typename === RentalCase) {
      return <MatchHeroContentRentalCase fragment={baseCase} isExpanded={isExpanded} />;
    } else if (baseCase?.__typename === RectifyCase || baseCase?.__typename === CounselingCase) {
      return <MatchHeroContentCounselingCase fragment={baseCase.baseCaseMainOffer} />;
    } else if (baseCase?.__typename === BusinessSalesCase) {
      return <MatchHeroContentBusinessSalesCase fragment={baseCase} isExpanded={isExpanded} />;
    }
  };

  const propertyType = baseCase?.propertyType || baseCase?.businessPropertyType;

  return (
    <Block backgroundColor="white" boxShadow className="match-hero-details" customPadding="md">
      <Block className="match-hero-details__title">
        <Typography className="match-hero-details__title--street" tag="h2" tagStyle="headlineMedium">
          {propertyType && formatBusinessPropertyType(propertyType)} <br />
          {baseCase?.address}
        </Typography>
        <Typography subjectColor="gray" tagStyle="bodyMedium">
          {baseCase?.zipCode} {baseCase?.city}
        </Typography>
        {InfoComponent()}
      </Block>

      <Button
        aria-controls={'section-content'}
        aria-expanded={isExpanded}
        className="match-hero-details__expand"
        hideText
        iconStart={isExpanded ? 'Minus' : 'Plus'}
        onClick={() => setIsExpanded(prevState => !prevState)}
        padding={false}
        version="secondary"
      >
        Expand
      </Button>
    </Block>
  );
};

import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';

export const NoNoficationData = () => {
  return (
    <div className="notifications__empty-state">
      <Icon className="notifications__empty-state-icon" height={48} name="Bell" width={48} />
      <Typography className="notifications__empty-state-text" subjectColor="gray" tagStyle="bodyLarge">
        <FormattedMessage defaultMessage="It looks like you have no messages yet!" id="Notifications.EmptyState" />
      </Typography>
    </div>
  );
};

import { FormattedMessage } from 'react-intl';
import Button from 'components/atoms/button/button';
import { useUpdate } from 'hooks/relay/useUpdate';
import { saleByOwnerMutation } from 'components/molecules/sale-by-owner/sale-by-owner-mutation';
import { LeadStatusTypeEnum } from 'Enums';
import { graphql, useFragment } from 'react-relay';
import {
  leadOpenHouseDialogContent$data,
  leadOpenHouseDialogContent$key,
} from '__generated__/leadOpenHouseDialogContent.graphql';
import {
  chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation,
  CreateAppointmentActivityInputType,
} from '__generated__/chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation.graphql';
import { toast } from 'react-toastify';
import { leadOpenHouseDialogContentViewer$key } from '__generated__/leadOpenHouseDialogContentViewer.graphql';
import { LeadInterface, LeadOpenHouse, LeadPresentation } from 'Types';
import { useList } from 'hooks/useList';
import { useMutation } from 'relay-hooks';
import { chooseActivityModalCreate } from 'components/organisms/self-sale/choose-activity-modal';
import { add } from 'date-fns';

type Props = {
  fragment: leadOpenHouseDialogContent$key;
  onClose: () => void;
  viewerFragment: leadOpenHouseDialogContentViewer$key;
};

export const LeadOpenHouseDialogContent = ({ fragment, viewerFragment, onClose }: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadOpenHouseDialogContent on LeadInterface {
        id
        __typename
        ... on LeadOpenHouse {
          openHouseStartTime
          firstName
          lastName
          phoneNumber
          email
          caseId
        }
      }
    `,
    fragment,
  );

  const viewer = useFragment(
    graphql`
      fragment leadOpenHouseDialogContentViewer on Viewer {
        propertyForSaleByOwnerAppointmentActivityTypes {
          category
          id
        }
      }
    `,
    viewerFragment,
  );
  const [createActivity, { loading: createActivityLoading }] =
    useMutation<chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation>(chooseActivityModalCreate);

  const [updateLead, { loading: updateLeadLoading }] = useUpdate(saleByOwnerMutation, {
    __typename: LeadInterface,
  });

  const propertyForSaleByOwnerAppointmentActivityTypes = useList(viewer.propertyForSaleByOwnerAppointmentActivityTypes);

  const getActivityId = (lead: leadOpenHouseDialogContent$data) => {
    return propertyForSaleByOwnerAppointmentActivityTypes?.find(activity => {
      if (lead?.__typename === LeadOpenHouse) {
        return activity?.category === 'OpenHouse';
      } else if (lead?.__typename === LeadPresentation) {
        return activity?.category === 'Showcasing';
      }
    });
  };

  const handleProcessLead = async () => {
    const activity = getActivityId(lead);
    const startDate = lead.openHouseStartTime ? new Date(lead.openHouseStartTime) : new Date();
    if (!activity || !activity.id) {
      return toast.error(
        <FormattedMessage id="Default.ErrorMessage" defaultMessage="We are sorry, something went wrong" />,
      );
    }

    if (!lead.caseId) {
      return toast.error(
        <FormattedMessage id="Default.ErrorMessage" defaultMessage="We are sorry, something went wrong" />,
      );
    }

    const input: CreateAppointmentActivityInputType = {
      activityTypeId: activity.id,
      startDatetime: lead.openHouseStartTime,
      endDatetime: add(startDate, { hours: 1 }),
      caseId: lead.caseId,
      customer: {
        firstname: lead.firstName,
        lastname: lead.lastName,
        email: lead.email,
        phone: lead.phoneNumber,
      },
    };

    try {
      await createActivity({ variables: { input } });
      await updateLead({
        variables: {
          input: {
            leadIdGlobal: lead.id,
            statusType: LeadStatusTypeEnum.Processed,
          },
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      toast.error(<FormattedMessage id="Default.ErrorMessage" defaultMessage="We are sorry, something went wrong" />);
    }
  };

  return (
    <div>
      <h3>
        <FormattedMessage id={'LeadContactDialogContent.openHouse'} defaultMessage={'Register to open house'} />
      </h3>
      <p>
        <FormattedMessage
          id={'LeadContactDialogContent.acceptOpenHouseSubmission'}
          defaultMessage={'Click on end lead to confirm that the buyer is registered for the open house'}
        />
      </p>
      <div className={'dialog-button'}>
        <Button onClick={handleProcessLead} disabled={createActivityLoading || updateLeadLoading}>
          {createActivityLoading || updateLeadLoading ? (
            <FormattedMessage id="Default.Saving" defaultMessage="Saving" />
          ) : (
            <FormattedMessage id={'LeadContactDialogContent.processLead'} defaultMessage={'Process lead'} />
          )}
        </Button>
      </div>
    </div>
  );
};

import { FC } from 'react';

type Props = {
  url: string;
  onImageClick?: (index: number) => void;
  activeSwiperIndex: number;
  noPlay?: boolean;
};
export const Video: FC<Props> = ({ url, onImageClick, activeSwiperIndex, noPlay }) => {
  const attr = noPlay
    ? { controls: false, muted: true, autoPlay: false }
    : {
        controls: true,
        autoPlay: true,
        muted: true,
        loop: true,
      };
  return (
    <video className="gallery-swiper__image" onClick={() => onImageClick && onImageClick(activeSwiperIndex)} {...attr}>
      <source src={url ?? ''} type="video/mp4" />
    </video>
  );
};

/**
 * @generated SignedSource<<51ac2657862349ee75678685004e6e0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateAppointmentActivityInputType = {
  activityTypeId: string;
  caseId: string;
  customer?: AppointmentActivityCustomerInputType | null | undefined;
  endDatetime: any;
  startDatetime: any;
};
export type AppointmentActivityCustomerInputType = {
  email?: string | null | undefined;
  firstname?: string | null | undefined;
  lastname?: string | null | undefined;
  phone?: string | null | undefined;
};
export type chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation$variables = {
  input: CreateAppointmentActivityInputType;
};
export type chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation$data = {
  readonly createPropertyForSaleByOwnerAppointmentActivity: {
    readonly createdObject: {
      readonly endDate: any | null | undefined;
      readonly id: string;
      readonly latestNoteContent: string | null | undefined;
      readonly name: string | null | undefined;
      readonly notes: ReadonlyArray<{
        readonly content: string | null | undefined;
      } | null | undefined> | null | undefined;
      readonly startDate: any | null | undefined;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly message: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation = {
  response: chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation$data;
  variables: chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestNoteContent",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppointmentActivityCreatedPayload",
        "kind": "LinkedField",
        "name": "createPropertyForSaleByOwnerAppointmentActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppointmentActivity",
            "kind": "LinkedField",
            "name": "createdObject",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppointmentActivityCreatedPayload",
        "kind": "LinkedField",
        "name": "createPropertyForSaleByOwnerAppointmentActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppointmentActivity",
            "kind": "LinkedField",
            "name": "createdObject",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da622b2b21d095b799e146c067d7954d",
    "id": null,
    "metadata": {},
    "name": "chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation",
    "operationKind": "mutation",
    "text": "mutation chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation(\n  $input: CreateAppointmentActivityInputType!\n) {\n  createPropertyForSaleByOwnerAppointmentActivity(input: $input) {\n    errors {\n      message\n      id\n    }\n    createdObject {\n      id\n      name\n      startDate\n      endDate\n      latestNoteContent\n      notes {\n        content\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6927c105a6190cd303257dc5fd6fc447";

export default node;

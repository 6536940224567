import { FC, Fragment, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import './simple-modal.scss';
import Icon from 'components/atoms/icon/icon';
import useOnClickOutside from 'hooks/use-on-click-outside';

type ModalProps = {
  children: ReactNode;
  onClose: () => void;
  disableClickOutside?: boolean;
};

export const SimpleModal: FC<ModalProps> = ({ children, onClose, disableClickOutside = false }) => {
  const customSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        onClose();
      }
    };

    document.body.classList.add('lock-scroll');
    window.addEventListener('keydown', handleEsc);

    return () => {
      document.body.classList.remove('lock-scroll');
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  useOnClickOutside(customSelectRef, onClose);

  return (
    <Fragment>
      {createPortal(
        <div className="modal-bg">
          <div className="modal-container" ref={disableClickOutside ? null : customSelectRef}>
            <button className="modal-button" onClick={onClose}>
              <Icon name={'Cross'} />
            </button>
            {children}
          </div>
        </div>,
        document.body,
      )}
    </Fragment>
  );
};

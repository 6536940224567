import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import Container from 'components/utilities/container/container';
import Row from 'components/utilities/row/row';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import './not-found.scss';
import { FC } from 'react';

interface NotFoundProps {
  reload?: boolean;
}

const NotFound: FC<NotFoundProps> = props => {
  const navigate = useNavigate();

  const handleRetry = () => {
    // manually reload only when necesary (eg when the 404 is thrown by the error boundry)
    if (props.reload) {
      window.location.href = window.location.origin + '/';
      return;
    }
    navigate('/');
  };

  return (
    <Container>
      <Row>
        <div className="not-found">
          <Block>
            <Typography className="not-found__headline" subjectColor="gray" tag="h1" tagStyle="display">
              404
            </Typography>
            <Typography className="not-found__text" subjectColor="gray" tag="p">
              <FormattedMessage defaultMessage="Oops! Something went wrong." id="404Page.SomethingWentWrong" />
              <br />
              <FormattedMessage
                defaultMessage="Unfortunately, we cannot find the page you are looking for."
                id="404Page.PageNotFound"
              />
            </Typography>
            <Button onClick={handleRetry}>
              <FormattedMessage defaultMessage="Go to overview" id="404Page.ButtonText" />
            </Button>
          </Block>
        </div>
      </Row>
    </Container>
  );
};

export default NotFound;

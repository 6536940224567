import Select from 'components/atoms/select/select';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { ComponentProps, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import './table-select.scss';

export interface TableSelectProps extends Omit<ComponentProps<'select'>, 'onChange'> {
  currentPage: number;
  onChange: (val: number) => void;
  options: number[];
  pageSize: number;
  totalItems: number;
}

const TableSelect: FC<TableSelectProps> = ({ currentPage, pageSize, totalItems, options, onChange, ...props }) => {
  const firstIndex = currentPage * pageSize - (pageSize - 1);
  const lastIndex = pageSize * currentPage;

  return (
    <Block className="table-select" flex spaceBetween>
      <Typography tag="span">
        <FormattedMessage defaultMessage="Show" id="TableSelect.vis" />
      </Typography>
      <Select
        className="select"
        max={totalItems}
        onChange={e => onChange(Number(e.target.value))}
        options={options}
        {...props}
      />
      <Typography tag="span">
        <FormattedMessage defaultMessage="Per page" id="Documents.PerPage" />
      </Typography>
      <Typography className="table-select__grey-text" tag="span">
        Vis {firstIndex}-{Math.min(lastIndex, totalItems)} af {totalItems}
      </Typography>
    </Block>
  );
};

export default TableSelect;

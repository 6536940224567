import { BusinessPropertyType, BusinessSalesCaseStatus } from 'Enums';
import { matchHeroContentBusinessSalesCase$key } from '__generated__/matchHeroContentBusinessSalesCase.graphql';
import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { useList } from 'hooks/useList';
import { FC, Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatNumber } from 'tools/string-formatters';

export interface MatchHeroContentBusinessSalesCaseProps {
  fragment: matchHeroContentBusinessSalesCase$key | null;
  isExpanded?: boolean;
}

const fragment = graphql`
  fragment matchHeroContentBusinessSalesCase on BaseCase {
    energyMark
    yearBuilt
    baseCaseMainOffer {
      cashPrice
      rateOfReturn {
        annualAmount
      }
    }
    caseAreas {
      commercialArea
      livableArea
      plotAreaSum
      builtUpArea
    }
    ... on BusinessSalesCase {
      buildings {
        isMainBuilding
        yearBuilt
      }
      investmentProperty
      businessPropertyType: propertyType
      status
      tenanciesYearlyRentTotal
    }
  }
`;

const MatchHeroContentBusinessSalesCase: FC<MatchHeroContentBusinessSalesCaseProps> = props => {
  const data = useFragment(fragment, props.fragment);

  const isValuation = data?.status === BusinessSalesCaseStatus.Valuation;
  const isPlot = data?.businessPropertyType === BusinessPropertyType.Plot;
  const buildings = useList(data?.buildings);

  const yearBuilt = useMemo(() => {
    if (data?.yearBuilt) return data.yearBuilt;

    const mainBuilding = buildings.find(building => building.isMainBuilding);
    if (mainBuilding) {
      return mainBuilding.yearBuilt;
    } else {
      return buildings.length > 0 ? buildings[0].yearBuilt : null;
    }
  }, [buildings, data?.yearBuilt]);

  return (
    <Fragment>
      {!isValuation && (
        <Block className="match-hero-details__row marginTop" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Case price" id="Match.Cashprice" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.baseCaseMainOffer?.cashPrice)}</Typography>
        </Block>
      )}
      <Block className="match-hero-details__row" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Ground area" id="Match.groundArea" />
        </Typography>
        <Typography tagStyle="bodySmall">{data?.caseAreas?.plotAreaSum} m²</Typography>
      </Block>
      {/* if: group one */}
      {!isPlot && (
        <Fragment>
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Commercial area" id="Match.CommercialArea" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.caseAreas?.commercialArea} m²</Typography>
          </Block>
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Housing area" id="Match.Area" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.caseAreas?.livableArea} m²</Typography>
          </Block>
        </Fragment>
      )}
      {/* if: group one */}
      {!isPlot && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Energy mark" id="Match.Energymark" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.energyMark}</Typography>
        </Block>
      )}

      <div
        className={classNames('match-hero-details__expandable', {
          isExpanded: props.isExpanded,
        })}
        id="section-content"
      >
        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="More info" id="Match.MoreInfo" />
        </Typography>
        {/* if: group 1 */}
        {!isPlot && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Investment property" id="Match.InvestmentProperty" />
              </Typography>
              <Typography tagStyle="bodySmall">
                {data?.investmentProperty ? (
                  <FormattedMessage defaultMessage="Yes" id="Case.Yes" />
                ) : (
                  <FormattedMessage defaultMessage="No" id="Case.No" />
                )}
              </Typography>
            </Block>

            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Built up area" id="Match.BuiltUpArea" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.caseAreas?.builtUpArea} m²</Typography>
            </Block>
          </Fragment>
        )}

        {/* if: group 1 */}
        {!isPlot && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Year build" id="Match.YearBuild" />
              </Typography>
              <Typography tagStyle="bodySmall">{yearBuilt}</Typography>
            </Block>
          </Fragment>
        )}

        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="Finances" id="Match.Finances" />
        </Typography>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Rate of return:" id="Match.RateOfReturn" />
          </Typography>
          <Typography tagStyle="bodySmall">
            {formatNumber(data?.baseCaseMainOffer?.rateOfReturn?.[0]?.annualAmount, '%')}
          </Typography>
        </Block>
        {!isPlot && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage
                defaultMessage="Rental income residential and business:"
                id="Match.tenanciesYearlyRentTotal"
              />
            </Typography>
            <Typography tagStyle="bodySmall">{formatNumber(data?.tenanciesYearlyRentTotal, 'kr')}</Typography>
          </Block>
        )}
      </div>
    </Fragment>
  );
};

export default MatchHeroContentBusinessSalesCase;

import { PropertyType, SalesCaseStatus } from 'Enums';
import { matchHeroContentSalesCase$key } from '__generated__/matchHeroContentSalesCase.graphql';
import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { useList } from 'hooks/useList';
import { FC, Fragment, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatNumber } from 'tools/string-formatters';

export interface MatchHeroContentSalesCaseProps {
  fragment: matchHeroContentSalesCase$key | null;
  isExpanded?: boolean;
}

const fragment = graphql`
  fragment matchHeroContentSalesCase on BaseCase {
    energyMark
    yearBuilt
    heat
    otherBuildingsSum
    annualHeatingPrice
    floorCount
    roomCount
    buildings {
      buildingInclAreas {
        area
      }
    }
    baseCaseMainOffer {
      cashPrice
      technicalPrice
      totalCosts {
        yearlyGrossOutput
        yearlyNetOutput
        minimumPayout
      }
    }
    caseAreas {
      livableArea
      plotAreaSum
      atticArea
      basementArea
      builtUpArea
    }
    ... on SalesCase {
      ownerCostsTotalMonthly
      propertyType
      salesStatus: status
    }
  }
`;

const MatchHeroContentSalesCase: FC<MatchHeroContentSalesCaseProps> = props => {
  const data = useFragment(fragment, props.fragment);
  const moreInfoRef = useRef<HTMLElement>(null);

  const isCondo = useMemo(() => {
    switch (data?.propertyType) {
      case PropertyType.VillaWithCondoStatus:
      case PropertyType.TerracedHouseWithCondoStatus:
      case PropertyType.Condo:
      case PropertyType.PartnershipOrLimitedPartnershipCondo:
      case PropertyType.VacationCondo:
      case PropertyType.VillaApartment:
      case PropertyType.IdeelAnpartCondo:
        return true;
      default:
        return false;
    }
  }, [data?.propertyType]);

  const isHousingCooperative = data?.propertyType === PropertyType.HousingCooperative;

  const isPlot = useMemo(() => {
    switch (data?.propertyType) {
      case PropertyType.AllotmentPlot:
      case PropertyType.AllYearRoundPlot:
      case PropertyType.VacationPlot:
      case PropertyType.FreeLand:
        return true;
      default:
        return false;
    }
  }, [data?.propertyType]);

  const isValuation = data?.salesStatus === SalesCaseStatus.Valuation;
  const buildings = useList(data?.buildings);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      {!isValuation && (
        <Block className="match-hero-details__row marginTop" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Case price" id="Match.Cashprice" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.baseCaseMainOffer?.cashPrice)}</Typography>
        </Block>
      )}
      {/* if: not group 4 */}
      {!isPlot && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Housing area" id="Match.Area" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.caseAreas?.livableArea} m²</Typography>
        </Block>
      )}
      {/* if: group 1 or group 4 */}
      {(isPlot || !isCondo || !isHousingCooperative) && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Ground area" id="Match.groundArea" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.caseAreas?.plotAreaSum} m²</Typography>
        </Block>
      )}
      {/* if: not group 3 */}
      {!isHousingCooperative && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Monthly owner cost" id="Match.OwnershipExpense" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.ownerCostsTotalMonthly)}</Typography>
        </Block>
      )}

      {/* if: not group 4 */}
      {!isPlot && (
        <Fragment>
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Rooms" id="Match.NumberOfRooms" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.roomCount}</Typography>
          </Block>
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Energy mark" id="Match.Energymark" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.energyMark}</Typography>
          </Block>
        </Fragment>
      )}

      <div
        className={classNames('match-hero-details__expandable', {
          isExpanded: props.isExpanded,
        })}
        id="section-content"
      >
        <Typography ref={moreInfoRef} className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="More info" id="Match.MoreInfo" />
        </Typography>

        {/* if: not group 4 */}
        {!isPlot && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Year build" id="Match.YearBuild" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.yearBuilt}</Typography>
          </Block>
        )}

        {/* if: group 1 */}
        {(!isPlot || !isCondo || !isHousingCooperative) && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage={'Floor area'} id="Match.floorArea" />
            </Typography>
            <Typography tagStyle="bodySmall">{data?.caseAreas?.builtUpArea} m²</Typography>
          </Block>
        )}
        {/* if: group 1 */}
        {(!isPlot || !isCondo || !isHousingCooperative) && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Integrated areas" id="Match.buildingInclAreas" />
            </Typography>
            <Typography tagStyle="bodySmall">{buildings[0]?.buildingInclAreas?.[0]?.area || 0} m²</Typography>
          </Block>
        )}
        {/* if: not group 4 */}
        {!isPlot && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Basement" id="Match.BasementArea" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.caseAreas?.basementArea} m²</Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Rooftop" id="Match.AtticArea" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.caseAreas?.atticArea} m²</Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Other buildings" id="Match.OtherBuildings" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.otherBuildingsSum} m²</Typography>
            </Block>
          </Fragment>
        )}

        {/* if: not group 4 */}
        {!isPlot && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Heat source" id="Match.HeatSource" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.heat}</Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Heating price" id="Match.AnnualHeatingPrice" />
              </Typography>
              <Typography tagStyle="bodySmall">{formatNumber(data?.annualHeatingPrice)}</Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Number of floors" id="Match.NumberOfFloors" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.floorCount}</Typography>
            </Block>
          </Fragment>
        )}

        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="Finances" id="Match.Finances" />
        </Typography>
        {/* if: not group3 */}
        {!isHousingCooperative && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Payment" id="Match.Payment" />
              </Typography>
              <Typography tagStyle="bodySmall">
                {formatNumber(data?.baseCaseMainOffer?.totalCosts?.minimumPayout, 'kr')}
              </Typography>
            </Block>
          </Fragment>
        )}
        {/* if: group 3 */}
        {isHousingCooperative && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Owner costs monthly total" id="Match.ownerCostsTotalMonthlyCondo" />
            </Typography>
            <Typography tagStyle="bodySmall">{formatNumber(data?.ownerCostsTotalMonthly)}</Typography>
          </Block>
        )}
        {/* if: group3 */}
        {isHousingCooperative && (
          <Block className="match-hero-details__row" flex spaceBetween>
            <Typography tagStyle="bodySmall">
              <FormattedMessage defaultMessage="Technical price" id="Match.TechnicalPrice" />
            </Typography>
            <Typography tagStyle="bodySmall">{formatNumber(data?.baseCaseMainOffer?.technicalPrice)}</Typography>
          </Block>
        )}
        {/* if: not group3 */}
        {!isHousingCooperative && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Gross excl. cost of ownership" id="Match.BruttoExcOwnership" />
              </Typography>
              <Typography tagStyle="bodySmall">
                {formatNumber(data?.baseCaseMainOffer?.totalCosts?.yearlyGrossOutput, 'kr')}
              </Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Net excl. cost of ownership" id="Match.NettoExcOwnership" />
              </Typography>
              <Typography tagStyle="bodySmall">
                {formatNumber(data?.baseCaseMainOffer?.totalCosts?.yearlyNetOutput, 'kr')}
              </Typography>
            </Block>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default MatchHeroContentSalesCase;

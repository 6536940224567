/**
 * @generated SignedSource<<bd540236bc49ee0562397b71ee95bbd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateThreadInput = {
  activityId?: string | null | undefined;
  attachmentIDs?: ReadonlyArray<string | null | undefined> | null | undefined;
  caseId?: string | null | undefined;
  initialMessage: string;
  participantId: string;
  subject: string;
};
export type createThread_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input?: CreateThreadInput | null | undefined;
};
export type createThread_Mutation$data = {
  readonly createThread: {
    readonly createdObject: {
      readonly createDate: any;
      readonly hasNewMessages: boolean;
      readonly id: string;
      readonly lastMessage: {
        readonly createDate: any;
        readonly id: string;
      } | null | undefined;
      readonly messageConnection: {
        readonly items: ReadonlyArray<{
          readonly author: {
            readonly fullName: string | null | undefined;
          };
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly subject: string;
      readonly threadParticipants: ReadonlyArray<{
        readonly lastReadMessageDate: any | null | undefined;
        readonly person: {
          readonly fullName: string | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly message: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type createThread_Mutation = {
  response: createThread_Mutation$data;
  variables: createThread_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNewMessages",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  {
    "kind": "Literal",
    "name": "excludeSelf",
    "value": true
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastReadMessageDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v8/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createThread_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ThreadCreatedPayload",
        "kind": "LinkedField",
        "name": "createThread",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Thread",
            "kind": "LinkedField",
            "name": "createdObject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "lastMessage",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "LOG",
                    "path": "createThread.createdObject.lastMessage.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "LOG",
                    "path": "createThread.createdObject.lastMessage.createDate"
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "MessageConnection",
                "kind": "LinkedField",
                "name": "messageConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "messageConnection(first:1)"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "ThreadParticipant",
                "kind": "LinkedField",
                "name": "threadParticipants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": "threadParticipants(excludeSelf:true)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createThread_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ThreadCreatedPayload",
        "kind": "LinkedField",
        "name": "createThread",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Thread",
            "kind": "LinkedField",
            "name": "createdObject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "lastMessage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "MessageConnection",
                "kind": "LinkedField",
                "name": "messageConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "messageConnection(first:1)"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "ThreadParticipant",
                "kind": "LinkedField",
                "name": "threadParticipants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": "threadParticipants(excludeSelf:true)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "createdObject",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ReviewEdge"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcffe605cef21a40dfab727637fbb057",
    "id": null,
    "metadata": {},
    "name": "createThread_Mutation",
    "operationKind": "mutation",
    "text": "mutation createThread_Mutation(\n  $input: CreateThreadInput\n) {\n  createThread(input: $input) {\n    createdObject {\n      lastMessage {\n        id\n        createDate\n      }\n      id\n      createDate\n      subject\n      hasNewMessages\n      messageConnection(first: 1) {\n        items {\n          author {\n            __typename\n            fullName\n            id\n          }\n          id\n        }\n      }\n      threadParticipants(excludeSelf: true) {\n        person {\n          __typename\n          fullName\n          id\n        }\n        lastReadMessageDate\n        id\n      }\n    }\n    errors {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b75734ab7471571fc45997e483b5204a";

export default node;

import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import { ComponentPropsWithoutRef, Fragment, forwardRef, useEffect, useState } from 'react';
import './select.scss';

export interface SelectOption {
  name: string;
  selected?: boolean;
  value: string;
}
export interface SelectProps extends ComponentPropsWithoutRef<'select'> {
  bigVersion?: boolean;
  errorMessage?: string;
  label?: string;
  noFloatingLabel?: boolean;
  options?: SelectOption[];
  placeholder?: string;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    bigVersion,
    className,
    disabled,
    errorMessage,
    label,
    placeholder,
    required = false,
    name,
    noFloatingLabel,
    onBlur,
    onChange,
    onFocus,
    options,
    ...rest
  } = props;
  const [selected, setSelected] = useState(() => options?.find(option => option.selected)?.value);
  const [isLabelShown, setIsLabelShown] = useState(false);
  const [isPlaceholderSelected, setIsPlaceholderSelected] = useState(!selected);

  useEffect(() => {
    const value = (document.querySelector(`#${name}`) as HTMLSelectElement)?.value;
    if (value) {
      setIsLabelShown(true);
      setIsPlaceholderSelected(false);
    }
  }, [name]);

  return (
    <Fragment>
      <div
        className={classNames('select', {
          bigVersion,
          className,
          hasError: !!errorMessage,
          isDisabled: disabled,
          isLabelShown,
          noFloatingLabel,
        })}
      >
        {label && (
          <Typography className="label" htmlFor={rest.id} tag="label" tagStyle="bodySmall">
            {label}
          </Typography>
        )}
        <select
          {...rest}
          ref={ref}
          className={classNames('select__field field', {
            select__placeholder: isPlaceholderSelected,
          })}
          defaultValue={selected}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={event => {
            event.target.value.length > 0 ? setIsLabelShown(true) : setIsLabelShown(false);
            setSelected(event.currentTarget.value);
            setIsPlaceholderSelected(false);
            onChange && onChange(event);
          }}
          onFocus={onFocus}
          required={required}
        >
          {placeholder && (
            <option className="select__placeholder" disabled selected value="">
              {placeholder}
            </option>
          )}
          {options &&
            options.map(option => (
              <option key={option.value} className="option" value={option.value}>
                {option.name}
              </option>
            ))}
        </select>
      </div>
      {errorMessage && (
        <Typography subjectColor="error" tag="span" tagStyle="bodyXSmall">
          *{errorMessage}
        </Typography>
      )}
    </Fragment>
  );
});

Select.displayName = 'Select';

export default Select;

import { FormattedMessage } from 'react-intl';
import Button from 'components/atoms/button/button';
import { useUpdate } from 'hooks/relay/useUpdate';
import { saleByOwnerMutation } from 'components/molecules/sale-by-owner/sale-by-owner-mutation';
import DatePicker from 'components/molecules/date-picker/date-picker';
import { SelectSingleEventHandler } from 'react-day-picker';
import { ChangeEvent, useState } from 'react';
import { AppointmentTimePicker } from 'components/organisms/self-sale/components/appointment-timepicker';
import { add, set } from 'date-fns';
import { LeadStatusTypeEnum } from 'Enums';
import { chooseActivityModalCreate } from 'components/organisms/self-sale/choose-activity-modal';
import { graphql, useFragment } from 'react-relay';
import {
  leadPresentationDialogContent$data,
  leadPresentationDialogContent$key,
} from '__generated__/leadPresentationDialogContent.graphql';
import { LeadInterface, LeadOpenHouse, LeadPresentation } from 'Types';
import { leadPresentationDialogContentViewer$key } from '__generated__/leadPresentationDialogContentViewer.graphql';
import { useList } from 'hooks/useList';
import { useMutation } from 'relay-hooks';
import {
  chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation,
  CreateAppointmentActivityInputType,
} from '__generated__/chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation.graphql';
import { toast } from 'react-toastify';
import { updateStartEndTimeFromDate } from 'components/molecules/sale-by-owner/dialog/utils';

type Props = {
  onClose: () => void;
  fragment: leadPresentationDialogContent$key;
  viewerFragment: leadPresentationDialogContentViewer$key;
};

const DEFAULT_DURATION_MINUTES = 60;
const TODAY = new Date();

export const LeadPresentationDialogContent = ({ onClose, fragment, viewerFragment }: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadPresentationDialogContent on LeadInterface {
        id
        __typename
        ... on LeadPresentation {
          firstName
          lastName
          phoneNumber
          email
          caseId
        }
      }
    `,
    fragment,
  );
  const viewer = useFragment(
    graphql`
      fragment leadPresentationDialogContentViewer on Viewer {
        propertyForSaleByOwnerAppointmentActivityTypes {
          category
          id
        }
      }
    `,
    viewerFragment,
  );

  const [updateLead, { loading: updateLeadLoading }] = useUpdate(saleByOwnerMutation, {
    __typename: LeadInterface,
  });

  const [createActivity, { loading: createActivityLoading }] =
    useMutation<chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation>(chooseActivityModalCreate);

  const [selectedDate, setSelectedDate] = useState(TODAY);
  const [time, setTime] = useState({
    timeStart: TODAY,
    timeEnd: add(TODAY, { minutes: DEFAULT_DURATION_MINUTES }),
  });

  const propertyForSaleByOwnerAppointmentActivityTypes = useList(viewer.propertyForSaleByOwnerAppointmentActivityTypes);

  const handleProcessLead = async () => {
    const activity = getActivityId(lead);
    if (!activity || !activity.id) {
      return toast.error(
        <FormattedMessage id="Default.ErrorMessage" defaultMessage="We are sorry, something went wrong" />,
      );
    }

    if (!lead.caseId) {
      return toast.error(
        <FormattedMessage id="Default.ErrorMessage" defaultMessage="We are sorry, something went wrong" />,
      );
    }

    const { start, end } = updateStartEndTimeFromDate(selectedDate, time.timeStart, time.timeEnd);

    const input: CreateAppointmentActivityInputType = {
      activityTypeId: activity.id,
      startDatetime: start,
      endDatetime: end,
      caseId: lead.caseId ?? '',
      customer: {
        firstname: lead.firstName,
        lastname: lead.lastName,
        email: lead.email,
        phone: lead.phoneNumber,
      },
    };

    try {
      await createActivity({ variables: { input } });
      await updateLead({
        variables: {
          input: {
            leadIdGlobal: lead.id,
            statusType: LeadStatusTypeEnum.Processed,
          },
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const getActivityId = (lead: leadPresentationDialogContent$data) => {
    return propertyForSaleByOwnerAppointmentActivityTypes?.find(activity => {
      if (lead?.__typename === LeadOpenHouse) {
        return activity?.category === 'OpenHouse';
      } else if (lead?.__typename === LeadPresentation) {
        return activity?.category === 'Showcasing';
      }
    });
  };

  const changeDay = (date: Date) => {
    setSelectedDate(date);
  };

  const handleChangeTimeStart = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(':');
    const timeStart = set(selectedDate, { hours: Number(value[0]), minutes: Number(value[1]), seconds: 0 });
    const timeEnd = add(timeStart, { minutes: DEFAULT_DURATION_MINUTES });
    setTime({ timeStart, timeEnd });
  };
  const handleChangeTimeEnd = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(':');
    const timeEnd = set(selectedDate, { hours: Number(value[0]), minutes: Number(value[1]), seconds: 0 });
    setTime(prevState => {
      return {
        ...prevState,
        timeEnd,
      };
    });
  };
  const handleBlurTimeStart = (_diff: number, _ms: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(':');
    const timeStart = set(selectedDate, { hours: Number(value[0]), minutes: Number(value[1]), seconds: 0 });
    const timeEnd = add(timeStart, { minutes: DEFAULT_DURATION_MINUTES });
    setTime({ timeStart, timeEnd });
  };
  const handleBlurTimeEnd = (_diff: number, _mr: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(':');
    const timeEnd = set(selectedDate, { hours: Number(value[0]), minutes: Number(value[1]), seconds: 0 });
    setTime(prevState => {
      return {
        ...prevState,
        timeEnd,
      };
    });
  };

  return (
    <div>
      <h3>
        <FormattedMessage id={'LeadContactDialogContent.bookPresentation'} defaultMessage={'Book show casing'} />
      </h3>
      <hr />
      <div className="dialog-content-grid">
        <div>
          <h4>
            <FormattedMessage id={'LeadContactDialogContent.pickADate'} defaultMessage={'Pick a Date'} />
          </h4>
          <DatePicker
            fromDate={new Date()}
            mode="single"
            onSelect={changeDay as SelectSingleEventHandler}
            selected={selectedDate}
          />
          <br />
          <AppointmentTimePicker
            labelStart={<FormattedMessage defaultMessage="Start time:" id="Default.StartTime" />}
            labelEnd={<FormattedMessage defaultMessage="End time:" id="endTime" />}
            timeStart={time.timeStart}
            timeEnd={time.timeEnd}
            handleChangeTimeStart={handleChangeTimeStart}
            handleChangeTimeEnd={handleChangeTimeEnd}
            handleBlurTimeStart={handleBlurTimeStart}
            handleBlurTimeEnd={handleBlurTimeEnd}
          />
        </div>
        <div className="dialog-content-customer">
          <h4>
            <FormattedMessage id={'LeadContactDialogContent.customerInfo'} defaultMessage={'Customer info'} />
          </h4>
          <div>
            <p>
              {lead.firstName} {lead.lastName}
            </p>
            <p>{lead.phoneNumber}</p>
            <p>{lead.email}</p>
          </div>
        </div>
      </div>
      <div className={'dialog-button'}>
        <Button onClick={handleProcessLead} disabled={createActivityLoading || updateLeadLoading}>
          {createActivityLoading || updateLeadLoading ? (
            <FormattedMessage id="Default.Saving" defaultMessage="Saving" />
          ) : (
            <FormattedMessage id={'LeadContactDialogContent.processLead'} defaultMessage={'Process lead'} />
          )}
        </Button>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import Icon from 'components/atoms/icon/icon';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import { formatDate } from 'tools/string-formatters';
import { FC } from 'react';
import { messageNotification_fragment$key } from '__generated__/messageNotification_fragment.graphql';

export interface MessageNotificationProps {
  notification: messageNotification_fragment$key;
  url: string;
}

const messageNotifation = graphql`
  fragment messageNotification_fragment on MessageNotification {
    createdDate
    read
    subject
    authorName
  }
`;

export const MessageNotification: FC<MessageNotificationProps> = ({ notification, url }) => {
  const data = useFragment<messageNotification_fragment$key>(messageNotifation, notification);

  return (
    <Link className={classNames('notifications__link', { unread: !data.read })} to={url}>
      <Icon name="Chat" />
      <div className="notifications__item-text">
        <Typography>
          <FormattedMessage defaultMessage="New message from" id="Notifications.NewMessageFrom" />
          <b> {data.authorName} </b>
          <FormattedMessage defaultMessage="in the conversation" id="Notifications.InSubject" />{' '}
          <b>
            {data.subject && (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.subject),
                }}
              />
            )}
          </b>
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </Link>
  );
};

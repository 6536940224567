import { graphql } from 'react-relay';

export const updateSearchProfileMatchedCase = graphql`
  mutation updateSearchProfileMatchedCase_Mutation($input: UpdateSearchProfileMatchedCaseInput!) {
    updateSearchProfileMatchedCase(input: $input) {
      errors {
        message
      }
      updatedObject {
        ...matchCardFragment
      }
    }
  }
`;

/**
 * @generated SignedSource<<928bf47804aeb4964f90127d51c28575>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadPresentationDialogContent$data = {
  readonly __typename: string;
  readonly caseId?: string | null | undefined;
  readonly email?: string | null | undefined;
  readonly firstName?: string | null | undefined;
  readonly id: string;
  readonly lastName?: string | null | undefined;
  readonly phoneNumber?: string | null | undefined;
  readonly " $fragmentType": "leadPresentationDialogContent";
};
export type leadPresentationDialogContent$key = {
  readonly " $data"?: leadPresentationDialogContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadPresentationDialogContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadPresentationDialogContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "caseId",
          "storageKey": null
        }
      ],
      "type": "LeadPresentation",
      "abstractKey": null
    }
  ],
  "type": "LeadInterface",
  "abstractKey": "__isLeadInterface"
};

(node as any).hash = "5c5db8aa9cdf49a566d184c7fd2bfd28";

export default node;

import classNames from 'classnames';
import Icon, { IconProps } from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import { useNavigateToTop } from 'hooks/use-navigate-to-top';
import { AnchorHTMLAttributes, ComponentPropsWithoutRef, FC, Fragment, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './button.scss';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  anchorTag?: boolean;
  download?: boolean;
  hideText?: boolean;
  href?: string;
  iconClassname?: string;
  iconEnd?: IconProps['name'];
  iconHeight?: number;
  iconStart?: IconProps['name'];
  iconWidth?: number;
  inline?: boolean;
  isFullWidth?: boolean;
  padding?: boolean;
  selected?: boolean;
  version?: 'primary' | 'secondary' | 'link' | 'tag';
  wrapper?: boolean;
}

const Button: FC<ButtonProps> = props => {
  const {
    anchorTag,
    children,
    className,
    disabled,
    href,
    hideText,
    iconClassname,
    iconHeight,
    iconStart,
    iconWidth,
    iconEnd,
    isFullWidth,
    type,
    padding = true,
    version = 'primary',
    selected,
    download,
    inline,
    wrapper,
    ...rest
  } = props;

  const navigateToTop = useNavigateToTop();

  const navigateAndReset: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    navigateToTop(href || '');
  };

  const classList = wrapper
    ? 'reset-styles'
    : classNames('button', version, className, {
        hideText,
        isDisabled: disabled,
        isFullWidth,
        isInline: inline,
        isSelected: selected,
        padding,
      });

  const getInnerContent = () => {
    return (
      <Fragment>
        {iconStart && (
          <Icon
            className={classNames('iconStart', iconClassname)}
            height={iconHeight}
            name={iconStart}
            width={iconWidth}
          />
        )}
        <Typography className="text" tagStyle={version !== 'link' ? 'cta' : undefined}>
          {children}
        </Typography>
        {iconEnd && <Icon className="iconEnd" height={iconHeight} name={iconEnd} width={iconWidth} />}
      </Fragment>
    );
  };

  if (anchorTag && href && href?.includes('http')) {
    return (
      <a
        className={classList}
        download={download}
        href={href}
        target="blank"
        {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {getInnerContent()}
      </a>
    );
  }

  if (anchorTag && href && href?.includes('#')) {
    return (
      <HashLink
        className={classList}
        to={href}
        {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
        scroll={el => {
          // add offset to scroll
          el.scrollIntoView();
          window.scrollBy(0, -56);
        }}
        smooth
      >
        {getInnerContent()}
      </HashLink>
    );
  }

  if (anchorTag && href) {
    return (
      <Link
        className={classList}
        onClick={navigateAndReset}
        to={href}
        {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {getInnerContent()}
      </Link>
    );
  }

  return (
    <button className={classList} type={type || 'button'} {...rest}>
      {getInnerContent()}
    </button>
  );
};

export default Button;

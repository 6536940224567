/**
 * @generated SignedSource<<9616dd02b70af47cdd3c046e9feab125>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityTypeCategoryEnum = "BuyerAdvisorObjectionExtension" | "BuyerFinancialInstitutionObjectionExtension" | "CounselingCases" | "CreditAssessment" | "CustomerMeetings" | "DeedAvailableForSignatureExtension" | "DeedConditionalFinalSignatureExtension" | "DeedLatestSignatureExtension" | "DeedReportedToLandRegistrationExtension" | "Education" | "ExternalMeeting" | "FirstDepositDeadlineExtension" | "GuaranteeExtension" | "InternalMeeting" | "KeyCollection" | "Miscellaneous" | "OpenHouse" | "OpenHouseWithRegistration" | "PaidValuation" | "Private" | "ProcessClosingProtest" | "PurchaseAgreementMeetingBuyer" | "PurchaseAgreementMeetingSeller" | "RectifyCases" | "RiskAssessmentReview" | "SalesValuation" | "SecondDepositDeadlineExtension" | "SellerAdvisorObjectionExtension" | "SellerFinancialInstitutionObjectionExtension" | "Showcasing" | "SignedUpOpenHouse" | "StatusMeetingBuyer" | "StatusMeetingSeller" | "StatusReport" | "Transfer";
export type chooseActivityModal_Query$variables = Record<PropertyKey, never>;
export type chooseActivityModal_Query$data = {
  readonly viewer: {
    readonly customer: {
      readonly email: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
      readonly phone: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly propertyForSaleByOwnerAppointmentActivityTypes: ReadonlyArray<{
      readonly category: ActivityTypeCategoryEnum | null | undefined;
      readonly defaultDurationInMinutes: number | null | undefined;
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type chooseActivityModal_Query = {
  response: chooseActivityModal_Query$data;
  variables: chooseActivityModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AppointmentActivityType",
  "kind": "LinkedField",
  "name": "propertyForSaleByOwnerAppointmentActivityTypes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultDurationInMinutes",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "chooseActivityModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "chooseActivityModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59afc1c47c9a7362a92214a9a89bf3b1",
    "id": null,
    "metadata": {},
    "name": "chooseActivityModal_Query",
    "operationKind": "query",
    "text": "query chooseActivityModal_Query {\n  viewer {\n    id\n    propertyForSaleByOwnerAppointmentActivityTypes {\n      category\n      defaultDurationInMinutes\n      id\n      name\n    }\n    customer {\n      firstName\n      lastName\n      email\n      phone\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fad2e754b643215124500ace0d0b2fa";

export default node;

/**
 * @generated SignedSource<<cee941d048ed3ad460e3c301166426ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityTypeCategoryEnum = "BuyerAdvisorObjectionExtension" | "BuyerFinancialInstitutionObjectionExtension" | "CounselingCases" | "CreditAssessment" | "CustomerMeetings" | "DeedAvailableForSignatureExtension" | "DeedConditionalFinalSignatureExtension" | "DeedLatestSignatureExtension" | "DeedReportedToLandRegistrationExtension" | "Education" | "ExternalMeeting" | "FirstDepositDeadlineExtension" | "GuaranteeExtension" | "InternalMeeting" | "KeyCollection" | "Miscellaneous" | "OpenHouse" | "OpenHouseWithRegistration" | "PaidValuation" | "Private" | "ProcessClosingProtest" | "PurchaseAgreementMeetingBuyer" | "PurchaseAgreementMeetingSeller" | "RectifyCases" | "RiskAssessmentReview" | "SalesValuation" | "SecondDepositDeadlineExtension" | "SellerAdvisorObjectionExtension" | "SellerFinancialInstitutionObjectionExtension" | "Showcasing" | "SignedUpOpenHouse" | "StatusMeetingBuyer" | "StatusMeetingSeller" | "StatusReport" | "Transfer";
import { FragmentRefs } from "relay-runtime";
export type leadOpenHouseDialogContentViewer$data = {
  readonly propertyForSaleByOwnerAppointmentActivityTypes: ReadonlyArray<{
    readonly category: ActivityTypeCategoryEnum | null | undefined;
    readonly id: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "leadOpenHouseDialogContentViewer";
};
export type leadOpenHouseDialogContentViewer$key = {
  readonly " $data"?: leadOpenHouseDialogContentViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadOpenHouseDialogContentViewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadOpenHouseDialogContentViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppointmentActivityType",
      "kind": "LinkedField",
      "name": "propertyForSaleByOwnerAppointmentActivityTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e33504b87fe6164732bd5946a6cd776a";

export default node;

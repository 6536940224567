/**
 * @generated SignedSource<<47e6b07876b11953f3173be7c7a5776f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MyPageCaseRelation = "Case" | "MatchedCase" | "None" | "Offer";
import { FragmentRefs } from "relay-runtime";
export type documentSigningNotification_fragment$data = {
  readonly address: string;
  readonly baseCase: {
    readonly relationType: {
      readonly relationId: string | null | undefined;
      readonly relationType: MyPageCaseRelation;
    } | null | undefined;
  };
  readonly createdDate: any;
  readonly document: {
    readonly documentTypeName: string | null | undefined;
  };
  readonly read: boolean;
  readonly " $fragmentType": "documentSigningNotification_fragment";
};
export type documentSigningNotification_fragment$key = {
  readonly " $data"?: documentSigningNotification_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"documentSigningNotification_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "documentSigningNotification_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "baseCase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CaseRelation",
          "kind": "LinkedField",
          "name": "relationType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relationType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "document",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "documentTypeName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    }
  ],
  "type": "DocumentSigningNotification",
  "abstractKey": null
};

(node as any).hash = "fb7018116a963c0f25f7c1386fc687c6";

export default node;

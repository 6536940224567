import './documents-table.scss';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const DocumentsTable: FC<Props> = props => {
  return (
    <div className="documents-table">
      <table className="table">{props.children}</table>
    </div>
  );
};

/**
 * @generated SignedSource<<a7947d3cbb8a58d9415d0f97bac919d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPropertyType = "Cattle" | "Cellar" | "Clinic" | "Crop" | "FarmLand" | "ForestProperty" | "FreeLand" | "Garage" | "HotelOrRestaurant" | "HousingAndBusiness" | "HousingCooperative" | "Investment" | "Liberal" | "MinkAndFurAnimals" | "Office" | "Parking" | "Pigs" | "Plot" | "PoultryProduction" | "ProductionFacility" | "ShoppingCenter" | "Showroom" | "SpecialProduction" | "Store" | "WareHouse" | "WareHouseAndProduction" | "Workshop";
export type PropertyType = "AllYearRoundPlot" | "AllotmentHut" | "AllotmentPlot" | "Condo" | "FarmHouseAgriculture" | "FormerFarm" | "FreeLand" | "HobbyAgriculture" | "HousingCooperative" | "IdeelAnpartCondo" | "IdeelAnpartTerracedHouse" | "IdeelAnpartVilla" | "Liebhaveri" | "MixedHousingAndBusiness" | "PartnershipOrLimitedPartnershipCondo" | "PartnershipOrLimitedPartnershipTerracedHouse" | "PartnershipOrLimitedPartnershipVilla" | "TerracedHouse" | "TerracedHouseWithCondoStatus" | "TwoFamilyProperty" | "VacationCondo" | "VacationHousing" | "VacationPlot" | "Villa" | "VillaApartment" | "VillaWithCondoStatus";
import { FragmentRefs } from "relay-runtime";
export type matchHeroDetailsFragment$data = {
  readonly __typename: "SalesCase";
  readonly address: string | null | undefined;
  readonly baseCaseMainOffer: {
    readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentCounselingCase">;
  } | null | undefined;
  readonly businessPropertyType?: BusinessPropertyType;
  readonly city: string | null | undefined;
  readonly propertyType?: PropertyType;
  readonly zipCode: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentAgriculturalCase_baseCase" | "matchHeroContentBusinessSalesCase" | "matchHeroContentRentalCase" | "matchHeroContentSalesCase">;
  readonly " $fragmentType": "matchHeroDetailsFragment";
};
export type matchHeroDetailsFragment$key = {
  readonly " $data"?: matchHeroDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v3 = [
  (v1/*: any*/),
  {
    "alias": "businessPropertyType",
    "args": null,
    "kind": "ScalarField",
    "name": "propertyType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matchHeroContentAgriculturalCase_baseCase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matchHeroContentSalesCase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matchHeroContentRentalCase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matchHeroContentBusinessSalesCase"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseCaseMainOffer",
      "kind": "LinkedField",
      "name": "baseCaseMainOffer",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "matchHeroContentCounselingCase"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "SalesCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "RectifyCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "CounselingPropertyCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "RentalCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "CounselingCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "BusinessSalesCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "BusinessRentalCase",
      "abstractKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};
})();

(node as any).hash = "0a0f08bbe58ce6492c7e53a0e4d030df";

export default node;

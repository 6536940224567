/**
 * @generated SignedSource<<6b796a5cca627a31b559298a0d28ce33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStatusTypeEnum = "ConvertedToCustomer" | "InProgress" | "MarkAsSpam" | "NotConvertedToCustomer" | "Processed" | "Recieved";
import { FragmentRefs } from "relay-runtime";
export type leadListItemFragment$data = {
  readonly __typename: string;
  readonly caseAddress?: string | null | undefined;
  readonly caseZipAndCity?: string | null | undefined;
  readonly comment?: string | null | undefined;
  readonly email: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly openHouseStartTime?: string | null | undefined;
  readonly phoneNumber: string | null | undefined;
  readonly presentationDate?: any | null | undefined;
  readonly statusType: LeadStatusTypeEnum | null | undefined;
  readonly type: {
    readonly displayName: string | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"leadDialogContent">;
  readonly " $fragmentType": "leadListItemFragment";
};
export type leadListItemFragment$key = {
  readonly " $data"?: leadListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caseAddress",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caseZipAndCity",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "presentationDate",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "LeadPresentation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "openHouseStartTime",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "LeadOpenHouse",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "LeadSalesMaterialWithContact",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadTypeGraphType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadDialogContent"
    }
  ],
  "type": "LeadInterface",
  "abstractKey": "__isLeadInterface"
};
})();

(node as any).hash = "48744c1a46b0e2efa46f3a0f48fec76d";

export default node;

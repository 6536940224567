import classNames from 'classnames';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'tools/string-formatters';
import { Link } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';
import { leadNotification_fragment$key } from '__generated__/leadNotification_fragment.graphql';
import { LeadStatusTypeEnum } from 'Enums';

const leadNotification = graphql`
  fragment leadNotification_fragment on LeadNotification {
    read
    lead {
      statusType
      type {
        displayName
      }
      receivedDate
    }
  }
`;

type Props = {
  notification: leadNotification_fragment$key;
};

export const LeadNotification = ({ notification }: Props) => {
  const data = useFragment<leadNotification_fragment$key>(leadNotification, notification);

  const tabString = data.lead.statusType !== LeadStatusTypeEnum.Processed ? 'newLeads' : 'processedLeads';

  return (
    <Link className={classNames('notifications__link', { unread: !data.read })} to={`/sale?tab=${tabString}`}>
      <Icon name="Lead" />
      <div className="notifications__item-text">
        <Typography>
          <FormattedMessage defaultMessage="You have a new lead:" id="Notifications.NewLeadNotifications" />
          <b> {data.lead.type?.displayName}</b>
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.lead.receivedDate)}
        </Typography>
      </div>
    </Link>
  );
};

import { AppTheme } from 'configs/app-theme-defaults';
import React, { useContext } from 'react';

const ThemeContext = React.createContext<AppTheme | null>(null);

const useTheme = () => {
  const theme = useContext(ThemeContext);

  return theme;
};

export { ThemeContext, useTheme };

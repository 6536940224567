import { contactSellerModal_customerIdQuery } from '__generated__/contactSellerModal_customerIdQuery.graphql';
import Modal from 'components/molecules/modal/modal';
import { SelectOption } from 'components/molecules/select/select';
import ContactSellerForm, { ContactSellerInputs } from 'components/organisms/contact-seller-form/contact-seller-form';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConnectionHandler, graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { createThreadMutation } from 'services/relay/mutations/createThread';
import './contact-seller-modal.scss';
import { useMixPanel } from 'hooks/useMixPanel';
import { MixPanelEvents } from 'app/mixpanel/mixpanelEvents';

export interface ContactSellerModalProps {
  activityOptions?: SelectOption[];
  caseOptions?: SelectOption[];
  isOpen: boolean;
  onClose: () => void;
  onSelectCase?: (caseId: string) => void;
  recipientOptions: SelectOption[];
}

const customerIdQuery = graphql`
  query contactSellerModal_customerIdQuery {
    viewer {
      customer {
        id
      }
    }
  }
`;

const ContactSellerModal: FC<ContactSellerModalProps> = ({
  onClose,
  isOpen,
  activityOptions,
  caseOptions,
  recipientOptions,
  onSelectCase,
}) => {
  const [commit] = useMutation(createThreadMutation);
  const customerIdData = useLazyLoadQuery<contactSellerModal_customerIdQuery>(customerIdQuery, {});
  const { track } = useMixPanel();

  const connectionID = ConnectionHandler.getConnectionID(
    customerIdData.viewer?.customer?.id || '',
    'messagesInbox__threadConnection',
  );

  const handleSubmitContactForm = (data: ContactSellerInputs) => {
    const { files, initialMessage, ...rest } = data;
    const uploadables: { [key: string]: File } = {};
    if (files) {
      files.forEach(file => {
        uploadables[file.id + file.name] = file;
      });
    }

    const haveFiles = files && files.length > 0;

    commit({
      updater: store => {
        const thread = store.get(connectionID);
        if (thread) {
          const value = thread.getValue('totalCount');
          thread.setValue(Number(value) + 1, 'totalCount');
        }
      },
      uploadables: haveFiles ? uploadables : undefined,
      variables: {
        connections: [connectionID],
        input: {
          initialMessage: initialMessage.replace(/\n\r?/g, '<br />'),
          ...rest,
        },
      },
    });
    track('Send_Message' as keyof MixPanelEvents, { sendFrom: 'DIALOG' });
  };

  return isOpen ? (
    <Modal
      closeHandler={onClose}
      headline={<FormattedMessage defaultMessage="Contact the mediator of the case" id="ContactSellerForm.title" />}
    >
      <ContactSellerForm
        activityOptions={activityOptions}
        caseOptions={caseOptions}
        onCloseForm={onClose}
        onSelectCase={caseId => onSelectCase && onSelectCase(caseId)}
        onSubmit={data => handleSubmitContactForm(data)}
        recipientOptions={recipientOptions}
      />
    </Modal>
  ) : null;
};

export default ContactSellerModal;

import { AvatarVersion } from 'components/molecules/avatar/avatar';
import { format } from 'date-fns';

const browserLocale = 'da-DK';

export function formatNumber(value?: number | string, unit = 'kr.', addedPlus = false) {
  if (value == 0) {
    return '0';
  }

  if (!value) {
    return '';
  }

  const numberVal = Number(value);

  const formattedNumber = Number(numberVal).toLocaleString(browserLocale);

  return formattedNumber + (addedPlus ? '+' : '') + (unit ? ` ${unit}` : '');
}

export function formatDate(date: Date | string | null) {
  if (!date) return;
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  return format(date, 'dd.MM.yyyy');
}

export function getMonthName(date: Date | string | null, locale: string) {
  if (!date) return;

  let month;

  const monthDA = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

  const monthEN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  switch (locale) {
    case 'da':
      month = monthDA;
      break;
    case 'en':
      month = monthEN;
      break;
    default:
      month = monthDA;
  }

  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  return month[date.getMonth()];
}

export const formatTime = (date?: Date | string | null, typeOfFormat = 'H:mm') => {
  if (!date) return;
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  return format(date, typeOfFormat);
};

export function formatQueryDate(date?: Date | string) {
  if (!date) return;
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }

  return format(date, 'YYY-MM-dd');
}

export const phoneRegExp = /^[0-9]{8}$/;

export const getInitialsFromFullname = (fullName?: string | null) => {
  if (!fullName) return;
  const names = fullName?.split(' ');
  if (!names) return;
  return names[0].charAt(0) + names[names.length - 1].charAt(0);
};

export const formatMultiPolygon = (polygon: string) =>
  polygon.replace('POLYGON ', 'MULTIPOLYGON (').replace(/(.*)/, '$1)');

export const getDatesFromRange = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = startDate;
  const addDays = (currentDate: Date, days: number) => {
    const date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const formatAppointmentDate = (startDate?: string | null, endDate?: string | null) => {
  if (startDate && endDate) {
    const timeStart = formatTime(startDate);
    const timeEnd = formatTime(endDate);
    const dayStart = formatDate(startDate);
    const dayEnd = formatDate(endDate);

    return `d. ${dayStart !== dayEnd ? dayStart : ''} ${dayEnd} kl. ${timeStart} - ${timeEnd} `;
  }

  if (!startDate && endDate) {
    const timeEnd = formatTime(endDate);
    const dayEnd = formatDate(endDate);

    return `${dayEnd} ${timeEnd}`;
  }

  if (!endDate && startDate) {
    const timeStart = formatTime(startDate);
    const dayStart = formatDate(startDate);
    return `${dayStart} ${timeStart}`;
  }

  return '';
};

export const getAvatarVersion = (name: string, chatParticipants: string[]) => {
  const index = chatParticipants?.indexOf(name) + 1;
  return Math.abs(index % 10) as AvatarVersion; // generate a number between 0 and 9 always
};

export const getRelatedLink = (
  relationType?: {
    relationId?: string | null;
    relationType?: string | null;
  } | null,
  hash?: string,
) => {
  const anchor = hash || '';

  if (relationType) {
    switch (relationType.relationType) {
      case 'Offer':
        return '/case/' + relationType.relationId + anchor;
      case 'MatchedCase':
        return '/match/' + relationType.relationId + anchor;
      case 'Case':
        return '/activity-case/' + relationType.relationId + anchor;
      default:
        return '';
    }
  }
  return '';
};

/**
 * @generated SignedSource<<070eb81eb2110fd2fd125da720faa9a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityTypeCategoryEnum = "BuyerAdvisorObjectionExtension" | "BuyerFinancialInstitutionObjectionExtension" | "CounselingCases" | "CreditAssessment" | "CustomerMeetings" | "DeedAvailableForSignatureExtension" | "DeedConditionalFinalSignatureExtension" | "DeedLatestSignatureExtension" | "DeedReportedToLandRegistrationExtension" | "Education" | "ExternalMeeting" | "FirstDepositDeadlineExtension" | "GuaranteeExtension" | "InternalMeeting" | "KeyCollection" | "Miscellaneous" | "OpenHouse" | "OpenHouseWithRegistration" | "PaidValuation" | "Private" | "ProcessClosingProtest" | "PurchaseAgreementMeetingBuyer" | "PurchaseAgreementMeetingSeller" | "RectifyCases" | "RiskAssessmentReview" | "SalesValuation" | "SecondDepositDeadlineExtension" | "SellerAdvisorObjectionExtension" | "SellerFinancialInstitutionObjectionExtension" | "Showcasing" | "SignedUpOpenHouse" | "StatusMeetingBuyer" | "StatusMeetingSeller" | "StatusReport" | "Transfer";
import { FragmentRefs } from "relay-runtime";
export type leadPresentationDialogContentViewer$data = {
  readonly propertyForSaleByOwnerAppointmentActivityTypes: ReadonlyArray<{
    readonly category: ActivityTypeCategoryEnum | null | undefined;
    readonly id: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "leadPresentationDialogContentViewer";
};
export type leadPresentationDialogContentViewer$key = {
  readonly " $data"?: leadPresentationDialogContentViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadPresentationDialogContentViewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadPresentationDialogContentViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppointmentActivityType",
      "kind": "LinkedField",
      "name": "propertyForSaleByOwnerAppointmentActivityTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c45e9b4d9675286821318f95d83fcca3";

export default node;

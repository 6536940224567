import { graphql } from 'react-relay';

export const markNotificationAsReadMutation = graphql`
  mutation markNotificationAsRead_Mutation($input: ID!) {
    markNotificationAsRead(notificationId: $input) {
      updatedObject {
        id
        read
      }
    }
  }
`;

import classNames from 'classnames';
import getVersion from 'utils/avatarVersions';
import './avatar.scss';
import { FC } from 'react';

export type AvatarVersion = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface AvatarProps {
  backgroundColor?: string;
  customVersion?: boolean;
  initials?: string;
  size?: 'lg' | 'sm' | 'xl';
  textColor?: string;
  version?: AvatarVersion;
}

const Avatar: FC<AvatarProps> = ({ initials = '', customVersion, size, version = 1, backgroundColor, textColor }) => {
  const avatarVersion = getVersion(version);

  return (
    <div
      className={classNames('avatar', {
        'avatar--extralarge': size === 'xl',
        'avatar--large': size === 'lg',
      })}
      style={customVersion ? { backgroundColor: backgroundColor, color: textColor } : avatarVersion}
    >
      <span className="avatar__initials">{initials}</span>
    </div>
  );
};

export default Avatar;

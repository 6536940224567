/**
 * @generated SignedSource<<1e727ff17607d601ad5de7ece115e25d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStatusTypeEnum = "ConvertedToCustomer" | "InProgress" | "MarkAsSpam" | "NotConvertedToCustomer" | "Processed" | "Recieved";
import { FragmentRefs } from "relay-runtime";
export type saleByOwnerGridFragment$data = {
  readonly myPageLeads: ReadonlyArray<{
    readonly __typename: string;
    readonly id: string;
    readonly statusType: LeadStatusTypeEnum | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"leadDialogContent" | "leadListItemFragment">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"leadDialogContentViewer">;
  readonly " $fragmentType": "saleByOwnerGridFragment";
};
export type saleByOwnerGridFragment$key = {
  readonly " $data"?: saleByOwnerGridFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"saleByOwnerGridFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "saleByOwnerGridFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "myPageLeads",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "leadDialogContent"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "leadListItemFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadDialogContentViewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "3e0c530d6809a37700d792b711fd438d";

export default node;

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = { activity: 'openHouse' | 'showCasing' | '' };

export const HeadLine: FC<Props> = ({ activity }) => {
  if (activity === 'openHouse') {
    return <FormattedMessage defaultMessage="Create open house" id="selfsale.CreateOpenHouse" />;
  } else if (activity === 'showCasing') {
    return <FormattedMessage defaultMessage="Create showing casing" id="selfsale.showingCasing" />;
  }

  return <FormattedMessage defaultMessage="Choose activity" id="selfsale.chooseActivity" />;
};

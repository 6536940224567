import { contactCard_employee$key } from '__generated__/contactCard_employee.graphql';
import Typography from 'components/atoms/typography/typography';
import Avatar from 'components/molecules/avatar/avatar';
import Block from 'components/utilities/block/block';
import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { getInitialsFromFullname } from 'tools/string-formatters';
import './contact-card.scss';

export interface ContactCardProps {
  fragment: contactCard_employee$key | null;
}

const contactCardFragment = graphql`
  fragment contactCard_employee on Employee {
    lastName
    firstName
    fullName
    phone
    employeePrimaryImageUrl
    title
    offerShop {
      name
      phone
      city
      address
    }
  }
`;

const ContactCard: FC<ContactCardProps> = props => {
  const data = useFragment(contactCardFragment, props.fragment);

  const initials = getInitialsFromFullname(
    `${data?.firstName ? data.firstName : ''}  ${data?.lastName ? data.lastName : ''}`,
  );

  return (
    <Block backgroundColor="white" boxShadow className="contact-card">
      <Block>
        {data?.employeePrimaryImageUrl ? (
          <img className="contact-card__image" src={data.employeePrimaryImageUrl} />
        ) : (
          <Avatar
            backgroundColor="var(--color-brand)"
            customVersion
            initials={initials}
            size="xl"
            textColor="var(--color-text-white)"
          />
        )}
      </Block>
      <Block>
        <Block className="contact-card__name">
          <Typography tag="h3" tagStyle="headlineMedium">
            {data?.fullName}
          </Typography>
          {data?.title && (
            <Typography subjectColor="gray" tag="p" tagStyle="bodySmall">
              {data?.title}
            </Typography>
          )}
        </Block>
        <Block className="contact-card__address">
          <Typography tag="p" tagStyle="bodyMedium">
            {data?.offerShop?.name || ''}, <br /> {data?.offerShop?.address || ''}, {data?.offerShop?.city || ''}
          </Typography>
        </Block>
        <Block className="contact-card__phone">
          <Typography tag="p" tagStyle="headlineMedium">
            {data?.phone ? data.phone : data?.offerShop?.phone}
          </Typography>
        </Block>
      </Block>
    </Block>
  );
};

export default ContactCard;

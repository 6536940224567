import { LeadOpenHouse, LeadPresentation, LeadSalesMaterialWithContact } from 'Types';
import { LeadPresentationDialogContent } from 'components/molecules/sale-by-owner/dialog/lead-presentation-dialog-content';
import { LeadContactDialogContent } from 'components/molecules/sale-by-owner/dialog/lead-contact-dialog-content';
import { FormattedMessage } from 'react-intl';
import { LeadOpenHouseDialogContent } from 'components/molecules/sale-by-owner/dialog/lead-open-house-dialog-content';
import { graphql, useFragment } from 'react-relay';
import { leadDialogContent$key } from '__generated__/leadDialogContent.graphql';
import { leadDialogContentViewer$key } from '__generated__/leadDialogContentViewer.graphql';

type Props = {
  fragment: leadDialogContent$key;
  handleOnClose: () => void;
  viewerFragment: leadDialogContentViewer$key;
};

export const LeadDialogContent = ({ fragment, handleOnClose, viewerFragment }: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadDialogContent on LeadInterface {
        __typename
        ...leadContactDialogContentFragment
        ...leadPresentationDialogContent
        ...leadOpenHouseDialogContent
      }
    `,
    fragment,
  );

  const viewer = useFragment(
    graphql`
      fragment leadDialogContentViewer on Viewer {
        ...leadPresentationDialogContentViewer
        ...leadOpenHouseDialogContentViewer
      }
    `,
    viewerFragment,
  );

  switch (lead?.__typename) {
    case LeadOpenHouse:
      return <LeadOpenHouseDialogContent fragment={lead} onClose={handleOnClose} viewerFragment={viewer} />;
    case LeadPresentation:
      return <LeadPresentationDialogContent fragment={lead} onClose={handleOnClose} viewerFragment={viewer} />;
    case LeadSalesMaterialWithContact:
      return <LeadContactDialogContent fragment={lead} onClose={handleOnClose} />;
    default:
      return (
        <div>
          <h4>
            <FormattedMessage
              id={'saleByOwner.unknownLeadType'}
              defaultMessage={'An error has occurred, this lead type is unknown. Contact your employed broker'}
            />
          </h4>
        </div>
      );
  }
};

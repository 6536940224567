import { graphql, useFragment } from 'react-relay';
import { saleByOwnerGridFragment$key } from '__generated__/saleByOwnerGridFragment.graphql';
import Block from 'components/utilities/block/block';
import Row from 'components/utilities/row/row';
import Column from 'components/utilities/column/column';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import './sale-by-owner.scss';
import { useList } from 'hooks/useList';
import { FC, Fragment, useMemo } from 'react';
import { LeadListItem } from 'components/molecules/sale-by-owner/lead-list-item';
import EmptyModule from '../empty-module/empty-module';
import { LeadStatusTypeEnum } from 'Enums';

type Props = {
  viewerFragment: saleByOwnerGridFragment$key;
};

const saleByOwnerProcessedGridFragment = graphql`
  fragment saleByOwnerProcessedGridFragment on Viewer {
    myPageLeads {
      statusType
      id
      __typename
      ...leadListItemFragment
    }
  }
`;

export const SaleByOwnerProcessedGrid: FC<Props> = ({ viewerFragment }) => {
  const data = useFragment(saleByOwnerProcessedGridFragment, viewerFragment);
  const mypageLeads = useList(data.myPageLeads ?? []);

  const leads = useMemo(() => {
    return mypageLeads.filter(lead => lead.statusType === LeadStatusTypeEnum.Processed);
  }, [mypageLeads]);

  return (
    <Fragment>
      <Block className="match-cards-list">
        <Row>
          <Column>
            <Typography className="match-cards-list__heading" tag="h2" tagStyle="headlineMedium">
              <FormattedMessage
                defaultMessage={'Your leads ({count})'}
                id={'saleByOwner.leads'}
                values={{ count: leads.length }}
              />
            </Typography>
          </Column>
        </Row>
        {leads.length > 0 ? (
          <div className={'grid'}>
            {leads.map(lead => (
              <LeadListItem key={lead.id} fragment={lead} disabled={true} />
            ))}
          </div>
        ) : (
          <EmptyModule
            iconName="Lead"
            message={
              <FormattedMessage defaultMessage="You have no processed leads" id="saleByOwner.NoLeadProcessedLeads" />
            }
          />
        )}
      </Block>
    </Fragment>
  );
};

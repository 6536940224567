import classNames from 'classnames';
import Typography, { TypographyProps } from 'components/atoms/typography/typography';

import './tag.scss';
import { FC } from 'react';

const Tag: FC<TypographyProps> = props => {
  const { children, className, ...rest } = props;
  return (
    <Typography className={classNames('tag-component', className)} {...rest} tagStyle="bodyMedium">
      {children}
    </Typography>
  );
};

export default Tag;

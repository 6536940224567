import classNames from 'classnames';
import Column from 'components/utilities/column/column';
import Container from 'components/utilities/container/container';
import ModuleWrapper from 'components/utilities/module-wrapper/module-wrapper';
import Row from 'components/utilities/row/row';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './tab-component.scss';

export interface TabComponentProps {
  activeTab: string;
  tabContent: { item: ReactNode; key: string }[];
  tabNav: { isDisabled?: boolean; item: ReactNode; key: string }[];
}

const TabComponent: FC<TabComponentProps> = props => {
  const { activeTab, tabContent, tabNav } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [visibleTab, setVisibleTab] = useState(activeTab);

  useEffect(() => {
    const tabFromParams = searchParams.get('tab');
    if (tabFromParams) {
      setVisibleTab(tabFromParams);
    }
  }, [searchParams]);

  useEffect(() => {
    document.querySelector('.main-navigation')?.classList.add('no-shadow');

    return () => {
      document.querySelector('.main-navigation')?.classList.remove('no-shadow');
    };
  }, []);

  const handleOnClick = (key: string) => {
    setVisibleTab(key);
    navigate({
      search: '?tab=' + key,
    });
  };

  return (
    <div className="tab-component">
      <ModuleWrapper backgroundColor="white">
        <Container paddingBottomReset paddingTopReset>
          <Row>
            <Column>
              <div className="tab-component__nav">
                {tabNav.map((item, index) => (
                  <button
                    key={index}
                    aria-controls={`tab-section-${index}`}
                    className={classNames('tab-component__nav-item', {
                      'is-active': visibleTab === item.key,
                      'is-disabled': item.isDisabled,
                    })}
                    disabled={item.isDisabled}
                    id={`tab-nav-id-${index}`}
                    onClick={() => handleOnClick(item.key)}
                    type="button"
                  >
                    {item.item}
                  </button>
                ))}
              </div>
            </Column>
          </Row>
        </Container>
      </ModuleWrapper>
      <div className="tab-component__content">
        {tabContent.map(component => {
          if (component.key !== visibleTab) return null;
          return (
            <div
              key={component.key}
              className={`tab-component__content-item ${visibleTab === component.key && 'is-active'}`}
              id={`tab-section-${component.key}`}
            >
              {component.item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabComponent;

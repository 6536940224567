import { AppTheme } from 'configs/app-theme-defaults';

export const appSettingsDefaults = {
  ENDPOINT_GRAPHQL: 'https://stagent.mindworking.eu/api/mypage2/graphql',
  ENDPOINT_SIGNALR: 'https://stagent.mindworking.eu/api/realtime/mypage',
  ENDPOINT_LOGPROXY: 'https://stagent.mindworking.eu/api/logproxy/',
  HTML_DOCUMENT_TITLE: '...',
  THEME_URL: '/theme/test-theme.json',
  AUTH_CLIENT_ID: 'mw-mypage2',
  AUTH_REALM: 'stagent-customer',
  AUTH_URL: 'https://keycloaktest.mindworking.eu/auth',
  CLIENT_VERSION: '',
  CI_COMMIT_REF_NAME: '',
  CI_COMMIT_SHA: '',
  CI_COMMIT_TAG: '',
  LOCALE: 'da',
  TRANSLATION_VARIANTS: [],
  IS_SINGLE_FEATURE_TOGGLE_ACTIVE: false,
  PHONE_NUMBER_IS_MANDATORY: true,
  GOOGLE_MAPS_API_KEY: '',
  MIXPANEL_TOKEN: 'd99e9fa9209415e49a87a2c009236df1',
};

export interface AppSettings {
  settings: typeof appSettingsDefaults;
  theme: AppTheme;
}

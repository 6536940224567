import Icon from 'components/atoms/icon/icon';
import { ComponentPropsWithoutRef, FC } from 'react';
import './select.scss';

export interface SelectProps extends ComponentPropsWithoutRef<'select'> {
  max: number;
  options: number[];
}

const Select: FC<SelectProps> = ({ options, max, ...props }) => {
  return (
    <div className="select-container">
      <select className="select" defaultValue={options?.[0]} {...props}>
        {options.map((option, i) => (
          <option key={i}>{option}</option>
        ))}
        <option value={max}>Alle</option>
      </select>
      <Icon className="arrow" name="ArrowDown" />
    </div>
  );
};

export default Select;

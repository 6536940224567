import Icon from 'components/atoms/icon/icon';
import { formatDate } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { searchProfileMatchNotification_fragment$key } from '__generated__/searchProfileMatchNotification_fragment.graphql';

export interface SearchProfileMatchNotificationProps {
  notification: searchProfileMatchNotification_fragment$key;
}

const searchProfileMatchNotification = graphql`
  fragment searchProfileMatchNotification_fragment on SearchProfileMatchNotification {
    read
    searchProfile {
      id
      name
    }
    createdDate
  }
`;

export const SearchProfileMatchNotification: FC<SearchProfileMatchNotificationProps> = ({ notification }) => {
  const data = useFragment<searchProfileMatchNotification_fragment$key>(searchProfileMatchNotification, notification);

  return (
    <Link
      className={classNames('notifications__link', { unread: !data.read })}
      to={`/match?id=${data.searchProfile?.id}`}
    >
      <Icon name="Bell" />
      <div className="notifications__item-text">
        <Typography>
          <FormattedMessage
            defaultMessage="You have a new match on your search profile:"
            id="Notifications.NewMatchFromSearchProfile"
          />
          <b> {data.searchProfile?.name}</b>
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </Link>
  );
};

import { contactModule_baseCase$key } from '__generated__/contactModule_baseCase.graphql';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import ContactCard from 'components/molecules/contact-card/contact-card';
import { SelectOption } from 'components/molecules/select/select';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Container from 'components/utilities/container/container';
import Row from 'components/utilities/row/row';
import { useList } from 'hooks/useList';
import { FC, Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import ContactSellerModal from '../contact-seller-modal/contact-seller-modal';
import './contact-module.scss';

export interface ContactModuleProps {
  fragment: contactModule_baseCase$key | null | undefined;
  isSeller?: boolean;
}

const contactModuleFragment = graphql`
  fragment contactModule_baseCase on BaseCase {
    id
    address
    baseCaseMainOffer {
      employeeOffer {
        employee {
          id
          fullName
          ...contactCard_employee
          offerShop {
            name
          }
        }
      }
    }
  }
`;

const ContactModule: FC<ContactModuleProps> = props => {
  const data = useFragment(contactModuleFragment, props.fragment || null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const employeeOffers = useList(data?.baseCaseMainOffer?.employeeOffer);
  const firstEmployeeOffer = employeeOffers[0] ? employeeOffers[0] : null;
  const employee = firstEmployeeOffer?.employee;

  if (!employee) {
    return <Fragment />;
  }

  const getRecipientOptions = () => {
    const recipients: SelectOption[] = [];

    employeeOffers.forEach(item => {
      recipients.push({
        name: item?.employee?.fullName || '',
        value: item?.employee?.id || '',
      });
    });

    return recipients;
  };

  return (
    <Container className="contact-module">
      <Row alignCenter className="contact-module-wrapper" spaceBetween>
        <Column width={{ tablet: 4 }}>
          <ContactCard fragment={employee} />
        </Column>

        <Column width={{ tablet: 3 }}>
          <Block className="contact-module-text">
            <Typography tag="h2" tagStyle="headlineMedium">
              <FormattedMessage defaultMessage="Contact us" id="ContactModule.Title" />
            </Typography>

            <Typography tag="p" tagStyle="bodyMedium">
              {props.isSeller ? (
                <FormattedMessage
                  defaultMessage="If you have any questions or need to contact {shopName}"
                  id="ContactModule.ParagraphSeller"
                  values={{
                    shopName: employee.offerShop?.name,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Do you have questions or would you like to know more about this property? Send us a message."
                  id="ContactModule.Paragraph"
                />
              )}
            </Typography>
          </Block>

          <Button onClick={() => setIsModalOpen(true)}>
            <FormattedMessage defaultMessage="Contact broker" id="ContactModule.CTA" />
          </Button>
        </Column>
      </Row>
      <ContactSellerModal
        caseOptions={[
          {
            name: data?.address || '',
            selected: true,
            value: data?.id || '',
          },
        ]}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        recipientOptions={getRecipientOptions()}
      />
    </Container>
  );
};

export default ContactModule;

import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { useTheme } from 'context/ThemeContext';
import { FormattedMessage } from 'react-intl';
import './footer.scss';

const Footer = () => {
  const theme = useTheme();

  const logoUrl = theme?.logoUrl;
  const logoAlt = theme?.logoAlt;

  return (
    <footer>
      <Block backgroundColor="white" className="footer">
        <Block className="footer__inner" flex>
          <Typography className="footer__inner--brandname" tagStyle="bodySmall">
            {theme?.footerText ? theme?.footerText : `© ${theme?.brokerageName}`}
          </Typography>
          <picture className="footer__inner--logo">
            <source srcSet={logoUrl ?? '/images/mindworking-logo.svg'} />
            <img alt={logoAlt ?? 'Mindworking'} src={logoUrl ?? '/images/mindworking-logo.jpg'} />
          </picture>
          <Button anchorTag className="footer__inner--privacyLinkButton" href={theme?.privacyPolicyLink} version="link">
            <FormattedMessage defaultMessage="Cookie - & privacy policy" id="Footer.privacyPolicyLink" />
          </Button>
        </Block>
      </Block>
    </footer>
  );
};

export default Footer;

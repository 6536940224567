import * as Icons from 'assets/icons/icons';
import classNames from 'classnames';
import './icon.scss';
import { FC, HTMLAttributes } from 'react';

export type IconName = keyof typeof Icons;

export type Fill =
  | 'CurrentColor'
  | 'None'
  | 'var(--color-notification)'
  | 'var(--color-text-grey)'
  | 'var(--color-brand)'
  | 'var(--color-success)';

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  fill?: Fill;
  height?: number | 'auto';
  name: IconName;
  noWrapper?: boolean;
  width?: number | 'auto';
}

const Icon: FC<IconProps> = ({
  className,
  name,
  height = 24,
  width = 24,
  fill = 'CurrentColor',
  noWrapper,
  ...rest
}) => {
  const IconComponent = Icons[name];

  if (noWrapper) {
    return (
      <IconComponent className="icon" fill={fill} height={height} preserveAspectRatio="xMidYMid meet" width={width} />
    );
  }

  return (
    <div {...rest} className={classNames('icon-component', className)}>
      <IconComponent className="icon" fill={fill} height={height} preserveAspectRatio="xMidYMid meet" width={width} />
    </div>
  );
};

export default Icon;

import { FormattedMessage } from 'react-intl';
import Button from 'components/atoms/button/button';
import { LeadStatusTypeEnum } from 'Enums';
import { useUpdate } from 'hooks/relay/useUpdate';
import { saleByOwnerMutation } from 'components/molecules/sale-by-owner/sale-by-owner-mutation';
import { graphql, useFragment } from 'react-relay';
import { leadContactDialogContentFragment$key } from '__generated__/leadContactDialogContentFragment.graphql';
import { LeadInterface } from 'Types';

type Props = {
  fragment: leadContactDialogContentFragment$key;
  onClose: () => void;
};

const leadContactDialogContentFragment = graphql`
  fragment leadContactDialogContentFragment on LeadInterface {
    id
    phoneNumber
    email
  }
`;

export const LeadContactDialogContent = ({ fragment, onClose }: Props) => {
  const lead = useFragment(leadContactDialogContentFragment, fragment);
  const phoneNumber = lead.phoneNumber;
  const email = lead.email;

  const [updateLead, { loading: updateLeadLoading }] = useUpdate(saleByOwnerMutation, {
    __typename: LeadInterface,
  });

  const handleProcessLead = async () => {
    await updateLead({
      variables: {
        input: {
          leadIdGlobal: lead.id,
          statusType: LeadStatusTypeEnum.Processed,
        },
      },
    });
    onClose();
  };

  return (
    <div>
      <h3>
        <FormattedMessage id={'LeadContactDialogContent.contactBuyer'} defaultMessage={'Contact buyer'} />
      </h3>
      <p>
        <FormattedMessage
          id={'LeadContactDialogContent.contactWays'}
          defaultMessage={
            'Choose a way to contact the buyer. When you have made contact with the buyer, you can end the lead by pressing the button.'
          }
        />
      </p>
      {phoneNumber && (
        <div>
          <p>
            <a href={'tel:' + phoneNumber}>
              <FormattedMessage id={'LeadContactDialogContent.call'} defaultMessage={'Call'} />: {phoneNumber}
            </a>
          </p>
          <p>
            <a href={'sms:' + phoneNumber}>
              <FormattedMessage id={'LeadContactDialogContent.sms'} defaultMessage={'Send sms'} />: {phoneNumber}
            </a>
          </p>
        </div>
      )}

      {email && (
        <p>
          <a href={'mailto:' + phoneNumber}>
            <FormattedMessage id={'LeadContactDialogContent.email'} defaultMessage={'Send email'} />: {email}
          </a>
        </p>
      )}
      <div className={'dialog-button'}>
        <Button onClick={handleProcessLead} disabled={updateLeadLoading}>
          {updateLeadLoading ? (
            <FormattedMessage id="Default.Saving" defaultMessage="Saving" />
          ) : (
            <FormattedMessage id={'LeadContactDialogContent.processLead'} defaultMessage={'Process lead'} />
          )}{' '}
        </Button>
      </div>
    </div>
  );
};

/**
 * @generated SignedSource<<cb1b36203d780195d46a168ae3a27f61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type bookShowcasingModal_Query$variables = Record<PropertyKey, never>;
export type bookShowcasingModal_Query$data = {
  readonly viewer: {
    readonly customer: {
      readonly email: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly isCompany: boolean;
      readonly lastName: string | null | undefined;
      readonly phone: string | null | undefined;
    } | null | undefined;
    readonly leadTypeFromEnum: {
      readonly currentConsent: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type bookShowcasingModal_Query = {
  response: bookShowcasingModal_Query$data;
  variables: bookShowcasingModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCompany",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "enum",
    "value": "Presentation"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadConsentGraphType",
  "kind": "LinkedField",
  "name": "currentConsent",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bookShowcasingModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "LeadTypeGraphType",
            "kind": "LinkedField",
            "name": "leadTypeFromEnum",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": "leadTypeFromEnum(enum:\"Presentation\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bookShowcasingModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "LeadTypeGraphType",
            "kind": "LinkedField",
            "name": "leadTypeFromEnum",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "leadTypeFromEnum(enum:\"Presentation\")"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5423e42d0a62f43b4fe6ac3bc96016b",
    "id": null,
    "metadata": {},
    "name": "bookShowcasingModal_Query",
    "operationKind": "query",
    "text": "query bookShowcasingModal_Query {\n  viewer {\n    customer {\n      isCompany\n      firstName\n      lastName\n      email\n      phone\n      id\n    }\n    leadTypeFromEnum(enum: Presentation) {\n      currentConsent {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7a70be05557437f56256e9795557939";

export default node;

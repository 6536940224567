import { caseGallerySwiper_mediaDataConnection$key } from '__generated__/caseGallerySwiper_mediaDataConnection.graphql';
import GalleryNavigation from 'components/molecules/gallery-navigation/gallery-navigation';
import SwiperNavigation from 'components/molecules/swiper-navigation/swiper-navigation';
import Container from 'components/utilities/container/container';
import { useNodes } from 'hooks/useNodes';
import { FC, useMemo, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import SwiperCore, { Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './case-gallery-swiper.scss';
import { Video } from 'components/molecules/images-swiper/video';
import { ImageElement } from 'components/molecules/images-swiper/image';

const caseGalleryFragment = graphql`
  fragment caseGallerySwiper_mediaDataConnection on MediaDataConnection {
    edges {
      node {
        id
        resourceUrl(resourceType: Asset)
        mediaType {
          name
        }
      }
    }
  }
`;

export interface CaseGallerySwiperProps {
  mediaDataConnection: caseGallerySwiper_mediaDataConnection$key | null;
  onSelectFullscreen: (imageIndex: number) => void;
  onSelectMap: () => void;
  slideToImage: number;
}

export const CaseGallerySwiper: FC<CaseGallerySwiperProps> = props => {
  const { onSelectFullscreen, onSelectMap, slideToImage } = props;
  const mediaDataConnection = useFragment(caseGalleryFragment, props.mediaDataConnection);

  const [firstSwiper, setFirstSwiper] = useState<SwiperCore>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperCore>();
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const gallerySwiperRef = useRef<HTMLDivElement>(null);

  const images = useNodes(mediaDataConnection?.edges);
  const hasPlan = useMemo(() => images.some(image => image.mediaType?.name === 'Plantegning'), [images]);
  const hasVideo = useMemo(() => images.some(image => image.mediaType?.name === 'Video'), [images]);

  const handleSelectPlan = () => {
    const index = images.findIndex(image => image.mediaType?.name === 'Plantegning');
    firstSwiper?.slideTo(index);
  };

  const handleSelectVideo = () => {
    const index = images.findIndex(image => image.mediaType?.name === 'Video');
    firstSwiper?.slideTo(index);
  };

  return (
    <Container className="gallery-swiper__container">
      <div className="gallery-swiper" ref={gallerySwiperRef}>
        <Swiper
          centeredSlides
          className="gallery-swiper__images swiper-container-1"
          controller={{ control: secondSwiper }}
          initialSlide={slideToImage || 0}
          modules={[Navigation, Controller]}
          navigation={{
            nextEl: '.swiper-navigation__arrow--next',
            prevEl: '.swiper-navigation__arrow--prev',
          }}
          onActiveIndexChange={swiperCore => setActiveSwiperIndex(swiperCore.activeIndex)}
          onSwiper={setFirstSwiper}
          slidesPerView={1}
          spaceBetween={0}
        >
          {images.map(image => (
            <SwiperSlide key={image.id}>
              {image.mediaType?.name === 'Video' ? (
                <Video url={image.resourceUrl ?? ''} activeSwiperIndex={activeSwiperIndex} />
              ) : (
                <ImageElement url={image.resourceUrl ?? ''} name={image.mediaType?.name ?? ''} />
              )}
            </SwiperSlide>
          ))}
          <SwiperNavigation activeImageIndex={activeSwiperIndex} imagesLength={images.length} />
          <GalleryNavigation
            isFullscreenVisible
            isPlanVisible={hasPlan}
            isVideoInList={hasVideo}
            onSelectFullscreen={() => onSelectFullscreen(activeSwiperIndex)}
            onSelectMap={onSelectMap}
            onSelectVideo={handleSelectVideo}
            onSelectPlan={handleSelectPlan}
          />
        </Swiper>
        <Swiper
          centeredSlides
          className="gallery-swiper__thumbs swiper-container-2"
          controller={{ control: firstSwiper }}
          initialSlide={slideToImage || 0}
          modules={[Controller]}
          onSwiper={setSecondSwiper}
          slideToClickedSlide
          slidesPerView={'auto'}
          spaceBetween={8}
        >
          {images.map(image => (
            <SwiperSlide key={image.id}>
              {image.mediaType?.name === 'Video' ? (
                <Video url={image.resourceUrl ?? ''} activeSwiperIndex={activeSwiperIndex} noPlay={true} />
              ) : (
                <ImageElement url={image.resourceUrl ?? ''} name={image.mediaType?.name ?? ''} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
};

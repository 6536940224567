import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import './container.scss';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: 'white';
  fullWidth?: boolean;
  fullWidthMobile?: boolean;
  paddingBottomReset?: boolean;
  paddingTopReset?: boolean;
  pushTop?: boolean;
}

const Container: FC<ContainerProps> = ({
  children,
  className,
  fullWidth,
  fullWidthMobile,
  backgroundColor,
  paddingTopReset,
  paddingBottomReset,
  pushTop,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames('grid-container', className, {
        'background-white': backgroundColor === 'white',
        'full-width': fullWidth,
        'full-width-mobile': fullWidthMobile,
        'padding-bottom-reset': paddingBottomReset,
        'padding-top-reset': paddingTopReset,
        'push-top': pushTop,
      })}
    >
      {children}
    </div>
  );
};

export default Container;

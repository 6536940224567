/**
 * @generated SignedSource<<c8621b06729eb327bb80ad56efc6211b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPropertyType = "Cattle" | "Cellar" | "Clinic" | "Crop" | "FarmLand" | "ForestProperty" | "FreeLand" | "Garage" | "HotelOrRestaurant" | "HousingAndBusiness" | "HousingCooperative" | "Investment" | "Liberal" | "MinkAndFurAnimals" | "Office" | "Parking" | "Pigs" | "Plot" | "PoultryProduction" | "ProductionFacility" | "ShoppingCenter" | "Showroom" | "SpecialProduction" | "Store" | "WareHouse" | "WareHouseAndProduction" | "Workshop";
export type BusinessRentalCaseStatus = "Commissioned" | "Discontinued" | "ForRent" | "LostCommission" | "LostSale" | "None" | "Rented" | "UnderRent" | "Valuation";
export type BusinessSalesCaseStatus = "BeforeSale" | "FinallyTrade" | "ForSale" | "LostCommission" | "NotDefined" | "Return" | "Sold" | "UnderSale" | "Valuation";
export type EnergyMarkSeverity = "A" | "A1" | "A2" | "A2010" | "A2015" | "A2020" | "B" | "C" | "D" | "E" | "F" | "G" | "Unknown";
export type PropertyType = "AllYearRoundPlot" | "AllotmentHut" | "AllotmentPlot" | "Condo" | "FarmHouseAgriculture" | "FormerFarm" | "FreeLand" | "HobbyAgriculture" | "HousingCooperative" | "IdeelAnpartCondo" | "IdeelAnpartTerracedHouse" | "IdeelAnpartVilla" | "Liebhaveri" | "MixedHousingAndBusiness" | "PartnershipOrLimitedPartnershipCondo" | "PartnershipOrLimitedPartnershipTerracedHouse" | "PartnershipOrLimitedPartnershipVilla" | "TerracedHouse" | "TerracedHouseWithCondoStatus" | "TwoFamilyProperty" | "VacationCondo" | "VacationHousing" | "VacationPlot" | "Villa" | "VillaApartment" | "VillaWithCondoStatus";
export type RentalCaseStatus = "Commissioned" | "Discontinued" | "ForRent" | "LostCommission" | "LostSale" | "Rented" | "UnderRent" | "Unknown" | "Valuation";
export type SalesCaseStatus = "BeforeSale" | "FinallyTrade" | "ForSale" | "LostCommission" | "NotDefined" | "Return" | "Sold" | "UnderSale" | "Valuation";
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentAgriculturalCase_baseCase$data = {
  readonly baseCaseMainOffer: {
    readonly cashPrice: any | null | undefined;
    readonly totalCosts: {
      readonly minimumPayout: any | null | undefined;
      readonly yearlyGrossOutput: any | null | undefined;
      readonly yearlyNetOutput: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly businessPropertyType?: BusinessPropertyType;
  readonly businessRentalCase?: BusinessRentalCaseStatus | null | undefined;
  readonly businessSalesCaseStatus?: BusinessSalesCaseStatus | null | undefined;
  readonly caseAreas: {
    readonly livableArea: number | null | undefined;
    readonly plotAreaSum: number | null | undefined;
  } | null | undefined;
  readonly energyMark: EnergyMarkSeverity | null | undefined;
  readonly heat: string | null | undefined;
  readonly ownerCostsTotalMonthly?: any | null | undefined;
  readonly propertyType?: PropertyType;
  readonly rentalStatus?: RentalCaseStatus | null | undefined;
  readonly salesStatus?: SalesCaseStatus | null | undefined;
  readonly yearBuilt: number | null | undefined;
  readonly " $fragmentType": "matchHeroContentAgriculturalCase_baseCase";
};
export type matchHeroContentAgriculturalCase_baseCase$key = {
  readonly " $data"?: matchHeroContentAgriculturalCase_baseCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentAgriculturalCase_baseCase">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": "businessPropertyType",
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentAgriculturalCase_baseCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "energyMark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yearBuilt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseCaseMainOffer",
      "kind": "LinkedField",
      "name": "baseCaseMainOffer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cashPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TotalCosts",
          "kind": "LinkedField",
          "name": "totalCosts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyGrossOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyNetOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimumPayout",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaseAreas",
      "kind": "LinkedField",
      "name": "caseAreas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livableArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plotAreaSum",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerCostsTotalMonthly",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": "salesStatus",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "type": "SalesCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "RectifyCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "CounselingPropertyCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "rentalStatus",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "type": "RentalCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "CounselingCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "businessSalesCaseStatus",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "type": "BusinessSalesCase",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        {
          "alias": "businessRentalCase",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "type": "BusinessRentalCase",
      "abstractKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};
})();

(node as any).hash = "ed2cc5e147f4bcf3957a176763578cf1";

export default node;

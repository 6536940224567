/**
 * @generated SignedSource<<dd96f4ce885b7e5f3d2235ef9dde3e51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contactCard_employee$data = {
  readonly employeePrimaryImageUrl: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly fullName: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly offerShop: {
    readonly address: string | null | undefined;
    readonly city: string | null | undefined;
    readonly name: string | null | undefined;
    readonly phone: string | null | undefined;
  } | null | undefined;
  readonly phone: string | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "contactCard_employee";
};
export type contactCard_employee$key = {
  readonly " $data"?: contactCard_employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"contactCard_employee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contactCard_employee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeePrimaryImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Shop",
      "kind": "LinkedField",
      "name": "offerShop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};
})();

(node as any).hash = "0497032d96a38795200f4b7cee717d0d";

export default node;

/**
 * @generated SignedSource<<04e2807d5a6fcc97b9d2a7e1421be7f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contactModule_baseCase$data = {
  readonly address: string | null | undefined;
  readonly baseCaseMainOffer: {
    readonly employeeOffer: ReadonlyArray<{
      readonly employee: {
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly offerShop: {
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"contactCard_employee">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "contactModule_baseCase";
};
export type contactModule_baseCase$key = {
  readonly " $data"?: contactModule_baseCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"contactModule_baseCase">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contactModule_baseCase",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseCaseMainOffer",
      "kind": "LinkedField",
      "name": "baseCaseMainOffer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmployeeOffer",
          "kind": "LinkedField",
          "name": "employeeOffer",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Employee",
              "kind": "LinkedField",
              "name": "employee",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "contactCard_employee"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Shop",
                  "kind": "LinkedField",
                  "name": "offerShop",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};
})();

(node as any).hash = "4549234df2bbf209af83aa023f73a8f5";

export default node;

import Input from 'components/molecules/input/input';
import { ChangeEvent, FC, ReactNode } from 'react';
import { CustomerFieldType } from '../choose-activity-modal';

type Props = {
  labelId: string;
  label: ReactNode;
  customerInfo: CustomerFieldType;
  handleCustomerInfoChange: (e: ChangeEvent<HTMLInputElement>, field: CustomerFieldType) => void;
  className?: string;
};

export const ShowCasingInput: FC<Props> = ({ labelId, label, customerInfo, handleCustomerInfoChange, className }) => {
  return (
    <div className={className}>
      <label htmlFor={labelId}>{label}</label>
      <Input
        id={labelId}
        onChange={e => {
          handleCustomerInfoChange(e, customerInfo);
        }}
        type={customerInfo === 'phone' ? 'tel' : undefined}
      />
    </div>
  );
};

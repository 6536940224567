import { SignalrConnectionContext } from 'context/SignalrConnectionContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { SignalrConnection, SignalrConnectionStateEnum } from './signalr-connection';

interface Props {
  children: ReactNode;
  connection: SignalrConnection;
}

const SignalrConnectionProvider = ({ children, connection }: Props) => (
  <SignalrConnectionContext.Provider value={connection}>{children}</SignalrConnectionContext.Provider>
);

const useSignalrConnection = (): [SignalrConnectionStateEnum, SignalrConnection | null] => {
  const connection = useContext(SignalrConnectionContext);

  const [connectionState, setConnectionState] = useState<SignalrConnectionStateEnum>(
    SignalrConnectionStateEnum.Disconnected,
  );

  useEffect(() => {
    const subscription = connection?.state.subscribe({
      next: val => {
        setConnectionState(val);
      },
    });

    return () => subscription?.unsubscribe();
  }, [connection]);

  return [connectionState, connection];
};

export { SignalrConnectionProvider, useSignalrConnection };

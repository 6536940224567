/**
 * @generated SignedSource<<135c360560a4c89f013510e640c72117>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type contactSellerModal_customerIdQuery$variables = Record<PropertyKey, never>;
export type contactSellerModal_customerIdQuery$data = {
  readonly viewer: {
    readonly customer: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type contactSellerModal_customerIdQuery = {
  response: contactSellerModal_customerIdQuery$data;
  variables: contactSellerModal_customerIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "contactSellerModal_customerIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "contactSellerModal_customerIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3dbfa247360d0ab61be81bcfcce8792",
    "id": null,
    "metadata": {},
    "name": "contactSellerModal_customerIdQuery",
    "operationKind": "query",
    "text": "query contactSellerModal_customerIdQuery {\n  viewer {\n    customer {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8255405f259562c02ca2251e5d8e4aa1";

export default node;

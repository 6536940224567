import { Component, ErrorInfo, ReactNode } from 'react';
import { sendErrorToServer } from 'services/errors/LogProxy';
import NotFound from 'components/molecules/not-found/not-found';

type Props = unknown & { children: ReactNode };

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
  hasError: boolean;
  errorId: string | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorId: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    const { message, stack } = error;
    const { componentStack } = errorInfo;

    const errorId = await sendErrorToServer({
      message,
      stack,
      componentStack: componentStack ?? '',
    });

    this.setState({ errorId });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound reload={true} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

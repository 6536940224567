/**
 * @generated SignedSource<<49f4f09e5e137fdb6197a8a692238300>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentCounselingCase$data = {
  readonly cashPrice: any | null | undefined;
  readonly " $fragmentType": "matchHeroContentCounselingCase";
};
export type matchHeroContentCounselingCase$key = {
  readonly " $data"?: matchHeroContentCounselingCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentCounselingCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentCounselingCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashPrice",
      "storageKey": null
    }
  ],
  "type": "BaseCaseMainOffer",
  "abstractKey": null
};

(node as any).hash = "5f34e8e5b1ba9cc73e5cc25098feb15c";

export default node;

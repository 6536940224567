import { favoritePageHero_fragment$key } from '__generated__/favoritePageHero_fragment.graphql';
import { MatchHeroContent } from 'components/organisms/match-hero-content/match-hero-content';
import Container from 'components/utilities/container/container';
import { graphql, useFragment } from 'react-relay';
import { CaseImages } from 'components/organisms/images/case-images';
import RegisterOpenHouseModal from 'components/organisms/register-open-house-modal/register-open-house-modal';
import { FormattedMessage } from 'react-intl';
import Tag from 'components/atoms/tag/tag';
import { OpenHouseImageOverlay } from 'components/molecules/match-hero-images/open-house-image-overlay';
import { useList } from 'hooks/useList';
import useToggle from 'hooks/useToggle';

interface Props {
  fragment: favoritePageHero_fragment$key;
}

const favoritePageHeroFragmentQuery = graphql`
  fragment favoritePageHero_fragment on CasePotentialBuyer {
    __typename
    baseCase {
      ...matchHeroContentFragment_baseCase
      ...registerOpenHouseModalFragment
      ...openHouseImageOverlay_baseCase
      noAdvertisement
      gpsCoordinates
      noAdvertisement
      gpsCoordinates
      openHouse(amount: 2) {
        __typename
      }
      mediaDataConnection {
        ...caseImages_Media
      }
    }
    offmarketCase {
      ...matchHeroContentFragment_offmarketCase
      mediaDataConnection {
        ...caseImages_Media
      }
    }
  }
`;

const FavoritePageHero = (props: Props) => {
  const [openHouseModalOpen, toggleOpenHouseModalOpen] = useToggle();
  const data = useFragment(favoritePageHeroFragmentQuery, props.fragment);

  const baseCase = data.baseCase ?? data.offmarketCase;
  const openHouses = useList(data?.baseCase?.openHouse);

  const noAdvertisement = data?.baseCase?.noAdvertisement;
  const hasOpenHouse = !data.offmarketCase && data.baseCase && openHouses.length > 0;

  const handleOnClickOpenHouseModal = () => {
    toggleOpenHouseModalOpen(true);
  };

  const handleCloseOpenHouseModal = () => {
    toggleOpenHouseModalOpen(false);
  };

  return (
    <Container paddingTopReset>
      <div className={'image-wrapper'}>
        <CaseImages fragment={baseCase?.mediaDataConnection} gpsCoordinates={data.baseCase?.gpsCoordinates ?? ''} />
        <div className="image-overlay">
          {!data.offmarketCase && noAdvertisement && (
            <div>
              <Tag>
                <FormattedMessage defaultMessage="Draw case" id="Match.DrawCase" />
              </Tag>
            </div>
          )}
        </div>
        {hasOpenHouse && (
          <OpenHouseImageOverlay fragment={data.baseCase} handleOnClickOpenHouseModal={handleOnClickOpenHouseModal} />
        )}
      </div>
      <MatchHeroContent fragment={data.baseCase} offmarketCase={data.offmarketCase} />
      {openHouseModalOpen && hasOpenHouse && (
        <RegisterOpenHouseModal closeModal={handleCloseOpenHouseModal} openHouses={data.baseCase} />
      )}
    </Container>
  );
};
export default FavoritePageHero;

import Icon from 'components/atoms/icon/icon';
import { FormattedMessage } from 'react-intl';
import { authenticationService } from 'services/authentication/authentication-service';

export const LogoutButton = () => {
  return (
    <button
      onClick={() => {
        authenticationService.logout();
      }}
      type="button"
    >
      <Icon name="LogOut" />
      <span className="main-navigation__logout-text">
        <FormattedMessage defaultMessage="Log ud" id="Navigation.Logout" />
      </span>
    </button>
  );
};

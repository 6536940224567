/**
 * @generated SignedSource<<db0ccb457474a2993f83922373a474ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadDialogContentViewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"leadOpenHouseDialogContentViewer" | "leadPresentationDialogContentViewer">;
  readonly " $fragmentType": "leadDialogContentViewer";
};
export type leadDialogContentViewer$key = {
  readonly " $data"?: leadDialogContentViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadDialogContentViewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadDialogContentViewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadPresentationDialogContentViewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadOpenHouseDialogContentViewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5987562b57362e4b0273da7fcbe78d3e";

export default node;

import React, { FC, ReactNode, createContext, useEffect } from 'react';
import { MixPanelEvents } from './mixpanelEvents';
import mixpanel, { Dict } from 'mixpanel-browser';

type MixPanelUser = {
  userId: string;
};

type State = {
  track: <T extends keyof MixPanelEvents>(event: T, properties?: MixPanelEvents[T]) => void;
  identify: (user: MixPanelUser) => void;
};

export const MixpanelContext = createContext<State>({
  track: () => {},
  identify: () => {},
});

type Props = {
  children: ReactNode;
  token: string;
};

export const MixPanelProvider: FC<Props> = ({ children, token }) => {
  useEffect(() => {
    if (token) {
      mixpanel.init(token, { api_host: 'https://api-eu.mixpanel.com' });
    }
  }, [token]);

  const track = <T extends keyof MixPanelEvents>(event: T, properties?: MixPanelEvents[T]) => {
    if (!token) {
      return;
    }

    mixpanel.track(event, properties as Dict);
  };

  const identify = (user: MixPanelUser) => {
    if (!token) return;
    mixpanel.identify(user.userId);
    mixpanel.register_once({ ...user });
  };

  return <MixpanelContext.Provider value={{ track, identify }}>{children}</MixpanelContext.Provider>;
};

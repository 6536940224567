/**
 * @generated SignedSource<<f672ae499bac2f7930323e5a3d594fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPropertyType = "Cattle" | "Cellar" | "Clinic" | "Crop" | "FarmLand" | "ForestProperty" | "FreeLand" | "Garage" | "HotelOrRestaurant" | "HousingAndBusiness" | "HousingCooperative" | "Investment" | "Liberal" | "MinkAndFurAnimals" | "Office" | "Parking" | "Pigs" | "Plot" | "PoultryProduction" | "ProductionFacility" | "ShoppingCenter" | "Showroom" | "SpecialProduction" | "Store" | "WareHouse" | "WareHouseAndProduction" | "Workshop";
export type BusinessSalesCaseStatus = "BeforeSale" | "FinallyTrade" | "ForSale" | "LostCommission" | "NotDefined" | "Return" | "Sold" | "UnderSale" | "Valuation";
export type EnergyMarkSeverity = "A" | "A1" | "A2" | "A2010" | "A2015" | "A2020" | "B" | "C" | "D" | "E" | "F" | "G" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentBusinessSalesCase$data = {
  readonly baseCaseMainOffer: {
    readonly cashPrice: any | null | undefined;
    readonly rateOfReturn: ReadonlyArray<{
      readonly annualAmount: any | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly buildings?: ReadonlyArray<{
    readonly isMainBuilding: boolean;
    readonly yearBuilt: number | null | undefined;
  } | null | undefined> | null | undefined;
  readonly businessPropertyType?: BusinessPropertyType;
  readonly caseAreas: {
    readonly builtUpArea: number | null | undefined;
    readonly commercialArea: number | null | undefined;
    readonly livableArea: number | null | undefined;
    readonly plotAreaSum: number | null | undefined;
  } | null | undefined;
  readonly energyMark: EnergyMarkSeverity | null | undefined;
  readonly investmentProperty?: boolean | null | undefined;
  readonly status?: BusinessSalesCaseStatus | null | undefined;
  readonly tenanciesYearlyRentTotal?: any | null | undefined;
  readonly yearBuilt: number | null | undefined;
  readonly " $fragmentType": "matchHeroContentBusinessSalesCase";
};
export type matchHeroContentBusinessSalesCase$key = {
  readonly " $data"?: matchHeroContentBusinessSalesCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentBusinessSalesCase">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearBuilt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentBusinessSalesCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "energyMark",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseCaseMainOffer",
      "kind": "LinkedField",
      "name": "baseCaseMainOffer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cashPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IncreasePerYear",
          "kind": "LinkedField",
          "name": "rateOfReturn",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "annualAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaseAreas",
      "kind": "LinkedField",
      "name": "caseAreas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commercialArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livableArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plotAreaSum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "builtUpArea",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "buildings",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isMainBuilding",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "investmentProperty",
          "storageKey": null
        },
        {
          "alias": "businessPropertyType",
          "args": null,
          "kind": "ScalarField",
          "name": "propertyType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenanciesYearlyRentTotal",
          "storageKey": null
        }
      ],
      "type": "BusinessSalesCase",
      "abstractKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};
})();

(node as any).hash = "193f315bcfcb87c72792564a1f6fe0de";

export default node;

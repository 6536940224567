import { favoritePage_Query } from '__generated__/favoritePage_Query.graphql';
import ContactModule from 'components/organisms/contact-module/contact-module';
import ContactModuleOffmarket from 'components/organisms/contact-module/contact-module-offmarket';
import MyPageSpots from 'components/organisms/my-page-spots/my-page-spots';
import FavoritePageHero from 'pages/favorites/favorite-page-hero';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { SuspenseHoc } from 'components/templates/suspense-hoc';
import Loader from 'components/atoms/loader/loader';
import { FavoritePageDocumentsRender } from 'pages/favorites/documents/favorite-page-documents-render';
import NotFound from 'components/molecules/not-found/not-found';
import { Fragment } from 'react';

const favoritePageQuery = graphql`
  query favoritePage_Query($matchId: ID!) {
    node(id: $matchId) {
      ... on CasePotentialBuyer {
        ...favoritePageHero_fragment
        baseCase {
          ...contactModule_baseCase
        }
        offmarketCase {
          ...contactModuleOffmarket_offmarketCase
        }
      }
    }
    viewer {
      ...myPageSpotsEntity
    }
  }
`;

const FavoritePage = () => {
  const { favoriteId } = useParams();

  const data = useLazyLoadQuery<favoritePage_Query>(favoritePageQuery, {
    matchId: favoriteId || '',
  });

  if (!data) {
    return <NotFound />;
  }

  return (
    <Fragment>
      {data.node && <FavoritePageHero fragment={data.node} />}
      {data.node?.baseCase && <ContactModule fragment={data.node.baseCase} />}
      {favoriteId && (
        <SuspenseHoc fallback={<Loader />}>
          <FavoritePageDocumentsRender favoriteId={favoriteId} />
        </SuspenseHoc>
      )}
      {data.node?.offmarketCase && <ContactModuleOffmarket fragment={data.node.offmarketCase} />}

      {data?.viewer && <MyPageSpots myPageSpots={data.viewer} filter={'PotentialBuyer'} />}
    </Fragment>
  );
};

export default FavoritePage;

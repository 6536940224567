import { FC } from 'react';

type Props = {
  url: string;
  name: string;
};
export const ImageElement: FC<Props> = ({ url, name }) => {
  return (
    <img
      className={'gallery-swiper__image ' + (name === 'Plantegning' && 'gallery-swiper__image--plan')}
      src={url}
      alt={'case image'}
    />
  );
};

import { defineMessages, useIntl } from 'react-intl';

interface Translation {
  [key: string]: { defaultMessage: string; id: string };
}
const PropertyType: Translation = defineMessages({
  AllotmentHut: {
    defaultMessage: 'Allotment hut',
    id: 'PropertyType.AllotmentHut',
  },
  AllotmentPlot: {
    defaultMessage: 'Allotment plot',
    id: 'PropertyType.AllotmentPlot',
  },
  AllYearRoundPlot: {
    defaultMessage: 'All year round plot',
    id: 'PropertyType.AllYearRoundPlot',
  },
  Cattle: {
    defaultMessage: 'Cattle property',
    id: 'PropertyType.Cattle',
  },
  Cellar: {
    defaultMessage: 'Cellar',
    id: 'PropertyType.Cellar',
  },
  Clinic: {
    defaultMessage: 'Clinic',
    id: 'PropertyType.Clinic',
  },
  Condo: {
    defaultMessage: 'Condo',
    id: 'PropertyType.Condo',
  },
  Crop: {
    defaultMessage: 'Crop property',
    id: 'PropertyType.Crop',
  },
  FarmHouseAgriculture: {
    defaultMessage: 'Farm house agriculture',
    id: 'PropertyType.FarmHouseAgriculture',
  },
  FarmLand: {
    defaultMessage: 'Farm land',
    id: 'PropertyType.FarmLand',
  },
  ForestProperty: {
    defaultMessage: 'Forest property',
    id: 'PropertyType.ForestProperty',
  },
  FormerFarm: {
    defaultMessage: 'Former farm',
    id: 'PropertyType.FormerFarm',
  },
  FreeLand: {
    defaultMessage: 'Free land',
    id: 'PropertyType.FreeLand',
  },
  Garage: {
    defaultMessage: 'Garage',
    id: 'PropertyType.Garage',
  },
  HobbyAgriculture: {
    defaultMessage: 'Hobby agriculture',
    id: 'PropertyType.HobbyAgriculture',
  },
  HotelOrRestaurant: {
    defaultMessage: 'Hotel or restaurant',
    id: 'PropertyType.HotelOrRestaurant',
  },
  HousingAndBusiness: {
    defaultMessage: 'Mixed residential and business',
    id: 'PropertyType.HousingAndBusiness',
  },
  HousingCooperative: {
    defaultMessage: 'Housing cooperative',
    id: 'PropertyType.HousingCooperative',
  },
  IdeelAnpartCondo: {
    defaultMessage: 'Ideal share (condo)',
    id: 'PropertyType.IdeelAnpartCondo',
  },
  IdeelAnpartTerracedHouse: {
    defaultMessage: 'Ideal share (Terraced house)',
    id: 'PropertyType.IdeelAnpartTerracedHouse',
  },
  IdeelAnpartVilla: {
    defaultMessage: 'Ideal share (villa)',
    id: 'PropertyType.IdeelAnpartVilla',
  },
  Investment: {
    defaultMessage: 'Residential rental property',
    id: 'PropertyType.Investment',
  },
  Liberal: {
    defaultMessage: 'Liberal profession',
    id: 'PropertyType.Liberal',
  },
  Liebhaveri: {
    defaultMessage: 'Liebhaveri',
    id: 'PropertyType.Liebhaveri',
  },
  MinkAndFurAnimals: {
    defaultMessage: 'Mink and fur animals',
    id: 'PropertyType.MinkAndFurAnimals',
  },
  MixedHousingAndBusiness: {
    defaultMessage: 'Mixed housing and business',
    id: 'PropertyType.MixedHousingAndBusiness',
  },
  Office: {
    defaultMessage: 'Office',
    id: 'PropertyType.Office',
  },
  Parking: {
    defaultMessage: 'Parking lot',
    id: 'PropertyType.Parking',
  },
  PartnershipOrLimitedPartnershipCondo: {
    defaultMessage: 'K/S, I/S (condo)',
    id: 'PropertyType.PartnershipOrLimitedPartnershipCondo',
  },
  PartnershipOrLimitedPartnershipTerracedHouse: {
    defaultMessage: 'I/S, K/S (Terraced house)',
    id: 'PropertyType.PartnershipOrLimitedPartnershipTerracedHouse',
  },
  PartnershipOrLimitedPartnershipVilla: {
    defaultMessage: 'I/S, K/S (villa)',
    id: 'PropertyType.PartnershipOrLimitedPartnershipVilla',
  },
  Pigs: {
    defaultMessage: 'Pig property',
    id: 'PropertyType.Pigs',
  },
  Plot: {
    defaultMessage: 'Plot',
    id: 'PropertyType.Plot',
  },
  PoultryProduction: {
    defaultMessage: 'Poultry production',
    id: 'PropertyType.PoultryProduction',
  },
  ProductionFacility: {
    defaultMessage: 'Production',
    id: 'PropertyType.ProductionFacility',
  },
  ShoppingCenter: {
    defaultMessage: 'Shoppingcenter',
    id: 'PropertyType.ShoppingCenter',
  },
  Showroom: {
    defaultMessage: 'Showroom',
    id: 'PropertyType.Showroom',
  },
  SpecialProduction: {
    defaultMessage: 'Special production',
    id: 'PropertyType.SpecialProduction',
  },
  Store: {
    defaultMessage: 'Business',
    id: 'PropertyType.Store',
  },
  TerracedHouse: {
    defaultMessage: 'Terraced house',
    id: 'PropertyType.TerracedHouse',
  },
  TerracedHouseWithCondoStatus: {
    defaultMessage: 'Terraced house with condo status',
    id: 'PropertyType.TerracedHouseWithCondoStatus',
  },
  TwoFamilyProperty: {
    defaultMessage: 'Two family property',
    id: 'PropertyType.TwoFamilyProperty',
  },
  VacationCondo: {
    defaultMessage: 'Vacation condo',
    id: 'PropertyType.VacationCondo',
  },
  VacationHousing: {
    defaultMessage: 'Vacation housing',
    id: 'PropertyType.VacationHousing',
  },
  VacationPlot: {
    defaultMessage: 'Vacation plot',
    id: 'PropertyType.VacationPlot',
  },
  Villa: {
    defaultMessage: 'Villa',
    id: 'PropertyType.Villa',
  },
  VillaApartment: {
    defaultMessage: 'Villa apartment',
    id: 'PropertyType.VillaApartment',
  },
  VillaWithCondoStatus: {
    defaultMessage: 'Villa with condo status',
    id: 'PropertyType.VillaWithCondoStatus',
  },
  WareHouse: {
    defaultMessage: 'Ware house',
    id: 'PropertyType.WareHouse',
  },
  WareHouseAndProduction: {
    defaultMessage: 'Ware house and production',
    id: 'PropertyType.WareHouseAndProduction',
  },
  Workshop: {
    defaultMessage: 'Workshop',
    id: 'PropertyType.Workshop',
  },
});
export const formatPropertyType = (intl: any) => (key: string) => {
  if (PropertyType[key]) return intl.formatMessage(PropertyType[key]);
  return key;
};
export function usePropertyType() {
  const intl = useIntl();
  return formatPropertyType(intl);
}

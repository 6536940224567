import { CaseAnnouncedType } from 'Enums';
import { matchHeroContentFragment_baseCase$key } from '__generated__/matchHeroContentFragment_baseCase.graphql';
import { matchHeroContentFragment_offmarketCase$key } from '__generated__/matchHeroContentFragment_offmarketCase.graphql';
import classNames from 'classnames';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import { MatchHeroDetails } from 'components/molecules/match-hero-details/match-hero-details';
import { MatchHeroOffmarketDetails } from 'components/molecules/match-hero-details/match-hero-offmarket-details';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Container from 'components/utilities/container/container';
import ModuleWrapper from 'components/utilities/module-wrapper/module-wrapper';
import Row from 'components/utilities/row/row';
import { useTheme } from 'context/ThemeContext';
import DOMPurify from 'dompurify';
import { useList } from 'hooks/useList';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { BookShowcasingModal } from '../book-showcasing-modal/book-showcasing-modal';
import './match-hero-content.scss';

export interface MatchHeroContentProps {
  fragment: matchHeroContentFragment_baseCase$key | null | undefined;
  offmarketCase: matchHeroContentFragment_offmarketCase$key | null | undefined;
}

const matchHeroContentFragment = graphql`
  fragment matchHeroContentFragment_baseCase on BaseCase {
    id
    caseAnnounced {
      linkUrl
      type
    }
    ...matchHeroDetailsFragment
    ... on SalesCase {
      noAdvertisement
      caseDescription
      caseDescriptionHeading
      __typename
    }
  }
`;

const matchHeroContentOffmarketFragment = graphql`
  fragment matchHeroContentFragment_offmarketCase on OffmarketCase {
    caseDescription
    caseDescriptionHeading
    ...matchHeroOffmarketDetailsFragment_offmarketCase
  }
`;

export const MatchHeroContent = (props: MatchHeroContentProps) => {
  const data = useFragment<matchHeroContentFragment_baseCase$key>(matchHeroContentFragment, props.fragment || null);
  const offmarketCase = useFragment<matchHeroContentFragment_offmarketCase$key>(
    matchHeroContentOffmarketFragment,
    props.offmarketCase || null,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBookingShowcaseModalOpen, setIsBookingShowcaseModalOpen] = useState(false);

  const theme = useTheme();
  const caseAnnouncements = useList(data?.caseAnnounced);

  const baseCase = data ?? offmarketCase;

  if (!baseCase) {
    return null;
  }

  const caseHeadline = baseCase.caseDescriptionHeading ? DOMPurify.sanitize(baseCase.caseDescriptionHeading) : '';

  const caseDescription = baseCase.caseDescription ? DOMPurify.sanitize(baseCase.caseDescription) : '';

  const getWebsiteLink = () => {
    if (caseAnnouncements.length > 0) {
      const websiteAnnouncements = caseAnnouncements.filter(
        announcement => announcement?.type === CaseAnnouncedType.Website,
      );
      return websiteAnnouncements.length > 0 ? websiteAnnouncements[0].linkUrl : null;
    }
  };
  return (
    <ModuleWrapper backgroundColor="white" className="match-hero-content__wrapper">
      <Container className="match-hero-content">
        <Row>
          <Column order={{ mobile: 2, tablet: 1 }} width={{ tablet: 4 }}>
            <Block>
              {caseHeadline && <h1 className="headlineMedium" dangerouslySetInnerHTML={{ __html: caseHeadline }} />}
              {data && (
                <Block className="match-hero-content__buttons" flex flexGutter="xs">
                  <Button onClick={() => setIsBookingShowcaseModalOpen(true)}>
                    <FormattedMessage defaultMessage="Book show casing" id="Match.BookShowing" />
                  </Button>
                  {!data?.noAdvertisement && (
                    <Button anchorTag href={getWebsiteLink() ?? ''}>
                      <FormattedMessage defaultMessage="Se på" id="Match.SeeOn" /> {theme?.websiteName}
                    </Button>
                  )}
                </Block>
              )}
              <Block className="match-hero-content__description">
                {caseDescription && (
                  <Fragment>
                    <Typography
                      className={classNames('match-hero-content__description--text', { isExpanded })}
                      id="section-content"
                      tag="p"
                      tagStyle="bodyMedium"
                    >
                      <span dangerouslySetInnerHTML={{ __html: caseDescription }} />
                    </Typography>
                    <Button
                      aria-controls={'section-content'}
                      aria-expanded={isExpanded}
                      className="match-hero-content__expand"
                      hideText
                      iconStart={isExpanded ? 'Minus' : 'Plus'}
                      onClick={() => setIsExpanded(prevState => !prevState)}
                      version="secondary"
                    >
                      Expand
                    </Button>
                  </Fragment>
                )}
              </Block>
            </Block>
          </Column>
          <Column className="match-hero-content__details" order={{ mobile: 1, tablet: 2 }} width={{ tablet: 4 }}>
            {offmarketCase ? (
              <MatchHeroOffmarketDetails fragment={offmarketCase} />
            ) : (
              <MatchHeroDetails fragment={data} />
            )}
          </Column>
        </Row>
      </Container>
      {data?.id && isBookingShowcaseModalOpen && (
        <BookShowcasingModal handleClose={() => setIsBookingShowcaseModalOpen(false)} caseId={data.id} />
      )}
    </ModuleWrapper>
  );
};

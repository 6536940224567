/**
 * @generated SignedSource<<11285155a6c6b4d1f8fe08673804162a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type openHouseImageOverlay_baseCase$data = {
  readonly openHouse: ReadonlyArray<{
    readonly activityType: {
      readonly isRegistration: boolean | null | undefined;
      readonly nameOnTimeline: string | null | undefined;
    } | null | undefined;
    readonly isRegistred: boolean | null | undefined;
    readonly startDate: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "openHouseImageOverlay_baseCase";
};
export type openHouseImageOverlay_baseCase$key = {
  readonly " $data"?: openHouseImageOverlay_baseCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"openHouseImageOverlay_baseCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "openHouseImageOverlay_baseCase",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "amount",
          "value": 2
        }
      ],
      "concreteType": "OpenHouse",
      "kind": "LinkedField",
      "name": "openHouse",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityType",
          "kind": "LinkedField",
          "name": "activityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameOnTimeline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRegistration",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRegistred",
          "storageKey": null
        }
      ],
      "storageKey": "openHouse(amount:2)"
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};

(node as any).hash = "4dcbb9982a5d9b588616eb16670906eb";

export default node;

import * as Icons from 'assets/icons/icons';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { FC, ReactElement, ReactNode } from 'react';
import './empty-module.scss';

export interface EmptyModuleProps {
  iconName?: keyof typeof Icons;
  message: ReactNode;
  children?: ReactElement;
}

const EmptyModule: FC<EmptyModuleProps> = props => {
  const { iconName = 'Files', message, children } = props;

  return (
    <Block backgroundColor="white" borderRadius className="empty-module" column flex>
      <Typography className="empty-module__text" subjectColor="gray" tagStyle="bodyLarge">
        <Icon className="empty-module__icon" height={48} name={iconName} width={48} />
        {message}
      </Typography>
      {children}
    </Block>
  );
};

export default EmptyModule;

export function responseHasErrors(obj: { [key: string]: any }): boolean {
  const propertyName = 'errors';
  // Base case: If the object has the property and it is truthy, return true.
  if (obj.hasOwnProperty(propertyName) && obj[propertyName].length > 0) {
    return true;
  }

  // Recursive case: Go through each property of the object.
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the property is an object, recursively call the function.
      if (responseHasErrors(obj[key])) {
        return true;
      }
    }
  }

  // If the property wasn't found or was falsy, return false.
  return false;
}

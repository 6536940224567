import './documents-table.scss';

export const TableSkeleton = () => {
  const trs = [];
  for (let i = 0; i < 10; i++) {
    trs.push(
      <tr style={{ height: '26px' }} key={i}>
        <td className="documents-table__checkbox skeleton" />
        <td className="documents-table__cell skeleton" />
        <td className="hideMobile documents-table__cell skeleton" />
        <td className="hideMobile documents-table__cell skeleton" />
        <td className="documents-table__cell skeleton" />
        <td className="hideMobile documents-table__cell skeleton" />
      </tr>,
    );
  }
  return <tbody className="table__body">{trs}</tbody>;
};

import React, { FC, Fragment } from 'react';
import { MessageNotification } from './message-notification';
import { ActivityNotification } from './activity-notification';
import { SearchProfileMatchNotification } from './search-profile-match-notification';
import { RequestedDocumentNotification } from './requested-document-notification';
import { SearchProfileMatchedCaseNotification } from './search-profile-matched-case-notification';
import { DocumentSigningNotification } from './document-signing-notification';
import { graphql, useFragment } from 'react-relay';
import { notificationComponent_fragment$key } from '__generated__/notificationComponent_fragment.graphql';
import {
  MessageNotification as MessageNotificationType,
  DocumentSigningNotification as DocumentSigningNotificationType,
  ActivityNotification as ActivityNotificationType,
  SearchProfileMatchNotification as SearchProfileMatchNotificationType,
  RequestedDocumentNotification as RequestedDocumentNotificationType,
  SearchProfileMatchedCaseNotification as SearchProfileMatchedCaseNotificationType,
  DocumentAccessGrantedNotification as DocumentAccessGrantedNotificationType,
  LeadNotification as LeadNotificationType,
} from 'Types';
import { getNotificationUrl } from 'utils/navigation';
import { DocumentAccessGrantedNotification } from './document-access-granted-notification';
import { LeadNotification } from 'components/organisms/notifications/components/lead-notification';

export interface Props {
  notification: notificationComponent_fragment$key;
}

const notificationComponent = graphql`
  fragment notificationComponent_fragment on NotificationInterface {
    __typename
    ... on MessageNotification {
      threadId
    }
    ...messageNotification_fragment
    ...documentSigningNotification_fragment
    ...activityNotification_fragment
    ...searchProfileMatchNotification_fragment
    ...requestedDocumentNotification_fragment
    ...searchProfileMatchedCaseNotification_fragment
    ...documentAccessGrantedNotification_fragment
    ...leadNotification_fragment
  }
`;

export const NotificationComponent: FC<Props> = props => {
  const data = useFragment<notificationComponent_fragment$key>(notificationComponent, props.notification);

  switch (data.__typename) {
    case MessageNotificationType:
      return (
        <MessageNotification
          notification={data}
          url={getNotificationUrl({ notificationType: data.__typename, threadId: data.threadId })}
        />
      );
    case DocumentSigningNotificationType:
      return <DocumentSigningNotification notification={data} />;
    case DocumentAccessGrantedNotificationType:
      return <DocumentAccessGrantedNotification notification={data} />;
    case RequestedDocumentNotificationType:
      return <RequestedDocumentNotification notification={data} />;
    case ActivityNotificationType:
      return <ActivityNotification notification={data} />;
    case SearchProfileMatchNotificationType:
      return <SearchProfileMatchNotification notification={data} />;
    case SearchProfileMatchedCaseNotificationType:
      return <SearchProfileMatchedCaseNotification notification={data} />;
    case LeadNotificationType:
      return <LeadNotification notification={data} />;
    default:
      return <Fragment />;
  }
};

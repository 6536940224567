/**
 * @generated SignedSource<<8a5fdee39bf6d9407281317e5d7493a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageNotification_fragment$data = {
  readonly authorName: string | null | undefined;
  readonly createdDate: any;
  readonly read: boolean;
  readonly subject: string | null | undefined;
  readonly " $fragmentType": "messageNotification_fragment";
};
export type messageNotification_fragment$key = {
  readonly " $data"?: messageNotification_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageNotification_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messageNotification_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorName",
      "storageKey": null
    }
  ],
  "type": "MessageNotification",
  "abstractKey": null
};

(node as any).hash = "f1e79df00d4f2523278da24c71cfd672";

export default node;

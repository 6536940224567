import { caseImagesSwiper_mediaDataConnection$key } from '__generated__/caseImagesSwiper_mediaDataConnection.graphql';
import Icon from 'components/atoms/icon/icon';
import SwiperNavigation from 'components/molecules/swiper-navigation/swiper-navigation';
import { useNodes } from 'hooks/useNodes';
import { FC, ReactNode, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './case-images-swiper.scss';
import { Video } from 'components/molecules/images-swiper/video';
import { ImageElement } from 'components/molecules/images-swiper/image';

const caseImagesSwiperFragment = graphql`
  fragment caseImagesSwiper_mediaDataConnection on MediaDataConnection {
    edges {
      node {
        id
        resourceUrl(resourceType: Asset)
        mediaType {
          name
        }
      }
    }
  }
`;

interface CaseImagesSwiperProps {
  children?: ReactNode;
  mediaDataConnection: caseImagesSwiper_mediaDataConnection$key | null;
  onImageClick?: (imageIndex: number) => void;
  slideToImage?: number;
}

const CaseImagesSwiper: FC<CaseImagesSwiperProps> = props => {
  const mediaDataConnection = useFragment(caseImagesSwiperFragment, props.mediaDataConnection);

  const { children, slideToImage, onImageClick } = props;

  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const imagesSwiperRef = useRef<HTMLDivElement>(null);
  const images = useNodes(mediaDataConnection?.edges);

  const handleOnClick = (activeIndex: number) => {
    if (onImageClick) {
      onImageClick(activeIndex);
    }
  };

  if (images.length === 0) {
    return (
      <div className="case-images-swiper">
        <Icon className="case-images-swiper__empty" name="Image" />
      </div>
    );
  }

  return (
    <div className="case-images-swiper" ref={imagesSwiperRef}>
      <Swiper
        centeredSlides
        className="case-images-swiper__images swiper-container-1"
        initialSlide={slideToImage || 0}
        modules={[Navigation]}
        navigation={{
          nextEl: '.swiper-navigation__arrow--next',
          prevEl: '.swiper-navigation__arrow--prev',
        }}
        onActiveIndexChange={swiperCore => setActiveSwiperIndex(swiperCore.activeIndex)}
        slidesPerView={1}
        spaceBetween={0}
      >
        {images.map(image => (
          <SwiperSlide key={image.id}>
            {image.mediaType?.name === 'Video' ? (
              <Video activeSwiperIndex={activeSwiperIndex} url={image.resourceUrl ?? ''} onImageClick={handleOnClick} />
            ) : (
              <ImageElement name={image.mediaType?.name ?? ''} url={image.resourceUrl ?? ''} />
            )}
          </SwiperSlide>
        ))}
        <SwiperNavigation activeImageIndex={activeSwiperIndex} imagesLength={images.length} />
        {children}
      </Swiper>
    </div>
  );
};

export default CaseImagesSwiper;

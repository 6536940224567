/**
 * @generated SignedSource<<b4aa657f3839265c3a8cf0d0db012b8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notificationComponent_fragment$data = {
  readonly __typename: string;
  readonly threadId?: string;
  readonly " $fragmentSpreads": FragmentRefs<"activityNotification_fragment" | "documentAccessGrantedNotification_fragment" | "documentSigningNotification_fragment" | "leadNotification_fragment" | "messageNotification_fragment" | "requestedDocumentNotification_fragment" | "searchProfileMatchNotification_fragment" | "searchProfileMatchedCaseNotification_fragment">;
  readonly " $fragmentType": "notificationComponent_fragment";
};
export type notificationComponent_fragment$key = {
  readonly " $data"?: notificationComponent_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"notificationComponent_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "notificationComponent_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "threadId",
          "storageKey": null
        }
      ],
      "type": "MessageNotification",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "messageNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "documentSigningNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activityNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "searchProfileMatchNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "requestedDocumentNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "searchProfileMatchedCaseNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "documentAccessGrantedNotification_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadNotification_fragment"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "6e60e5509f8a148efecb98e99c853008";

export default node;

import { AppSettings, appSettingsDefaults } from 'configs/app-settings-defaults';
import { loadThemeConfig } from './setup-theme';
const APPSETTINGS_JSON = `${import.meta.env.VITE_PUBLIC_URL}/appsettings.json?v=${Date.now()}`;

export const loadAppConfig = async () => {
  try {
    const resp = await fetch(APPSETTINGS_JSON, {
      credentials: 'same-origin',
    });
    if (!resp.ok) {
      throw new Error('Could not fetch settings');
    }
    return resp.json();
  } catch (error) {
    console.error('Initialization failed', error);
  }
};

let appSettings: AppSettings;

async function initAppSettings(): Promise<AppSettings> {
  const loadedSettings = await loadAppConfig();
  const settings = { ...appSettingsDefaults, ...loadedSettings };

  const theme = await loadThemeConfig(settings.THEME_URL);

  appSettings = {
    settings,
    theme,
  };

  return appSettings;
}

export { appSettings, initAppSettings };

/**
 * @generated SignedSource<<1e43bfec027d5efd21f90d47422cbe89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnergyMarkSeverity = "A" | "A1" | "A2" | "A2010" | "A2015" | "A2020" | "B" | "C" | "D" | "E" | "F" | "G" | "Unknown";
export type PropertyType = "AllYearRoundPlot" | "AllotmentHut" | "AllotmentPlot" | "Condo" | "FarmHouseAgriculture" | "FormerFarm" | "FreeLand" | "HobbyAgriculture" | "HousingCooperative" | "IdeelAnpartCondo" | "IdeelAnpartTerracedHouse" | "IdeelAnpartVilla" | "Liebhaveri" | "MixedHousingAndBusiness" | "PartnershipOrLimitedPartnershipCondo" | "PartnershipOrLimitedPartnershipTerracedHouse" | "PartnershipOrLimitedPartnershipVilla" | "TerracedHouse" | "TerracedHouseWithCondoStatus" | "TwoFamilyProperty" | "VacationCondo" | "VacationHousing" | "VacationPlot" | "Villa" | "VillaApartment" | "VillaWithCondoStatus";
export type SalesCaseStatus = "BeforeSale" | "FinallyTrade" | "ForSale" | "LostCommission" | "NotDefined" | "Return" | "Sold" | "UnderSale" | "Valuation";
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentSalesCase$data = {
  readonly annualHeatingPrice: any | null | undefined;
  readonly baseCaseMainOffer: {
    readonly cashPrice: any | null | undefined;
    readonly technicalPrice: any | null | undefined;
    readonly totalCosts: {
      readonly minimumPayout: any | null | undefined;
      readonly yearlyGrossOutput: any | null | undefined;
      readonly yearlyNetOutput: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly buildings: ReadonlyArray<{
    readonly buildingInclAreas: ReadonlyArray<{
      readonly area: any | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly caseAreas: {
    readonly atticArea: number | null | undefined;
    readonly basementArea: number | null | undefined;
    readonly builtUpArea: number | null | undefined;
    readonly livableArea: number | null | undefined;
    readonly plotAreaSum: number | null | undefined;
  } | null | undefined;
  readonly energyMark: EnergyMarkSeverity | null | undefined;
  readonly floorCount: number | null | undefined;
  readonly heat: string | null | undefined;
  readonly otherBuildingsSum: any | null | undefined;
  readonly ownerCostsTotalMonthly?: any | null | undefined;
  readonly propertyType?: PropertyType;
  readonly roomCount: number | null | undefined;
  readonly salesStatus?: SalesCaseStatus | null | undefined;
  readonly yearBuilt: number | null | undefined;
  readonly " $fragmentType": "matchHeroContentSalesCase";
};
export type matchHeroContentSalesCase$key = {
  readonly " $data"?: matchHeroContentSalesCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentSalesCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentSalesCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "energyMark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yearBuilt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherBuildingsSum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualHeatingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "buildings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuildingInclArea",
          "kind": "LinkedField",
          "name": "buildingInclAreas",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "area",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseCaseMainOffer",
      "kind": "LinkedField",
      "name": "baseCaseMainOffer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cashPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "technicalPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TotalCosts",
          "kind": "LinkedField",
          "name": "totalCosts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyGrossOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyNetOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimumPayout",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaseAreas",
      "kind": "LinkedField",
      "name": "caseAreas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livableArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plotAreaSum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "atticArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basementArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "builtUpArea",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerCostsTotalMonthly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "propertyType",
          "storageKey": null
        },
        {
          "alias": "salesStatus",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "type": "SalesCase",
      "abstractKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};

(node as any).hash = "f51d88e8140e628ede9fd38f7fbbdba9";

export default node;

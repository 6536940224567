import { matchHeroOffmarketDetailsFragment_offmarketCase$key } from '__generated__/matchHeroOffmarketDetailsFragment_offmarketCase.graphql';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { usePropertyType } from 'hooks/usePropertyCategories';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import './match-hero-details.scss';

export interface MatchHeroOffmarketDetailsProps {
  fragment: matchHeroOffmarketDetailsFragment_offmarketCase$key | null;
}

const matchHeroOffmarketDetailsFragment = graphql`
  fragment matchHeroOffmarketDetailsFragment_offmarketCase on OffmarketCase {
    propertyType
    businessPropertyType
  }
`;

export const MatchHeroOffmarketDetails = (props: MatchHeroOffmarketDetailsProps) => {
  const baseCase = useFragment(matchHeroOffmarketDetailsFragment, props.fragment);
  const formatBusinessPropertyType = usePropertyType();

  const propertyType = baseCase?.propertyType || baseCase?.businessPropertyType;

  return (
    <Block backgroundColor="white" boxShadow className="match-hero-details" customPadding="md">
      <Block className="match-hero-details__title">
        <Typography className="match-hero-details__title--street" tag="h2" tagStyle="headlineMedium">
          {propertyType && formatBusinessPropertyType(propertyType)} <br />
        </Typography>
        <Block className="match-hero-details__row marginTop" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Contact us for more information" id="Match.ContactUs" />
          </Typography>
        </Block>
      </Block>
    </Block>
  );
};

import { FocusEvent, FC, ChangeEvent } from 'react';
import './timepicker.scss';

export const TimerPicker: FC<{
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  date: null | Date;
}> = ({ handleChange, date, onBlur }) => {
  const getTimeFromDate = (day: Date | null): string => {
    const hours = day?.getHours().toString().padStart(2, '0');
    const minutes = day?.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="inputWrapper">
      <input
        id="time"
        type="time"
        value={getTimeFromDate(date)}
        onChange={handleChange}
        onBlur={onBlur}
        className="inputPadding"
      />
    </div>
  );
};

import { ChangeEvent, FC, ReactNode } from 'react';
import { SelectSingleEventHandler } from 'react-day-picker';
import DatePicker from 'components/molecules/date-picker/date-picker';
import Column from 'components/utilities/column/column';
import { AppointmentTimePicker } from './components/appointment-timepicker';
import { FormattedMessage } from 'react-intl';
import './choose-activity-modal.scss';

type Props = {
  onChangeDay: (currentTimeStart: Date, currentTimeEnd: Date) => void;
  time: time;
  handleChangeTimeStart: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimeEnd: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlurTimeStart: (timeDif: number, timeInMs: number) => void;
  handleBlurTimeEnd: (timeDif: number, timeInMs: number, e?: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  secondChildren?: ReactNode;
};
export type time = { timeStart: Date; timeEnd: Date };
const today = new Date();

export const AppointmentModalContent: FC<Props> = ({
  onChangeDay,
  time,
  handleChangeTimeStart,
  handleChangeTimeEnd,
  handleBlurTimeStart,
  handleBlurTimeEnd,
  children,
  secondChildren,
}) => {
  const changeDay = (date: Date) => {
    const currentTimeStart = time.timeStart;
    const currentTimeEnd = time.timeEnd;

    currentTimeStart.setFullYear(date.getFullYear());
    currentTimeStart.setMonth(date.getMonth());
    currentTimeStart.setDate(date.getDate());

    currentTimeEnd.setFullYear(date.getFullYear());
    currentTimeEnd.setMonth(date.getMonth());
    currentTimeEnd.setDate(date.getDate());
    onChangeDay(currentTimeStart, currentTimeEnd);
  };

  return (
    <div className="appointmentModalContentWrapper">
      <div className="appointmentModalContentDateTimeWrapper">
        <DatePicker
          fromDate={today}
          mode="single"
          onSelect={changeDay as SelectSingleEventHandler}
          selected={time.timeStart}
        />
        <Column className="appointmentModalContentColumn">
          <AppointmentTimePicker
            labelStart={<FormattedMessage defaultMessage="Start time:" id="Default.StartTime" />}
            labelEnd={<FormattedMessage defaultMessage="End time:" id="endTime" />}
            timeStart={time.timeStart}
            timeEnd={time.timeEnd}
            handleChangeTimeStart={handleChangeTimeStart}
            handleChangeTimeEnd={handleChangeTimeEnd}
            handleBlurTimeStart={handleBlurTimeStart}
            handleBlurTimeEnd={handleBlurTimeEnd}
          />
          {children && children}
        </Column>
      </div>
      {secondChildren && secondChildren}
    </div>
  );
};

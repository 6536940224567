import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { bookShowcasingModal_Query } from '__generated__/bookShowcasingModal_Query.graphql';
import { Presentation, createPresentationMutation } from '__generated__/createPresentationMutation.graphql';
import Button from 'components/atoms/button/button';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import Input from 'components/molecules/input/input';
import Modal from 'components/molecules/modal/modal';
import Textarea from 'components/molecules/textarea/textarea';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Row from 'components/utilities/row/row';
import { useTheme } from 'context/ThemeContext';
import { FC, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { commitMutation, graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import { createPresentationMutationQuery } from 'services/relay/mutations/createPresentationMutation';
import { phoneRegExp } from 'tools/string-formatters';
import * as yup from 'yup';
import '../showcasing-form/showcasing-form.scss';

type Step = 1 | 2;

const STEPS = 2;

export interface ShowcasingFormInputs {
  caseId: string;
  comment?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface BookShowcasingModalProps {
  caseId: string;
  handleClose: () => void;
}

const customerDataQuery = graphql`
  query bookShowcasingModal_Query {
    viewer {
      customer {
        isCompany
        firstName
        lastName
        email
        phone
      }
      leadTypeFromEnum(enum: Presentation) {
        currentConsent {
          id
        }
      }
    }
  }
`;

export const BookShowcasingModal: FC<BookShowcasingModalProps> = props => {
  const customerData = useLazyLoadQuery<bookShowcasingModal_Query>(customerDataQuery, {});
  const environment = useRelayEnvironment();
  const [modalHasError, setModalHasError] = useState(false);

  const closeModal = () => {
    props.handleClose();
    setModalHasError(false);
  };

  const handleOnSubmit = (data: ShowcasingFormInputs) => {
    const input = {
      ...data,
      consentIdGlobal: customerData?.viewer?.leadTypeFromEnum?.currentConsent?.id || '',
    };

    commitMutation<createPresentationMutation>(environment, {
      mutation: createPresentationMutationQuery,
      onCompleted: () => {
        setModalHasError(false);
      },
      onError: err => {
        setModalHasError(true);
        console.log(err);
      },
      variables: {
        input: input as Presentation,
      },
    });
  };

  const customerInfo = customerData.viewer?.customer;

  const [step, setStep] = useState<Step>(1);
  const intl = useIntl();
  const theme = useTheme();

  const isCompany = customerData.viewer?.customer?.isCompany;

  const messages = defineMessages({
    companyNameRequired: {
      defaultMessage: 'Company name is required',
      id: 'ShowcasingForm.requiredCompanyName',
    },
    emailInvalid: {
      defaultMessage: 'Enter a valid e-mail',
      id: 'ShowcasingForm.invalidEmail',
    },
    emailRequired: {
      defaultMessage: 'E-mail is required',
      id: 'ShowcasingForm.requiredEmail',
    },
    firstNameRequired: {
      defaultMessage: 'First name is required',
      id: 'ShowcasingForm.requiredFirstName',
    },
    lastNameRequired: {
      defaultMessage: 'Last name is required',
      id: 'ShowcasingForm.requiredLastName',
    },
    phoneInvalid: {
      defaultMessage: 'Enter a valid phone number',
      id: 'ShowcasingForm.invalidPhone',
    },
    phoneRequired: {
      defaultMessage: 'Phone number is required',
      id: 'ShowcasingForm.requiredPhone',
    },
  });

  const schema = yup.object().shape({
    caseId: yup.string().required(),
    comment: yup.string().nullable(),
    email: yup
      .string()
      .email(intl.formatMessage(messages.emailInvalid))
      .required(intl.formatMessage(messages.emailRequired))
      .trim(),
    firstName: yup
      .string()
      .required(
        isCompany ? intl.formatMessage(messages.companyNameRequired) : intl.formatMessage(messages.firstNameRequired),
      )
      .trim(),
    lastName: yup
      .string()
      .required(
        isCompany ? intl.formatMessage(messages.companyNameRequired) : intl.formatMessage(messages.lastNameRequired),
      )
      .trim(),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, {
        message: intl.formatMessage(messages.phoneInvalid),
      })
      .required(intl.formatMessage(messages.phoneRequired))
      .trim(),
  });

  const {
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    trigger,
    register,
  } = useForm<ShowcasingFormInputs>({
    defaultValues: {
      caseId: props.caseId,
      comment: '',
      email: customerInfo?.email || '',
      firstName: customerInfo?.firstName || '',
      lastName: customerInfo?.lastName || '',
      phoneNumber: customerInfo?.phone || '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const isLastStep = step === STEPS;

  const handleNextStep = async () => {
    await trigger();

    if (isValid && !isLastStep) {
      setStep(step => (step + 1) as Step);
    }
  };

  return (
    <Modal closeHandler={closeModal}>
      {isSubmitted ? (
        <div>
          <Block className="showcasing-form__submitted" flex>
            <Icon
              fill={modalHasError ? 'var(--color-notification)' : 'var(--color-success)'}
              height={48}
              name={modalHasError ? 'Cross' : 'Check'}
              width={48}
            />
            <Typography tagStyle="bodyLarge">
              {modalHasError ? (
                <FormattedMessage defaultMessage="There has been an error." id="BookShowcasingModal.ErrorMessage" />
              ) : (
                <FormattedMessage
                  defaultMessage="We have received your request for a demonstration and will contact you as soon as possible."
                  id="BookShowcasingModal.SuccessMessage"
                />
              )}
            </Typography>
          </Block>
          <div className="showcasing-form__button-container">
            <Button onClick={closeModal}>
              <FormattedMessage defaultMessage="Close" id="ContactSellerForm.buttonClose" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="showcasing-form">
          {isLastStep && (
            <Button onClick={() => setStep((step - 1) as Step)} version="link">
              <Icon name="ArrowLeftLong" />
            </Button>
          )}
          <Typography className="showcasing-form__title" tag="h2">
            <FormattedMessage defaultMessage="Order show casing" id="ShowcasingForm.title" />
          </Typography>
          <Typography className="showcasing-form__text" tag="p">
            <FormattedMessage
              defaultMessage="Fill in information about when it would suit you to have a show casing of the home."
              id="Showcasing.subTitle"
            />
          </Typography>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            {step === 1 && (
              <Row>
                {!isCompany && (
                  <Fragment>
                    <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
                      <Input
                        errorMessage={errors.firstName?.message}
                        label={intl.formatMessage({
                          defaultMessage: 'First name',
                          id: 'ShowcasingForm.firstname',
                        })}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'First name',
                          id: 'ShowcasingForm.firstname',
                        })}
                        {...register('firstName')}
                      />
                    </Column>
                    <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
                      <Input
                        errorMessage={errors.lastName?.message}
                        label={intl.formatMessage({
                          defaultMessage: 'Middle name & last name',
                          id: 'ShowcasingForm.lastname',
                        })}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Middle name & last name',
                          id: 'ShowcasingForm.lastname',
                        })}
                        {...register('lastName')}
                      />
                    </Column>
                  </Fragment>
                )}
                {isCompany && (
                  <Column marginBottom width={{ mobile: 8, tablet: 8 }}>
                    <Input
                      errorMessage={errors.firstName?.message}
                      label={intl.formatMessage({
                        defaultMessage: 'Company name',
                        id: 'ShowcasingForm.companyName',
                      })}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Company name',
                        id: 'ShowcasingForm.companyName',
                      })}
                      {...register('firstName')}
                    />
                  </Column>
                )}
                <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
                  <Input
                    errorMessage={errors.phoneNumber?.message}
                    label={intl.formatMessage({
                      defaultMessage: 'Phone',
                      id: 'ShowcasingForm.phone',
                    })}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Phone',
                      id: 'ShowcasingForm.phone',
                    })}
                    {...register('phoneNumber')}
                  />
                </Column>
                <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
                  <Input
                    errorMessage={errors.email?.message}
                    label="E-mail"
                    placeholder="E-mail"
                    {...register('email')}
                  />
                </Column>
              </Row>
            )}
            {step === 2 && (
              <Fragment>
                <Textarea
                  classNameInput="showcasing-form__comments"
                  errorMessage={errors.comment?.message}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Possible comment',
                    id: 'ShowcasingForm.comment',
                  })}
                  {...register('comment')}
                  noFloatingLabel
                />
                <Typography className="showcasing-form__privacyText" tag="span" tagStyle="bodySmall">
                  <FormattedMessage
                    defaultMessage="To order a show casing you will accept "
                    id="ShowcasingForm.privacyText"
                  />{' '}
                  <Button anchorTag href={theme?.privacyPolicyLink} inline version="link">
                    <FormattedMessage
                      defaultMessage="{broker} guidelines for the use of personal information."
                      id="ShowcasingForm.privacyLink"
                      values={{ broker: theme?.brokerageName }}
                    />
                  </Button>
                  {'.'}
                </Typography>
              </Fragment>
            )}
            <Row>
              <Column width={{ mobile: 8 }}>
                {isLastStep && (
                  <Button className="showcasing-form__button showcasing-form__button-step2" isFullWidth type="submit">
                    <FormattedMessage defaultMessage="Order show casing" id="ShowcasingForm.buttonText" />
                  </Button>
                )}
              </Column>
            </Row>
          </form>
          {!isLastStep && (
            <Button
              anchorTag
              className="showcasing-form__button showcasing-form__button-step1"
              isFullWidth
              onClick={handleNextStep}
            >
              <FormattedMessage defaultMessage="Order show casing" id="ShowcasingForm.buttonText" />
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

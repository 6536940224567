/**
 * @generated SignedSource<<723dae9d3528bbe58297b3f8a8ac1ede>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type caseGallery_mediaDataConnection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"caseGallerySwiper_mediaDataConnection">;
  readonly " $fragmentType": "caseGallery_mediaDataConnection";
};
export type caseGallery_mediaDataConnection$key = {
  readonly " $data"?: caseGallery_mediaDataConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"caseGallery_mediaDataConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "caseGallery_mediaDataConnection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "caseGallerySwiper_mediaDataConnection"
    }
  ],
  "type": "MediaDataConnection",
  "abstractKey": null
};

(node as any).hash = "025ea3785e5cfc5a03382f338cb7e32f";

export default node;

/**
 * @generated SignedSource<<c170f15fea25d3c7b93d6e9b15eccda6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OpenHouseInputType = {
  consentIdGlobal: string;
  email?: string | null | undefined;
  firstName: string;
  lastName: string;
  openHouseId: string;
  phoneNumber: string;
  sourceIdGlobal?: string | null | undefined;
};
export type registerOpenHouseModalMutation$variables = {
  input: OpenHouseInputType;
};
export type registerOpenHouseModalMutation$data = {
  readonly createOpenhouse: {
    readonly createdObject: {
      readonly id: string;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly message: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type registerOpenHouseModalMutation = {
  response: registerOpenHouseModalMutation$data;
  variables: registerOpenHouseModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadOpenHouse",
  "kind": "LinkedField",
  "name": "createdObject",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "registerOpenHouseModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeadOpenHouseCreatedPayload",
        "kind": "LinkedField",
        "name": "createOpenhouse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "registerOpenHouseModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeadOpenHouseCreatedPayload",
        "kind": "LinkedField",
        "name": "createOpenhouse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae230e296c39c6b06d14885b8dd0b593",
    "id": null,
    "metadata": {},
    "name": "registerOpenHouseModalMutation",
    "operationKind": "mutation",
    "text": "mutation registerOpenHouseModalMutation(\n  $input: OpenHouseInputType!\n) {\n  createOpenhouse(input: $input) {\n    createdObject {\n      id\n    }\n    errors {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6835ad8903d5874df28d6070d388e663";

export default node;

/**
 * @generated SignedSource<<c2d845698ac0e0fc09f3f2332fad2d52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type documentsPaginationQuery_CasePotentialBuyer$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
  searchText?: string | null | undefined;
};
export type documentsPaginationQuery_CasePotentialBuyer$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"favoritePageDocumentsBody_CasePotentialBuyer">;
  } | null | undefined;
};
export type documentsPaginationQuery_CasePotentialBuyer = {
  response: documentsPaginationQuery_CasePotentialBuyer$data;
  variables: documentsPaginationQuery_CasePotentialBuyer$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "documentsPaginationQuery_CasePotentialBuyer",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "favoritePageDocumentsBody_CasePotentialBuyer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "documentsPaginationQuery_CasePotentialBuyer",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "baseCase",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicDocumentAllDownloadUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicDocumentMultipleDownloadUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "DocumentConnection",
                    "kind": "LinkedField",
                    "name": "publicDocumentConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "inline",
                                    "value": false
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "previewState",
                                    "value": "FullVersion"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "documentDownloadUrlCookieAuth",
                                "storageKey": "documentDownloadUrlCookieAuth(inline:false,previewState:\"FullVersion\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "propertyOverviewUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "documentName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "documentTypeName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "readDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grantedDate",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "filters": [
                      "searchText"
                    ],
                    "handle": "connection",
                    "key": "documents__publicDocumentConnection",
                    "kind": "LinkedHandle",
                    "name": "publicDocumentConnection"
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CasePotentialBuyer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72da2c26c8476b87a34358f9980acdea",
    "id": null,
    "metadata": {},
    "name": "documentsPaginationQuery_CasePotentialBuyer",
    "operationKind": "query",
    "text": "query documentsPaginationQuery_CasePotentialBuyer(\n  $count: Int = 10\n  $cursor: String\n  $searchText: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...favoritePageDocumentsBody_CasePotentialBuyer_4CrFSY\n    id\n  }\n}\n\nfragment documentsTableRowFragment on Document {\n  documentDownloadUrlCookieAuth(inline: false, previewState: FullVersion)\n  propertyOverviewUrl\n  documentName\n  documentTypeName\n  readDate\n  grantedDate\n}\n\nfragment favoritePageDocumentsBody_CasePotentialBuyer_4CrFSY on CasePotentialBuyer {\n  baseCase {\n    __typename\n    publicDocumentAllDownloadUrl\n    publicDocumentMultipleDownloadUrl\n    publicDocumentConnection(first: $count, after: $cursor, searchText: $searchText) {\n      edges {\n        node {\n          id\n          ...documentsTableRowFragment\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "c2b974d0419d58f7a2dca71dab9701e0";

export default node;

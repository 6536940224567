import FavoritePage from 'pages/favorites/favorite-page';
import { lazy, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { SaleByOwnerPage } from 'pages/sale-by-owner/sale-by-owner-page';

const Activities = lazy(() => import('pages/activities/activities' /* webpackChunkName: "Activities" */));
const CasePage = lazy(() => import('pages/case/case' /* webpackChunkName: "Case" */));
const ActivityCasePage = lazy(
  () => import('pages/activity-case/activity-case' /* webpackChunkName: "ActivityCasePage" */),
);
const FavouritesPage = lazy(() => import(/* webpackChunkName: "FavouritesPage" */ 'pages/favorites/favourites-page'));
const MatchCasePage = lazy(() => import(/* webpackChunkName: "MatchCasePage" */ 'pages/match/match-case-page'));
const MatchPage = lazy(() => import('pages/match/match-page' /* webpackChunkName: "MatchPage" */));
const MySalesPage = lazy(() => import('app/pages/my-sales/my-sales-page' /* webpackChunkName: "MySalesPage" */));

const MessagesPage = lazy(() => import('pages/messages/messages' /* webpackChunkName: "Messages" */));
const NotFoundPage = lazy(() => import('pages/404' /* webpackChunkName: "404Page" */));
const Overview = lazy(() =>
  import('pages/overview/overview' /* webpackChunkName: "Overview" */).then(module => ({ default: module.Overview })),
);
const ProfilePage = lazy(() => import('pages/profile/profile-page' /* webpackChunkName: "ProfilePage" */));
const SearchProfile = lazy(
  () => import('pages/search-profile/create-search-profile' /* webpackChunkName: "CreateSearchProfile" */),
);

const NotificationPage = lazy(() =>
  import('pages/notification/notification-page' /* webpackChunkName: "ProfilePage" */).then(module => ({
    default: module.NotificationPage,
  })),
);

interface RouteConfig {
  component: ReactNode;
  inlcudeInNav?: boolean;
  name: ReactNode;
  path: string;
  subRoute?: RouteConfig;
}

export const mainNavigationRoutes: RouteConfig[] = [
  {
    component: <Overview />,
    name: <FormattedMessage defaultMessage="Overview" id="Routes.Overview" />,
    path: '/',
  },
  {
    component: <MessagesPage />,
    name: <FormattedMessage defaultMessage="Messages" id="Routes.Messages" />,
    path: '/messages',
  },
  {
    component: <Activities />,
    name: <FormattedMessage defaultMessage="Activities" id="Routes.Activities" />,
    path: '/activities',
  },
  {
    component: <FavouritesPage />,
    name: <FormattedMessage defaultMessage="Favorites" id="Routes.Favorites" />,
    path: '/favorites',
  },
  {
    component: <MatchPage />,
    name: <FormattedMessage defaultMessage="Match" id="Routes.Match" />,
    path: '/match',
  },
  {
    component: <MySalesPage />,
    name: <FormattedMessage defaultMessage="My sales" id="Routes.MySales" />,
    path: '/my-sales',
  },
];

export const mainAppRoutes: RouteConfig[] = [
  ...mainNavigationRoutes,
  {
    component: <FavoritePage />,
    name: <FormattedMessage defaultMessage="Favorite" id="Routes.Favorite" />,
    path: '/favorite/:favoriteId',
  },
  {
    component: <ProfilePage />,
    name: <FormattedMessage defaultMessage="Profil" id="Routes.Profile" />,
    path: '/profile',
  },
  {
    component: <SearchProfile />,
    name: <FormattedMessage defaultMessage="Søge Profil" id="Routes.SearchProfile" />,
    path: '/create-search-profile/:searchProfileId',
  },
  {
    component: <CasePage />,
    name: <FormattedMessage defaultMessage="Case" id="Routes.Case" />,
    path: '/case/:offerId',
  },
  {
    component: <MatchCasePage />,
    name: <FormattedMessage defaultMessage="Match" id="Routes.MatchCase" />,
    path: '/match/:matchId',
  },
  {
    component: <ActivityCasePage />,
    name: <FormattedMessage defaultMessage="Activity Case" id="Routes.ActivityCase" />,
    path: '/activity-case/:activityCaseId',
  },
  {
    component: <NotificationPage />,
    name: <FormattedMessage defaultMessage="Notification" id="Routes.Notification" />,
    path: '/notification/:notificationId',
  },
  {
    component: <SaleByOwnerPage />,
    name: <FormattedMessage defaultMessage="Self-sale" id="Routes.Sale" />,
    path: '/sale',
  },
  {
    component: <NotFoundPage />,
    name: '404',
    path: '*',
  },
];

import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import './row.scss';

export interface RowProps extends HTMLAttributes<HTMLDivElement> {
  alignCenter?: boolean;
  center?: boolean;
  noAdjacentMargin?: boolean;
  pageHeight?: boolean;
  pushBottom?: boolean;
  pushTop?: boolean;
  spaceBetween?: boolean;
}

const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      children,
      className,
      pageHeight,
      pushTop,
      pushBottom,
      spaceBetween,
      alignCenter,
      center,
      noAdjacentMargin,
      ...rest
    },
    ref,
  ) => {
    const classList = classNames('row', className, {
      alignCenter,
      center,
      noAdjacentMargin,
      pageHeight,
      pushBottom,
      pushTop,
      spaceBetween,
    });
    return (
      <div ref={ref} {...rest} className={classList}>
        {children}
      </div>
    );
  },
);

Row.displayName = 'Row';

export default Row;

export const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer | null) => {
  if (!arrayBuffer) {
    return '';
  }

  // Convert ArrayBuffer to Uint8Array
  const uint8Array = new Uint8Array(arrayBuffer);

  // Create a binary string from Uint8Array
  let binaryString = '';
  for (let i = 0; i < uint8Array.length; i++) {
    binaryString += String.fromCharCode(uint8Array[i]);
  }

  // Convert binary string to Base64
  const base64String = window.btoa(binaryString);

  return base64String;
};

import { caseGallery_mediaDataConnection$key } from '__generated__/caseGallery_mediaDataConnection.graphql';
import PopupOverlay from 'components/molecules/popup-overlay/popup-overlay';
import Container from 'components/utilities/container/container';
import { graphql, useFragment } from 'react-relay';
import { FC } from 'react';
import { CaseGallerySwiper } from '../case-gallery-swiper/case-gallery-swiper';
import './case-gallery.scss';

const caseGalleryFragment = graphql`
  fragment caseGallery_mediaDataConnection on MediaDataConnection {
    ...caseGallerySwiper_mediaDataConnection
  }
`;
export interface CaseGalleryProps {
  onCloseGallery: () => void;
  onSelectFullscreen: (imageIndex: number) => void;
  onSelectMap: () => void;
  slideToImage: number;
  mediaDataConnection: caseGallery_mediaDataConnection$key | null;
}

export const CaseGallery: FC<CaseGalleryProps> = props => {
  const mediaDataConnection = useFragment(caseGalleryFragment, props.mediaDataConnection);

  const { onSelectFullscreen, onSelectMap, onCloseGallery, slideToImage } = props;

  return (
    <div className="case-gallery">
      <PopupOverlay onClose={onCloseGallery}>
        <Container>
          <CaseGallerySwiper
            mediaDataConnection={mediaDataConnection}
            onSelectFullscreen={onSelectFullscreen}
            onSelectMap={onSelectMap}
            slideToImage={slideToImage}
          />
        </Container>
      </PopupOverlay>
    </div>
  );
};

import { myPageSpotsEntity$key } from '__generated__/myPageSpotsEntity.graphql';
import Container from 'components/utilities/container/container';
import { useList } from 'hooks/useList';
import { FC, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import './my-page-spots.scss';

export interface MyPageSpotsProps {
  myPageSpots: myPageSpotsEntity$key;
  filter: string;
}

const MyPageSpots: FC<MyPageSpotsProps> = props => {
  const data = useFragment<myPageSpotsEntity$key>(
    graphql`
      fragment myPageSpotsEntity on Viewer {
        myPageSpots {
          myPageSpotRole
          entries {
            id
            linkUrl
            imageUrl
            width
          }
        }
      }
    `,
    props.myPageSpots,
  );
  const myPageSpot = useMemo(() => {
    if (data.myPageSpots) {
      return data.myPageSpots.find(spot => spot?.myPageSpotRole === props.filter);
    } else {
      return null;
    }
  }, [data, props.filter]);

  const entries = useList(myPageSpot?.entries);

  const link = useMemo(() => {
    if (entries.length > 0) {
      return entries[0]?.linkUrl ? entries[0].linkUrl : '';
    } else {
      return '';
    }
  }, [entries]);
  return (
    <Container>
      {entries.length > 0 && (
        <div className="my-page-spots">
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <picture>
                {entries.map(entry => (
                  <source
                    className={'my-page-spots__image'}
                    key={entry.id}
                    media={`(min-width: ${entry.width ?? 0}px)`}
                    srcSet={entry.imageUrl ?? ''}
                  />
                ))}

                <img alt="my page spot img" className={'my-page-spots__image'} src={entries[0]?.imageUrl ?? ''} />
              </picture>
            </a>
          ) : (
            <picture>
              {entries.map(entry => (
                <source
                  className={'my-page-spots__image'}
                  key={entry.id}
                  media={`(max-width: ${entry.width ?? 0}px)`}
                  srcSet={entry.imageUrl ?? ''}
                />
              ))}
              <img alt="my page spot img" className={'my-page-spots__image'} src={entries[0]?.imageUrl ?? ''} />
            </picture>
          )}
        </div>
      )}
    </Container>
  );
};

export default MyPageSpots;

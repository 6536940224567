/**
 * @generated SignedSource<<20c91ef91ef1a52f80f26060cfeca7be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WebPushSubscriptionInput = {
  auth: string;
  endpoint: string;
  p256Dh: string;
};
export type unsubscribeClientMutation$variables = {
  input: WebPushSubscriptionInput;
};
export type unsubscribeClientMutation$data = {
  readonly unsubscribe: boolean | null | undefined;
};
export type unsubscribeClientMutation = {
  response: unsubscribeClientMutation$data;
  variables: unsubscribeClientMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "unsubscribe",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unsubscribeClientMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unsubscribeClientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "df4ee0296d10cfdb8fa779d7eda7ba4d",
    "id": null,
    "metadata": {},
    "name": "unsubscribeClientMutation",
    "operationKind": "mutation",
    "text": "mutation unsubscribeClientMutation(\n  $input: WebPushSubscriptionInput!\n) {\n  unsubscribe(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "d307353c63f3f3ea051b8ab77410b0a6";

export default node;

import { AppTheme } from 'configs/app-theme-defaults';

const fetchTheme = (url: string) =>
  fetch(url)
    .then(res => res.json())
    .then(config => {
      return config;
    })
    .catch(e => console.error(e));

const applyThemeConfigToDocument = (themeConfig: AppTheme) => {
  const { rootVars, assets } = themeConfig;
  if (rootVars) {
    Object.entries(rootVars).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
  }

  if (assets) {
    for (const asset in assets) {
      const cssUrls = assets[asset].cssUrls;
      if (cssUrls) {
        for (const cssUrl of cssUrls) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = cssUrl;
          document.head.append(link);
        }
      }
    }
  }
};

export const loadThemeConfig = async (url: string) => {
  try {
    const config = await fetchTheme(url);
    if (config) {
      applyThemeConfigToDocument(config);
    }
    return config;
  } catch (e) {
    return {
      theme: 'default',
    };
  }
};

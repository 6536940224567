import Icon from 'components/atoms/icon/icon';
import Loader from 'components/atoms/loader/loader';
import Typography from 'components/atoms/typography/typography';
import GalleryNavigation from 'components/molecules/gallery-navigation/gallery-navigation';
import { CaseGallery } from 'components/organisms/case-gallery/case-gallery';
import MapOverlay from 'components/organisms/map-overlay/map-overlay';
import { SuspenseHoc } from 'components/templates/suspense-hoc';
import Column from 'components/utilities/column/column';
import Container from 'components/utilities/container/container';
import Row from 'components/utilities/row/row';
import { useNodes } from 'hooks/useNodes';
import { FC, Fragment, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { caseImages_Media$key } from '__generated__/caseImages_Media.graphql';
import CaseImagesSwiper from 'components/molecules/case-images-swiper/case-images-swiper';
import { CaseFullscreenGallery } from 'components/molecules/case-fullscreen-gallery/case-fullscreen-gallery';

const caseImagesFragment = graphql`
  fragment caseImages_Media on MediaDataConnection {
    ...caseGallery_mediaDataConnection
    ...caseFullscreenGallery_mediaDataConnection
    ...caseImagesSwiper_mediaDataConnection
    edges {
      node {
        resourceUrl(resourceType: Asset)
        mediaType {
          name
        }
      }
    }
  }
`;

const getImageColumnWidth = (images: readonly unknown[]) => {
  if (images.length >= 3) {
    return 6;
  } else if (images.length === 2) {
    return 4;
  } else {
    return 8;
  }
};

interface CaseImagesProps {
  fragment: caseImages_Media$key | undefined | null;
  gpsCoordinates: string;
}

export const CaseImages: FC<CaseImagesProps> = ({ fragment, gpsCoordinates }) => {
  const data = useFragment(caseImagesFragment, fragment ?? null);

  const images = useNodes(data?.edges);
  const firstImage = images[0] ? images[0] : null;
  const secondImage = images[1] ? images[1] : null;
  const thirdImage = images[2] ? images[2] : null;
  const hiddenNumberOfImages = images.length - 3;

  const [galleryOpen, setGalleryOpen] = useState<number | null>(null);
  const [mapOverlayOpen, setMapOverlayOpen] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [fullscreenGalleryOpen, setFullscreenGalleryOpen] = useState<number | null>(null);

  const hasPlan = useMemo(() => images.some(image => image.mediaType?.name === 'Plantegning'), [images]);
  const hasVideo = useMemo(() => images.some(image => image.mediaType?.name === 'Video'), [images]);

  const handleSelectMap = () => {
    if (gpsCoordinates) {
      const coords = gpsCoordinates.split(' ');
      setMapOverlayOpen({
        lat: +coords[0],
        lng: +coords[1],
      });
    }
  };

  const onImageClick = (imageIndex: number) => {
    if (images.length > 0) {
      setGalleryOpen(imageIndex);
    }
  };
  const onSelectFullscreen = (imageIndex: number) => setFullscreenGalleryOpen(imageIndex);

  const onSelectPlan = () => {
    setFullscreenGalleryOpen(images.findIndex(image => image.mediaType?.name === 'Plantegning'));
  };

  const onSelectVideo = () => {
    setFullscreenGalleryOpen(images.findIndex(image => image.mediaType?.name === 'Video'));
  };

  if (!images) return <Loader />;

  return (
    <Fragment>
      <Container className="case-hero-images" fullWidth paddingBottomReset paddingTopReset>
        <Row className="case-hero-images__desktop">
          <Column className="image-container" width={{ mobile: 8, tablet: getImageColumnWidth(images) }}>
            <div className="image-container__image" onClick={() => onImageClick(0)}>
              {firstImage && <img alt="firstImage" src={firstImage?.resourceUrl ?? ''} />}
              {!firstImage && <Icon name="Image" />}
            </div>
          </Column>
          {secondImage && (
            <Column className="image-container" width={{ mobile: 0, tablet: images.length >= 3 ? 2 : 4 }}>
              <div className="image-container__image" onClick={() => onImageClick(1)}>
                <img alt="" src={secondImage.resourceUrl ?? ''} />
              </div>
              {thirdImage && (
                <div
                  className={'image-container__image ' + (hiddenNumberOfImages > 0 ? 'has-overlay' : '')}
                  onClick={() => onImageClick(2)}
                >
                  {hiddenNumberOfImages > 0 && (
                    <div className="has-overlay__text">
                      <Icon name="Images" />
                      <Typography tagStyle="bodyMedium">
                        <FormattedMessage
                          defaultMessage={'+ {count} {count, plural, one {billede} other {billeder}}'}
                          values={{ count: hiddenNumberOfImages }}
                          id="CaseGallery.image"
                        />
                      </Typography>
                    </div>
                  )}

                  <img alt="" src={thirdImage?.resourceUrl ?? ''} />
                </div>
              )}
            </Column>
          )}
        </Row>
        <Row className="case-hero-images__mobile">
          <CaseImagesSwiper mediaDataConnection={data} onImageClick={imageIndex => onSelectFullscreen(imageIndex)} />
        </Row>
        <GalleryNavigation
          isFullscreenVisible={images.length > 0}
          isPlanVisible={hasPlan}
          isVideoInList={hasVideo}
          onSelectVideo={onSelectVideo}
          onSelectFullscreen={() => onSelectFullscreen(0)}
          onSelectMap={handleSelectMap}
          onSelectPlan={onSelectPlan}
        />
      </Container>
      {galleryOpen !== null && (
        <SuspenseHoc fallback={<Loader />}>
          <CaseGallery
            onCloseGallery={() => setGalleryOpen(null)}
            onSelectFullscreen={setFullscreenGalleryOpen}
            onSelectMap={handleSelectMap}
            slideToImage={galleryOpen}
            mediaDataConnection={data}
          />
        </SuspenseHoc>
      )}
      {fullscreenGalleryOpen !== null && (
        <CaseFullscreenGallery
          mediaDataConnection={data}
          onCloseFullscreen={() => setFullscreenGalleryOpen(null)}
          slideToImage={fullscreenGalleryOpen}
        />
      )}
      {mapOverlayOpen && (
        <MapOverlay lat={mapOverlayOpen.lat} lng={mapOverlayOpen.lng} onClose={() => setMapOverlayOpen(null)} />
      )}
    </Fragment>
  );
};

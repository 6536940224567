/**
 * @generated SignedSource<<6156cf4bf7ce86e183f1c40abc2ea784>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchProfileStatus = "Favorite" | "None" | "Removed" | "Selected";
export type UpdateSearchProfileMatchedCaseInput = {
  id: string;
  status: MatchProfileStatus;
};
export type updateSearchProfileMatchedCase_Mutation$variables = {
  input: UpdateSearchProfileMatchedCaseInput;
};
export type updateSearchProfileMatchedCase_Mutation$data = {
  readonly updateSearchProfileMatchedCase: {
    readonly errors: ReadonlyArray<{
      readonly message: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly updatedObject: {
      readonly " $fragmentSpreads": FragmentRefs<"matchCardFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type updateSearchProfileMatchedCase_Mutation = {
  response: updateSearchProfileMatchedCase_Mutation$data;
  variables: updateSearchProfileMatchedCase_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 5
    }
  ],
  "concreteType": "MediaDataConnection",
  "kind": "LinkedField",
  "name": "mediaDataConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MediaDataEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Media",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "resourceType",
                  "value": "Presentation"
                }
              ],
              "kind": "ScalarField",
              "name": "resourceUrl",
              "storageKey": "resourceUrl(resourceType:\"Presentation\")"
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "mediaDataConnection(first:5)"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipCode",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": "businessPropertyType",
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSearchProfileMatchedCase_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchProfileMatchedCaseUpdatedPayload",
        "kind": "LinkedField",
        "name": "updateSearchProfileMatchedCase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchProfileMatchedCase",
            "kind": "LinkedField",
            "name": "updatedObject",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "matchCardFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSearchProfileMatchedCase_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchProfileMatchedCaseUpdatedPayload",
        "kind": "LinkedField",
        "name": "updateSearchProfileMatchedCase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchProfileMatchedCase",
            "kind": "LinkedField",
            "name": "updatedObject",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayAsNewMatch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "baseCase",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "amount",
                        "value": 1
                      }
                    ],
                    "concreteType": "OpenHouse",
                    "kind": "LinkedField",
                    "name": "openHouse",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityType",
                        "kind": "LinkedField",
                        "name": "activityType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameOnTimeline",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isRegistration",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": "openHouse(amount:1)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noAdvertisement",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gpsCoordinates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BaseCaseMainOffer",
                    "kind": "LinkedField",
                    "name": "baseCaseMainOffer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cashPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CaseAreas",
                    "kind": "LinkedField",
                    "name": "caseAreas",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "livableArea",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "plotAreaSum",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "SalesCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "RectifyCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "CounselingPropertyCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "RentalCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "CounselingCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "floorAreaTotal",
                        "storageKey": null
                      }
                    ],
                    "type": "BusinessSalesCase",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/)
                    ],
                    "type": "BusinessRentalCase",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OffmarketCase",
                "kind": "LinkedField",
                "name": "offmarketCase",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessPropertyType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "caseDescriptionHeading",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "334e25849fbba53316113566473e576c",
    "id": null,
    "metadata": {},
    "name": "updateSearchProfileMatchedCase_Mutation",
    "operationKind": "mutation",
    "text": "mutation updateSearchProfileMatchedCase_Mutation(\n  $input: UpdateSearchProfileMatchedCaseInput!\n) {\n  updateSearchProfileMatchedCase(input: $input) {\n    errors {\n      message\n      id\n    }\n    updatedObject {\n      ...matchCardFragment\n      id\n    }\n  }\n}\n\nfragment matchCardFragment on SearchProfileMatchedCase {\n  id\n  status\n  displayAsNewMatch\n  baseCase {\n    __typename\n    openHouse(amount: 1) {\n      activityType {\n        nameOnTimeline\n        isRegistration\n        id\n      }\n      startDate\n      id\n    }\n    noAdvertisement\n    gpsCoordinates\n    address\n    city\n    id\n    baseCaseMainOffer {\n      cashPrice\n    }\n    mediaDataConnection(first: 5) {\n      edges {\n        node {\n          resourceUrl(resourceType: Presentation)\n          id\n        }\n      }\n    }\n    caseAreas {\n      livableArea\n      plotAreaSum\n    }\n    ... on SalesCase {\n      propertyType\n      zipCode\n    }\n    ... on RectifyCase {\n      propertyType\n      zipCode\n    }\n    ... on CounselingPropertyCase {\n      propertyType\n      zipCode\n    }\n    ... on RentalCase {\n      propertyType\n      zipCode\n    }\n    ... on CounselingCase {\n      propertyType\n      zipCode\n    }\n    ... on BusinessSalesCase {\n      businessPropertyType: propertyType\n      zipCode\n      floorAreaTotal\n    }\n    ... on BusinessRentalCase {\n      businessPropertyType: propertyType\n      zipCode\n    }\n  }\n  offmarketCase {\n    propertyType\n    businessPropertyType\n    caseDescriptionHeading\n    mediaDataConnection(first: 5) {\n      edges {\n        node {\n          resourceUrl(resourceType: Presentation)\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ff4cbafe9f089bdc50eeae7ab97ac1a";

export default node;

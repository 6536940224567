/**
 * @generated SignedSource<<f753187c5285622597baa73bcb1622fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnergyMarkSeverity = "A" | "A1" | "A2" | "A2010" | "A2015" | "A2020" | "B" | "C" | "D" | "E" | "F" | "G" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentRentalCase$data = {
  readonly accountElectricity?: any | null | undefined;
  readonly accountHeat?: any | null | undefined;
  readonly accountWater?: any | null | undefined;
  readonly caseAreas: {
    readonly livableArea: number | null | undefined;
  } | null | undefined;
  readonly deposit?: any | null | undefined;
  readonly energyMark: EnergyMarkSeverity | null | undefined;
  readonly prepaidRent?: any | null | undefined;
  readonly rentMonthly?: any | null | undefined;
  readonly roomCount: number | null | undefined;
  readonly yearBuilt: number | null | undefined;
  readonly " $fragmentType": "matchHeroContentRentalCase";
};
export type matchHeroContentRentalCase$key = {
  readonly " $data"?: matchHeroContentRentalCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentRentalCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentRentalCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "energyMark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yearBuilt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaseAreas",
      "kind": "LinkedField",
      "name": "caseAreas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livableArea",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rentMonthly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountWater",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountHeat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountElectricity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prepaidRent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deposit",
          "storageKey": null
        }
      ],
      "type": "RentalCase",
      "abstractKey": null
    }
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};

(node as any).hash = "eba1845af439cc8f9e366f061f8b7e10";

export default node;

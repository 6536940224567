import { useMemo } from 'react';

export type Edges<T> = readonly (Readonly<{ node: T | null | undefined }> | null | undefined)[] | null | undefined;

export function useNodes<T>(edges: Edges<T>): readonly T[] {
  return useMemo(() => {
    if (!edges) {
      return [];
    }

    return edges.reduce((accumulator, edge) => {
      if (notEmpty(edge) && notEmpty(edge.node)) {
        accumulator.push(edge.node as T);
      }

      return accumulator;
    }, [] as T[]);
  }, [edges]);
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

import { differenceInDays, endOfDay } from 'date-fns';
import { useEffect, useState } from 'react';

interface iosNavigator extends Navigator {
  standalone: boolean;
}

function checkForIOS() {
  const today = endOfDay(new Date());
  const installPrompt = localStorage.getItem('installPrompt');
  const lastPrompt = new Date(installPrompt ?? '');
  const days = differenceInDays(today, lastPrompt);
  const isIOS = /(iPhone|iPod|iPad)/i.test(window.navigator.platform);
  const isSafari = !!window.navigator.userAgent.match(/safari/i);
  const prompt = (isNaN(days) || days > 30) && isIOS && isSafari;
  const isAndroid = !isIOS;

  // console.log('today: ' + today);
  // console.log('installPrompt: ' + installPrompt);
  // console.log('lastPrompt: ' + lastPrompt);
  // console.log('days: ' + days);
  // console.log('prompt: ' + prompt);

  if (isIOS && isSafari) {
    const nav = window.navigator as iosNavigator;
    if (nav.standalone) {
      return { isIOS: false, isSafari: false, prompt: false, isAndroid: false };
    }
  }

  if (installPrompt === 'dont show') {
    return { isIOS: false, isSafari: false, prompt: false, isAndroid: false };
  }

  if (prompt && 'localStorage' in window) {
    localStorage.setItem('installPrompt', today.toString());
  }

  return { isIOS, isSafari, prompt, isAndroid };
}

export default function useIsIOS() {
  const [isIOS, setIsIOS] = useState({ isIOS: false, isSafari: false, prompt: false, isAndroid: false });

  useEffect(() => {
    setIsIOS(checkForIOS());
  }, []);

  return isIOS;
}

import { graphql } from 'react-relay';

export const createThreadMutation = graphql`
  mutation createThread_Mutation($input: CreateThreadInput, $connections: [ID!]!) {
    createThread(input: $input) {
      createdObject @prependNode(connections: $connections, edgeTypeName: "ReviewEdge") {
        lastMessage {
          id @required(action: LOG)
          createDate @required(action: LOG)
        }
        id
        createDate
        subject
        hasNewMessages
        messageConnection(first: 1) {
          items {
            author {
              fullName
            }
          }
        }
        threadParticipants(excludeSelf: true) {
          person {
            fullName
          }
          lastReadMessageDate
        }
      }
      errors {
        message
      }
    }
  }
`;

/* eslint custom-eslint-plugin/unused-fields: 0 */
import { MyPageTextSection } from 'Enums';
import { viewerContextQuery, viewerContextQuery$data } from '__generated__/viewerContextQuery.graphql';
import { FC, ReactNode, createContext, useContext } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

export type Viewer = NonNullable<viewerContextQuery$data['viewer']> | null;
export type ConfigurableText = NonNullable<
  NonNullable<viewerContextQuery$data['viewer']>['configurableTextSections']
> | null;

const ViewerContext = createContext<Viewer>(null);
export const useViewer = () => useContext(ViewerContext);

type Props = {
  children: ReactNode;
};

const ViewerProvider: FC<Props> = providerProps => {
  const query = graphql`
    query viewerContextQuery {
      viewer {
        vapidPublicKey
        featureToggle {
          isAnalyticReportsEnabled
          isSellerSaysEnabled
          isMyNeighborhoodEnabled
          isMatchExternalMarketCaseEnabled
          isWebPushNotificationsEnabled
          isCanBeContactedByPhoneEnabled
          searchProfileTypes
          isPropertyForSaleByOwnerEnabled
          useSearchProfileContactShop
          rentalSearchProfileEnabled
          useCumulativeDaysOnMarketInSearchProfile
          searchProfileWords
          useGenerelConsent
          useDistancesInSearchProfile
        }
        customer {
          id
        }
        configurableTextSections(
          myPageTextSections: [
            CasePromotion
            Consent
            CustomerGDPR
            Favorites
            GetStarted
            Match
            MyNeighbourhood
            UserProfile
            Welcome
            WelcomeHeadline
            Messages
            UpcomingActivities
            FinishedActivties
            OtherBrokerFavorites
            EditSearchProfile
            SearchProfileMatch
          ]
        ) {
          sectionText
          myPageTextSection
          fileDownloadUrl
        }
      }
    }
  `;

  const { error, data } = useQuery<viewerContextQuery>(query);

  if (error) {
    throw error;
  }

  if (!data?.viewer) {
    return null;
  }

  return <ViewerContext.Provider value={data.viewer}>{providerProps.children}</ViewerContext.Provider>;
};

export default ViewerProvider;

export const findConfigurableSectionText = (textList: ConfigurableText, key: MyPageTextSection) => {
  const arr = textList || [];
  return arr.find(text => text?.myPageTextSection === key);
};

/**
 * @generated SignedSource<<3f2c24b743c089846719c04bbe4f6747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type activityNotification_fragment$data = {
  readonly appointmentActivity: {
    readonly baseCase: {
      readonly address: string | null | undefined;
    } | null | undefined;
    readonly endDate: any | null | undefined;
    readonly location: string | null | undefined;
    readonly name: string | null | undefined;
    readonly startDate: any | null | undefined;
  };
  readonly createdDate: any;
  readonly read: boolean;
  readonly " $fragmentType": "activityNotification_fragment";
};
export type activityNotification_fragment$key = {
  readonly " $data"?: activityNotification_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"activityNotification_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "activityNotification_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppointmentActivity",
      "kind": "LinkedField",
      "name": "appointmentActivity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "baseCase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    }
  ],
  "type": "ActivityNotification",
  "abstractKey": null
};

(node as any).hash = "d225dbfd031172cbea0a5099e05d0dfb";

export default node;

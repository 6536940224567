import Block from 'components/utilities/block/block';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import Button from 'components/atoms/button/button';
import { FC } from 'react';

type SuccessSummitProps = {
  hasServerError: boolean;
  closeModal: () => void;
};
export const SuccessSummit: FC<SuccessSummitProps> = props => {
  return (
    <Block className="form-success" flex>
      <Icon
        fill={props.hasServerError ? 'var(--color-notification)' : 'var(--color-text-grey)'}
        height={48}
        name={props.hasServerError ? 'Cross' : 'Check'}
        width={48}
        className="form-success__icon"
      />
      <Typography tagStyle="bodyLarge" className="form-success__text">
        {props.hasServerError ? (
          <FormattedMessage defaultMessage="We are sorry, something went wrong" id="Default.ErrorMessage" />
        ) : (
          <FormattedMessage
            defaultMessage="We have now received your request to participate in the Open House. We will contact you as soon as possible with a final confirmation of the time"
            id="RegisterOpenHouse.RegisteredOpenHouse"
          />
        )}
      </Typography>
      <Button onClick={props.closeModal} type="submit">
        <FormattedMessage defaultMessage="Go back" id="Default.goBack" />
      </Button>
    </Block>
  );
};

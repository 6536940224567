import { Activity } from './choose-activity-modal';
import { ChangeEvent } from 'react';
import { chooseActivityModal_Query$data } from '__generated__/chooseActivityModal_Query.graphql';

export const getSpecificActivityObj = (
  data: chooseActivityModal_Query$data | null | undefined,
  isRegistration: boolean,
  chosenActivity: Activity,
) => {
  if (!data || !data.viewer) {
    return null;
  }
  return data.viewer.propertyForSaleByOwnerAppointmentActivityTypes?.find(activity => {
    if (chosenActivity === 'openHouse' && isRegistration) {
      return activity?.category === 'OpenHouseWithRegistration';
    } else if (chosenActivity === 'showCasing') {
      return activity?.category === 'Showcasing';
    } else {
      return activity?.category === 'OpenHouse';
    }
  });
};

const DEFAULT_ADDED_MINUTES = 30;
export const getDateWithAddedDefaultDurationInMin = (
  data: chooseActivityModal_Query$data | null | undefined,
  isRegistration: boolean,
  chosenActivity: Activity,
) => {
  const getAddedMinutes = () => {
    if (!data) {
      return DEFAULT_ADDED_MINUTES;
    }

    return (
      getSpecificActivityObj(data, isRegistration, chosenActivity)?.defaultDurationInMinutes ?? DEFAULT_ADDED_MINUTES
    );
  };

  const date = new Date();
  date.setMinutes(date.getMinutes() + getAddedMinutes());
  return date;
};
export const getDateWithSelectedTime = (e: ChangeEvent<HTMLInputElement>, selectedDate: Date): Date => {
  const timeString = e.target.value.split(':');
  const hours = parseInt(timeString[0]);
  const minutes = parseInt(timeString[1]);
  const givenDate = new Date(selectedDate);
  givenDate.setHours(hours);
  givenDate.setMinutes(minutes);
  return givenDate;
};

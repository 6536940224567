import Icon from 'components/atoms/icon/icon';
import { format } from 'date-fns';
import da from 'date-fns/locale/da';
import {
  CaptionProps,
  DayPicker,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  useNavigation,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { FormattedMessage } from 'react-intl';
import './date-picker.scss';
import { FC } from 'react';

export type DatePickerProps =
  | DayPickerDefaultProps
  | DayPickerSingleProps
  | DayPickerMultipleProps
  | DayPickerRangeProps;

const DatePicker: FC<DatePickerProps> = props => {
  return (
    <DayPicker
      className="rdpModifier"
      components={{
        Caption: CustomCaption,
      }}
      locale={da}
      modifiersClassNames={{
        outside: 'outside',
        today: 'today',
      }}
      {...props}
    />
  );
};

const CustomCaption = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <div className="customCaption">
      <button className="prevMonth" disabled={!previousMonth} onClick={() => previousMonth && goToMonth(previousMonth)}>
        <span className="navButtonText">
          <FormattedMessage defaultMessage="Previous" id="Calendar.Previous" />
        </span>
        <Icon name="ArrowLeft" />
      </button>
      <h3>{format(props.displayMonth, 'MMMM yyy', { locale: da })}</h3>
      <button className="nextMonth" disabled={!nextMonth} onClick={() => nextMonth && goToMonth(nextMonth)}>
        <span className="navButtonText">
          <FormattedMessage defaultMessage="Next" id="Calendar.Next" />
        </span>
        <Icon name="ArrowRight" />
      </button>
    </div>
  );
};

export default DatePicker;

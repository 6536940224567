import Icon from 'components/atoms/icon/icon';
import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import { formatDate, getRelatedLink } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { graphql, useFragment } from 'react-relay';
import { documentSigningNotification_fragment$key } from '__generated__/documentSigningNotification_fragment.graphql';

export interface DocumentSigningNotificationProps {
  notification: documentSigningNotification_fragment$key;
}

const documentSigningNotification = graphql`
  fragment documentSigningNotification_fragment on DocumentSigningNotification {
    read
    baseCase {
      relationType {
        relationId
        relationType
      }
    }
    document {
      documentTypeName
    }
    address
    createdDate
  }
`;

export const DocumentSigningNotification: FC<DocumentSigningNotificationProps> = ({ notification }) => {
  const data = useFragment<documentSigningNotification_fragment$key>(documentSigningNotification, notification);

  const relationType = {
    relationType: data.baseCase.relationType?.relationType,
    relationId: data.baseCase.relationType?.relationId,
  };
  return (
    <HashLink
      className={classNames('notifications__link', { unread: !data?.read })}
      smooth
      to={getRelatedLink(relationType, '#sign')}
    >
      <Icon name="Bell" />
      <div className="notifications__item-text">
        <Typography>
          <b>
            {data?.document?.documentTypeName}{' '}
            <FormattedMessage defaultMessage="Ready to sign" id="Notifications.ReadyToSign" />:
          </b>{' '}
          {data.address}
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </HashLink>
  );
};

import classNames from 'classnames';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { Fragment, useRef, useState } from 'react';
import './custom-select.scss';

interface SelectOption {
  name: string;
  selected?: boolean;
  value: string;
}

export interface CustomSelectProps {
  bigVersion?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (option: SelectOption) => void;
  options?: SelectOption[];
  placeholder?: string;
  selectedOption?: SelectOption;
}

const CustomSelect = (props: CustomSelectProps) => {
  const { bigVersion, options, label, placeholder, errorMessage, onChange, selectedOption } = props;

  const [isOpen, setIsOpen] = useState(false);
  const customSelectRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(customSelectRef, () => setIsOpen(false));

  return (
    <Fragment>
      <div ref={customSelectRef} className={classNames('custom-select', { bigVersion })}>
        <Typography className="custom-select__label" tag="label" tagStyle="bodySmall">
          {label}
        </Typography>
        <Button
          className={classNames('custom-select__value', {
            placeholder: !selectedOption,
          })}
          iconEnd="TickDown"
          iconHeight={5}
          iconWidth={10}
          onClick={() => setIsOpen(prev => !prev)}
          version="link"
        >
          {selectedOption?.name || placeholder}
        </Button>

        {isOpen && (
          <div className="custom-select__options">
            {options &&
              options.map(option => (
                <Button
                  key={option.value}
                  className="custom-select__options--item"
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                  }}
                  version="link"
                >
                  {option.name}
                </Button>
              ))}
          </div>
        )}
      </div>
      {errorMessage && (
        <Typography subjectColor="error" tag="span" tagStyle="bodySmall">
          *{errorMessage}
        </Typography>
      )}
    </Fragment>
  );
};

CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;

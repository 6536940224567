import Checkbox from 'components/molecules/checkbox/checkbox';
import { ComponentPropsWithoutRef, FC, useEffect, useRef } from 'react';
import './indeterminate-checkbox.scss';

export interface IndeterminateCheckboxProps extends ComponentPropsWithoutRef<'input'> {
  indeterminate?: boolean;
}

const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({ indeterminate, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && inputRef.current) {
      inputRef.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [inputRef, indeterminate, rest.checked]);

  return <Checkbox ref={inputRef} {...rest} />;
};

export default IndeterminateCheckbox;

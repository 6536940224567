import { GoogleMap, Libraries, Marker, useJsApiLoader } from '@react-google-maps/api';
import PopupOverlay from 'components/molecules/popup-overlay/popup-overlay';

import { FC } from 'react';
import { appSettings } from '../../../setup/setup-app-settings';
import './map-overlay.scss';

export interface MapOverlayProps {
  lat: number;
  lng: number;
  onClose: () => void;
}

const LIBRARIES: Libraries = ['places'];

const MapOverlay: FC<MapOverlayProps> = props => {
  const { onClose, lat, lng } = props;

  const mapOptions = {
    fullscreenControl: false,
    gestureHandling: 'greedy',
    mapTypeControl: false,
    overviewMapControl: true,
    rotateControl: true,
    scaleControl: true,
    scrollwheel: true,
    streetViewControl: false,
    zoomControl: true,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: LIBRARIES,
    googleMapsApiKey: appSettings.settings.GOOGLE_MAPS_API_KEY,
  });

  return (
    <PopupOverlay className="map-overlay" onClose={onClose}>
      <div className="map-overlay-map">
        {isLoaded && (
          <GoogleMap center={{ lat, lng }} mapContainerStyle={{ height: '100%' }} options={mapOptions} zoom={14}>
            <Marker position={{ lat, lng }} />
          </GoogleMap>
        )}
      </div>
    </PopupOverlay>
  );
};

export default MapOverlay;

/**
 * @generated SignedSource<<6d6f1e975380cbf795baa3c13f8ba7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPropertyType = "Cattle" | "Cellar" | "Clinic" | "Crop" | "FarmLand" | "ForestProperty" | "FreeLand" | "Garage" | "HotelOrRestaurant" | "HousingAndBusiness" | "HousingCooperative" | "Investment" | "Liberal" | "MinkAndFurAnimals" | "Office" | "Parking" | "Pigs" | "Plot" | "PoultryProduction" | "ProductionFacility" | "ShoppingCenter" | "Showroom" | "SpecialProduction" | "Store" | "WareHouse" | "WareHouseAndProduction" | "Workshop";
export type PropertyType = "AllYearRoundPlot" | "AllotmentHut" | "AllotmentPlot" | "Condo" | "FarmHouseAgriculture" | "FormerFarm" | "FreeLand" | "HobbyAgriculture" | "HousingCooperative" | "IdeelAnpartCondo" | "IdeelAnpartTerracedHouse" | "IdeelAnpartVilla" | "Liebhaveri" | "MixedHousingAndBusiness" | "PartnershipOrLimitedPartnershipCondo" | "PartnershipOrLimitedPartnershipTerracedHouse" | "PartnershipOrLimitedPartnershipVilla" | "TerracedHouse" | "TerracedHouseWithCondoStatus" | "TwoFamilyProperty" | "VacationCondo" | "VacationHousing" | "VacationPlot" | "Villa" | "VillaApartment" | "VillaWithCondoStatus";
import { FragmentRefs } from "relay-runtime";
export type matchHeroOffmarketDetailsFragment_offmarketCase$data = {
  readonly businessPropertyType: BusinessPropertyType | null | undefined;
  readonly propertyType: PropertyType | null | undefined;
  readonly " $fragmentType": "matchHeroOffmarketDetailsFragment_offmarketCase";
};
export type matchHeroOffmarketDetailsFragment_offmarketCase$key = {
  readonly " $data"?: matchHeroOffmarketDetailsFragment_offmarketCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroOffmarketDetailsFragment_offmarketCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroOffmarketDetailsFragment_offmarketCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessPropertyType",
      "storageKey": null
    }
  ],
  "type": "OffmarketCase",
  "abstractKey": null
};

(node as any).hash = "b6f8c1332e09e304b2916350863639e8";

export default node;

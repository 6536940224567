import Icon from 'components/atoms/icon/icon';
import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import { formatDate, getRelatedLink } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { graphql, useFragment } from 'react-relay';
import { requestedDocumentNotification_fragment$key } from '__generated__/requestedDocumentNotification_fragment.graphql';

export interface RequestedDocumentNotificationProps {
  notification: requestedDocumentNotification_fragment$key;
}

const requestedDocumentNotification = graphql`
  fragment requestedDocumentNotification_fragment on RequestedDocumentNotification {
    read
    baseCase {
      relationType {
        relationId
        relationType
      }
    }
    typeName
    createdDate
  }
`;

export const RequestedDocumentNotification: FC<RequestedDocumentNotificationProps> = ({ notification }) => {
  const data = useFragment<requestedDocumentNotification_fragment$key>(requestedDocumentNotification, notification);
  const relationData = {
    relationType: data.baseCase.relationType?.relationType,
    relationId: data.baseCase.relationType?.relationId,
  };

  return (
    <HashLink
      className={classNames('notifications__link', { unread: !data.read })}
      smooth
      to={getRelatedLink(relationData, '#sign')}
    >
      <Icon name="Bell" />
      <div className="notifications__item-text">
        <Typography>
          <FormattedMessage defaultMessage="New document requested" id="Notifications.NewDocumentRequested" />:
          <b> {data.typeName}</b>
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </HashLink>
  );
};

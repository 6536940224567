import Container from 'components/utilities/container/container';
import { useViewer } from 'contexts/viewerContext';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import Row from 'components/utilities/row/row';
import Block from 'components/utilities/block/block';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { saleByOwnerPageQuery } from '__generated__/saleByOwnerPageQuery.graphql';
import { SaleByOwnerGrid } from 'components/molecules/sale-by-owner/sale-by-owner-grid';
import TabComponent from 'components/organisms/tab-component/tab-component';
import { SaleByOwnerProcessedGrid } from 'components/molecules/sale-by-owner/sale-by-owner-processed-grid';

export const SaleByOwnerPage = () => {
  const data = useLazyLoadQuery<saleByOwnerPageQuery>(
    graphql`
      query saleByOwnerPageQuery {
        viewer {
          ...saleByOwnerGridFragment
          ...saleByOwnerProcessedGridFragment
        }
      }
    `,
    {},
  );
  const viewer = useViewer();

  if (!viewer?.featureToggle?.isPropertyForSaleByOwnerEnabled) {
    return (
      <Container>
        <Row center>
          <Block>
            <Typography tag={'h1'}>
              <FormattedMessage id={'saleByOwner.NotAccess'} defaultMessage={'You do not have access to this page'} />
            </Typography>
          </Block>
        </Row>
      </Container>
    );
  }

  if (!data.viewer) {
    return (
      <Container>
        <Row center>
          <Block>
            <Typography tag={'h1'}>
              <FormattedMessage id={'saleByOwner.NoLeads'} defaultMessage={'You do not have new leads'} />
            </Typography>
          </Block>
        </Row>
      </Container>
    );
  }

  const tabContent = [
    {
      item: (
        <Container>
          <SaleByOwnerGrid viewerFragment={data.viewer} />
        </Container>
      ),
      key: 'newLeads',
    },
    {
      item: (
        <Container>
          <SaleByOwnerProcessedGrid viewerFragment={data.viewer} />
        </Container>
      ),
      key: 'processedLeads',
    },
  ];

  const tabNav = [
    {
      item: (
        <Typography tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="New leads" id="Leads.NewLeads" />
        </Typography>
      ),
      key: 'newLeads',
    },
    {
      item: (
        <Typography tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="Processed leads" id="Leads.ProcessedLeads" />
        </Typography>
      ),
      key: 'processedLeads',
    },
  ];

  return <TabComponent activeTab={'newLeads'} tabContent={tabContent} tabNav={tabNav} />;
};

import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  IRetryPolicy,
  LogLevel,
} from '@microsoft/signalr';
import { Observable } from 'relay-runtime';
import { authenticationService } from 'services/authentication/authentication-service';

export enum SignalrConnectionStateEnum {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export interface SignalrConnection {
  invoke: (name: string, ...args: unknown[]) => Promise<unknown>;
  off: (name: string, fn: (...args: unknown[]) => unknown) => void;
  on: (name: string, fn: (...args: unknown[]) => unknown) => () => void;
  onReconnected: (f: (...args: unknown[]) => unknown) => () => ((...args: unknown[]) => unknown)[];
  state: Observable<SignalrConnectionStateEnum>;
}

const defaultReconnectPolicy: IRetryPolicy = {
  nextRetryDelayInMilliseconds: () => 5000,
};

function createSignalrConnectionInstance(
  endpoint: string,
  reconnectPolicy: IRetryPolicy = defaultReconnectPolicy,
  startRetryTimeout = 5000,
): SignalrConnection {
  let pendingInvokes: [string, unknown[]][] = [];
  let reconnectedListeners: ((...args: unknown[]) => unknown)[] = [];

  const connection = new HubConnectionBuilder()
    .withUrl(endpoint, {
      accessTokenFactory: () => authenticationService.tokenAsync(),
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect(reconnectPolicy)
    .configureLogging(LogLevel.Warning)
    .build();

  function start(startRetryCount = 0): Promise<SignalrConnectionStateEnum> {
    return connection
      .start()
      .then(() => SignalrConnectionStateEnum.Connected)
      .catch(error => {
        console.error(error);
        return new Promise<SignalrConnectionStateEnum>(resolve =>
          setTimeout(() => resolve(start(startRetryCount + 1)), startRetryTimeout),
        );
      });
  }

  // call start() and maintain stream of connection state
  const state = Observable.from(SignalrConnectionStateEnum.Disconnected)
    .concat(Observable.from(start()))
    .concat(
      Observable.create<SignalrConnectionStateEnum>(sink => {
        connection.onreconnecting(error => {
          if (error) console.error(error);
          sink.next(SignalrConnectionStateEnum.Disconnected);
        });
        connection.onreconnected(() => {
          handleReconnected();
          sink.next(SignalrConnectionStateEnum.Connected);
        });
        connection.onclose(error => {
          if (error) console.error(error);
          sink.next(SignalrConnectionStateEnum.Disconnected);
          sink.complete();
        });
      }),
    );

  function handleReconnected() {
    reconnectedListeners.forEach(f => f());

    pendingInvokes.forEach(async pending => await invoke(pending[0], ...pending[1]));
    pendingInvokes = [];
  }

  function onReconnected(f: (...args: unknown[]) => unknown) {
    reconnectedListeners.push(f);
    return () => (reconnectedListeners = reconnectedListeners.filter(x => x !== f)); // dispose
  }

  function isConnected() {
    return connection.state === HubConnectionState.Connected;
  }

  async function invoke(name: string, ...args: unknown[]) {
    if (isConnected()) {
      try {
        const res = await connection.invoke(name, ...args);
        return res;
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      pendingInvokes.push([name, args]);
    }
  }

  return {
    invoke,
    off: (name: string, fn: (...args: unknown[]) => unknown) => connection.off(name, fn),
    on: (name: string, fn: (...args: unknown[]) => unknown) => {
      connection.on(name, fn);
      return () => connection.off(name, fn); // off
    },
    onReconnected,
    state,
  };
}

export { createSignalrConnectionInstance };

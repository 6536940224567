import React, { FC, Reducer, useReducer } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import Container from 'components/utilities/container/container';
import Row from 'components/utilities/row/row';
import Column from 'components/utilities/column/column';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import { favoritePageDocumentsRender_Query } from '__generated__/favoritePageDocumentsRender_Query.graphql';
import { DocumentsTableHeader } from 'components/organisms/documents-table/documents-table-header';
import { SuspenseHoc } from 'components/templates/suspense-hoc';
import { TableSkeleton } from 'components/organisms/documents-table/table-skeleton';
import { DocumentsTableFooter } from 'components/organisms/documents-table/documents-table-footer';
import { DocumentsTable } from 'components/organisms/documents-table/documents-table';
import DocumentsTableStateContext from 'components/organisms/documents-table/documents-table-state-context';
import {
  DocumentsTableAction,
  documentsTableInitialState,
  DocumentsTableState,
  documentsTableStateReducer,
} from 'components/organisms/documents-table/documents-table-state-reducer';
import { FavoritePageDocumentsBody } from 'pages/favorites/documents/favorite-page-documents-body';

type Props = {
  favoriteId: string;
};

const favoritePageDocumentsRenderQuery = graphql`
  query favoritePageDocumentsRender_Query($favoriteId: ID!) {
    node(id: $favoriteId) {
      ...favoritePageDocumentsBody_CasePotentialBuyer
    }
  }
`;
export const FavoritePageDocumentsRender: FC<Props> = props => {
  const [state, dispatch] = useReducer<Reducer<DocumentsTableState, DocumentsTableAction>>(
    documentsTableStateReducer,
    documentsTableInitialState,
  );
  const data = useLazyLoadQuery<favoritePageDocumentsRender_Query>(favoritePageDocumentsRenderQuery, {
    favoriteId: props.favoriteId,
  });

  return (
    <DocumentsTableStateContext.Provider value={{ state, dispatch }}>
      <Container>
        <Row id="documents">
          <Column>
            <Typography tag="h2" tagStyle="headlineMedium">
              <FormattedMessage defaultMessage="Dokumenter" id="MatchCaseDocuments.title" />
            </Typography>
            {data.node && (
              <DocumentsTable>
                <DocumentsTableHeader />
                <SuspenseHoc fallback={<TableSkeleton />}>
                  <FavoritePageDocumentsBody documentFragment={data.node} />
                </SuspenseHoc>
                <DocumentsTableFooter />
              </DocumentsTable>
            )}
          </Column>
        </Row>
      </Container>
    </DocumentsTableStateContext.Provider>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/atoms/button/button';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import ChatActions, {
  FileInputInterface,
  FileInputInterface as FileInputType,
} from 'components/molecules/chat-actions/chat-actions';
import CustomSelect from 'components/molecules/custom-select/custom-select';
import Input from 'components/molecules/input/input';
import Select, { SelectOption } from 'components/molecules/select/select';
import Textarea from 'components/molecules/textarea/textarea';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Row from 'components/utilities/row/row';
import useIsMobile from 'hooks/useIsMobile';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import './contact-seller-form.scss';

export interface ContactSellerFormProps {
  activityOptions?: SelectOption[];
  caseOptions?: SelectOption[];
  onCloseForm: () => void;
  onSelectCase?: (caseId: string) => void;
  onSubmit: (data: ContactSellerInputs) => void;
  recipientOptions?: SelectOption[];
}
export interface ContactSellerInputs {
  activityId?: string;
  caseId: string;
  files?: FileInputInterface[];
  initialMessage: string;
  participantId: string;
  subject: string;
}

const ContactSellerForm: FC<ContactSellerFormProps> = ({
  onCloseForm,
  caseOptions,
  activityOptions,
  recipientOptions,
  onSubmit,
  onSelectCase,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  const [selectedCaseOption, setSelectedCaseOption] = useState<SelectOption>();
  const [selectedRecepientOption, setSelectedRecepientOption] = useState<SelectOption>();

  const schema = useMemo(() => {
    return yup.object().shape({
      activityId: yup.string().when([], {
        is: () => !caseOptions,
        otherwise: yup.string().notRequired().nullable(),
        then: yup.string().required(
          formatMessage({
            defaultMessage: 'Choose an activity',
            id: 'ContactSellerForm.activityRequired',
          }),
        ),
      }),
      caseId: yup.string().when([], {
        is: () => caseOptions,
        otherwise: yup.string().notRequired().nullable(),
        then: yup.string().required(
          formatMessage({
            defaultMessage: 'Choose a case',
            id: 'ContactSellerForm.casetRequired',
          }),
        ),
      }),
      files: yup.array().of(yup.mixed()).nullable(),
      initialMessage: yup.string().required(
        formatMessage({
          defaultMessage: 'A message is required',
          id: 'ContactSellerForm.messageRequired',
        }),
      ),
      participantId: yup.string().required(
        formatMessage({
          defaultMessage: 'Choose a recipient',
          id: 'ContactSellerForm.recipientRequired',
        }),
      ),
      subject: yup.string().required(
        formatMessage({
          defaultMessage: 'Subject is required',
          id: 'ContactSellerForm.subjectRequired',
        }),
      ),
    });
  }, [formatMessage, caseOptions]);

  const {
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    setFocus,
    setValue,
    register,
    reset,
    watch,
  } = useForm<ContactSellerInputs>({
    resolver: yupResolver(schema),
  });

  const handleSelectedCase = useCallback(
    (option: SelectOption) => {
      reset({ participantId: undefined });
      setValue(caseOptions ? 'caseId' : 'activityId', option.value);
      setSelectedCaseOption(option);
      setSelectedRecepientOption(undefined);
      onSelectCase && onSelectCase(option.value);
    },
    [reset, setValue, caseOptions, onSelectCase],
  );

  useEffect(() => {
    register(caseOptions ? 'caseId' : 'activityId');
    register('participantId');
    setValue(caseOptions ? 'caseId' : 'activityId', '');

    if (caseOptions?.length === 1) {
      handleSelectedCase(caseOptions[0]);
    }
  }, [register, caseOptions, setValue, handleSelectedCase]);

  const watchedFiles = watch('files');

  const onFileUpload = (file: FileInputType) => {
    const newFiles = watchedFiles && watchedFiles.length > 0 ? [...watchedFiles, file] : [file];
    setValue('files', newFiles);
  };

  const onDeleteFile = (fileId: string) => {
    const newFiles = watchedFiles?.filter(file => file.id !== fileId);
    setValue('files', newFiles);
  };

  return isSubmitSuccessful ? (
    <Block className="contact-seller-form__submitted" column flex>
      <Icon fill="var(--color-text-grey)" height={48} name="Check" width={48} />
      <Typography tagStyle="bodyLarge">
        <FormattedMessage defaultMessage="The formular has been uploaded" id="ContactSellerForm.SuccessMessage" />
      </Typography>
      <Button className="contact-seller-form__submitted--button" onClick={onCloseForm} type="submit">
        <FormattedMessage defaultMessage="Go back" id="ContactSellerForm.CloseModal" />
      </Button>
    </Block>
  ) : (
    <div className="contact-seller-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row center pushBottom>
          <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
            {!isMobile ? (
              <CustomSelect
                disabled={!!activityOptions}
                errorMessage={errors.caseId?.message || errors.activityId?.message}
                label={
                  caseOptions
                    ? formatMessage({
                        defaultMessage: 'Case',
                        id: 'ContactSeller.Case',
                      })
                    : formatMessage({
                        defaultMessage: 'Activity',
                        id: 'ContactSeller.Aktivitet',
                      })
                }
                onChange={option => handleSelectedCase(option)}
                options={caseOptions || activityOptions}
                placeholder={
                  caseOptions
                    ? formatMessage({
                        defaultMessage: 'Choose a case',
                        id: 'ContactSeller.CasePlaceholder',
                      })
                    : formatMessage({
                        defaultMessage: 'Choose an activity',
                        id: 'ContactSeller.ActivityPlaceholder',
                      })
                }
                selectedOption={selectedCaseOption}
              />
            ) : (
              <Select
                disabled={!!activityOptions}
                {...register(caseOptions ? 'caseId' : 'activityId')}
                errorMessage={errors.caseId?.message}
                label={
                  caseOptions
                    ? formatMessage({
                        defaultMessage: 'Case',
                        id: 'ContactSeller.Case',
                      })
                    : formatMessage({
                        defaultMessage: 'Activity',
                        id: 'ContactSeller.Aktivitet',
                      })
                }
                noFloatingLabel
                onChange={event => onSelectCase && onSelectCase(event.target.value)}
                options={caseOptions || activityOptions}
                placeholder={
                  caseOptions
                    ? formatMessage({
                        defaultMessage: 'Choose a case',
                        id: 'ContactSeller.CasePlaceholder',
                      })
                    : formatMessage({
                        defaultMessage: 'Choose an activity',
                        id: 'ContactSeller.ActivityPlaceholder',
                      })
                }
              />
            )}
          </Column>
          <Column marginBottom width={{ mobile: 8, tablet: 4 }}>
            {!isMobile ? (
              <CustomSelect
                errorMessage={errors.participantId?.message}
                label={formatMessage({
                  defaultMessage: 'Recipient',
                  id: 'ContactSeller.Recipient',
                })}
                onChange={option => {
                  setValue('participantId', option.value);
                  setSelectedRecepientOption(option);
                }}
                options={recipientOptions}
                placeholder={formatMessage({
                  defaultMessage: 'Recipient',
                  id: 'ContactSeller.Recipient',
                })}
                selectedOption={selectedRecepientOption}
              />
            ) : (
              <Select
                {...register('participantId')}
                errorMessage={errors.participantId?.message}
                label={formatMessage({
                  defaultMessage: 'Recipient',
                  id: 'ContactSeller.Recipient',
                })}
                noFloatingLabel
                options={recipientOptions}
                placeholder={formatMessage({
                  defaultMessage: 'Recipient',
                  id: 'ContactSeller.Recipient',
                })}
              />
            )}
          </Column>
          <Column marginBottom width={{ mobile: 8 }}>
            <Input
              errorMessage={errors.subject?.message}
              focusInput={() => setFocus('subject')}
              label={formatMessage({
                defaultMessage: 'Subject',
                id: 'ContactSeller.Subject',
              })}
              placeholder={formatMessage({
                defaultMessage: 'Subject',
                id: 'ContactSeller.Subject',
              })}
              {...register('subject')}
            />
          </Column>
          <Column marginBottom width={{ mobile: 8 }}>
            <Block marginBottom>
              <Textarea
                className="contact-seller-form__message-container"
                classNameInput="contact-seller-form__message"
                errorMessage={errors.initialMessage?.message}
                minRows={5}
                placeholder={formatMessage({
                  defaultMessage: 'Type here...',
                  id: 'ContactSeller.TypeHere',
                })}
                {...register('initialMessage')}
                noFloatingLabel
              >
                <FileComponent onFileUpload={onFileUpload} />
              </Textarea>
            </Block>
            {watchedFiles && watchedFiles.length > 0 && (
              <div className="contact-seller-form__documents">
                {watchedFiles
                  .filter(file => !file.type.includes('image'))
                  .map(file => (
                    <div key={file.id} className="contact-seller-form__documents--item">
                      <Icon name="Files" />{' '}
                      <Typography elipsis tagStyle="bodyMedium">
                        {file.name}
                      </Typography>
                      <Button
                        className="contact-seller-form__delete"
                        hideText
                        iconStart="Cross"
                        onClick={() => onDeleteFile(file.id)}
                      >
                        <FormattedMessage defaultMessage="Delete" id="Messages.DeleteAttachment" />
                      </Button>
                    </div>
                  ))}
              </div>
            )}
            {watchedFiles && watchedFiles.length > 0 && (
              <div className="contact-seller-form__images">
                {watchedFiles
                  .filter(file => file.type.includes('image'))
                  .map(file => (
                    <div key={file.id} className="contact-seller-form__images--item">
                      <img src={URL.createObjectURL(file)} />
                      <Button
                        className="contact-seller-form__delete"
                        hideText
                        iconStart="Cross"
                        onClick={() => onDeleteFile(file.id)}
                      >
                        <FormattedMessage defaultMessage="Delete" id="Messages.DeleteAttachment" />
                      </Button>
                    </div>
                  ))}
              </div>
            )}
          </Column>
        </Row>
        <Block className="contact-seller-form__button-container" flex justifyRight>
          <Button onClick={onCloseForm} version="secondary">
            <FormattedMessage defaultMessage="Close" id="ContactSellerForm.buttonClose" />
          </Button>
          <Button type="submit" version="primary">
            <FormattedMessage defaultMessage="Send" id="ContactSellerForm.buttonSend" />
          </Button>
        </Block>
      </form>
    </div>
  );
};

const FileComponent: FC<{
  onFileUpload: (file: FileInputType) => void;
}> = ({ onFileUpload }) => (
  <Block className="contact-seller-form__file-input" flex justifyRight>
    <ChatActions onFileUpload={onFileUpload} />
  </Block>
);

export default ContactSellerForm;

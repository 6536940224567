import Icon from 'components/atoms/icon/icon';
import { formatAppointmentDate, formatDate } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';
import { activityNotification_fragment$key } from '__generated__/activityNotification_fragment.graphql';

export interface ActivityNotificationProps {
  notification: activityNotification_fragment$key;
}

const activityNotification = graphql`
  fragment activityNotification_fragment on ActivityNotification {
    read
    appointmentActivity {
      name
      location
      baseCase {
        address
      }
      startDate
      endDate
    }
    createdDate
  }
`;

export const ActivityNotification: FC<ActivityNotificationProps> = ({ notification }) => {
  const data = useFragment<activityNotification_fragment$key>(activityNotification, notification);
  const location = data.appointmentActivity?.location || data.appointmentActivity?.baseCase?.address;
  const appointmentTime = useMemo(
    () => formatAppointmentDate(data.appointmentActivity?.startDate, data.appointmentActivity?.endDate),
    [data.appointmentActivity?.startDate, data.appointmentActivity?.endDate],
  );

  return (
    <Link className={classNames('notifications__link', { unread: !data.read })} to="/activities">
      <Icon name="Calendar" />
      <div className="notifications__item-text">
        <Typography>
          <b>{data.appointmentActivity?.name}: </b>
          {location}, {appointmentTime}
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </Link>
  );
};

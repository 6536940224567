import classNames from 'classnames';
import { CSSProperties, forwardRef, ReactNode } from 'react';
import './module-wrapper.scss';

export interface ModuleWrapperProps {
  backgroundColor?: 'white' | 'grey' | 'primary' | 'brand';
  borderTop?: boolean;
  boxShadow?: boolean;
  children: ReactNode;
  className?: string;
  fullHeight?: boolean;
  fullHeightWithoutMainNav?: boolean;
  style?: CSSProperties;
}

const ModuleWrapper = forwardRef<HTMLDivElement, ModuleWrapperProps>((props, ref) => {
  const {
    backgroundColor,
    boxShadow,
    children,
    className,
    borderTop,
    fullHeight,
    fullHeightWithoutMainNav,
    style,
    ...rest
  } = props;
  return (
    <div
      {...rest}
      ref={ref}
      className={classNames('module-wrapper', className, {
        borderTop,
        boxShadow,
        brand: backgroundColor === 'brand',
        fullHeight,
        fullHeightWithoutMainNavOnMobile: fullHeightWithoutMainNav,
        grey: backgroundColor === 'grey',
        primary: backgroundColor === 'primary',
        white: backgroundColor === 'white',
      })}
      style={style}
    >
      {children}
    </div>
  );
});

ModuleWrapper.displayName = 'ModuleWrapper';

export default ModuleWrapper;

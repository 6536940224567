const getVersion = (version: number) => {
  switch (version) {
    case 0:
      return {
        backgroundColor: '#EAEFE6',
        textColor: '#69785E',
      };
    case 1:
      return {
        backgroundColor: '#E0E6EB',
        textColor: '#2D3A46',
      };
    case 2:
      return {
        backgroundColor: '#E4E2F3',
        textColor: '#280F6D',
      };
    case 3:
      return {
        backgroundColor: '#E6DFEC',
        textColor: '#37364F',
      };
    case 4:
      return {
        backgroundColor: '#E2F4E8',
        textColor: '#363548',
      };
    case 5: {
      return {
        backgroundColor: '#E6EBEF',
        textColor: '#4D176E',
      };
    }
    case 6: {
      return {
        backgroundColor: '#EBE6EF',
        textColor: '#AB133E',
      };
    }
    case 7: {
      return {
        backgroundColor: '#E8DEF6',
        textColor: '#420790',
      };
    }
    case 8: {
      return { backgroundColor: '#D8E8F3', textColor: '#222A54' };
    }
    case 9: {
      return { backgroundColor: '#EBE0FE', textColor: '#EBE0FE' };
    }
  }
};

export default getVersion;

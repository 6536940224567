import Icon from 'components/atoms/icon/icon';
import FileInput from 'components/molecules/file-input/file-input';
import { ComponentPropsWithoutRef, FC } from 'react';
import './chat-actions.scss';
import { isMobile, isTablet } from 'react-device-detect';

export interface FileInputInterface extends File {
  id: string;
}

export interface ChatActionsProps extends ComponentPropsWithoutRef<'input'> {
  onFileUpload: (file: FileInputInterface) => void;
}

const ChatActions: FC<ChatActionsProps> = ({ onFileUpload, ...rest }) => {
  return (
    <div className="chat-actions">
      <FileInput
        accept="image/*"
        className="chat-actions__icon"
        id="image-input"
        label={<Icon name="Image" />}
        name="image-input"
        onFileSelectError={error => console.log(error)}
        onFileSelectSuccess={file => onFileUpload(file)}
        {...rest}
      />
      <FileInput
        accept="application/msword, application/vnd.ms-excel, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, message/rfc822, application/vnd.ms-outlook"
        className="chat-actions__icon"
        id="document-input"
        label={<Icon name="Clip" />}
        name="document-input"
        onFileSelectError={error => console.log(error)}
        onFileSelectSuccess={file => onFileUpload(file)}
        {...rest}
      />
      {(isMobile || isTablet) && (
        <FileInput
          accept="image/*"
          capture="environment"
          className="chat-actions__icon chat-actions__icon--camera"
          id="camera-input"
          label={<Icon name="Camera" />}
          name="camera-input"
          onFileSelectError={error => console.log(error)}
          onFileSelectSuccess={file => onFileUpload(file)}
          {...rest}
        />
      )}
    </div>
  );
};

export default ChatActions;

import { defaultLayout_Query } from '__generated__/defaultLayout_Query.graphql';
import { defaultLayout_unreadNotifications_Subscription } from '__generated__/defaultLayout_unreadNotifications_Subscription.graphql';
import Loader from 'components/atoms/loader/loader';
import StickyChat from 'components/molecules/sticky-chat/sticky-chat';
import Footer from 'components/organisms/footer/footer';
import { InstallPWA } from 'components/organisms/install-pwa-modal/installPWA';
import MainNavigation from 'components/organisms/main-navigation/main-navigation';
import useIsIOS from 'hooks/useIsIOS';
import { useSubscriptionWithReconnect } from 'hooks/useSubscriptionWithReconnect';
import React, { FC, Suspense, useCallback, useMemo, useRef } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useLocation } from 'react-router-dom';
import { GraphQLSubscriptionConfig } from 'relay-runtime';
import ErrorBoundary from 'services/error-boundary/error-boundary';
import './default-layout.scss';

const defaultLayout_query = graphql`
  query defaultLayout_Query {
    viewer {
      customer {
        firstName
        isCompany
        lastName
        ...notifications_fragment
        ...mainNavigation_fragment
        ...stickyChat_fragment
      }
    }
  }
`;

const unreadNotificationsSubscriptionQuery = graphql`
  subscription defaultLayout_unreadNotifications_Subscription {
    onNotificationCountUpdatedEvent {
      viewer {
        customer {
          unreadNotificationsCount
          unreadThreadCount
          ...notifications_fragment
        }
      }
    }
  }
`;

type refreshType = {
  fetchKey: number;
  fetchPolicy: 'network-only';
  networkCacheConfig: { force: boolean };
};

const DefaultLayout: FC = () => {
  const refreshedQueryOptions = useRef<refreshType>({
    fetchKey: 0,
    fetchPolicy: 'network-only',
    networkCacheConfig: { force: true },
  });

  const data = useLazyLoadQuery<defaultLayout_Query>(defaultLayout_query, {}, refreshedQueryOptions.current);

  const { pathname } = useLocation();
  const { prompt } = useIsIOS();

  const refresh = useCallback(() => {
    refreshedQueryOptions.current = {
      ...refreshedQueryOptions.current,
      fetchKey: refreshedQueryOptions.current.fetchKey + 1,
    };
  }, []);

  const notificationSubscriptionConfig: GraphQLSubscriptionConfig<defaultLayout_unreadNotifications_Subscription> =
    useMemo(
      () => ({
        subscription: unreadNotificationsSubscriptionQuery,
        variables: {},
        onNext: () => {
          refresh();
        },
      }),
      [refresh],
    );

  useSubscriptionWithReconnect(notificationSubscriptionConfig);

  const initials = useMemo(() => {
    if (!data.viewer) return null;

    if (data.viewer.customer?.isCompany) {
      return data.viewer.customer?.firstName?.charAt(0).toUpperCase();
    } else {
      return `${data.viewer.customer?.firstName?.charAt(0).toUpperCase()}${data.viewer.customer?.lastName
        ?.charAt(0)
        .toUpperCase()}`;
    }
  }, [data.viewer]);

  const customer = useMemo(() => {
    if (!data.viewer) return null;

    return data.viewer.customer;
  }, [data.viewer]);

  if (!customer || !initials) {
    return null;
  }

  return (
    <div className="defaultLayout">
      <MainNavigation fragment={customer} initials={initials} />
      <main className="defaultLayout__main">
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            {prompt && <InstallPWA />}

            <div id="app-outlet">
              <Outlet />
              <StickyChat fragment={customer} />
            </div>
          </Suspense>
        </ErrorBoundary>
      </main>
      {!pathname.includes('messages') && <Footer />}
    </div>
  );
};

export default DefaultLayout;

/**
 * @generated SignedSource<<6df910821495976ef8a6bc79cd0cae50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type registerOpenHouseQuery$variables = {
  id: string;
};
export type registerOpenHouseQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"registerOpenHouseModalFragment">;
  } | null | undefined;
};
export type registerOpenHouseQuery = {
  response: registerOpenHouseQuery$data;
  variables: registerOpenHouseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "registerOpenHouseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "registerOpenHouseModalFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "registerOpenHouseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "amount",
                    "value": 2
                  }
                ],
                "concreteType": "OpenHouse",
                "kind": "LinkedField",
                "name": "openHouse",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityType",
                    "kind": "LinkedField",
                    "name": "activityType",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isRegistration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameOnTimeline",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRegistred",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  }
                ],
                "storageKey": "openHouse(amount:2)"
              }
            ],
            "type": "BaseCase",
            "abstractKey": "__isBaseCase"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38e7405d5808fb8e8e1424c74d72356a",
    "id": null,
    "metadata": {},
    "name": "registerOpenHouseQuery",
    "operationKind": "query",
    "text": "query registerOpenHouseQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...registerOpenHouseModalFragment\n    id\n  }\n}\n\nfragment registerOpenHouseModalFragment on BaseCase {\n  __isBaseCase: __typename\n  openHouse(amount: 2) {\n    activityType {\n      isRegistration\n      nameOnTimeline\n      id\n    }\n    isRegistred\n    id\n    startDate\n    endDate\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "302468eb38bd271c0d6733f7ec1c2cc9";

export default node;

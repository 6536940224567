import { createContext, Dispatch } from 'react';
import {
  DocumentsTableAction,
  documentsTableInitialState,
  DocumentsTableState,
} from 'components/organisms/documents-table/documents-table-state-reducer';

interface ContextProps {
  state: DocumentsTableState;
  dispatch: Dispatch<DocumentsTableAction>;
}

const defaultDispatch: Dispatch<DocumentsTableAction> = () => undefined; // does nothing

const DocumentsTableStateContext = createContext<ContextProps>({
  state: documentsTableInitialState,
  dispatch: defaultDispatch,
});
export default DocumentsTableStateContext;

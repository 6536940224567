import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import { forwardRef, Fragment, ReactNode, useEffect, useState } from 'react';
import ReactTextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import './textarea.scss';

export interface TextareaProps extends TextareaAutosizeProps {
  autoExpand?: boolean;
  children?: ReactNode;
  classNameInput?: string;
  errorMessage?: string;
  focusInput?: () => void;
  isFullWidth?: boolean;
  label?: string;
  maxLength?: number;
  noBorder?: boolean;
  noFloatingLabel?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    autoExpand,
    className,
    disabled,
    errorMessage,
    label,
    isFullWidth,
    classNameInput,
    children,
    focusInput,
    noFloatingLabel,
    noBorder,
    onKeyUp,
    ...rest
  } = props;

  const [isLabelShown, setIsLabelShown] = useState(false);

  useEffect(() => {
    const value = (document.querySelector(`#${rest.name}`) as HTMLTextAreaElement)?.value;
    if (value || rest.value) {
      setIsLabelShown(true);
    }
  }, [rest.name, rest.value]);

  return (
    <Fragment>
      <div
        className={classNames('textarea', className, {
          hasError: errorMessage,
          isDisabled: disabled,
          isFullWidth,
          isLabelShown,
          noBorder,
          noFloatingLabel,
        })}
      >
        {label && (
          <Typography className="label" htmlFor={rest.id} tag="label" tagStyle="bodySmall">
            {label}
          </Typography>
        )}
        <div className="inputWrap">
          <ReactTextareaAutosize
            className={classNames('field p', classNameInput, {
              hasError: !!errorMessage,
              'is-autoexpand': autoExpand,
            })}
            {...rest}
            ref={ref}
            id={rest.name}
            onChange={e => {
              e.target.value.length > 0 ? setIsLabelShown(true) : setIsLabelShown(false);
            }}
            onClick={() => focusInput && focusInput()}
            onKeyUp={onKeyUp}
          />
          {children}
        </div>
      </div>
      {errorMessage && (
        <Typography subjectColor="error" tag="span" tagStyle="bodyXSmall">
          *{errorMessage}
        </Typography>
      )}
    </Fragment>
  );
});

Textarea.displayName = 'Textarea';
export default Textarea;

import { FC, ReactNode, Suspense } from 'react';
import { ErrorBoundaryBlock } from 'components/templates/error-boundary-block';

interface Props {
  fallback: ReactNode;
  children: ReactNode[] | ReactNode;
  errorMessage?: string;
}
export const SuspenseHoc: FC<Props> = ({ children, fallback, errorMessage = '' }) => {
  return (
    <ErrorBoundaryBlock customErrorMessage={errorMessage}>
      <Suspense fallback={fallback}>{children}</Suspense>
    </ErrorBoundaryBlock>
  );
};

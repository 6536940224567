import Loader from 'components/atoms/loader/loader';
import { AppSettings } from 'configs/app-settings-defaults';
import { ThemeContext } from 'context/ThemeContext';
import ViewerProvider from 'contexts/viewerContext';
import DefaultLayout from 'layouts/default-layout';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { mainAppRoutes } from 'router/routes-setup';
import ErrorBoundary from 'services/error-boundary/error-boundary';
import { createRelayEnvironmentInstance } from 'services/relay/relay-environment';
import { createSignalrConnectionInstance } from 'services/relay/signalr-connection';
import { SignalrConnectionProvider } from 'services/relay/signalr-connection-provider';
import { IntlProvider } from 'services/translations/IntlProvider';
import 'styles/_app.scss';
import { TopLevelToastContainer } from 'components/molecules/top-level-toast-container/top-level-toast-container';
import { MixPanelProvider } from './mixpanel/MixPanelProvider';
import UserDeviceProvider from 'contexts/userDeviceContext';

const AppRoot = (props: AppSettings) => {
  const signalrConnectionInstance = createSignalrConnectionInstance(props.settings.ENDPOINT_SIGNALR);
  const relayEnvironmentInstance = createRelayEnvironmentInstance(
    props.settings.ENDPOINT_GRAPHQL,
    signalrConnectionInstance,
  );

  return (
    <ThemeContext.Provider value={props.theme}>
      <MixPanelProvider token={props.settings.MIXPANEL_TOKEN ?? ''}>
        <Helmet>
          <link href={props.theme?.favIcon32} rel="icon" sizes="32x32" type="image/png" />
          <link href={props.theme?.favIcon16} rel="icon" sizes="16x16" type="image/png" />
          <link href={props.theme?.appleTouchIcon} rel="apple-touch-icon" sizes="180x180" />
          <title>{props.theme?.websiteTitle}</title>
        </Helmet>
        <IntlProvider translationVariants={props.settings.TRANSLATION_VARIANTS ?? ''} locale={props.settings.LOCALE}>
          <ErrorBoundary>
            <UserDeviceProvider>
              <SignalrConnectionProvider connection={signalrConnectionInstance}>
                <RelayEnvironmentProvider environment={relayEnvironmentInstance}>
                  <ViewerProvider>
                    <Suspense fallback={<Loader />}>
                      <App />
                      <TopLevelToastContainer />
                    </Suspense>
                  </ViewerProvider>
                </RelayEnvironmentProvider>
              </SignalrConnectionProvider>
            </UserDeviceProvider>
          </ErrorBoundary>
        </IntlProvider>
      </MixPanelProvider>
    </ThemeContext.Provider>
  );
};

const App = () => {
  const routesSetup = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<DefaultLayout />} path="/">
        {mainAppRoutes.map(route => (
          <Route key={route.path} element={route.component} path={route.path}>
            <Route element={route.subRoute?.component} path={route.subRoute?.path} />
          </Route>
        ))}
      </Route>,
    ),
  );

  return <RouterProvider router={routesSetup} />;
};

export default AppRoot;

//==============================//
// AUTOGENERATED - DO NOT EDIT //
//============================//
//  UPDATE BY RUNNING TYPES  //
//==========================//

import { useMemo } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import * as Types from 'Types';

/* eslint-disable */
/* prettier-ignore-start*/

const TypeMessages: Record<string, { id: string; defaultMessage: string }> = defineMessages({
  ActivityNote: {
    id: "Types.ActivityNote",
    defaultMessage: "ActivityNote"
  },
  ActivityNotification: {
    id: "Types.ActivityNotification",
    defaultMessage: "ActivityNotification"
  },
  ActivityType: {
    id: "Types.ActivityType",
    defaultMessage: "ActivityType"
  },
  Address: {
    id: "Types.Address",
    defaultMessage: "Address"
  },
  AgricultureSearchProfile: {
    id: "Types.AgricultureSearchProfile",
    defaultMessage: "AgricultureSearchProfile"
  },
  AnalyticsData: {
    id: "Types.AnalyticsData",
    defaultMessage: "AnalyticsData"
  },
  AnalyticsReport: {
    id: "Types.AnalyticsReport",
    defaultMessage: "AnalyticsReport"
  },
  AnalyticsReportPercentage: {
    id: "Types.AnalyticsReportPercentage",
    defaultMessage: "AnalyticsReportPercentage"
  },
  AppointmentActivity: {
    id: "Types.AppointmentActivity",
    defaultMessage: "AppointmentActivity"
  },
  AppointmentActivityConnection: {
    id: "Types.AppointmentActivityConnection",
    defaultMessage: "AppointmentActivityConnection"
  },
  AppointmentActivityEdge: {
    id: "Types.AppointmentActivityEdge",
    defaultMessage: "AppointmentActivityEdge"
  },
  AppointmentActivityType: {
    id: "Types.AppointmentActivityType",
    defaultMessage: "AppointmentActivityType"
  },
  BaseCase: {
    id: "Types.BaseCase",
    defaultMessage: "BaseCase"
  },
  BaseCaseMainOffer: {
    id: "Types.BaseCaseMainOffer",
    defaultMessage: "BaseCaseMainOffer"
  },
  Building: {
    id: "Types.Building",
    defaultMessage: "Building"
  },
  BuildingInclArea: {
    id: "Types.BuildingInclArea",
    defaultMessage: "BuildingInclArea"
  },
  BusinessBuilding: {
    id: "Types.BusinessBuilding",
    defaultMessage: "BusinessBuilding"
  },
  BusinessCase: {
    id: "Types.BusinessCase",
    defaultMessage: "BusinessCase"
  },
  BusinessRentalCase: {
    id: "Types.BusinessRentalCase",
    defaultMessage: "BusinessRentalCase"
  },
  BusinessRentalSearchProfile: {
    id: "Types.BusinessRentalSearchProfile",
    defaultMessage: "BusinessRentalSearchProfile"
  },
  BusinessSaleSearchProfile: {
    id: "Types.BusinessSaleSearchProfile",
    defaultMessage: "BusinessSaleSearchProfile"
  },
  BusinessSalesCase: {
    id: "Types.BusinessSalesCase",
    defaultMessage: "BusinessSalesCase"
  },
  BusinessSalesPresentation: {
    id: "Types.BusinessSalesPresentation",
    defaultMessage: "BusinessSalesPresentation"
  },
  BusinessValuationCase: {
    id: "Types.BusinessValuationCase",
    defaultMessage: "BusinessValuationCase"
  },
  CaseAnnounced: {
    id: "Types.CaseAnnounced",
    defaultMessage: "CaseAnnounced"
  },
  CaseAreas: {
    id: "Types.CaseAreas",
    defaultMessage: "CaseAreas"
  },
  CaseMarketingCampaignStatistics: {
    id: "Types.CaseMarketingCampaignStatistics",
    defaultMessage: "CaseMarketingCampaignStatistics"
  },
  CasePotentialBuyer: {
    id: "Types.CasePotentialBuyer",
    defaultMessage: "CasePotentialBuyer"
  },
  CasePromotion: {
    id: "Types.CasePromotion",
    defaultMessage: "CasePromotion"
  },
  CaseRelation: {
    id: "Types.CaseRelation",
    defaultMessage: "CaseRelation"
  },
  ConfigurableTextSection: {
    id: "Types.ConfigurableTextSection",
    defaultMessage: "ConfigurableTextSection"
  },
  Consent: {
    id: "Types.Consent",
    defaultMessage: "Consent"
  },
  ContractOfRentInterface: {
    id: "Types.ContractOfRentInterface",
    defaultMessage: "ContractOfRentInterface"
  },
  ContractOfSaleBusiness: {
    id: "Types.ContractOfSaleBusiness",
    defaultMessage: "ContractOfSaleBusiness"
  },
  ContractOfSaleInterface: {
    id: "Types.ContractOfSaleInterface",
    defaultMessage: "ContractOfSaleInterface"
  },
  ContractOfSalePrivate: {
    id: "Types.ContractOfSalePrivate",
    defaultMessage: "ContractOfSalePrivate"
  },
  CounselingCase: {
    id: "Types.CounselingCase",
    defaultMessage: "CounselingCase"
  },
  CounselingPropertyCase: {
    id: "Types.CounselingPropertyCase",
    defaultMessage: "CounselingPropertyCase"
  },
  Country: {
    id: "Types.Country",
    defaultMessage: "Country"
  },
  Customer: {
    id: "Types.Customer",
    defaultMessage: "Customer"
  },
  CustomerAddress: {
    id: "Types.CustomerAddress",
    defaultMessage: "CustomerAddress"
  },
  CustomerConsent: {
    id: "Types.CustomerConsent",
    defaultMessage: "CustomerConsent"
  },
  CustomerContactInfo: {
    id: "Types.CustomerContactInfo",
    defaultMessage: "CustomerContactInfo"
  },
  CustomerEmail: {
    id: "Types.CustomerEmail",
    defaultMessage: "CustomerEmail"
  },
  CustomerFavoriteCaseConnection: {
    id: "Types.CustomerFavoriteCaseConnection",
    defaultMessage: "CustomerFavoriteCaseConnection"
  },
  CustomerFavoriteCaseEdge: {
    id: "Types.CustomerFavoriteCaseEdge",
    defaultMessage: "CustomerFavoriteCaseEdge"
  },
  CustomerPhone: {
    id: "Types.CustomerPhone",
    defaultMessage: "CustomerPhone"
  },
  Document: {
    id: "Types.Document",
    defaultMessage: "Document"
  },
  DocumentAccessGrantedNotification: {
    id: "Types.DocumentAccessGrantedNotification",
    defaultMessage: "DocumentAccessGrantedNotification"
  },
  DocumentConnection: {
    id: "Types.DocumentConnection",
    defaultMessage: "DocumentConnection"
  },
  DocumentEdge: {
    id: "Types.DocumentEdge",
    defaultMessage: "DocumentEdge"
  },
  DocumentSignature: {
    id: "Types.DocumentSignature",
    defaultMessage: "DocumentSignature"
  },
  DocumentSigningNotification: {
    id: "Types.DocumentSigningNotification",
    defaultMessage: "DocumentSigningNotification"
  },
  DocumentType: {
    id: "Types.DocumentType",
    defaultMessage: "DocumentType"
  },
  Employee: {
    id: "Types.Employee",
    defaultMessage: "Employee"
  },
  EmployeeOffer: {
    id: "Types.EmployeeOffer",
    defaultMessage: "EmployeeOffer"
  },
  Error: {
    id: "Types.Error",
    defaultMessage: "Error"
  },
  FeatureToggle: {
    id: "Types.FeatureToggle",
    defaultMessage: "FeatureToggle"
  },
  INotificationBadgeCount: {
    id: "Types.INotificationBadgeCount",
    defaultMessage: "INotificationBadgeCount"
  },
  IThreadBadgeCount: {
    id: "Types.IThreadBadgeCount",
    defaultMessage: "IThreadBadgeCount"
  },
  IncreasePerYear: {
    id: "Types.IncreasePerYear",
    defaultMessage: "IncreasePerYear"
  },
  LeadConsentGraphType: {
    id: "Types.LeadConsentGraphType",
    defaultMessage: "LeadConsentGraphType"
  },
  LeadInterface: {
    id: "Types.LeadInterface",
    defaultMessage: "LeadInterface"
  },
  LeadNotification: {
    id: "Types.LeadNotification",
    defaultMessage: "LeadNotification"
  },
  LeadOpenHouse: {
    id: "Types.LeadOpenHouse",
    defaultMessage: "LeadOpenHouse"
  },
  LeadPresentation: {
    id: "Types.LeadPresentation",
    defaultMessage: "LeadPresentation"
  },
  LeadSalesMaterialWithContact: {
    id: "Types.LeadSalesMaterialWithContact",
    defaultMessage: "LeadSalesMaterialWithContact"
  },
  LeadTypeGraphType: {
    id: "Types.LeadTypeGraphType",
    defaultMessage: "LeadTypeGraphType"
  },
  MarketDataCase: {
    id: "Types.MarketDataCase",
    defaultMessage: "MarketDataCase"
  },
  MarketDataCasePrimaryImage: {
    id: "Types.MarketDataCasePrimaryImage",
    defaultMessage: "MarketDataCasePrimaryImage"
  },
  MarketingCampaign: {
    id: "Types.MarketingCampaign",
    defaultMessage: "MarketingCampaign"
  },
  MarketingCampaignInteraction: {
    id: "Types.MarketingCampaignInteraction",
    defaultMessage: "MarketingCampaignInteraction"
  },
  MarketingCampaignInteractionStatistics: {
    id: "Types.MarketingCampaignInteractionStatistics",
    defaultMessage: "MarketingCampaignInteractionStatistics"
  },
  MarketingCampaignInteractionsStatistics: {
    id: "Types.MarketingCampaignInteractionsStatistics",
    defaultMessage: "MarketingCampaignInteractionsStatistics"
  },
  MarketingCampaignLink: {
    id: "Types.MarketingCampaignLink",
    defaultMessage: "MarketingCampaignLink"
  },
  MarketingCampaignTotals: {
    id: "Types.MarketingCampaignTotals",
    defaultMessage: "MarketingCampaignTotals"
  },
  Media: {
    id: "Types.Media",
    defaultMessage: "Media"
  },
  MediaDataConnection: {
    id: "Types.MediaDataConnection",
    defaultMessage: "MediaDataConnection"
  },
  MediaDataEdge: {
    id: "Types.MediaDataEdge",
    defaultMessage: "MediaDataEdge"
  },
  MediaType: {
    id: "Types.MediaType",
    defaultMessage: "MediaType"
  },
  Message: {
    id: "Types.Message",
    defaultMessage: "Message"
  },
  MessageConnection: {
    id: "Types.MessageConnection",
    defaultMessage: "MessageConnection"
  },
  MessageEdge: {
    id: "Types.MessageEdge",
    defaultMessage: "MessageEdge"
  },
  MessageNotification: {
    id: "Types.MessageNotification",
    defaultMessage: "MessageNotification"
  },
  Mutation: {
    id: "Types.Mutation",
    defaultMessage: "Mutation"
  },
  MyPageMwSubscription: {
    id: "Types.MyPageMwSubscription",
    defaultMessage: "MyPageMwSubscription"
  },
  MyPageSpot: {
    id: "Types.MyPageSpot",
    defaultMessage: "MyPageSpot"
  },
  MyPageSpotEntry: {
    id: "Types.MyPageSpotEntry",
    defaultMessage: "MyPageSpotEntry"
  },
  Neighborhood: {
    id: "Types.Neighborhood",
    defaultMessage: "Neighborhood"
  },
  NeighborhoodPin: {
    id: "Types.NeighborhoodPin",
    defaultMessage: "NeighborhoodPin"
  },
  NeighborhoodPinConnection: {
    id: "Types.NeighborhoodPinConnection",
    defaultMessage: "NeighborhoodPinConnection"
  },
  NeighborhoodPinEdge: {
    id: "Types.NeighborhoodPinEdge",
    defaultMessage: "NeighborhoodPinEdge"
  },
  Node: {
    id: "Types.Node",
    defaultMessage: "Node"
  },
  NotificationInterface: {
    id: "Types.NotificationInterface",
    defaultMessage: "NotificationInterface"
  },
  NotificationInterfaceConnection: {
    id: "Types.NotificationInterfaceConnection",
    defaultMessage: "NotificationInterfaceConnection"
  },
  NotificationInterfaceEdge: {
    id: "Types.NotificationInterfaceEdge",
    defaultMessage: "NotificationInterfaceEdge"
  },
  Offer: {
    id: "Types.Offer",
    defaultMessage: "Offer"
  },
  OfferConnection: {
    id: "Types.OfferConnection",
    defaultMessage: "OfferConnection"
  },
  OfferEdge: {
    id: "Types.OfferEdge",
    defaultMessage: "OfferEdge"
  },
  OffmarketCase: {
    id: "Types.OffmarketCase",
    defaultMessage: "OffmarketCase"
  },
  OpenHouse: {
    id: "Types.OpenHouse",
    defaultMessage: "OpenHouse"
  },
  OwnerCost: {
    id: "Types.OwnerCost",
    defaultMessage: "OwnerCost"
  },
  PageInfo: {
    id: "Types.PageInfo",
    defaultMessage: "PageInfo"
  },
  ParticipantIdentity: {
    id: "Types.ParticipantIdentity",
    defaultMessage: "ParticipantIdentity"
  },
  Person: {
    id: "Types.Person",
    defaultMessage: "Person"
  },
  Portfolio: {
    id: "Types.Portfolio",
    defaultMessage: "Portfolio"
  },
  PredefinedArea: {
    id: "Types.PredefinedArea",
    defaultMessage: "PredefinedArea"
  },
  PrivateBuilding: {
    id: "Types.PrivateBuilding",
    defaultMessage: "PrivateBuilding"
  },
  PrivateRentalSearchProfile: {
    id: "Types.PrivateRentalSearchProfile",
    defaultMessage: "PrivateRentalSearchProfile"
  },
  PrivateSaleSearchProfile: {
    id: "Types.PrivateSaleSearchProfile",
    defaultMessage: "PrivateSaleSearchProfile"
  },
  Query: {
    id: "Types.Query",
    defaultMessage: "Query"
  },
  RectifyCase: {
    id: "Types.RectifyCase",
    defaultMessage: "RectifyCase"
  },
  RentalCase: {
    id: "Types.RentalCase",
    defaultMessage: "RentalCase"
  },
  RentalPresentation: {
    id: "Types.RentalPresentation",
    defaultMessage: "RentalPresentation"
  },
  RentalPresentationBusiness: {
    id: "Types.RentalPresentationBusiness",
    defaultMessage: "RentalPresentationBusiness"
  },
  RentalPresentationPrivate: {
    id: "Types.RentalPresentationPrivate",
    defaultMessage: "RentalPresentationPrivate"
  },
  RequestedDocumentNotification: {
    id: "Types.RequestedDocumentNotification",
    defaultMessage: "RequestedDocumentNotification"
  },
  RequestedDocumentType: {
    id: "Types.RequestedDocumentType",
    defaultMessage: "RequestedDocumentType"
  },
  Response: {
    id: "Types.Response",
    defaultMessage: "Response"
  },
  SalesCase: {
    id: "Types.SalesCase",
    defaultMessage: "SalesCase"
  },
  SalesPresentation: {
    id: "Types.SalesPresentation",
    defaultMessage: "SalesPresentation"
  },
  SalesPresentationPrivate: {
    id: "Types.SalesPresentationPrivate",
    defaultMessage: "SalesPresentationPrivate"
  },
  SearchArea: {
    id: "Types.SearchArea",
    defaultMessage: "SearchArea"
  },
  SearchProfile: {
    id: "Types.SearchProfile",
    defaultMessage: "SearchProfile"
  },
  SearchProfileExternalMatchedCase: {
    id: "Types.SearchProfileExternalMatchedCase",
    defaultMessage: "SearchProfileExternalMatchedCase"
  },
  SearchProfileExternalMatchedCaseConnection: {
    id: "Types.SearchProfileExternalMatchedCaseConnection",
    defaultMessage: "SearchProfileExternalMatchedCaseConnection"
  },
  SearchProfileExternalMatchedCaseEdge: {
    id: "Types.SearchProfileExternalMatchedCaseEdge",
    defaultMessage: "SearchProfileExternalMatchedCaseEdge"
  },
  SearchProfileInterfaceConnection: {
    id: "Types.SearchProfileInterfaceConnection",
    defaultMessage: "SearchProfileInterfaceConnection"
  },
  SearchProfileInterfaceEdge: {
    id: "Types.SearchProfileInterfaceEdge",
    defaultMessage: "SearchProfileInterfaceEdge"
  },
  SearchProfileMatchNotification: {
    id: "Types.SearchProfileMatchNotification",
    defaultMessage: "SearchProfileMatchNotification"
  },
  SearchProfileMatchedCase: {
    id: "Types.SearchProfileMatchedCase",
    defaultMessage: "SearchProfileMatchedCase"
  },
  SearchProfileMatchedCaseConnection: {
    id: "Types.SearchProfileMatchedCaseConnection",
    defaultMessage: "SearchProfileMatchedCaseConnection"
  },
  SearchProfileMatchedCaseEdge: {
    id: "Types.SearchProfileMatchedCaseEdge",
    defaultMessage: "SearchProfileMatchedCaseEdge"
  },
  SearchProfileMatchedCaseNotification: {
    id: "Types.SearchProfileMatchedCaseNotification",
    defaultMessage: "SearchProfileMatchedCaseNotification"
  },
  ServiceIdentity: {
    id: "Types.ServiceIdentity",
    defaultMessage: "ServiceIdentity"
  },
  Shop: {
    id: "Types.Shop",
    defaultMessage: "Shop"
  },
  StakeholderIdentity: {
    id: "Types.StakeholderIdentity",
    defaultMessage: "StakeholderIdentity"
  },
  StakeholderInOffer: {
    id: "Types.StakeholderInOffer",
    defaultMessage: "StakeholderInOffer"
  },
  StakeholderInRegistry: {
    id: "Types.StakeholderInRegistry",
    defaultMessage: "StakeholderInRegistry"
  },
  TaskActivityConnection: {
    id: "Types.TaskActivityConnection",
    defaultMessage: "TaskActivityConnection"
  },
  TaskActivityEdge: {
    id: "Types.TaskActivityEdge",
    defaultMessage: "TaskActivityEdge"
  },
  TaskActivityGraph: {
    id: "Types.TaskActivityGraph",
    defaultMessage: "TaskActivityGraph"
  },
  Thread: {
    id: "Types.Thread",
    defaultMessage: "Thread"
  },
  ThreadConnection: {
    id: "Types.ThreadConnection",
    defaultMessage: "ThreadConnection"
  },
  ThreadEdge: {
    id: "Types.ThreadEdge",
    defaultMessage: "ThreadEdge"
  },
  ThreadParticipant: {
    id: "Types.ThreadParticipant",
    defaultMessage: "ThreadParticipant"
  },
  TimelineActivity: {
    id: "Types.TimelineActivity",
    defaultMessage: "TimelineActivity"
  },
  TimelineActivityConnection: {
    id: "Types.TimelineActivityConnection",
    defaultMessage: "TimelineActivityConnection"
  },
  TimelineActivityEdge: {
    id: "Types.TimelineActivityEdge",
    defaultMessage: "TimelineActivityEdge"
  },
  TimelineActivityNote: {
    id: "Types.TimelineActivityNote",
    defaultMessage: "TimelineActivityNote"
  },
  TotalCosts: {
    id: "Types.TotalCosts",
    defaultMessage: "TotalCosts"
  },
  ValuationCase: {
    id: "Types.ValuationCase",
    defaultMessage: "ValuationCase"
  },
  Viewer: {
    id: "Types.Viewer",
    defaultMessage: "Viewer"
  },
  ZipCode: {
    id: "Types.ZipCode",
    defaultMessage: "ZipCode"
  }
});

export type Type = keyof typeof Types;

export const formatType = (intl: IntlShape) => (key?: Type | string | null) => {
  if (key && TypeMessages[key]) {
    return intl.formatMessage(TypeMessages[key]);
  }

  return key ?? null;
}

export const useFormatType = () => {
  const intl = useIntl();
  return useMemo(() => formatType(intl), [intl]);
} 

/* prettier-ignore-end */

import ReactDOM from 'react-dom/client';
import AppRoot from 'app/AppRoot';
import { AppSettings } from 'configs/app-settings-defaults';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const initReactApp = (config: AppSettings) => {
  root.render(
    // <React.StrictMode>
    <AppRoot {...config} />,
    // </React.StrictMode>
  );
};

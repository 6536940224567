import {
  ConnectionHandler,
  MutationParameters,
  RecordProxy,
  RecordSourceSelectorProxy,
  Variables,
} from 'relay-runtime';
import { GraphQLTaggedNode } from 'react-relay';
import { ReactNode, useCallback } from 'react';
import { MutationConfig, useMutation } from 'relay-hooks';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useFormatType } from '../../trans/TypeTranslations';
import { responseHasErrors } from 'hooks/relay/relay-utils';

type MwBaseUpdaterConfig = {
  __typename?: string | null;
  listArgs?: Variables;
  listName?: string;
  parentID?: string;
  rootField?: string;
  mutationName?: string;
  payloadName?: string;
};

type MwToastConfig = {
  toast?: boolean;
  toastMessageSuccess?: ReactNode;
  toastMessageFail?: ReactNode;
};

type MwCreateUpdaterConfig = MwBaseUpdaterConfig;
type MwConfig<T extends MutationParameters> = MutationConfig<T>;

type CreateHookConfig<T extends MutationParameters> = MwBaseUpdaterConfig & MwToastConfig & MwConfig<T>;

export function useCreate<T extends MutationParameters>(
  graphqlTaggedNode: GraphQLTaggedNode,
  hookConfig: CreateHookConfig<T> = {},
) {
  const [nativeMutationFn, mutationState] = useMutation<T>(graphqlTaggedNode, hookConfig);
  const formatType = useFormatType();

  const makeUpdater = useCallback(
    ({ __typename, listName, listArgs, parentID, rootField, payloadName, mutationName }: MwCreateUpdaterConfig) => {
      return (store: RecordSourceSelectorProxy<T['response']>) => {
        if (!mutationName) {
          return;
        }

        const payload = store.getRootField(mutationName);
        if (!payload) {
          return;
        }

        let parent: RecordProxy<object> | null | undefined = null;
        if (parentID) {
          parent = store.get(parentID);
        }
        if (!parent && rootField) {
          parent = store.getRoot().getLinkedRecord(rootField);
        }
        if (!parent || !listName) {
          return;
        }

        let nodePayload: RecordProxy | RecordProxy[] | null = payload.getLinkedRecord(payloadName ?? 'createdObject');
        if (nodePayload === null) {
          nodePayload = payload.getLinkedRecords(payloadName ?? 'createdObjects');
        }
        if (!nodePayload) return;

        const nodes = Array.isArray(nodePayload) ? nodePayload : [nodePayload];
        if (!nodes) return;

        const connection = ConnectionHandler.getConnection(parent, listName, listArgs);

        if (connection) {
          const totalCount = (connection.getValue('totalCount') as number) ?? 0;
          connection.setValue(totalCount + nodes.length, 'totalCount');

          nodes.forEach(node => {
            const edge = ConnectionHandler.createEdge(store, connection, node, `${__typename}Edge`);
            ConnectionHandler.insertEdgeAfter(connection, edge);
          });

          return;
        }

        const linkedRecords = parent.getLinkedRecords(listName, listArgs) || [];
        parent.setLinkedRecords([...linkedRecords, ...nodes], listName, listArgs);
      };
    },
    [],
  );

  const mutationFn = useCallback(
    (mutationConfig: CreateHookConfig<T> = {}) => {
      const mergedConfig: CreateHookConfig<T> = {
        mutationName: `create${hookConfig?.__typename ?? mutationConfig.__typename}`,
        ...hookConfig,
        ...mutationConfig,
      };

      const updater = makeUpdater(mergedConfig);

      const promise = nativeMutationFn({
        variables: {},
        updater,
        ...mutationConfig,
      });

      if (mergedConfig?.toast) {
        promise
          .then(() => {
            toast.success(mergedConfig.toastMessageSuccess);
          })
          .catch(() => {
            toast.error(mergedConfig.toastMessageFail);
          });
      } else {
        promise
          .then(res => {
            if (responseHasErrors(res)) {
              toast.error(
                <FormattedMessage
                  id="UseCreate.Error"
                  defaultMessage="{value} was not created"
                  values={{ value: formatType(hookConfig.__typename) }}
                />,
              );
            } else {
              toast.success(
                <FormattedMessage
                  id="UseCreate.Success"
                  defaultMessage="{value} was created"
                  values={{ value: formatType(hookConfig.__typename) }}
                />,
              );
            }
          })
          .catch(() => {
            toast.error(
              <FormattedMessage
                id="UseCreate.Error"
                defaultMessage="{value} was not created"
                values={{ value: formatType(hookConfig.__typename) }}
              />,
            );
          });
      }

      return promise;
    },
    [formatType, hookConfig, makeUpdater, nativeMutationFn],
  );

  return [mutationFn, mutationState] as const;
}

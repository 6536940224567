import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';

import { matchHeroContentCounselingCase$key } from '__generated__/matchHeroContentCounselingCase.graphql';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatNumber } from 'tools/string-formatters';

export interface MatchHeroContentCounselingCaseProps {
  fragment: matchHeroContentCounselingCase$key | null | undefined;
}

const fragment = graphql`
  fragment matchHeroContentCounselingCase on BaseCaseMainOffer {
    cashPrice
  }
`;
const MatchHeroContentCounselingCase: FC<MatchHeroContentCounselingCaseProps> = props => {
  const data = useFragment(fragment, props.fragment || null);

  return (
    <Block className="match-hero-details__row marginTop" flex spaceBetween>
      <Typography tagStyle="bodySmall">
        <FormattedMessage defaultMessage="Case price" id="Match.Cashprice" />
      </Typography>
      <Typography tagStyle="bodySmall">{formatNumber(data?.cashPrice)}</Typography>
    </Block>
  );
};

export default MatchHeroContentCounselingCase;

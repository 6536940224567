import { graphql } from 'react-relay';

export const createPresentationMutationQuery = graphql`
  mutation createPresentationMutation($input: Presentation!) {
    createPresentation(input: $input) {
      createdObject {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const requestNotificationPermission = () => {
  // handle old callback way and new promise way of using Notification.requestPermission.
  //https://web.dev/push-notifications-subscribing-a-user/#requesting-permission
  return new Promise((resolve, reject) =>
    Notification.requestPermission(result => {
      if (result) {
        resolve(result);
      }
      reject('no result found');
    }).catch(err => {
      reject(err);
    }),
  );
};

import {
  MessageNotification,
  DocumentSigningNotification,
  ActivityNotification,
  SearchProfileMatchNotification,
  RequestedDocumentNotification,
  SearchProfileMatchedCaseNotification,
  DocumentAccessGrantedNotification,
} from 'Types';
import { getRelatedLink } from 'tools/string-formatters';

type Props = {
  notificationType: string;
  threadId?: string;
  relationType?: {
    relationId?: string | null;
    relationType?: string | null;
  } | null;
};

export const getNotificationUrl = ({ notificationType, threadId, relationType }: Props) => {
  switch (notificationType) {
    case MessageNotification:
      return threadId ? `/messages/?id=${threadId}` : '/messages';
    case RequestedDocumentNotification:
    case DocumentSigningNotification:
    case DocumentAccessGrantedNotification:
      return relationType ? getRelatedLink(relationType, '#sign') : '/';
    case ActivityNotification:
      return '/activities';
    case SearchProfileMatchNotification:
    case SearchProfileMatchedCaseNotification:
    default:
      return '/';
  }
};

/**
 * @generated SignedSource<<d21b4168df26cc13ee11a21dbbf89f5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type documentsTableRowFragment$data = {
  readonly documentDownloadUrlCookieAuth: string | null | undefined;
  readonly documentName: string | null | undefined;
  readonly documentTypeName: string | null | undefined;
  readonly grantedDate: any | null | undefined;
  readonly propertyOverviewUrl: string | null | undefined;
  readonly readDate: any | null | undefined;
  readonly " $fragmentType": "documentsTableRowFragment";
};
export type documentsTableRowFragment$key = {
  readonly " $data"?: documentsTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"documentsTableRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "documentsTableRowFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "inline",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "previewState",
          "value": "FullVersion"
        }
      ],
      "kind": "ScalarField",
      "name": "documentDownloadUrlCookieAuth",
      "storageKey": "documentDownloadUrlCookieAuth(inline:false,previewState:\"FullVersion\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyOverviewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentTypeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantedDate",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "11e886bc1c9511cdad5817da11a9e6b6";

export default node;

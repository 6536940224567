import classNames from 'classnames';

import './typography.global.scss';
import { ElementType, forwardRef, HTMLAttributes } from 'react';

type TypographyTags = 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'label' | 'small';

type TypographyStyles =
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'bodyXSmall'
  | 'cta'
  | 'emphasize'
  | 'headlineLarge'
  | 'headlineMedium'
  | 'headlineSmall'
  | 'navigation'
  | 'display';

type TypographySubjectColors = 'error' | 'success' | 'primary' | 'gray' | 'white' | 'brand';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  alignRight?: boolean;
  elipsis?: boolean;
  htmlFor?: string;
  subjectColor?: TypographySubjectColors;
  tag?: TypographyTags;
  tagStyle?: TypographyStyles;
  upperCase?: boolean;
}

const Typography = forwardRef<HTMLElement, TypographyProps>(
  ({ children, tag = 'span', tagStyle, upperCase, subjectColor, className, elipsis, alignRight, ...props }, ref) => {
    const Tag: ElementType = tag;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      <Tag //@ts-ignore
        ref={ref} //todo: fix wrong type
        className={classNames(tagStyle, className, subjectColor, {
          'align-right': alignRight,
          elipsis,
          uppercase: upperCase,
        })}
        {...props}
      >
        {children}
      </Tag>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;

import {
  CacheConfig,
  Environment,
  GraphQLResponseWithData,
  Network,
  Observable,
  QueryResponseCache,
  RecordSource,
  RequestParameters,
  RequiredFieldLogger,
  Store,
  UploadableMap,
  Variables,
} from 'relay-runtime';
import { GraphQLResponse } from 'relay-runtime/lib/network/RelayNetworkTypes';

import { authenticationService } from 'services/authentication/authentication-service';
import { SignalrConnection } from './signalr-connection';

const cache = new QueryResponseCache({ size: 25, ttl: 10000 });
let _clientId = 0;

function createGraphQLQueryFetcher(endpoint: string) {
  return async function createFetchGraphQLQuery(
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables?: UploadableMap | null,
  ) {
    const queryId = request.name;
    const isMutation = request.operationKind === 'mutation';
    const isQuery = request.operationKind === 'query';
    const cachedData = cache.get(queryId, variables);

    const isImpersonated = !!authenticationService?.idTokenParsed?.impersonator;
    if (isMutation && isImpersonated) {
      return;
    }

    const forceLoad = cacheConfig && cacheConfig.force;

    if (
      !forceLoad &&
      (cachedData as GraphQLResponseWithData)?.data &&
      !(cachedData as GraphQLResponseWithData)?.errors
    ) {
      return cachedData;
    }

    const query = JSON.stringify({
      operationName: request.name,
      query: request.text,
      variables,
    });

    let body, headers;

    if (uploadables) {
      const formData = new FormData();
      for (const uploadable of Object.values(uploadables)) {
        formData.append('files[]', uploadable);
      }
      formData.append('query', query);
      body = formData;
      headers = {};
    } else {
      body = query;
      headers = { 'Content-Type': 'application/json' };
    }
    const token = await authenticationService.tokenAsync();

    const response = await fetch(endpoint, {
      body,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      method: 'POST',
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const json = await response.json();

    if (isQuery && json.data && !json.errors && !uploadables) {
      cache.set(queryId, variables, json);
    }

    // Clear cache on mutations
    if (isMutation) {
      cache.clear();
    }

    if (json?.errors) {
      return {
        data: null,
        errors: json.errors,
      };
    }

    return json;
  };
}

export function createRelayEnvironmentInstance(endpoint: string, connection: SignalrConnection) {
  const subscribe = (request: RequestParameters, variables: Variables) => {
    const clientId = _clientId++;

    const observable = Observable.create<GraphQLResponse>(sink => {
      connection.on('onOperation', (...payload: any[]) => sink.next(payload.map(x => x.payload)));

      connection.invoke('Subscribe', {
        id: clientId,
        payload: {
          operationName: request.name,
          query: request.text,
          variables,
        },
        type: 'start',
      });
    });

    const observableWithUnsubscribe = observable.finally(() => {
      connection.invoke('Subscribe', {
        id: clientId,
        type: 'stop',
      });
    });

    return observableWithUnsubscribe;
  };

  const network = Network.create(createGraphQLQueryFetcher(endpoint), subscribe);

  const source = new RecordSource();
  const store = new Store(source);
  const handlerProvider = null;
  const requiredFieldLogger: RequiredFieldLogger = e => console.error(e);

  const environment = new Environment({
    handlerProvider,
    network,
    store,
    requiredFieldLogger,
  });

  return environment;
}

import { ReactNode } from 'react';
import './card.scss';

type CardProps = {
  title?: string | ReactNode;
  children: ReactNode;
};
export const Card = ({ title, children }: CardProps) => {
  return (
    <div className={'card'}>
      {title && <div className={'card-title'}>{title}</div>}
      {children}
    </div>
  );
};

/**
 * @generated SignedSource<<f54a767e238de44fef4521c1c802d8d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type registerOpenHouseModal_Query$variables = Record<PropertyKey, never>;
export type registerOpenHouseModal_Query$data = {
  readonly viewer: {
    readonly customer: {
      readonly email: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
      readonly phone: string | null | undefined;
    } | null | undefined;
    readonly leadTypeFromEnum: {
      readonly currentConsent: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type registerOpenHouseModal_Query = {
  response: registerOpenHouseModal_Query$data;
  variables: registerOpenHouseModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "enum",
    "value": "OpenHouse"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadConsentGraphType",
  "kind": "LinkedField",
  "name": "currentConsent",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "registerOpenHouseModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LeadTypeGraphType",
            "kind": "LinkedField",
            "name": "leadTypeFromEnum",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": "leadTypeFromEnum(enum:\"OpenHouse\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "registerOpenHouseModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LeadTypeGraphType",
            "kind": "LinkedField",
            "name": "leadTypeFromEnum",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": "leadTypeFromEnum(enum:\"OpenHouse\")"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "860fb17b32c7b7fbfee80c912461a28e",
    "id": null,
    "metadata": {},
    "name": "registerOpenHouseModal_Query",
    "operationKind": "query",
    "text": "query registerOpenHouseModal_Query {\n  viewer {\n    customer {\n      firstName\n      lastName\n      email\n      phone\n      id\n    }\n    leadTypeFromEnum(enum: OpenHouse) {\n      currentConsent {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b840cce54195401398a688bdb2a174f0";

export default node;

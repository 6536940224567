import Typography from 'components/atoms/typography/typography';

import './badge.scss';
import { FC } from 'react';

export interface BadgeProps {
  count: number;
}

const Badge: FC<BadgeProps> = props => {
  const { count } = props;

  return count > 0 ? (
    <Typography className="badge" subjectColor="white" tagStyle="bodyXSmall">
      {count}
    </Typography>
  ) : null;
};

export default Badge;

import Icon from 'components/atoms/icon/icon';
import { FC } from 'react';
import { formatDate } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { searchProfileMatchedCaseNotification_fragment$key } from '__generated__/searchProfileMatchedCaseNotification_fragment.graphql';

export interface SearchProfileMatchedCaseNotificationProps {
  notification: searchProfileMatchedCaseNotification_fragment$key;
}

const searchProfileMatchedCaseNotification = graphql`
  fragment searchProfileMatchedCaseNotification_fragment on SearchProfileMatchedCaseNotification {
    id
    createdDate
    __typename
    read
    searchProfileMatchedCase {
      baseCase {
        address
        relationType {
          relationId
          relationType
        }
      }
    }
    searchProfile {
      name
    }
  }
`;

export const SearchProfileMatchedCaseNotification: FC<SearchProfileMatchedCaseNotificationProps> = ({
  notification,
}) => {
  const data = useFragment<searchProfileMatchedCaseNotification_fragment$key>(
    searchProfileMatchedCaseNotification,
    notification,
  );

  return (
    <Link
      className={classNames('notifications__link', { unread: !data.read })}
      to={`/match/${data.searchProfileMatchedCase?.baseCase?.relationType?.relationId}`}
    >
      <Icon name="Bell" />
      <div className="notifications__item-text">
        <Typography>
          <FormattedMessage
            defaultMessage="You have a new match on your search profile:"
            id="Notifications.NewMatchFromSearchProfile"
          />
          {data.searchProfile?.name}:<b> {data.searchProfileMatchedCase?.baseCase?.address}</b>
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </Link>
  );
};

import EmptyModule from 'components/molecules/empty-module/empty-module';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  searchString: string;
};
const EmptyTableBody: FC<Props> = props => {
  if (props.searchString) {
    return (
      <tr>
        <td colSpan={6}>
          <EmptyModule
            message={
              <FormattedMessage defaultMessage="Your search yielded no results" id="DocumentsTable.SearchIsEmpty" />
            }
          />
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td colSpan={6}>
          <EmptyModule
            message={
              <FormattedMessage
                defaultMessage="Your broker has not yet shared any documents with you"
                id="DocumentsTable.Empty"
              />
            }
          />
        </td>
      </tr>
    );
  }
};

export default EmptyTableBody;

import { matchHeroContentRentalCase$key } from '__generated__/matchHeroContentRentalCase.graphql';
import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatNumber } from 'tools/string-formatters';

export interface MatchHeroContentRentalCaseProps {
  fragment: matchHeroContentRentalCase$key | null;
  isExpanded?: boolean;
}

const fragment = graphql`
  fragment matchHeroContentRentalCase on BaseCase {
    energyMark
    yearBuilt
    roomCount
    caseAreas {
      livableArea
    }
    ... on RentalCase {
      rentMonthly
      accountWater
      accountHeat
      accountElectricity
      prepaidRent
      deposit
    }
  }
`;

const MatchHeroContentRentalCase: FC<MatchHeroContentRentalCaseProps> = props => {
  const data = useFragment(fragment, props.fragment);

  return (
    <Fragment>
      <Block className="match-hero-details__row marginTop" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Monthly rent" id="Match.RentMonthly" />
        </Typography>
        <Typography tagStyle="bodySmall">{formatNumber(data?.rentMonthly)}</Typography>
      </Block>
      <Block className="match-hero-details__row" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Housing area" id="Match.Area" />
        </Typography>
        <Typography tagStyle="bodySmall">{data?.caseAreas?.livableArea} m²</Typography>
      </Block>

      <Block className="match-hero-details__row" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Rooms" id="Match.NumberOfRooms" />
        </Typography>
        <Typography tagStyle="bodySmall">{data?.roomCount}</Typography>
      </Block>
      <Block className="match-hero-details__row" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Energy mark" id="Match.Energymark" />
        </Typography>
        <Typography tagStyle="bodySmall">{data?.energyMark}</Typography>
      </Block>

      <div
        className={classNames('match-hero-details__expandable', {
          isExpanded: props.isExpanded,
        })}
        id="section-content"
      >
        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="More info" id="Match.MoreInfo" />
        </Typography>

        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Year build" id="Match.YearBuild" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.yearBuilt}</Typography>
        </Block>

        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="Finances" id="Match.Finances" />
        </Typography>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Account water" id="Match.AccountWater" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.accountWater)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Account heat" id="Match.AccountHeat" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.accountHeat)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Account heat" id="Match.AccountHeat" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.accountHeat)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Account electricity" id="Match.AccountElectricity" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.accountElectricity)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Prepaid rent" id="Match.PrepaidRent" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.prepaidRent)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Deposit" id="Match.Deposit" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.deposit)}</Typography>
        </Block>
      </div>
    </Fragment>
  );
};

export default MatchHeroContentRentalCase;

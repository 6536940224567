import { graphql } from 'react-relay';

export const saleByOwnerMutation = graphql`
  mutation saleByOwnerMutation($input: UpdateLeadInputType!) {
    updateLead(input: $input) {
      errors {
        message
      }
      updatedObject {
        statusType
        id
      }
    }
  }
`;

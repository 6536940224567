import Button from 'components/atoms/button/button';
import { FormattedMessage } from 'react-intl';
import './sticky-chat.scss';
import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { stickyChat_fragment$key } from '__generated__/stickyChat_fragment.graphql';

export interface StickyChatProps {
  fragment: stickyChat_fragment$key | null;
}

const stickyChatFragment = graphql`
  fragment stickyChat_fragment on Customer {
    unreadThreadCount
  }
`;

const StickyChat: FC<StickyChatProps> = props => {
  const data = useFragment(stickyChatFragment, props.fragment);

  return (
    <div className="sticky-chat">
      <Button anchorTag className="sticky-chat__button" hideText href="/messages" iconStart="Chat" padding={false}>
        <FormattedMessage defaultMessage="Open Chat" id="Messages.OpenChat" />
      </Button>
      {!!data?.unreadThreadCount && <div className="sticky-chat__number">{data?.unreadThreadCount ?? 0}</div>}
    </div>
  );
};

export default StickyChat;

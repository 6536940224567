import { Component, ErrorInfo, ReactNode } from 'react';
import { sendErrorToServer } from 'services/errors/LogProxy';
import Container from 'components/utilities/container/container';
import { FormattedMessage } from 'react-intl';
import './error-boundary-block.scss';

type Props = unknown & {
  children: ReactNode;
  customErrorMessage?: string;
};

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
  hasError: boolean;
  errorId: string | null;
}

export class ErrorBoundaryBlock extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorId: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    const { message, stack } = error;
    const { componentStack } = errorInfo;
    const errorId = await sendErrorToServer({
      message,
      stack,
      componentStack: componentStack ?? '',
    });
    this.setState({ errorId });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container paddingTopReset className="error-message">
          <FormattedMessage defaultMessage="We are sorry, something went wrong" id="Default.ErrorMessage" />
          <span>{this.state.errorId}</span>
        </Container>
      );
    }

    return this.props.children;
  }
}

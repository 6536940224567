import { Card } from 'components/molecules/card/card';
import { FormattedMessage } from 'react-intl';
import { formatDate, formatTime } from 'tools/string-formatters';
import Button from 'components/atoms/button/button';
import { graphql, useFragment } from 'react-relay';
import { leadListItemFragment$data, leadListItemFragment$key } from '__generated__/leadListItemFragment.graphql';
import { LeadStatusTypeEnum } from 'Enums';

type Props = {
  fragment: leadListItemFragment$key;
  handleOnClick?: (lead: Lead) => void;
  disabled?: boolean;
};

export type Lead = NonNullable<leadListItemFragment$data>;

const leadListItemFragment = graphql`
  fragment leadListItemFragment on LeadInterface {
    id
    __typename
    ... on LeadPresentation {
      comment
      presentationDate
      caseAddress
      caseZipAndCity
    }
    ... on LeadOpenHouse {
      openHouseStartTime
      comment
      caseAddress
      caseZipAndCity
    }
    ... on LeadSalesMaterialWithContact {
      comment
      caseAddress
      caseZipAndCity
    }
    email
    phoneNumber
    firstName
    lastName
    type {
      id
      displayName
    }
    statusType
    ...leadDialogContent
  }
`;

export const LeadListItem = ({ fragment, ...props }: Props) => {
  const lead = useFragment(leadListItemFragment, fragment);

  const handleOnClick = (lead: leadListItemFragment$data) => {
    if (props.handleOnClick) {
      props.handleOnClick(lead);
    }
  };
  return (
    <div key={lead.id}>
      <Card>
        <div>
          <h3>{lead.type?.displayName}</h3>
        </div>
        <div className={'info-section'}>
          <span>{lead.caseAddress}</span>
          <span>{lead.caseZipAndCity}</span>
        </div>
        <div className={'info-section'}>
          <span>
            <FormattedMessage defaultMessage={'Date'} id={'Default.Date'} />:
          </span>
          <span>
            {lead.presentationDate || lead.openHouseStartTime
              ? `${formatDate(lead.presentationDate || lead.openHouseStartTime)}, kl. ${formatTime(lead.presentationDate || lead.openHouseStartTime)}`
              : ' '}
          </span>
        </div>
        <div className={'info-section'}>
          <span>
            <FormattedMessage defaultMessage={'Info'} id={'Default.Info'} />:
          </span>
          <span>
            {lead.firstName} {lead.lastName}
          </span>
          <span>{lead.email}</span>
          <span>{lead.phoneNumber}</span>
        </div>
        {lead.comment && (
          <div className={'info-section'}>
            <span>
              <FormattedMessage defaultMessage={'Remarks'} id={'Default.Remarks'} />:
            </span>
            <span>{lead.comment}</span>
          </div>
        )}
        <Button
          isFullWidth
          onClick={() => handleOnClick(lead)}
          disabled={props.disabled || !props.handleOnClick}
          className="sales-card-button"
        >
          <FormattedMessage
            id={'saleByOwner.handle'}
            defaultMessage={'{bool, select, true {Handle} false {Handled} other {Handle}}'}
            values={{ bool: lead.statusType === LeadStatusTypeEnum.Recieved }}
          />
        </Button>
      </Card>
    </div>
  );
};

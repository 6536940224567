import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  registerOpenHouseModalFragment$data,
  registerOpenHouseModalFragment$key,
} from '__generated__/registerOpenHouseModalFragment.graphql';
import {
  OpenHouseInputType,
  registerOpenHouseModalMutation,
} from '__generated__/registerOpenHouseModalMutation.graphql';
import { registerOpenHouseModal_Query } from '__generated__/registerOpenHouseModal_Query.graphql';
import Button from 'components/atoms/button/button';
import { Spinner } from 'components/atoms/spinner/spinner';
import Typography from 'components/atoms/typography/typography';
import Input from 'components/molecules/input/input';
import { SimpleModal } from 'components/molecules/simple-modal/simple-modal';
import { SuccessSummit } from 'components/organisms/register-open-house-modal/success-summit';
import Row from 'components/utilities/row/row';
import { useTheme } from 'context/ThemeContext';
import { useList } from 'hooks/useList';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { useLazyLoadQuery, useMutation } from 'relay-hooks';
import { formatDate, formatTime, phoneRegExp } from 'tools/string-formatters';
import { InferType, object, string } from 'yup';
import './register-open-house-modal.scss';
import { updateSearchProfileMatchedCase } from 'services/relay/mutations/updateSearchProfileMatchedCase';
import { MatchProfileStatus } from 'Enums';

interface RegisterOpenHouseModalProps {
  closeModal: () => void;
  openHouses: registerOpenHouseModalFragment$key;
  preSelectedOpenHouseId?: string;
  matchId?: string | undefined;
}

export const getOpenHouseText = (startDate: string, endDate?: string) => {
  return `D. ${formatDate(startDate)}, kl ${formatTime(startDate)} - ${formatTime(endDate)}`;
};

const registerOpenHouseModalQuery = graphql`
  query registerOpenHouseModal_Query {
    viewer {
      customer {
        firstName
        lastName
        email
        phone
      }
      leadTypeFromEnum(enum: OpenHouse) {
        currentConsent {
          id
        }
      }
    }
  }
`;

type OpenHouse = NonNullable<NonNullable<registerOpenHouseModalFragment$data['openHouse']>[number]>;

const messages = defineMessages({
  companyNameRequired: {
    defaultMessage: 'Company name is required',
    id: 'ShowcasingForm.requiredCompanyName',
  },
  emailInvalid: {
    defaultMessage: 'Enter a valid e-mail',
    id: 'ShowcasingForm.invalidEmail',
  },
  emailRequired: {
    defaultMessage: 'E-mail is required',
    id: 'ShowcasingForm.requiredEmail',
  },
  firstNameRequired: {
    defaultMessage: 'First name is required',
    id: 'ShowcasingForm.requiredFirstName',
  },
  lastNameRequired: {
    defaultMessage: 'Last name is required',
    id: 'ShowcasingForm.requiredLastName',
  },
  phoneInvalid: {
    defaultMessage: 'Enter a valid phone number',
    id: 'ShowcasingForm.invalidPhone',
  },
  phoneRequired: {
    defaultMessage: 'Phone number is required',
    id: 'ShowcasingForm.requiredPhone',
  },
});

const registerOpenHouseModalFragment = graphql`
  fragment registerOpenHouseModalFragment on BaseCase @refetchable(queryName: "registerOpenHouseQuery") {
    openHouse(amount: 2) {
      activityType {
        isRegistration
        nameOnTimeline
      }
      isRegistred
      id
      startDate
      endDate
    }
  }
`;

export const createOpenHouseLeadMutation = graphql`
  mutation registerOpenHouseModalMutation($input: OpenHouseInputType!) {
    createOpenhouse(input: $input) {
      createdObject {
        id
      }
      errors {
        message
      }
    }
  }
`;
const RegisterOpenHouseModal = (props: RegisterOpenHouseModalProps) => {
  const { data: customerData } = useLazyLoadQuery<registerOpenHouseModal_Query>(registerOpenHouseModalQuery, {});
  const data = useFragment(registerOpenHouseModalFragment, props.openHouses);
  const theme = useTheme();
  const intl = useIntl();

  const [hasServerError, setHasServerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOpenHouse, setSelectedOpenHouse] = useState<OpenHouse | null>(null);
  const [step, setStep] = useState(0);

  const openHouses = useList(data.openHouse);

  // Here we handle if we pass a open house id to the modal.
  // If we can find it, then select it and go to next step
  useEffect(() => {
    if (!props.preSelectedOpenHouseId || !data) return;

    const openHouseFound = data.openHouse?.find(oHouse => oHouse?.id === props.preSelectedOpenHouseId);
    if (openHouseFound) {
      setSelectedOpenHouse(openHouseFound);
      setStep(1);
    }
  }, [props.preSelectedOpenHouseId, data]);

  const schema = object({
    caseId: string()
      .required()
      .default(customerData?.viewer?.leadTypeFromEnum?.currentConsent?.id),
    email: string()
      .email(intl.formatMessage(messages.emailInvalid))
      .trim()
      .default(customerData?.viewer?.customer?.email ?? ''),
    firstName: string()
      .required(intl.formatMessage(messages.firstNameRequired))
      .trim()
      .default(customerData?.viewer?.customer?.firstName ?? ''),
    lastName: string()
      .required(intl.formatMessage(messages.lastNameRequired))
      .trim()
      .default(customerData?.viewer?.customer?.lastName ?? ''),
    phoneNumber: string()
      .matches(phoneRegExp, {
        message: intl.formatMessage(messages.phoneInvalid),
      })
      .required(intl.formatMessage(messages.phoneRequired))
      .trim()
      .default(customerData?.viewer?.customer?.phone ?? ''),
  }).required();

  type RegisterOpenHouseSchemaType = InferType<typeof schema>;

  const caseId = customerData?.viewer?.leadTypeFromEnum?.currentConsent?.id ?? '';

  const [createOpenHouseLead] = useMutation<registerOpenHouseModalMutation>(createOpenHouseLeadMutation);

  const [markAsFavourite] = useMutation(updateSearchProfileMatchedCase);

  const {
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    register,
  } = useForm<RegisterOpenHouseSchemaType>({
    defaultValues: schema.getDefault(),
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: RegisterOpenHouseSchemaType) => {
    if (loading) return false;
    const input = {
      consentIdGlobal: data.caseId,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      openHouseId: selectedOpenHouse?.id || '',
    };
    setLoading(true);
    try {
      await createOpenHouseLead({
        variables: {
          input: input as OpenHouseInputType,
        },
      });

      if (props.matchId) {
        await markAsFavourite({
          variables: {
            input: {
              id: props.matchId,
              status: MatchProfileStatus.Favorite,
            },
          },
        });
      }

      setHasServerError(false);
    } catch (e) {
      setHasServerError(true);
    } finally {
      setLoading(false);
    }
  };

  const onError = (error: FieldErrors) => {
    console.log(error);
    setHasServerError(true);
    setLoading(false);
  };

  const handleClickOnRegisterOpenHouse = (openHouse: OpenHouse) => {
    setSelectedOpenHouse(openHouse);
    setStep(1);
  };

  if (caseId) {
    return (
      <SimpleModal onClose={props.closeModal}>
        <div className="register-modal">
          {isSubmitSuccessful && <SuccessSummit closeModal={props.closeModal} hasServerError={hasServerError} />}
          {!isSubmitSuccessful && (
            <Fragment>
              {step === 0 && (
                <div className="modal-list">
                  {openHouses.length > 0 &&
                    openHouses.map(openHouse => (
                      <Fragment key={openHouse?.id}>
                        <div className={'modal-list__item'}>
                          <div className="modal-list__item-text">
                            <h4>{openHouse?.activityType?.nameOnTimeline}</h4>
                            <p>{getOpenHouseText(openHouse?.startDate, openHouse?.endDate)}</p>
                          </div>

                          {openHouse?.activityType?.isRegistration && openHouse?.isRegistred && (
                            <div className={'modal-list__item-badge'}>
                              <p>
                                <FormattedMessage defaultMessage="Registered" id="Match.Registered" />
                              </p>
                            </div>
                          )}
                          {openHouse?.activityType?.isRegistration && !openHouse?.isRegistred && (
                            <Button onClick={() => handleClickOnRegisterOpenHouse(openHouse)}>
                              <FormattedMessage defaultMessage="Register" id="Match.Register" />
                            </Button>
                          )}
                        </div>
                      </Fragment>
                    ))}
                </div>
              )}
              {step === 1 && (
                <div className="modal-form">
                  <Typography className="modal-form__title" tag="h2">
                    <FormattedMessage defaultMessage="Register open house" id="OpenHouse.registerOpenHouse" />
                  </Typography>
                  <Typography className="modal-form__subtitle" tag="p">
                    <FormattedMessage
                      defaultMessage="You have wanted to register for the open house"
                      id="OpenHouse.subTitle"
                    />{' '}
                    {getOpenHouseText(selectedOpenHouse?.startDate || '', selectedOpenHouse?.endDate || '')}
                  </Typography>
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="modal-form__form-grid form-grid form-grid--column-1 tablet:form-grid--column-2">
                      <div>
                        <Input
                          errorMessage={errors.firstName?.message}
                          label={<FormattedMessage defaultMessage="First name" id="Form.firstname" />}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'First name',
                            id: 'Form.firstname',
                          })}
                          {...register('firstName')}
                        />
                      </div>

                      <div>
                        <Input
                          errorMessage={errors.lastName?.message}
                          label={<FormattedMessage defaultMessage="Middle name & last name" id="Form.lastname" />}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Middle name & last name',
                            id: 'Form.lastname',
                          })}
                          {...register('lastName')}
                        />
                      </div>
                      <div>
                        <Input
                          errorMessage={errors.phoneNumber?.message}
                          label={<FormattedMessage defaultMessage="Telefon" id="Form.phone" />}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Telefon',
                            id: 'Form.phone',
                          })}
                          {...register('phoneNumber')}
                        />
                      </div>
                      <div>
                        <Input
                          errorMessage={errors.email?.message}
                          label={<FormattedMessage defaultMessage="E-mail" id="Form.email" />}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'E-mail',
                            id: 'Form.email',
                          })}
                          {...register('email')}
                        />
                      </div>
                    </div>
                    <div className={'modal-form__privacy'}>
                      <p className="modal-form__privacy-text">
                        <FormattedMessage
                          defaultMessage="By registering for the Open House, you agree"
                          id="OpenHouse.privacyText"
                        />
                      </p>
                      <a href={theme?.privacyPolicyLink} target={'_blank'} rel="noreferrer">
                        <FormattedMessage
                          defaultMessage="{broker} guidelines for the use of personal information."
                          id="OpenHouse.privacyLink"
                          values={{ broker: theme?.brokerageName }}
                        />
                      </a>
                    </div>
                    <Row>
                      <div className={'modal-form__button'}>
                        <Button type="submit">
                          <FormattedMessage defaultMessage="Register open house" id="OpenHouse.registerOpenHouse" />
                        </Button>
                        {loading && <Spinner />}
                      </div>
                    </Row>
                  </form>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </SimpleModal>
    );
  } else {
    return null;
  }
};

export default RegisterOpenHouseModal;

import Button from 'components/atoms/button/button';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import './gallery-navigation.scss';

export interface GalleryNavigationProps {
  isFullscreenVisible?: boolean;
  isPlanVisible?: boolean;
  isMapVisible?: boolean;
  isVideoInList?: boolean;
  onSelectFullscreen: () => void;
  onSelectMap: () => void;
  onSelectPlan: () => void;
  onSelectVideo: () => void;
}

const GalleryNavigation: FC<GalleryNavigationProps> = props => {
  const {
    onSelectFullscreen,
    onSelectMap,
    onSelectPlan,
    onSelectVideo,
    isFullscreenVisible,
    isPlanVisible = true,
    isMapVisible = true,
    isVideoInList = false,
  } = props;

  return (
    <div className="gallery-navigation">
      {isVideoInList && (
        <Button
          className="gallery-navigation__button"
          hideText
          iconStart="Play"
          onClick={onSelectVideo}
          padding={false}
        >
          <FormattedMessage defaultMessage="Video" id="Gallery.Video" />
        </Button>
      )}
      {isPlanVisible && (
        <Button className="gallery-navigation__button" hideText iconStart="Plan" onClick={onSelectPlan} padding={false}>
          <FormattedMessage defaultMessage="Plan" id="Gallery.Plan" />
        </Button>
      )}
      {isFullscreenVisible && (
        <Button
          className="gallery-navigation__button"
          hideText
          iconStart="Expand"
          onClick={onSelectFullscreen}
          padding={false}
        >
          <FormattedMessage defaultMessage="Expand" id="Gallery.Expand" />
        </Button>
      )}
      {isMapVisible && (
        <Button className="gallery-navigation__button" hideText iconStart="Pin" onClick={onSelectMap} padding={false}>
          <FormattedMessage defaultMessage="Map" id="Gallery.Map" />
        </Button>
      )}
    </div>
  );
};

export default GalleryNavigation;

import { useState } from 'react';

type UseToggleReturnType = [boolean, (value?: boolean) => void];

const useToggle = (initialState = false): UseToggleReturnType => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = (value?: boolean) => {
    setState(value !== undefined ? value : prevState => !prevState);
  };

  return [state, toggle];
};

export default useToggle;

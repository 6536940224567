import useWindowSize from './useWindowSize';

// TODO: use import { isMobile, isTablet } from 'react-device-detect';

const useIsMobile = () => {
  const { width } = useWindowSize();

  return !!width && width < 1024; // aligned with $breakpoint-tablet
};

export default useIsMobile;

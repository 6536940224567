/**
 * @generated SignedSource<<8c87b9c661bf56836ade8eacc6f31b5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type registerOpenHouseModalFragment$data = {
  readonly id: string;
  readonly openHouse: ReadonlyArray<{
    readonly activityType: {
      readonly isRegistration: boolean | null | undefined;
      readonly nameOnTimeline: string | null | undefined;
    } | null | undefined;
    readonly endDate: any | null | undefined;
    readonly id: string | null | undefined;
    readonly isRegistred: boolean | null | undefined;
    readonly startDate: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "registerOpenHouseModalFragment";
};
export type registerOpenHouseModalFragment$key = {
  readonly " $data"?: registerOpenHouseModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"registerOpenHouseModalFragment">;
};

import registerOpenHouseQuery_graphql from './registerOpenHouseQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": registerOpenHouseQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "registerOpenHouseModalFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "amount",
          "value": 2
        }
      ],
      "concreteType": "OpenHouse",
      "kind": "LinkedField",
      "name": "openHouse",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityType",
          "kind": "LinkedField",
          "name": "activityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRegistration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameOnTimeline",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRegistred",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": "openHouse(amount:2)"
    },
    (v0/*: any*/)
  ],
  "type": "BaseCase",
  "abstractKey": "__isBaseCase"
};
})();

(node as any).hash = "302468eb38bd271c0d6733f7ec1c2cc9";

export default node;

import { ReactNode, useCallback } from 'react';
import { useMutation, MutationConfig } from 'relay-hooks';
import { MutationParameters } from 'relay-runtime';
import { GraphQLTaggedNode } from 'react-relay';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useFormatType } from '../../trans/TypeTranslations';

type MwToastConfig = {
  __typename?: string;
  toast?: boolean;
  toastMessageSuccess?: ReactNode;
  toastMessageFail?: ReactNode;
};

type UpdateHookConfig<T extends MutationParameters> = MutationConfig<T> & MwToastConfig;

export function useUpdate<T extends MutationParameters>(
  graphqlTaggedNode: GraphQLTaggedNode,
  hookConfig: UpdateHookConfig<T> = {},
) {
  const formatType = useFormatType();

  const [mutation, mutationState] = useMutation<T>(graphqlTaggedNode);

  const mutationFn = useCallback(
    (customConfig: UpdateHookConfig<T> = {}) => {
      const mergedConfig: UpdateHookConfig<T> = {
        ...hookConfig,
        ...customConfig,
      };

      const promise = mutation({ variables: {}, ...mergedConfig });

      if (mergedConfig?.toast) {
        promise
          .then(() => {
            toast.success(mergedConfig.toastMessageSuccess);
          })
          .catch(() => {
            toast.error(mergedConfig.toastMessageFail);
          });
      } else {
        promise
          .then(() => {
            toast.success(
              <FormattedMessage
                id="UseUpdate.Success"
                defaultMessage="{value} was updated"
                values={{ value: formatType(hookConfig.__typename) }}
              />,
            );
          })
          .catch(() => {
            toast.error(
              <FormattedMessage
                id="UseUpdate.Error"
                defaultMessage="{value} was not updated"
                values={{ value: formatType(hookConfig.__typename) }}
              />,
            );
          });
      }
      return promise;
    },
    [formatType, hookConfig, mutation],
  );

  return [mutationFn, mutationState] as const;
}

import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';

import { FormattedMessage } from 'react-intl';
import './swiper-navigation.scss';
import { FC } from 'react';

export interface SwiperNavigationProps {
  activeImageIndex: number;
  imagesLength: number;
}

const SwiperNavigation: FC<SwiperNavigationProps> = props => {
  const { activeImageIndex, imagesLength } = props;
  return (
    <div className="swiper-navigation">
      <div className="swiper-navigation__arrows">
        <Button className="swiper-navigation__arrow swiper-navigation__arrow--prev" hideText iconStart="FullArrowLeft">
          <FormattedMessage defaultMessage="Previous image" id="Gallery.Previous" />
        </Button>
        <Button className="swiper-navigation__arrow swiper-navigation__arrow--next" hideText iconStart="FullArrowRight">
          <FormattedMessage defaultMessage="Next image" id="Gallery.Next" />
        </Button>
      </div>
      <div className="swiper-navigation__number">
        <Typography tagStyle="bodyLarge">{activeImageIndex + 1} / </Typography>
        <Typography subjectColor="gray" tagStyle="bodyLarge">
          {imagesLength}
        </Typography>
      </div>
    </div>
  );
};

export default SwiperNavigation;

import { graphql, useFragment } from 'react-relay';
import { saleByOwnerGridFragment$key } from '__generated__/saleByOwnerGridFragment.graphql';
import Block from 'components/utilities/block/block';
import Row from 'components/utilities/row/row';
import Column from 'components/utilities/column/column';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import './sale-by-owner.scss';
import { useList } from 'hooks/useList';
import { FC, Fragment, useMemo, useState } from 'react';
import { SimpleModal } from 'components/molecules/simple-modal/simple-modal';
import useToggle from 'hooks/useToggle';
import Icon from 'components/atoms/icon/icon';
import { Lead, LeadListItem } from 'components/molecules/sale-by-owner/lead-list-item';
import { LeadDialogContent } from 'components/molecules/sale-by-owner/dialog/lead-dialog-content';
import EmptyModule from '../empty-module/empty-module';
import { LeadStatusTypeEnum } from 'Enums';

type Props = {
  viewerFragment: saleByOwnerGridFragment$key;
  maxAmountDisplayed?: number;
};

const saleByOwnerGridFragment = graphql`
  fragment saleByOwnerGridFragment on Viewer {
    myPageLeads {
      statusType
      id
      __typename
      ...leadDialogContent
      ...leadListItemFragment
    }
    ...leadDialogContentViewer
  }
`;

export const SaleByOwnerGrid: FC<Props> = ({ maxAmountDisplayed, viewerFragment }) => {
  const data = useFragment(saleByOwnerGridFragment, viewerFragment);
  const mypageLeads = useList(data.myPageLeads ?? []);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  const leads = useMemo(() => {
    const filteredLeads = mypageLeads.filter(lead => lead.statusType === LeadStatusTypeEnum.Recieved);
    if (maxAmountDisplayed) {
      return filteredLeads.slice(0, maxAmountDisplayed);
    } else {
      return filteredLeads;
    }
  }, [maxAmountDisplayed, mypageLeads]);

  const handleOnClick = (lead: Lead) => {
    toggleIsModalOpen();
    setSelectedLead(lead);
  };

  const handleOnClose = () => {
    toggleIsModalOpen();
  };

  return (
    <Fragment>
      <Block className="match-cards-list">
        <Row>
          <Column>
            <Typography className="match-cards-list__heading" tag="h2" tagStyle="headlineMedium">
              <FormattedMessage
                defaultMessage={'Your leads ({count})'}
                id={'saleByOwner.leads'}
                values={{ count: leads.length }}
              />
            </Typography>
          </Column>
        </Row>
        {leads.length > 0 ? (
          <div className={'grid'}>
            {leads.map(lead => (
              <LeadListItem key={lead.id} fragment={lead} handleOnClick={handleOnClick} />
            ))}
          </div>
        ) : (
          <EmptyModule
            iconName="Lead"
            message={
              <FormattedMessage
                defaultMessage="You have no leads awaiting processing"
                id="saleByOwner.NoLeadsAwaiting"
              />
            }
          />
        )}
      </Block>
      {isModalOpen && selectedLead && (
        <SimpleModal onClose={handleOnClose}>
          <div className={'dialog-container'}>
            <div>
              <div className={'dialog-icon-wrapper'}>
                <Icon name={'Home'} className={'dialog-icon'} />
              </div>
            </div>
            <div className={'dialog-content'}>
              <LeadDialogContent fragment={selectedLead} handleOnClose={handleOnClose} viewerFragment={data} />
            </div>
          </div>
        </SimpleModal>
      )}
    </Fragment>
  );
};

/**
 * @generated SignedSource<<06828c307c4d7e231e292318b115cbf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type matchHeroContentFragment_offmarketCase$data = {
  readonly caseDescription: string | null | undefined;
  readonly caseDescriptionHeading: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroOffmarketDetailsFragment_offmarketCase">;
  readonly " $fragmentType": "matchHeroContentFragment_offmarketCase";
};
export type matchHeroContentFragment_offmarketCase$key = {
  readonly " $data"?: matchHeroContentFragment_offmarketCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"matchHeroContentFragment_offmarketCase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matchHeroContentFragment_offmarketCase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caseDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caseDescriptionHeading",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matchHeroOffmarketDetailsFragment_offmarketCase"
    }
  ],
  "type": "OffmarketCase",
  "abstractKey": null
};

(node as any).hash = "d6abeab55867c422590b5f550813ec72";

export default node;

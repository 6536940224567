import { useTheme } from 'context/ThemeContext';
import Button from 'components/atoms/button/button';

export const HomeLink = () => {
  const theme = useTheme();

  return (
    <Button anchorTag href="/" version="link">
      <picture>
        <source srcSet={theme?.logoUrl ? theme?.logoUrl : '/images/mindworking-logo.svg'} />
        <img
          alt={theme?.logoAlt ? theme.logoAlt : 'Logo'}
          src={theme?.logoUrl ? theme?.logoUrl : '/images/mindworking-logo.jpg'}
        />
      </picture>
    </Button>
  );
};

import { contactModuleOffmarket_offmarketCase$key } from '__generated__/contactModuleOffmarket_offmarketCase.graphql';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import ContactCard from 'components/molecules/contact-card/contact-card';
import { SelectOption } from 'components/molecules/select/select';
import Block from 'components/utilities/block/block';
import Column from 'components/utilities/column/column';
import Container from 'components/utilities/container/container';
import Row from 'components/utilities/row/row';
import { useList } from 'hooks/useList';
import { usePropertyType } from 'hooks/usePropertyCategories';
import { FC, Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import ContactSellerModal from '../contact-seller-modal/contact-seller-modal';
import './contact-module.scss';

export interface ContactModuleOffmarketProps {
  fragment: contactModuleOffmarket_offmarketCase$key | null | undefined;
}

const contactModuleOffmarketFragment = graphql`
  fragment contactModuleOffmarket_offmarketCase on OffmarketCase {
    id
    caseId
    caseDescriptionHeading
    propertyType
    businessPropertyType
    mainOfferEmployees {
      employee {
        id
        fullName
        ...contactCard_employee
      }
    }
  }
`;

const ContactModuleOffmarket: FC<ContactModuleOffmarketProps> = props => {
  const data = useFragment(contactModuleOffmarketFragment, props.fragment || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formatPropertyType = usePropertyType();

  const employeeOffers = useList(data?.mainOfferEmployees);
  const employee = employeeOffers?.[0]?.employee;
  const propertyType = formatPropertyType(data?.propertyType || data?.businessPropertyType || '');

  if (!employee) {
    return <Fragment />;
  }

  const getRecipientOptions = () => {
    const recipients: SelectOption[] = [];

    employeeOffers.forEach(item => {
      recipients.push({
        name: item?.employee?.fullName || '',
        value: item?.employee?.id || '',
      });
    });

    return recipients;
  };

  return (
    <Container className="contact-module">
      <Row alignCenter className="contact-module-wrapper" spaceBetween>
        <Column width={{ tablet: 4 }}>
          <ContactCard fragment={employee} />
        </Column>

        <Column width={{ tablet: 3 }}>
          <Block className="contact-module-text">
            <Typography tag="h2" tagStyle="headlineMedium">
              <FormattedMessage defaultMessage="Contact us" id="ContactModule.Title" />
            </Typography>

            <Typography tag="p" tagStyle="bodyMedium">
              <FormattedMessage
                defaultMessage="Do you have questions or would you like to know more about this property? Send us a message."
                id="ContactModule.Paragraph"
              />
            </Typography>
          </Block>

          <Button onClick={() => setIsModalOpen(true)}>
            <FormattedMessage defaultMessage="Contact broker" id="ContactModule.CTA" />
          </Button>
        </Column>
      </Row>
      <ContactSellerModal
        caseOptions={[
          {
            name: data?.caseDescriptionHeading || propertyType || '',
            selected: true,
            value: data?.caseId || '',
          },
        ]}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        recipientOptions={getRecipientOptions()}
      />
    </Container>
  );
};

export default ContactModuleOffmarket;

export const updateStartEndTimeFromDate = (newDate: Date, start: Date, end: Date): { start: Date; end: Date } => {
  const startHours = start.getHours();
  const startMinutes = start.getMinutes();

  const endHours = end.getHours();
  const endMinutes = end.getMinutes();

  const newStartTime = new Date(newDate);
  newStartTime.setHours(startHours, startMinutes, 0, 0);

  const newEndTime = new Date(newDate);
  newEndTime.setHours(endHours, endMinutes, 0, 0);
  return { start: newStartTime, end: newEndTime };
};

import { BusinessRentalCaseStatus, BusinessSalesCaseStatus, RentalCaseStatus, SalesCaseStatus } from 'Enums';
import { matchHeroContentAgriculturalCase_baseCase$key } from '__generated__/matchHeroContentAgriculturalCase_baseCase.graphql';
import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import { FC, Fragment, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatNumber } from 'tools/string-formatters';

export interface MatchHeroContentAgriculturalcaseProps {
  fragment: matchHeroContentAgriculturalCase_baseCase$key | null;
  isExpanded?: boolean;
}

const fragment = graphql`
  fragment matchHeroContentAgriculturalCase_baseCase on BaseCase {
    energyMark
    yearBuilt
    heat

    baseCaseMainOffer {
      cashPrice
      totalCosts {
        yearlyGrossOutput
        yearlyNetOutput
        minimumPayout
      }
    }
    caseAreas {
      livableArea
      plotAreaSum
    }

    ... on SalesCase {
      ownerCostsTotalMonthly
      propertyType
      salesStatus: status
    }

    ... on RectifyCase {
      propertyType
    }

    ... on CounselingPropertyCase {
      propertyType
    }

    ... on RentalCase {
      propertyType
      rentalStatus: status
    }

    ... on CounselingCase {
      propertyType
    }
    ... on BusinessSalesCase {
      businessSalesCaseStatus: status

      businessPropertyType: propertyType
    }
    ... on BusinessRentalCase {
      businessPropertyType: propertyType
      businessRentalCase: status
    }
  }
`;

const MatchHeroContentAgriculturalCase: FC<MatchHeroContentAgriculturalcaseProps> = props => {
  const data = useFragment(fragment, props.fragment);

  const showAdditionalInfo = useMemo(() => {
    const propertyType = data?.propertyType || data?.businessPropertyType;
    switch (propertyType) {
      case 'HobbyAgriculture':
      case 'Liebhaveri':
      case 'Cattle':
      case 'Pigs':
      case 'Crop':
      case 'SpecialProduction':
      case 'ForestProperty':
        return true;
      default:
        return false;
    }
  }, [data?.propertyType, data?.businessPropertyType]);

  const moreInfoRef = useRef<HTMLElement>(null);

  const isValuation =
    data?.businessSalesCaseStatus === BusinessSalesCaseStatus.Valuation ||
    data?.businessRentalCase === BusinessRentalCaseStatus.Valuation ||
    data?.salesStatus === SalesCaseStatus.Valuation ||
    data?.rentalStatus === RentalCaseStatus.Valuation;

  return (
    <Fragment>
      {!isValuation && (
        <Block className="match-hero-details__row marginTop" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Case price" id="Match.Cashprice" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.baseCaseMainOffer?.cashPrice)}</Typography>
        </Block>
      )}
      {/* if: group2 */}
      {showAdditionalInfo && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Housing area" id="Match.Area" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.caseAreas?.livableArea} m²</Typography>
        </Block>
      )}
      <Block className="match-hero-details__row" flex spaceBetween>
        <Typography tagStyle="bodySmall">
          <FormattedMessage defaultMessage="Ground area" id="Match.groundArea" />
        </Typography>
        <Typography tagStyle="bodySmall">{data?.caseAreas?.plotAreaSum} ha</Typography>
      </Block>
      {/* if: group 2 */}
      {showAdditionalInfo && (
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Energy mark" id="Match.Energymark" />
          </Typography>
          <Typography tagStyle="bodySmall">{data?.energyMark}</Typography>
        </Block>
      )}

      <div
        className={classNames('match-hero-details__expandable', {
          isExpanded: props.isExpanded,
        })}
        id="section-content"
      >
        <Typography ref={moreInfoRef} className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="More info" id="Match.MoreInfo" />
        </Typography>

        {/* if: group2 */}
        {showAdditionalInfo && (
          <Fragment>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Year build" id="Match.YearBuild" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.yearBuilt}</Typography>
            </Block>
            <Block className="match-hero-details__row" flex spaceBetween>
              <Typography tagStyle="bodySmall">
                <FormattedMessage defaultMessage="Heat source" id="Match.HeatSource" />
              </Typography>
              <Typography tagStyle="bodySmall">{data?.heat}</Typography>
            </Block>
          </Fragment>
        )}

        <Typography className="match-hero-details__heading" tag="h4" tagStyle="bodyLarge">
          <FormattedMessage defaultMessage="Finances" id="Match.Finances" />
        </Typography>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Payment" id="Match.Payment" />
          </Typography>
          <Typography tagStyle="bodySmall">
            {formatNumber(data?.baseCaseMainOffer?.totalCosts?.minimumPayout, 'kr')}
          </Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Monthly owner cost" id="Match.OwnershipExpense" />
          </Typography>
          <Typography tagStyle="bodySmall">{formatNumber(data?.ownerCostsTotalMonthly)}</Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Gross excl. cost of ownership" id="Match.BruttoExcOwnership" />
          </Typography>
          <Typography tagStyle="bodySmall">
            {formatNumber(data?.baseCaseMainOffer?.totalCosts?.yearlyGrossOutput, 'kr')}
          </Typography>
        </Block>
        <Block className="match-hero-details__row" flex spaceBetween>
          <Typography tagStyle="bodySmall">
            <FormattedMessage defaultMessage="Net excl. cost of ownership" id="Match.NettoExcOwnership" />
          </Typography>
          <Typography tagStyle="bodySmall">
            {formatNumber(data?.baseCaseMainOffer?.totalCosts?.yearlyNetOutput, 'kr')}
          </Typography>
        </Block>
      </div>
    </Fragment>
  );
};

export default MatchHeroContentAgriculturalCase;

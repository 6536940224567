import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import { ComponentPropsWithoutRef, forwardRef, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import './input.scss';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  bigVersion?: boolean;
  customPadding?: 'xs' | 'sm';
  errorMessage?: string;
  focusInput?: () => void;
  formattedInput?: boolean;
  hasError?: boolean;
  isFullWidth?: boolean;
  label?: ReactNode;
  noBorder?: boolean;
  noFloatingLabel?: boolean;
  onValueChange?: (values: number) => void;
  readonly?: boolean;
  wrapperClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    bigVersion,
    className,
    disabled,
    formattedInput,
    hasError,
    label,
    onValueChange,
    type,
    isFullWidth,
    noBorder,
    noFloatingLabel,
    customPadding,
    readonly,
    required,
    onChange,
    placeholder,
    focusInput,
    errorMessage,
    wrapperClassName,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isLabelShown, setIsLabelShown] = useState(false);
  const inputWrap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const value = (document.querySelector(`#${rest.name}`) as HTMLInputElement)?.value;
    if (value) {
      setIsLabelShown(true);
    }
  }, [rest.name]);

  return (
    <Fragment>
      <div
        className={classNames('input', wrapperClassName, {
          bigVersion,
          hasError: hasError || errorMessage,
          isDisabled: disabled,
          isFocused,
          isFullWidth,
          isLabelShown,
          noBorder,
          noFloatingLabel,
          'padding-sm': customPadding === 'sm',
          'padding-xs': customPadding === 'xs',
        })}
        onClick={() => focusInput && focusInput()}
      >
        {label && (
          <Typography className="label" htmlFor={rest.id} tag="label" tagStyle="bodySmall">
            {label}
            {required && <Typography subjectColor="error">*</Typography>}
          </Typography>
        )}
        <div ref={inputWrap} className="inputWrap">
          {formattedInput ? (
            <NumericFormat
              className="field"
              decimalSeparator=","
              max={rest.max}
              min={rest.min}
              onValueChange={values => onValueChange && values.floatValue && onValueChange(values.floatValue)}
              readOnly={readonly}
              thousandSeparator="."
              value={rest.value as any}
            />
          ) : (
            <input
              className={classNames('field', className)}
              id={rest.name}
              readOnly={readonly}
              {...rest}
              ref={ref}
              onBlur={() => setIsFocused(false)}
              onChange={e => {
                e.target.value.length > 0 ? setIsLabelShown(true) : setIsLabelShown(false);
                {
                  onChange && onChange(e);
                }
              }}
              onFocus={() => setIsFocused(true)}
              placeholder={placeholder}
              type={type || 'text'}
            />
          )}
        </div>
      </div>
      {errorMessage && (
        <Typography subjectColor="error" tag="span" tagStyle="bodyXSmall">
          *{errorMessage}
        </Typography>
      )}
    </Fragment>
  );
});

Input.displayName = 'Input';

export default Input;

import Modal from 'components/molecules/modal/modal';
import { FC, Fragment, useState, ChangeEvent, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/atoms/button/button';
import Row from 'components/utilities/row/row';
import { graphql } from 'react-relay';
import useToggle from 'hooks/useToggle';
import { useQuery } from 'relay-hooks';
import { HeadLine } from './components/headline';
import {
  getDateWithAddedDefaultDurationInMin,
  getDateWithSelectedTime,
  getSpecificActivityObj,
} from './self-sale-functions';
import { AppointmentModalContent, time } from './appointment-modal-content';
import Checkbox from 'components/molecules/checkbox/checkbox';
import './choose-activity-modal.scss';
import { ShowCasingInput } from './components/show-casing-input';
import { chooseActivityModal_Query } from '__generated__/chooseActivityModal_Query.graphql';
import { useCreate } from 'hooks/relay/useCreate';
import {
  chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation,
  CreateAppointmentActivityInputType,
} from '__generated__/chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation.graphql';
import { AppointmentActivity } from 'Types';

type Props = {
  isOpen: boolean;
  toggleIsModalOpen: (bool?: boolean) => void;
  caseId: string;
};

export type Activity = 'openHouse' | 'showCasing' | '';
export type CustomerFieldType = 'firstName' | 'lastName' | 'email' | 'phone';

type CustomerInfo = { firstName: string; lastName: string; email: string; phone: string };

const chooseActivityModalQuery = graphql`
  query chooseActivityModal_Query {
    viewer {
      ... on Viewer {
        id
        propertyForSaleByOwnerAppointmentActivityTypes {
          category
          defaultDurationInMinutes
          id
          name
        }
        customer {
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`;

export const chooseActivityModalCreate = graphql`
  mutation chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation(
    $input: CreateAppointmentActivityInputType!
  ) {
    createPropertyForSaleByOwnerAppointmentActivity(input: $input) {
      errors {
        message
      }
      createdObject {
        id
        name
        startDate
        endDate
        latestNoteContent
        notes {
          content
        }
      }
    }
  }
`;

const TO_MS = 60 * 1000;

export const ChooseActivityModal: FC<Props> = ({ isOpen, toggleIsModalOpen, caseId }) => {
  const { data } = useQuery<chooseActivityModal_Query>(chooseActivityModalQuery);
  const [createActivity] = useCreate<chooseActivityModal_createPropertyForSaleByOwnerAppointmentActivityMutation>(
    chooseActivityModalCreate,
    {
      __typename: AppointmentActivity,
      mutationName: 'createPropertyForSaleByOwnerAppointmentActivity',
      listName: 'futureActivities__timelineActivitiesConnection',
      parentID: caseId,
    },
  );

  const [chosenActivity, setChosenActivity] = useState<Activity>('');
  const [isRegistration, toggleIsRegistration] = useToggle();
  const [time, setTime] = useState<time>({
    timeStart: new Date(),
    timeEnd: new Date(),
  });
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({ firstName: '', lastName: '', email: '', phone: '' });

  useEffect(() => {
    setTime({
      timeStart: new Date(),
      timeEnd: getDateWithAddedDefaultDurationInMin(data, isRegistration, chosenActivity),
    });
  }, [chosenActivity, data, isRegistration]);

  const handleChosenActivity = (activity: Activity) => {
    setChosenActivity(activity);
  };
  const handleCloseCross = () => {
    toggleIsModalOpen();
    setChosenActivity('');
  };

  const onChangeDay = (currentTimeStart: Date, currentTimeEnd: Date) => {
    setTime({ timeStart: currentTimeStart, timeEnd: currentTimeEnd });
  };

  /* TIMEPICKER  */
  const handleBlurTimeStart = (timeDif: number, timeInMS: number) => {
    const defaultDurationInMinutes =
      getSpecificActivityObj(data, isRegistration, chosenActivity)?.defaultDurationInMinutes ?? 30;

    if (timeDif < defaultDurationInMinutes) {
      const newTimeEnd = new Date(timeInMS + defaultDurationInMinutes * TO_MS);
      setTime({ ...time, timeEnd: newTimeEnd });
    }
  };

  const handleChangeTimeStart = (e: ChangeEvent<HTMLInputElement>) => {
    setTime({ ...time, timeStart: getDateWithSelectedTime(e, time.timeStart) });
  };

  const handleBlurTimeEnd = (timeDif: number, timeInMs: number, e?: ChangeEvent<HTMLInputElement>) => {
    const defaultDurationInMinutes =
      getSpecificActivityObj(data, isRegistration, chosenActivity)?.defaultDurationInMinutes ?? 30;

    if (e && timeDif < defaultDurationInMinutes) {
      const newtimeStart = new Date(timeInMs - defaultDurationInMinutes * TO_MS);
      setTime({ timeStart: newtimeStart, timeEnd: getDateWithSelectedTime(e, time.timeEnd) });
    }
  };

  const handleChangeTimeEnd = (e: ChangeEvent<HTMLInputElement>) => {
    setTime({ ...time, timeEnd: getDateWithSelectedTime(e, time.timeEnd) });
  };

  /* CUSTOMER INFO */
  const handleCustomerInfoChange = (e: ChangeEvent<HTMLInputElement>, field: CustomerFieldType) => {
    setCustomerInfo({ ...customerInfo, [field]: e.target.value });
  };

  const isConfirmButtonDisabled = useMemo(() => {
    return Object.values(customerInfo).some(value => value === '');
  }, [customerInfo]);

  const onSubmit = async () => {
    const openHouseObj = getSpecificActivityObj(data, isRegistration, chosenActivity);
    const isOpenHouse = chosenActivity === 'openHouse';

    const input: CreateAppointmentActivityInputType = {
      activityTypeId: openHouseObj?.id ?? '',
      endDatetime: time.timeEnd,
      startDatetime: time.timeStart,
      caseId,
      customer: {
        firstname: isOpenHouse ? data?.viewer?.customer?.firstName : customerInfo.firstName,
        lastname: isOpenHouse ? data?.viewer?.customer?.lastName : customerInfo.lastName,
        email: isOpenHouse ? data?.viewer?.customer?.email : customerInfo.email,
        phone: isOpenHouse ? data?.viewer?.customer?.phone : customerInfo.phone,
      },
    };

    handleCloseCross();
    await createActivity({
      variables: { input },
    });
  };

  return (
    <Fragment>
      {isOpen && (
        <Modal
          buttonConfig={
            chosenActivity !== ''
              ? [
                  {
                    callback: () => {
                      toggleIsRegistration(false);
                      setChosenActivity('');
                    },
                    label: 'Annuller',
                    version: 'secondary',
                  },
                  {
                    callback: async () => {
                      await onSubmit();
                    },
                    label: 'Opret',
                    type: 'submit',
                    version: 'primary',
                    disabled: chosenActivity === 'showCasing' ? isConfirmButtonDisabled : false,
                  },
                ]
              : []
          }
          closeHandler={handleCloseCross}
          headline={<HeadLine activity={chosenActivity} />}
        >
          {chosenActivity === '' && (
            <div className="chooseActionsWrapper">
              <Row center style={{ gap: '8px' }}>
                <Button version="secondary" onClick={() => handleChosenActivity('openHouse')}>
                  <FormattedMessage defaultMessage="Open house" id="StatisticsHeader.OpenHouse" />
                </Button>
                <Button version="secondary" onClick={() => handleChosenActivity('showCasing')}>
                  <FormattedMessage defaultMessage="Show casing" id="Default.ShowCasing" />
                </Button>
              </Row>
            </div>
          )}
          {chosenActivity !== '' && (
            <AppointmentModalContent
              onChangeDay={onChangeDay}
              time={time}
              handleChangeTimeStart={handleChangeTimeStart}
              handleChangeTimeEnd={handleChangeTimeEnd}
              handleBlurTimeStart={handleBlurTimeStart}
              handleBlurTimeEnd={handleBlurTimeEnd}
              secondChildren={
                chosenActivity === 'showCasing' && (
                  <div className="showCasingInputTypeWrapper">
                    <ShowCasingInput
                      labelId="selfsale.customerName"
                      label={<FormattedMessage id="customer.name" defaultMessage="Customer name" />}
                      customerInfo="firstName"
                      handleCustomerInfoChange={handleCustomerInfoChange}
                      className="showCasingInputTypeItem"
                    />
                    <ShowCasingInput
                      labelId="selfsale.customerlastname"
                      label={<FormattedMessage id="customer.lastname" defaultMessage="Customer last name" />}
                      customerInfo="lastName"
                      handleCustomerInfoChange={handleCustomerInfoChange}
                      className="showCasingInputTypeItem"
                    />
                    <ShowCasingInput
                      labelId="selfsale.customerEmail"
                      label={<FormattedMessage id="customer.email" defaultMessage="Customer e-mail" />}
                      customerInfo="email"
                      handleCustomerInfoChange={handleCustomerInfoChange}
                      className="showCasingInputTypeItem"
                    />
                    <ShowCasingInput
                      labelId="selfsale.customerPhone"
                      label={<FormattedMessage id="customer.phone" defaultMessage="Customer phone" />}
                      customerInfo="phone"
                      handleCustomerInfoChange={handleCustomerInfoChange}
                    />
                  </div>
                )
              }
            >
              {chosenActivity === 'openHouse' && (
                <div className="checkBoxWrapper">
                  <FormattedMessage defaultMessage="With registration:" id="selfSale.withRegistration" />
                  <Checkbox onChange={() => toggleIsRegistration()} />
                </div>
              )}
            </AppointmentModalContent>
          )}
        </Modal>
      )}
    </Fragment>
  );
};

import Icon from 'components/atoms/icon/icon';
import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import { formatDate, getRelatedLink } from 'tools/string-formatters';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { graphql, useFragment } from 'react-relay';
import { documentAccessGrantedNotification_fragment$key } from '__generated__/documentAccessGrantedNotification_fragment.graphql';

export interface DocumentSigningNotificationProps {
  notification: documentAccessGrantedNotification_fragment$key;
}

const documentSigningNotification = graphql`
  fragment documentAccessGrantedNotification_fragment on DocumentAccessGrantedNotification {
    baseCase {
      relationType {
        relationId
        relationType
      }
      caseNo
    }
    id
    createdDate
    read
    address
  }
`;

export const DocumentAccessGrantedNotification: FC<DocumentSigningNotificationProps> = ({ notification }) => {
  const data = useFragment<documentAccessGrantedNotification_fragment$key>(documentSigningNotification, notification);

  const relationType = {
    relationType: data.baseCase.relationType?.relationType,
    relationId: data.baseCase.relationType?.relationId,
  };

  return (
    <HashLink
      className={classNames('notifications__link', { unread: !data?.read })}
      smooth
      to={getRelatedLink(relationType, '#sign')}
    >
      <Icon name="Bell" />
      <div className="notifications__item-text">
        <Typography>
          <b>
            <FormattedMessage
              defaultMessage="You have been granted access to new documents"
              id="Notifications.AccessToNewDocuments"
            />
            :
          </b>{' '}
          {data.address} <br />
          <FormattedMessage defaultMessage="Case number" id="Case.caseNumber" /> :{data.baseCase.caseNo}
        </Typography>
        <Typography className="notifications__time" tagStyle="bodyXSmall">
          {formatDate(data.createdDate)}
        </Typography>
      </div>
    </HashLink>
  );
};

import classNames from 'classnames';
import Button, { ButtonProps } from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import Block from 'components/utilities/block/block';
import FocusTrap from 'focus-trap-react';
import { FC, KeyboardEvent, ReactNode, useEffect } from 'react';
import './modal.scss';

export interface ModalProps {
  buttonConfig?: {
    callback: () => void;
    disabled?: boolean;
    isFullWidth?: boolean;
    label: string;
    type?: 'button' | 'submit';
    version?: ButtonProps['version'];
  }[];
  children?: ReactNode;
  className?: string;
  closeHandler?: () => void;
  contentPadding?: boolean;
  focusTrapOptions?: FocusTrap.Props['focusTrapOptions'];
  headline?: ReactNode;
  showCross?: boolean;
}

const Modal: FC<ModalProps> = props => {
  const {
    headline,
    showCross = true,
    children,
    className,
    focusTrapOptions,
    buttonConfig,
    closeHandler,
    contentPadding = true,
  } = props;

  useEffect(() => {
    document.body.classList.add('lock-scroll');

    return () => {
      document.body.classList.remove('lock-scroll');
    };
  }, []);

  const handleClosePopup = () => {
    document.body.classList.remove('lock-scroll');
    closeHandler && closeHandler();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Escape') {
      handleClosePopup();
    }
  };

  return (
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div className={classNames('modal', className)} onKeyDown={handleKeyDown}>
        <div className="modal__container" onClick={e => e.stopPropagation()}>
          <div className="modal__header">
            {headline && (
              <div className="modal__header-title">
                <Typography tag="h2" tagStyle="headlineMedium">
                  {headline}
                </Typography>
              </div>
            )}
            {showCross && (
              <Button
                className="modal__close"
                hideText
                iconStart="Cross"
                onClick={handleClosePopup}
                padding={false}
                version="link"
              >
                Close
              </Button>
            )}
          </div>
          <div className="modal__content">
            {contentPadding && (
              <Block className="modal__content--block" customPadding="xs">
                {children}
              </Block>
            )}
            {!contentPadding && children}
          </div>
          {buttonConfig && (
            <div className="modal__footer">
              <Block customPadding="xs" flex flexGutter="xs" flexWrap>
                {buttonConfig.map(button => (
                  <Button
                    key={button.label}
                    disabled={button.disabled}
                    isFullWidth={button.isFullWidth}
                    onClick={() => button.callback()}
                    type={button.type}
                    version={button.version}
                  >
                    {button.label}
                  </Button>
                ))}
              </Block>
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;

import { FunctionComponent, ReactNode, useMemo } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import messages_da from './locales/da.json';
import messages_da_agent from './locales/da-agent.json';
import messages_da_kartotek from './locales/da-kartotek.json';
import messages_da_kartotek_heading from './locales/da-kartotek-heading.json';
import messages_da_ejendom from './locales/da-ejendom.json';
import messages_da_bygning from './locales/da-bygning.json';
import messages_da_boligoensker from './locales/da-boligoensker.json';
import messages_da_ejendomsoensker from './locales/da-ejendomoensker.json';

type Props = {
  locale: string;
  translationVariants: string[];
  children: ReactNode;
};

type TranslationFileType = Record<string, string>;

const languageMapping: Record<string, TranslationFileType> = {
  agent: messages_da_agent,
  kartotek: messages_da_kartotek,
  'kartotek-heading': messages_da_kartotek_heading,
  bygning: messages_da_bygning,
  ejendom: messages_da_ejendom,
  boligoensker: messages_da_boligoensker,
  ejendomsoensker: messages_da_ejendomsoensker,
};
const danishDefaultTranslations: TranslationFileType = messages_da;

export const IntlProvider: FunctionComponent<Props> = props => {
  const intlProviderMessages = useMemo(() => {
    let baseTranslations = { ...danishDefaultTranslations };

    if (props.translationVariants.length > 0) {
      props.translationVariants.forEach(variant => {
        if (!variant) return;
        const mappedTranslation = languageMapping[variant] ?? {};
        baseTranslations = { ...baseTranslations, ...mappedTranslation };
      });
    }
    return baseTranslations;
  }, [props.translationVariants]);

  return (
    <ReactIntlProvider locale={props.locale} messages={intlProviderMessages}>
      {props.children}
    </ReactIntlProvider>
  );
};

import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

class AuthenticationService {
  minValidity = 15;
  updateTokenTimeoutId: number | null = null;
  keycloak: Keycloak;

  constructor(config: KeycloakConfig) {
    this.keycloak = new Keycloak(config);
    this.keycloak.onTokenExpired = this.handleUpdateToken;
    this.keycloak.onAuthRefreshError = this.handleAuthRefreshError;
  }

  clearUpdateTokenTimeoutId = () => {
    window.removeEventListener('online', this.handleUpdateToken);
    if (this.updateTokenTimeoutId) {
      clearTimeout(this.updateTokenTimeoutId);
      this.updateTokenTimeoutId = null;
    }
  };

  handleUpdateToken = () => {
    this.clearUpdateTokenTimeoutId();
    this.keycloak.updateToken(this.minValidity);
  };

  handleAuthRefreshError = () => {
    this.clearUpdateTokenTimeoutId();

    if (!navigator.onLine) {
      console.error(`AuthRefreshError: navigator.onLine = ${navigator.onLine} wait for online event`);
      window.addEventListener('online', this.handleUpdateToken);
    } else {
      this.updateTokenTimeoutId = window.setTimeout(this.handleUpdateToken, 10000);
    }
  };

  init(
    options: KeycloakInitOptions = {
      enableLogging: false,
      onLoad: 'login-required',
    },
  ) {
    return this.keycloak.init({ ...options, pkceMethod: 'S256' });
  }

  login() {
    this.keycloak.login();
  }

  logout() {
    let pathname = window.location.pathname;
    if (pathname !== '' && pathname[0] !== '/') {
      pathname = `/${pathname}`;
    }
    this.keycloak.logout({ redirectUri: window.location.origin + pathname });
  }

  get isTokenExpired() {
    return this.keycloak.isTokenExpired(this.minValidity);
  }

  get idTokenParsed() {
    return this.keycloak.idTokenParsed;
  }

  updateToken() {
    return this.keycloak.updateToken(this.minValidity);
  }

  loadUserProfile() {
    return this.keycloak.loadUserProfile();
  }

  loadUserInfo() {
    return this.keycloak.loadUserInfo();
  }

  tokenOrError() {
    if (!this.keycloak.token) {
      throw new Error('keycloak token is undefined');
    }
    return this.keycloak.token;
  }

  tokenAsync() {
    if (this.keycloak.isTokenExpired(this.minValidity)) {
      return this.updateToken().then(() => this.tokenOrError());
    } else {
      return Promise.resolve(this.tokenOrError());
    }
  }

  addTokenToUrl(originalUrl: string | undefined) {
    if (!originalUrl) return '';

    const url = new URL(originalUrl);
    url.searchParams.append('access_token', this.tokenOrError());
    return url.toString();
  }
}

export let authenticationService: AuthenticationService;

export function createAuthenticationService({ clientId, realm, url }: KeycloakConfig) {
  authenticationService = new AuthenticationService({ clientId, realm, url });
  return authenticationService;
}

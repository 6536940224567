import { caseFullscreenGallery_mediaDataConnection$key } from '__generated__/caseFullscreenGallery_mediaDataConnection.graphql';
import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import CaseImagesSwiper from '../case-images-swiper/case-images-swiper';
import PopupOverlay from '../popup-overlay/popup-overlay';
import './case-fullscreen-gallery.scss';

const caseFullscreenGalleryFragment = graphql`
  fragment caseFullscreenGallery_mediaDataConnection on MediaDataConnection {
    ...caseImagesSwiper_mediaDataConnection
  }
`;

export interface CaseFullscreenGalleryProps {
  mediaDataConnection: caseFullscreenGallery_mediaDataConnection$key | null;
  onCloseFullscreen: () => void;
  slideToImage: number;
}

export const CaseFullscreenGallery: FC<CaseFullscreenGalleryProps> = props => {
  const mediaDataConnection = useFragment(caseFullscreenGalleryFragment, props.mediaDataConnection);
  const { slideToImage, onCloseFullscreen } = props;

  return (
    <div className="case-fullscreen-gallery">
      <PopupOverlay onClose={onCloseFullscreen}>
        <CaseImagesSwiper mediaDataConnection={mediaDataConnection} slideToImage={slideToImage} />
      </PopupOverlay>
    </div>
  );
};

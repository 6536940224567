import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createAuthenticationService } from './services/authentication/authentication-service';
import { initAppSettings } from './setup/setup-app-settings';
import { initReactApp } from './setup/setup-react-app';

async function initialize() {
  try {
    const appSettings = await initAppSettings();
    const {
      settings: { HTML_DOCUMENT_TITLE, AUTH_CLIENT_ID, AUTH_REALM, AUTH_URL },
    } = appSettings;

    document.title = HTML_DOCUMENT_TITLE;

    const authenticationService = createAuthenticationService({
      clientId: AUTH_CLIENT_ID,
      realm: AUTH_REALM,
      url: AUTH_URL,
    });

    await authenticationService.init();

    initReactApp(appSettings);
  } catch (e) {
    console.log(e);
  }
}
initialize();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: { input: string; output: string };
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: { input: string; output: string };
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: { input: any; output: any };
  Decimal: { input: number; output: number };
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds in range [-922337203685477, 922337203685477]. */
  Milliseconds: { input: any; output: any };
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds in range [-922337203685, 922337203685]. */
  Seconds: { input: any; output: any };
  ULong: { input: any; output: any };
};

export enum ActivityTypeCategoryEnum {
  /** BuyerAdvisorObjectionExtension */
  BuyerAdvisorObjectionExtension = 'BuyerAdvisorObjectionExtension',
  /** BuyerFinancialInstitutionObjectionExtension */
  BuyerFinancialInstitutionObjectionExtension = 'BuyerFinancialInstitutionObjectionExtension',
  /** CounselingCases */
  CounselingCases = 'CounselingCases',
  /** CreditAssessment */
  CreditAssessment = 'CreditAssessment',
  /** CustomerMeetings */
  CustomerMeetings = 'CustomerMeetings',
  /** DeedAvailableForSignatureExtension */
  DeedAvailableForSignatureExtension = 'DeedAvailableForSignatureExtension',
  /** DeedConditionalFinalSignatureExtension */
  DeedConditionalFinalSignatureExtension = 'DeedConditionalFinalSignatureExtension',
  /** DeedLatestSignatureExtension */
  DeedLatestSignatureExtension = 'DeedLatestSignatureExtension',
  /** DeedReportedToLandRegistrationExtension */
  DeedReportedToLandRegistrationExtension = 'DeedReportedToLandRegistrationExtension',
  /** Education */
  Education = 'Education',
  /** ExternalMeeting */
  ExternalMeeting = 'ExternalMeeting',
  /** FirstDepositDeadlineExtension */
  FirstDepositDeadlineExtension = 'FirstDepositDeadlineExtension',
  /** GuaranteeExtension */
  GuaranteeExtension = 'GuaranteeExtension',
  /** InternalMeeting */
  InternalMeeting = 'InternalMeeting',
  /** KeyCollection */
  KeyCollection = 'KeyCollection',
  /** Miscellaneous */
  Miscellaneous = 'Miscellaneous',
  /** OpenHouse */
  OpenHouse = 'OpenHouse',
  /** OpenHouseWithRegistration */
  OpenHouseWithRegistration = 'OpenHouseWithRegistration',
  /** PaidValuation */
  PaidValuation = 'PaidValuation',
  /** Private */
  Private = 'Private',
  /** ProcessClosingProtest */
  ProcessClosingProtest = 'ProcessClosingProtest',
  /** PurchaseAgreementMeetingBuyer */
  PurchaseAgreementMeetingBuyer = 'PurchaseAgreementMeetingBuyer',
  /** PurchaseAgreementMeetingSeller */
  PurchaseAgreementMeetingSeller = 'PurchaseAgreementMeetingSeller',
  /** RectifyCases */
  RectifyCases = 'RectifyCases',
  /** RiskAssessmentReview */
  RiskAssessmentReview = 'RiskAssessmentReview',
  /** SalesValuation */
  SalesValuation = 'SalesValuation',
  /** SecondDepositDeadlineExtension */
  SecondDepositDeadlineExtension = 'SecondDepositDeadlineExtension',
  /** SellerAdvisorObjectionExtension */
  SellerAdvisorObjectionExtension = 'SellerAdvisorObjectionExtension',
  /** SellerFinancialInstitutionObjectionExtension */
  SellerFinancialInstitutionObjectionExtension = 'SellerFinancialInstitutionObjectionExtension',
  /** Showcasing */
  Showcasing = 'Showcasing',
  /** SignedUpOpenHouse */
  SignedUpOpenHouse = 'SignedUpOpenHouse',
  /** StatusMeetingBuyer */
  StatusMeetingBuyer = 'StatusMeetingBuyer',
  /** StatusMeetingSeller */
  StatusMeetingSeller = 'StatusMeetingSeller',
  /** StatusReport */
  StatusReport = 'StatusReport',
  /** Transfer */
  Transfer = 'Transfer',
}

export enum AddressType {
  /** NewAddress */
  NewAddress = 'NewAddress',
  /** Private */
  Private = 'Private',
  /** VacationHousing */
  VacationHousing = 'VacationHousing',
  /** Work */
  Work = 'Work',
}

export enum AgriculturePropertyTypeCategory {
  /** CattleFarm */
  CattleFarm = 'CattleFarm',
  /** CropsFarm */
  CropsFarm = 'CropsFarm',
  /** FarmHouseAgriculture */
  FarmHouseAgriculture = 'FarmHouseAgriculture',
  /** ForestProperties */
  ForestProperties = 'ForestProperties',
  /** Liebhaveri */
  Liebhaveri = 'Liebhaveri',
  /** PigFarm */
  PigFarm = 'PigFarm',
  /** SpecialProduction */
  SpecialProduction = 'SpecialProduction',
}

export type AppointmentActivityCustomerInputType = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum AppointmentActivityStatusEnum {
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** Planned */
  Planned = 'Planned',
}

export enum BuildingInclAreaTypeGraphType {
  /** Carport */
  Carport = 'Carport',
  /** Commercial */
  Commercial = 'Commercial',
  /** Conservatory */
  Conservatory = 'Conservatory',
  /** Garage */
  Garage = 'Garage',
  /** Other */
  Other = 'Other',
  /** Outhouse */
  Outhouse = 'Outhouse',
}

export enum BusinessBuildingTypeGraphType {
  /** Abandoned */
  Abandoned = 'Abandoned',
  /** AdventurePark */
  AdventurePark = 'AdventurePark',
  /** AllDayService */
  AllDayService = 'AllDayService',
  /** AllYearRoundHousingAnex */
  AllYearRoundHousingAnex = 'AllYearRoundHousingAnex',
  /** AllotmentHut */
  AllotmentHut = 'AllotmentHut',
  /** Apartment */
  Apartment = 'Apartment',
  /** Aviation */
  Aviation = 'Aviation',
  /** Barracks */
  Barracks = 'Barracks',
  /** BedAndbreakfast */
  BedAndbreakfast = 'BedAndbreakfast',
  /** Carport */
  Carport = 'Carport',
  /** ChurchOrOtherStateRecognizedBuildingForReligiousCommunities */
  ChurchOrOtherStateRecognizedBuildingForReligiousCommunities = 'ChurchOrOtherStateRecognizedBuildingForReligiousCommunities',
  /** CinemaTheaterConcertVenue */
  CinemaTheaterConcertVenue = 'CinemaTheaterConcertVenue',
  /** ClubhouseForLeisureAndSport */
  ClubhouseForLeisureAndSport = 'ClubhouseForLeisureAndSport',
  /** College */
  College = 'College',
  /** CombinedDetached */
  CombinedDetached = 'CombinedDetached',
  /** DayCareFacility */
  DayCareFacility = 'DayCareFacility',
  /** Detached */
  Detached = 'Detached',
  /** DetachedCover */
  DetachedCover = 'DetachedCover',
  /** DetachedSunroom */
  DetachedSunroom = 'DetachedSunroom',
  /** Double */
  Double = 'Double',
  /** EnergyProduction */
  EnergyProduction = 'EnergyProduction',
  /** FarmhouseForAgriculturalProperty */
  FarmhouseForAgriculturalProperty = 'FarmhouseForAgriculturalProperty',
  /** FeedStorage */
  FeedStorage = 'FeedStorage',
  /** Garage */
  Garage = 'Garage',
  /** GasStation */
  GasStation = 'GasStation',
  /** Greenhouse */
  Greenhouse = 'Greenhouse',
  /** HealthCenter */
  HealthCenter = 'HealthCenter',
  /** HolidayApartmentForBusinessRental */
  HolidayApartmentForBusinessRental = 'HolidayApartmentForBusinessRental',
  /** HolidayApartmentForOwn */
  HolidayApartmentForOwn = 'HolidayApartmentForOwn',
  /** HolidayCenter */
  HolidayCenter = 'HolidayCenter',
  /** Hospital */
  Hospital = 'Hospital',
  /** HotelInnConferenceCenterWithAccommodation */
  HotelInnConferenceCenterWithAccommodation = 'HotelInnConferenceCenterWithAccommodation',
  /** IndoorPool */
  IndoorPool = 'IndoorPool',
  /** IndustryWithIntegratedProduction */
  IndustryWithIntegratedProduction = 'IndustryWithIntegratedProduction',
  /** IndustryWithoutIntegratedProduction */
  IndustryWithoutIntegratedProduction = 'IndustryWithoutIntegratedProduction',
  /** Jail */
  Jail = 'Jail',
  /** Library */
  Library = 'Library',
  /** LivestockStable */
  LivestockStable = 'LivestockStable',
  /** MachineHouse */
  MachineHouse = 'MachineHouse',
  /** MinkHall */
  MinkHall = 'MinkHall',
  /** MoreThanTwoVehiclesParkingAssociatedWithHousing */
  MoreThanTwoVehiclesParkingAssociatedWithHousing = 'MoreThanTwoVehiclesParkingAssociatedWithHousing',
  /** Museum */
  Museum = 'Museum',
  /** Office */
  Office = 'Office',
  /** Orangery */
  Orangery = 'Orangery',
  /** Other */
  Other = 'Other',
  /** OtherBuildingForAgriculture */
  OtherBuildingForAgriculture = 'OtherBuildingForAgriculture',
  /** OtherBuildingForCulturalPurposes */
  OtherBuildingForCulturalPurposes = 'OtherBuildingForCulturalPurposes',
  /** OtherBuildingForHealthCare */
  OtherBuildingForHealthCare = 'OtherBuildingForHealthCare',
  /** OtherBuildingForHoliday */
  OtherBuildingForHoliday = 'OtherBuildingForHoliday',
  /** OtherBuildingForInstitutionalPurposes */
  OtherBuildingForInstitutionalPurposes = 'OtherBuildingForInstitutionalPurposes',
  /** OtherBuildingForLeisurePurpose */
  OtherBuildingForLeisurePurpose = 'OtherBuildingForLeisurePurpose',
  /** OtherBuildingForOfficeStorageCommercial */
  OtherBuildingForOfficeStorageCommercial = 'OtherBuildingForOfficeStorageCommercial',
  /** OtherBuildingForProduction */
  OtherBuildingForProduction = 'OtherBuildingForProduction',
  /** OtherBuildingForServiceIndustry */
  OtherBuildingForServiceIndustry = 'OtherBuildingForServiceIndustry',
  /** OtherBuildingForSport */
  OtherBuildingForSport = 'OtherBuildingForSport',
  /** OtherBuildingForSupplyAndEnergyDistribution */
  OtherBuildingForSupplyAndEnergyDistribution = 'OtherBuildingForSupplyAndEnergyDistribution',
  /** OtherBuildingTeachingAndResearch */
  OtherBuildingTeachingAndResearch = 'OtherBuildingTeachingAndResearch',
  /** OtherBuildingToAllYearRoundHousing */
  OtherBuildingToAllYearRoundHousing = 'OtherBuildingToAllYearRoundHousing',
  /** OtherTransportFacility */
  OtherTransportFacility = 'OtherTransportFacility',
  /** Outhouse */
  Outhouse = 'Outhouse',
  /** ParkingAndTransportFacilities */
  ParkingAndTransportFacilities = 'ParkingAndTransportFacilities',
  /** PigStable */
  PigStable = 'PigStable',
  /** PortFacilities */
  PortFacilities = 'PortFacilities',
  /** PoultryStable */
  PoultryStable = 'PoultryStable',
  /** PrimarySchool */
  PrimarySchool = 'PrimarySchool',
  /** PrivateServiceCompany */
  PrivateServiceCompany = 'PrivateServiceCompany',
  /** RailAndBusOperations */
  RailAndBusOperations = 'RailAndBusOperations',
  /** RemainingAgricultural */
  RemainingAgricultural = 'RemainingAgricultural',
  /** ResidentialInstitution */
  ResidentialInstitution = 'ResidentialInstitution',
  /** RestaurantCafeAndConferenceCenterWithoutAccommodation */
  RestaurantCafeAndConferenceCenterWithoutAccommodation = 'RestaurantCafeAndConferenceCenterWithoutAccommodation',
  /** Retail */
  Retail = 'Retail',
  /** RidingSchool */
  RidingSchool = 'RidingSchool',
  /** ShoppingCentre */
  ShoppingCentre = 'ShoppingCentre',
  /** SportHall */
  SportHall = 'SportHall',
  /** Storage */
  Storage = 'Storage',
  /** StrawStorage */
  StrawStorage = 'StrawStorage',
  /** SummerHouse */
  SummerHouse = 'SummerHouse',
  /** SupplyAndEnergyDistribution */
  SupplyAndEnergyDistribution = 'SupplyAndEnergyDistribution',
  /** TerracedAndLinked */
  TerracedAndLinked = 'TerracedAndLinked',
  /** TrashAndWastewater */
  TrashAndWastewater = 'TrashAndWastewater',
  /** TreatmentCenter */
  TreatmentCenter = 'TreatmentCenter',
  /** TribuneAssociatedWithStadium */
  TribuneAssociatedWithStadium = 'TribuneAssociatedWithStadium',
  /** University */
  University = 'University',
  /** VacationAndSummerHouseAnex */
  VacationAndSummerHouseAnex = 'VacationAndSummerHouseAnex',
  /** VillageHall */
  VillageHall = 'VillageHall',
  /** WaterSupply */
  WaterSupply = 'WaterSupply',
  /** Workshop */
  Workshop = 'Workshop',
}

export enum BusinessPropertyCategory {
  /** CattleFarm */
  CattleFarm = 'CattleFarm',
  /** Cellar */
  Cellar = 'Cellar',
  /** Clinic */
  Clinic = 'Clinic',
  /** CropFarms */
  CropFarms = 'CropFarms',
  /** ForestProperties */
  ForestProperties = 'ForestProperties',
  /** Garage */
  Garage = 'Garage',
  /** HotelOrRestaurant */
  HotelOrRestaurant = 'HotelOrRestaurant',
  /** HousingAndBusiness */
  HousingAndBusiness = 'HousingAndBusiness',
  /** Investment */
  Investment = 'Investment',
  /** Liberal */
  Liberal = 'Liberal',
  /** Office */
  Office = 'Office',
  /** Parking */
  Parking = 'Parking',
  /** PigFarm */
  PigFarm = 'PigFarm',
  /** Plot */
  Plot = 'Plot',
  /** PrivateResidence */
  PrivateResidence = 'PrivateResidence',
  /** ProductionFacility */
  ProductionFacility = 'ProductionFacility',
  /** ShoppingCenter */
  ShoppingCenter = 'ShoppingCenter',
  /** Showroom */
  Showroom = 'Showroom',
  /** SpecialProduction */
  SpecialProduction = 'SpecialProduction',
  /** Store */
  Store = 'Store',
  /** WareHouse */
  WareHouse = 'WareHouse',
  /** WarehouseAndProduction */
  WarehouseAndProduction = 'WarehouseAndProduction',
  /** Workshop */
  Workshop = 'Workshop',
}

export enum BusinessPropertyType {
  /** Cattle */
  Cattle = 'Cattle',
  /** Cellar */
  Cellar = 'Cellar',
  /** Clinic */
  Clinic = 'Clinic',
  /** Crop */
  Crop = 'Crop',
  /** FarmLand */
  FarmLand = 'FarmLand',
  /** ForestProperty */
  ForestProperty = 'ForestProperty',
  /** FreeLand */
  FreeLand = 'FreeLand',
  /** Garage */
  Garage = 'Garage',
  /** HotelOrRestaurant */
  HotelOrRestaurant = 'HotelOrRestaurant',
  /** HousingAndBusiness */
  HousingAndBusiness = 'HousingAndBusiness',
  /** HousingCooperative */
  HousingCooperative = 'HousingCooperative',
  /** Investment */
  Investment = 'Investment',
  /** Liberal */
  Liberal = 'Liberal',
  /** MinkAndFurAnimals */
  MinkAndFurAnimals = 'MinkAndFurAnimals',
  /** Office */
  Office = 'Office',
  /** Parking */
  Parking = 'Parking',
  /** Pigs */
  Pigs = 'Pigs',
  /** Plot */
  Plot = 'Plot',
  /** PoultryProduction */
  PoultryProduction = 'PoultryProduction',
  /** ProductionFacility */
  ProductionFacility = 'ProductionFacility',
  /** ShoppingCenter */
  ShoppingCenter = 'ShoppingCenter',
  /** Showroom */
  Showroom = 'Showroom',
  /** SpecialProduction */
  SpecialProduction = 'SpecialProduction',
  /** Store */
  Store = 'Store',
  /** WareHouse */
  WareHouse = 'WareHouse',
  /** WareHouseAndProduction */
  WareHouseAndProduction = 'WareHouseAndProduction',
  /** Workshop */
  Workshop = 'Workshop',
}

export enum BusinessRentalCaseStatus {
  /** Commissioned */
  Commissioned = 'Commissioned',
  /** Discontinued */
  Discontinued = 'Discontinued',
  /** ForRent */
  ForRent = 'ForRent',
  /** LostCommission */
  LostCommission = 'LostCommission',
  /** LostSale */
  LostSale = 'LostSale',
  /** None */
  None = 'None',
  /** Rented */
  Rented = 'Rented',
  /** UnderRent */
  UnderRent = 'UnderRent',
  /** Valuation */
  Valuation = 'Valuation',
}

export enum BusinessSalesCaseStatus {
  /** BeforeSale */
  BeforeSale = 'BeforeSale',
  /** FinallyTrade */
  FinallyTrade = 'FinallyTrade',
  /** ForSale */
  ForSale = 'ForSale',
  /** LostCommission */
  LostCommission = 'LostCommission',
  /** NotDefined */
  NotDefined = 'NotDefined',
  /** Return */
  Return = 'Return',
  /** Sold */
  Sold = 'Sold',
  /** UnderSale */
  UnderSale = 'UnderSale',
  /** Valuation */
  Valuation = 'Valuation',
}

export enum BusinessSearchProfilePropertyCategory {
  Cellar = 'Cellar',
  Clinic = 'Clinic',
  Garage = 'Garage',
  HotelOrRestaurant = 'HotelOrRestaurant',
  HousingAndBusiness = 'HousingAndBusiness',
  Investment = 'Investment',
  Liberal = 'Liberal',
  Office = 'Office',
  Parking = 'Parking',
  Plot = 'Plot',
  ProductionFacility = 'ProductionFacility',
  ShoppingCenter = 'ShoppingCenter',
  Showroom = 'Showroom',
  Store = 'Store',
  WareHouse = 'WareHouse',
  WarehouseAndProduction = 'WarehouseAndProduction',
  Workshop = 'Workshop',
}

export type CancelAppointmentActivityInputType = {
  id: Scalars['ID']['input'];
};

export enum CaseAnnouncedType {
  /** Boligsiden */
  Boligsiden = 'Boligsiden',
  /** BoligsidenPreview */
  BoligsidenPreview = 'BoligsidenPreview',
  /** BorsenEjendomslink */
  BorsenEjendomslink = 'BorsenEjendomslink',
  /** Landbrugsavisen */
  Landbrugsavisen = 'Landbrugsavisen',
  /** Landbrugsmarkedet */
  Landbrugsmarkedet = 'Landbrugsmarkedet',
  /** LokaleBasen */
  LokaleBasen = 'LokaleBasen',
  /** Offmarket */
  Offmarket = 'Offmarket',
  /** Oline */
  Oline = 'Oline',
  /** Respace */
  Respace = 'Respace',
  /** Website */
  Website = 'Website',
}

export type CasePromotionInputType = {
  caseId: Scalars['ID']['input'];
  ownerSaysPropertyHighlights?: InputMaybe<Scalars['String']['input']>;
  ownerSaysThisIsTheNeighborhood?: InputMaybe<Scalars['String']['input']>;
  ownerSaysWeRelaxHere?: InputMaybe<Scalars['String']['input']>;
  ownerSaysWeShopHere?: InputMaybe<Scalars['String']['input']>;
  ownerSaysWeShowOurGuestsThis?: InputMaybe<Scalars['String']['input']>;
  ownerSaysWhyWeMovedHere?: InputMaybe<Scalars['String']['input']>;
  ownerSaysWhyWereMovingAway?: InputMaybe<Scalars['String']['input']>;
};

export enum CaseTypeEnumGraphType {
  /** BusinessRental */
  BusinessRental = 'BusinessRental',
  /** BusinessSale */
  BusinessSale = 'BusinessSale',
  /** BusinessValuation */
  BusinessValuation = 'BusinessValuation',
  /** Counseling */
  Counseling = 'Counseling',
  /** CounselingProperty */
  CounselingProperty = 'CounselingProperty',
  /** Loan */
  Loan = 'Loan',
  /** Rectify */
  Rectify = 'Rectify',
  /** Rental */
  Rental = 'Rental',
  /** Sale */
  Sale = 'Sale',
  /** Valuation */
  Valuation = 'Valuation',
}

export enum CounselingCaseStatus {
  /** Deal */
  Deal = 'Deal',
  /** DealDisregarded */
  DealDisregarded = 'DealDisregarded',
  /** DealMade */
  DealMade = 'DealMade',
  /** DealNotMade */
  DealNotMade = 'DealNotMade',
  /** FinallyTrade */
  FinallyTrade = 'FinallyTrade',
  /** Return */
  Return = 'Return',
  /** Sold */
  Sold = 'Sold',
}

export enum CounselingPropertyCaseStatus {
  /** Initial */
  Initial = 'Initial',
  /** Rejected */
  Rejected = 'Rejected',
  /** Selected */
  Selected = 'Selected',
}

/** Input object for creating and updating the address for a customer. */
export type CreateAddressInput = {
  att?: InputMaybe<Scalars['String']['input']>;
  /** Care of. The letter is to be delivered and entrusted to the care of... */
  co?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  door?: InputMaybe<Scalars['String']['input']>;
  doorLocation?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  houseName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  locationCityName?: InputMaybe<Scalars['String']['input']>;
  roadName?: InputMaybe<Scalars['String']['input']>;
  /** The foreign address */
  text?: InputMaybe<Scalars['String']['input']>;
  zipCodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAgricultureSearchProfileInput = {
  /** Property categories */
  agriculturePropertyCategories?: InputMaybe<Array<AgriculturePropertyTypeCategory>>;
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  /** Consent of the customer to create a searchprofile */
  consentId: Scalars['ID']['input'];
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateAppointmentActivityInputType = {
  activityTypeId: Scalars['ID']['input'];
  caseId: Scalars['ID']['input'];
  customer?: InputMaybe<AppointmentActivityCustomerInputType>;
  endDatetime: Scalars['DateTime']['input'];
  startDatetime: Scalars['DateTime']['input'];
};

export type CreateAppointmentActivityNoteInputType = {
  activityId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
};

export type CreateBusinessSearchProfileInput = {
  /** Property categories */
  businessPropertyCategories?: InputMaybe<Array<BusinessSearchProfilePropertyCategory>>;
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  /** Consent of the customer to create a searchprofile */
  consentId: Scalars['ID']['input'];
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Rate of return from in percent */
  rateOfReturnFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Rate of return to in percent */
  rateOfReturnTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateConsentOnCustomerInputType = {
  /** The id of consent */
  consentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateCustomerAddressInput = {
  /** The customer address */
  address: CreateAddressInput;
  /** The address type */
  addressType: AddressType;
  /** The customer address valid from */
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateCustomerEmailInput = {
  customerId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  emailType?: InputMaybe<EmailType>;
  isLoginEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateMessageResponseInput = {
  /** Boolean value response. Only one of BooleanValue, StringValue or DateValue can be set. */
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date value response. Only one of BooleanValue, StringValue or DateValue can be set. */
  dateValue?: InputMaybe<Scalars['DateTime']['input']>;
  /** The id of the message to respond to */
  messageId: Scalars['ID']['input'];
  /** String value response. Only one of BooleanValue, StringValue or DateValue can be set. */
  stringValue?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNeighborhoodPinInputType = {
  /** The category of pins that a pin belongs to */
  category: NeighborhoodPinCategory;
  /** A detailed description of the pin */
  description: Scalars['String']['input'];
  /** The latitude component of the GPS-coordinate for the pin */
  lat: Scalars['Float']['input'];
  /** The longtitude component of the GPS-coordinate for the pin */
  lng: Scalars['Float']['input'];
  /** The short title of the pin */
  title: Scalars['String']['input'];
};

export type CreateSearchProfileInput = {
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  /** Consent of the customer to create a searchprofile */
  consentId: Scalars['ID']['input'];
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Cumulative number of days on market (from) */
  cumulativeDaysOnMarketFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Cumulative number of days on market (to) */
  cumulativeDaysOnMarketTo?: InputMaybe<Scalars['Int']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Distance to coast in meters (from) */
  distanceCoastFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to coast in meters (to) */
  distanceCoastTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to daycare in meters (from) */
  distanceDaycareFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to daycare in meters (to) */
  distanceDaycareTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to public transportation (bus, metro, S train, train) in meters (from) */
  distancePublicTransportationFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to public transportation (bus, metro, S train, train) in meters (to) */
  distancePublicTransportationTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to school in meters (from) */
  distanceSchoolFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to school in meters (to) */
  distanceSchoolTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to supermarket in meters (from) */
  distanceSupermarketFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to supermarket in meters (to) */
  distanceSupermarketTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The number of living rooms from */
  livingRoomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of living rooms to */
  livingRoomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Property categories */
  propertyCategories?: InputMaybe<Array<PropertyCategory>>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  /** Search profile words */
  searchProfileWords?: InputMaybe<Array<InputMaybe<SearchProfileWord>>>;
  /** Customer special wishes */
  specialWishes?: InputMaybe<Scalars['String']['input']>;
  /** The number of toilets from */
  toiletCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of toilets to */
  toiletCountTo?: InputMaybe<Scalars['Int']['input']>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateThreadInput = {
  /** Id of activity to send to */
  activityId?: InputMaybe<Scalars['ID']['input']>;
  /** Message attachments */
  attachmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Id of case to send to */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  /** Initial message */
  initialMessage: Scalars['String']['input'];
  /** Id of the participant */
  participantId: Scalars['ID']['input'];
  /** The thread name */
  subject: Scalars['String']['input'];
};

export enum CustomerContext {
  /** Buyer */
  Buyer = 'Buyer',
  /** Counselee */
  Counselee = 'Counselee',
  /** Landlord */
  Landlord = 'Landlord',
  /** MatchCase */
  MatchCase = 'MatchCase',
  /** None */
  None = 'None',
  /** PotentialBuyer */
  PotentialBuyer = 'PotentialBuyer',
  /** Seller */
  Seller = 'Seller',
  /** Tenant */
  Tenant = 'Tenant',
}

export type CustomerEmailInputType = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailType?: InputMaybe<EmailType>;
  isLoginEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerPhoneInputType = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<PhoneType>;
};

export type DeleteCustomerConsentInputType = {
  /** The customer consent global id */
  customerConsentId: Scalars['ID']['input'];
};

export type DeleteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteNeighborhoodPinInputType = {
  /** The unique identifier for the neighborhood pin */
  id: Scalars['ID']['input'];
};

export type DeleteSearchProfileInput = {
  /** The globally unique Id */
  id: Scalars['ID']['input'];
};

export enum DocumentPreviewState {
  /** FullVersion */
  FullVersion = 'FullVersion',
  /** HoverPreview */
  HoverPreview = 'HoverPreview',
  /** IconPreview */
  IconPreview = 'IconPreview',
}

export enum DocumentSignatureStatus {
  /** AwaitingSigning */
  AwaitingSigning = 'AwaitingSigning',
  /** SigningFailed */
  SigningFailed = 'SigningFailed',
  /** SigningSucceeded */
  SigningSucceeded = 'SigningSucceeded',
  /** Unknown */
  Unknown = 'Unknown',
}

export enum EmailType {
  /** Private */
  Private = 'Private',
  /** Work */
  Work = 'Work',
}

export enum EmployeeOfferRole {
  /** AccountableBroker */
  AccountableBroker = 'AccountableBroker',
  /** BuyerAdvisor */
  BuyerAdvisor = 'BuyerAdvisor',
  /** Caseworker */
  Caseworker = 'Caseworker',
  /** CommissionBroker */
  CommissionBroker = 'CommissionBroker',
  /** MarketingBroker */
  MarketingBroker = 'MarketingBroker',
  /** SellingBroker */
  SellingBroker = 'SellingBroker',
  /** ServiceCenterCaseworker */
  ServiceCenterCaseworker = 'ServiceCenterCaseworker',
}

export enum EnergyMarkSeverity {
  /** A */
  A = 'A',
  /** A1 */
  A1 = 'A1',
  /** A2 */
  A2 = 'A2',
  /** A2010 */
  A2010 = 'A2010',
  /** A2015 */
  A2015 = 'A2015',
  /** A2020 */
  A2020 = 'A2020',
  /** B */
  B = 'B',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** E */
  E = 'E',
  /** F */
  F = 'F',
  /** G */
  G = 'G',
  /** Unknown */
  Unknown = 'Unknown',
}

export enum EnergyMarkSeverityEnum {
  /** A */
  A = 'A',
  /** A1 */
  A1 = 'A1',
  /** A2 */
  A2 = 'A2',
  /** A2010 */
  A2010 = 'A2010',
  /** A2015 */
  A2015 = 'A2015',
  /** A2020 */
  A2020 = 'A2020',
  /** B */
  B = 'B',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** E */
  E = 'E',
  /** F */
  F = 'F',
  /** G */
  G = 'G',
  /** Unknown */
  Unknown = 'Unknown',
}

export enum ErrorType {
  /** Exist */
  Exist = 'Exist',
  /** InvalidValue */
  InvalidValue = 'InvalidValue',
  /** NotComplete */
  NotComplete = 'NotComplete',
  /** Other */
  Other = 'Other',
  /** Required */
  Required = 'Required',
}

export enum GoogleAnalyticsSource {
  /** Boliga */
  Boliga = 'Boliga',
  /** Boligsiden */
  Boligsiden = 'Boligsiden',
  /** Direct */
  Direct = 'Direct',
  /** Ejendomstorvet */
  Ejendomstorvet = 'Ejendomstorvet',
  /** Facebook */
  Facebook = 'Facebook',
  /** Google */
  Google = 'Google',
  /** Instagram */
  Instagram = 'Instagram',
  /** Landbrugsavisen */
  Landbrugsavisen = 'Landbrugsavisen',
  /** Landbrugsmarkedet */
  Landbrugsmarkedet = 'Landbrugsmarkedet',
  /** LinkedIn */
  LinkedIn = 'LinkedIn',
  /** Lokalebasen */
  Lokalebasen = 'Lokalebasen',
  /** Other */
  Other = 'Other',
}

export enum InsuranceCoverage {
  /** Full */
  Full = 'Full',
  /** None */
  None = 'None',
  /** SumInsured */
  SumInsured = 'SumInsured',
  /** Text */
  Text = 'Text',
}

export enum LeadStatusTypeEnum {
  /** ConvertedToCustomer */
  ConvertedToCustomer = 'ConvertedToCustomer',
  /** InProgress */
  InProgress = 'InProgress',
  /** MarkAsSpam */
  MarkAsSpam = 'MarkAsSpam',
  /** NotConvertedToCustomer */
  NotConvertedToCustomer = 'NotConvertedToCustomer',
  /** Processed */
  Processed = 'Processed',
  /** Recieved */
  Recieved = 'Recieved',
}

export enum LeadTypeEnum {
  /** BankEvaluation */
  BankEvaluation = 'BankEvaluation',
  /** BuyersAdvice */
  BuyersAdvice = 'BuyersAdvice',
  /** BuyersRegistration */
  BuyersRegistration = 'BuyersRegistration',
  /** Contact */
  Contact = 'Contact',
  /** ContactEmployee */
  ContactEmployee = 'ContactEmployee',
  /** IndicativeValuation */
  IndicativeValuation = 'IndicativeValuation',
  /** LeadBankReferralBuyerConsultation */
  LeadBankReferralBuyerConsultation = 'LeadBankReferralBuyerConsultation',
  /** LeadBankReferralSalesEvaluation */
  LeadBankReferralSalesEvaluation = 'LeadBankReferralSalesEvaluation',
  /** LeadBuyerReference */
  LeadBuyerReference = 'LeadBuyerReference',
  /** LeadCrossBrandReferral */
  LeadCrossBrandReferral = 'LeadCrossBrandReferral',
  /** LeadCustomerBankReferral */
  LeadCustomerBankReferral = 'LeadCustomerBankReferral',
  /** LeadCustomerInternalReferral */
  LeadCustomerInternalReferral = 'LeadCustomerInternalReferral',
  /** OpenHouse */
  OpenHouse = 'OpenHouse',
  /** OwnershipChangeValuation */
  OwnershipChangeValuation = 'OwnershipChangeValuation',
  /** Presentation */
  Presentation = 'Presentation',
  /** PurchaseOffer */
  PurchaseOffer = 'PurchaseOffer',
  /** SalesMaterial */
  SalesMaterial = 'SalesMaterial',
  /** SalesMaterialWithContact */
  SalesMaterialWithContact = 'SalesMaterialWithContact',
  /** SalesValuation */
  SalesValuation = 'SalesValuation',
  /** TKValuation */
  TKValuation = 'TKValuation',
}

export enum MarketDataCaseOrderBy {
  /** Address */
  Address = 'Address',
  /** HouseArea */
  HouseArea = 'HouseArea',
  /** Id */
  Id = 'Id',
  /** Price */
  Price = 'Price',
  /** SalesPrice */
  SalesPrice = 'SalesPrice',
}

export enum MarketingCampaignInteractionTypeEnum {
  /** Click */
  Click = 'Click',
  /** Comment */
  Comment = 'Comment',
  /** Share */
  Share = 'Share',
  /** Unknown */
  Unknown = 'Unknown',
  /** View */
  View = 'View',
}

export enum MarketingCampaignPlacementEnum {
  /** Display */
  Display = 'Display',
  /** Facebook */
  Facebook = 'Facebook',
  /** Instagram */
  Instagram = 'Instagram',
  /** LinkedIn */
  LinkedIn = 'LinkedIn',
  /** Snapchat */
  Snapchat = 'Snapchat',
  /** Unknown */
  Unknown = 'Unknown',
  /** YouTube */
  YouTube = 'YouTube',
}

export enum MarketingCampaignStatusEnum {
  /** Done */
  Done = 'Done',
  /** Failed */
  Failed = 'Failed',
  /** Ongoing */
  Ongoing = 'Ongoing',
  /** Pending */
  Pending = 'Pending',
}

export enum MatchProfileStatus {
  /** Favorite */
  Favorite = 'Favorite',
  /** None */
  None = 'None',
  /** Removed */
  Removed = 'Removed',
  /** Selected */
  Selected = 'Selected',
}

export enum MediaResourceType {
  /** The asset */
  Asset = 'Asset',
  /** A presentation of the asset */
  Presentation = 'Presentation',
  /** A preview of the asset */
  Preview = 'Preview',
  /** A thumbnail of the asset */
  Thumbnail = 'Thumbnail',
}

export enum MyPageCaseRelation {
  /** Case */
  Case = 'Case',
  /** MatchedCase */
  MatchedCase = 'MatchedCase',
  /** None */
  None = 'None',
  /** Offer */
  Offer = 'Offer',
}

export enum MyPageSpotRole {
  /** Buyer */
  Buyer = 'Buyer',
  /** Frontpage */
  Frontpage = 'Frontpage',
  /** PotentialBuyer */
  PotentialBuyer = 'PotentialBuyer',
  /** Seller */
  Seller = 'Seller',
}

export enum MyPageTextSection {
  /** CasePromotion */
  CasePromotion = 'CasePromotion',
  /** Consent */
  Consent = 'Consent',
  /** CustomerGDPR */
  CustomerGDPR = 'CustomerGDPR',
  /** EditSearchProfile */
  EditSearchProfile = 'EditSearchProfile',
  /** Favorites */
  Favorites = 'Favorites',
  /** FinishedActivties */
  FinishedActivties = 'FinishedActivties',
  /** GetStarted */
  GetStarted = 'GetStarted',
  /** Match */
  Match = 'Match',
  /** Messages */
  Messages = 'Messages',
  /** MyNeighbourhood */
  MyNeighbourhood = 'MyNeighbourhood',
  /** OtherBrokerFavorites */
  OtherBrokerFavorites = 'OtherBrokerFavorites',
  /** SearchProfileMatch */
  SearchProfileMatch = 'SearchProfileMatch',
  /** UpcomingActivities */
  UpcomingActivities = 'UpcomingActivities',
  /** UserProfile */
  UserProfile = 'UserProfile',
  /** Welcome */
  Welcome = 'Welcome',
  /** WelcomeHeadline */
  WelcomeHeadline = 'WelcomeHeadline',
}

export enum NeighborhoodPinCategory {
  /** Dining */
  Dining = 'Dining',
  /** Events */
  Events = 'Events',
  /** ExerciseAndRecreation */
  ExerciseAndRecreation = 'ExerciseAndRecreation',
  /** ForKids */
  ForKids = 'ForKids',
  /** Nature */
  Nature = 'Nature',
  /** Nightlife */
  Nightlife = 'Nightlife',
  /** NoteworhtyLandmark */
  NoteworhtyLandmark = 'NoteworhtyLandmark',
  /** Other */
  Other = 'Other',
  /** Shopping */
  Shopping = 'Shopping',
  /** SocialMeetup */
  SocialMeetup = 'SocialMeetup',
}

export type OpenHouseInputType = {
  consentIdGlobal: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /** The id of the open house */
  openHouseId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
  sourceIdGlobal?: InputMaybe<Scalars['ID']['input']>;
};

export enum OperatingCostType {
  /** PricePrM2 */
  PricePrM2 = 'PricePrM2',
  /** Total */
  Total = 'Total',
}

export enum OrderDirection {
  /** Ascending order. */
  asc = 'asc',
  /** Descending order. */
  desc = 'desc',
}

export enum PhoneType {
  /** Main */
  Main = 'Main',
  /** Mobile */
  Mobile = 'Mobile',
  /** Private */
  Private = 'Private',
  /** Work */
  Work = 'Work',
}

export enum PotentialBuyerCreateContext {
  /** BrookerChoice */
  BrookerChoice = 'BrookerChoice',
  /** LeadSalesMaterialRequest */
  LeadSalesMaterialRequest = 'LeadSalesMaterialRequest',
  /** Match */
  Match = 'Match',
  /** OpenHouse */
  OpenHouse = 'OpenHouse',
  /** OwnChoice */
  OwnChoice = 'OwnChoice',
  /** PurchaseOffer */
  PurchaseOffer = 'PurchaseOffer',
  /** Showcase */
  Showcase = 'Showcase',
}

/** Input object for creating a presentation lead. */
export type Presentation = {
  caseId: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  consentIdGlobal: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  presentationDate?: InputMaybe<Scalars['DateTime']['input']>;
  sourceIdGlobal?: InputMaybe<Scalars['ID']['input']>;
};

export enum PreservationType {
  /** FreeText */
  FreeText = 'FreeText',
  /** NoPreservation */
  NoPreservation = 'NoPreservation',
  /** PreservationValue1 */
  PreservationValue1 = 'PreservationValue1',
  /** PreservationValue2 */
  PreservationValue2 = 'PreservationValue2',
  /** PreservationValue3 */
  PreservationValue3 = 'PreservationValue3',
  /** PreservationValue4 */
  PreservationValue4 = 'PreservationValue4',
  /** PreservationValue5 */
  PreservationValue5 = 'PreservationValue5',
  /** PreservationValue6 */
  PreservationValue6 = 'PreservationValue6',
  /** PreservationValue7 */
  PreservationValue7 = 'PreservationValue7',
  /** PreservationValue8 */
  PreservationValue8 = 'PreservationValue8',
  /** PreservationValue9 */
  PreservationValue9 = 'PreservationValue9',
  /** Preserved */
  Preserved = 'Preserved',
}

export enum PrivateBuildingTypeGraphType {
  /** Carport */
  Carport = 'Carport',
  /** Commercial */
  Commercial = 'Commercial',
  /** Garage */
  Garage = 'Garage',
  /** Other */
  Other = 'Other',
  /** Outhouse */
  Outhouse = 'Outhouse',
}

export enum PropertyCategory {
  /** AllYearRoundPlot */
  AllYearRoundPlot = 'AllYearRoundPlot',
  /** Condo */
  Condo = 'Condo',
  /** CropsFarm */
  CropsFarm = 'CropsFarm',
  /** FarmHouse */
  FarmHouse = 'FarmHouse',
  /** HousingCooperative */
  HousingCooperative = 'HousingCooperative',
  /** Liebhaveri */
  Liebhaveri = 'Liebhaveri',
  /** TerracedHouse */
  TerracedHouse = 'TerracedHouse',
  /** VacationHousing */
  VacationHousing = 'VacationHousing',
  /** VacationPlot */
  VacationPlot = 'VacationPlot',
  /** Villa */
  Villa = 'Villa',
  /** VillaApartment */
  VillaApartment = 'VillaApartment',
}

export enum PropertyType {
  /** AllYearRoundPlot */
  AllYearRoundPlot = 'AllYearRoundPlot',
  /** AllotmentHut */
  AllotmentHut = 'AllotmentHut',
  /** AllotmentPlot */
  AllotmentPlot = 'AllotmentPlot',
  /** Condo */
  Condo = 'Condo',
  /** FarmHouseAgriculture */
  FarmHouseAgriculture = 'FarmHouseAgriculture',
  /** FormerFarm */
  FormerFarm = 'FormerFarm',
  /** FreeLand */
  FreeLand = 'FreeLand',
  /** HobbyAgriculture */
  HobbyAgriculture = 'HobbyAgriculture',
  /** HousingCooperative */
  HousingCooperative = 'HousingCooperative',
  /** IdeelAnpartCondo */
  IdeelAnpartCondo = 'IdeelAnpartCondo',
  /** IdeelAnpartTerracedHouse */
  IdeelAnpartTerracedHouse = 'IdeelAnpartTerracedHouse',
  /** IdeelAnpartVilla */
  IdeelAnpartVilla = 'IdeelAnpartVilla',
  /** Liebhaveri */
  Liebhaveri = 'Liebhaveri',
  /** MixedHousingAndBusiness */
  MixedHousingAndBusiness = 'MixedHousingAndBusiness',
  /** PartnershipOrLimitedPartnershipCondo */
  PartnershipOrLimitedPartnershipCondo = 'PartnershipOrLimitedPartnershipCondo',
  /** PartnershipOrLimitedPartnershipTerracedHouse */
  PartnershipOrLimitedPartnershipTerracedHouse = 'PartnershipOrLimitedPartnershipTerracedHouse',
  /** PartnershipOrLimitedPartnershipVilla */
  PartnershipOrLimitedPartnershipVilla = 'PartnershipOrLimitedPartnershipVilla',
  /** TerracedHouse */
  TerracedHouse = 'TerracedHouse',
  /** TerracedHouseWithCondoStatus */
  TerracedHouseWithCondoStatus = 'TerracedHouseWithCondoStatus',
  /** TwoFamilyProperty */
  TwoFamilyProperty = 'TwoFamilyProperty',
  /** VacationCondo */
  VacationCondo = 'VacationCondo',
  /** VacationHousing */
  VacationHousing = 'VacationHousing',
  /** VacationPlot */
  VacationPlot = 'VacationPlot',
  /** Villa */
  Villa = 'Villa',
  /** VillaApartment */
  VillaApartment = 'VillaApartment',
  /** VillaWithCondoStatus */
  VillaWithCondoStatus = 'VillaWithCondoStatus',
}

export enum RectifyCaseStatus {
  /** Deal */
  Deal = 'Deal',
  /** DealDisregarded */
  DealDisregarded = 'DealDisregarded',
  /** DealMade */
  DealMade = 'DealMade',
  /** DealNotMade */
  DealNotMade = 'DealNotMade',
  /** FinallyTrade */
  FinallyTrade = 'FinallyTrade',
  /** Return */
  Return = 'Return',
  /** Sold */
  Sold = 'Sold',
}

export enum RentalCaseStatus {
  /** Commissioned */
  Commissioned = 'Commissioned',
  /** Discontinued */
  Discontinued = 'Discontinued',
  /** ForRent */
  ForRent = 'ForRent',
  /** LostCommission */
  LostCommission = 'LostCommission',
  /** LostSale */
  LostSale = 'LostSale',
  /** Rented */
  Rented = 'Rented',
  /** UnderRent */
  UnderRent = 'UnderRent',
  /** Unknown */
  Unknown = 'Unknown',
  /** Valuation */
  Valuation = 'Valuation',
}

export enum ResponseType {
  /** BooleanAndStringType */
  BooleanAndStringType = 'BooleanAndStringType',
  /** BooleanType */
  BooleanType = 'BooleanType',
  /** DropdownStringType */
  DropdownStringType = 'DropdownStringType',
}

export enum SalesCaseStatus {
  /** BeforeSale */
  BeforeSale = 'BeforeSale',
  /** FinallyTrade */
  FinallyTrade = 'FinallyTrade',
  /** ForSale */
  ForSale = 'ForSale',
  /** LostCommission */
  LostCommission = 'LostCommission',
  /** NotDefined */
  NotDefined = 'NotDefined',
  /** Return */
  Return = 'Return',
  /** Sold */
  Sold = 'Sold',
  /** UnderSale */
  UnderSale = 'UnderSale',
  /** Valuation */
  Valuation = 'Valuation',
}

export enum SearchProfileEnabledType {
  /** A searchprofile for business salescases, using the agriculture propertyTypes */
  AgricultureSearchProfile = 'AgricultureSearchProfile',
  /** A searchprofile for business rentalcases, using business propertyTypes */
  BusinessRentalSearchProfile = 'BusinessRentalSearchProfile',
  /** A searchprofile for business salescases, using the business propertyTypes */
  BusinessSaleSearchProfile = 'BusinessSaleSearchProfile',
  /** A searchprofile for private rentalcases, using private propertyTypes */
  PrivateRentalSearchProfile = 'PrivateRentalSearchProfile',
  /** A searchprofile for private salescases, using private propertyTypes */
  PrivateSaleSearchProfile = 'PrivateSaleSearchProfile',
}

export enum SearchProfileMatchedCaseOrderBy {
  /** Address */
  Address = 'Address',
  /** Date */
  Date = 'Date',
  /** HouseArea */
  HouseArea = 'HouseArea',
  /** Price */
  Price = 'Price',
  /** Status */
  Status = 'Status',
  /** VisibleForCustomerDate */
  VisibleForCustomerDate = 'VisibleForCustomerDate',
}

export enum SearchProfileStatus {
  /** Active */
  Active = 'Active',
  /** Looking */
  Looking = 'Looking',
}

export enum SearchProfileType {
  /** Agriculture */
  Agriculture = 'Agriculture',
  /** BusinessRental */
  BusinessRental = 'BusinessRental',
  /** BusinessSale */
  BusinessSale = 'BusinessSale',
  /** Rental */
  Rental = 'Rental',
  /** Sale */
  Sale = 'Sale',
}

export enum SearchProfileWord {
  /** Balcony */
  Balcony = 'Balcony',
  /** Elevator */
  Elevator = 'Elevator',
  /** Furnished */
  Furnished = 'Furnished',
  /** IsProjectCase */
  IsProjectCase = 'IsProjectCase',
  /** Liebhaveri */
  Liebhaveri = 'Liebhaveri',
  /** ParkingSpace */
  ParkingSpace = 'ParkingSpace',
  /** Pets */
  Pets = 'Pets',
  /** ResidenceRequirement */
  ResidenceRequirement = 'ResidenceRequirement',
  /** Shareable */
  Shareable = 'Shareable',
  /** Terrace */
  Terrace = 'Terrace',
}

export type SendMessageInput = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  message: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
};

export enum TaskActivityStatusEnum {
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** Finished */
  Finished = 'Finished',
  /** Planned */
  Planned = 'Planned',
  /** Postponed */
  Postponed = 'Postponed',
}

export type ThreadEventInput = {
  threadId: Scalars['ID']['input'];
};

export enum ThreadOrderBy {
  /** CreateDate */
  CreateDate = 'CreateDate',
  /** Id */
  Id = 'Id',
  /** LastMessageCreatedDate */
  LastMessageCreatedDate = 'LastMessageCreatedDate',
  /** Subject */
  Subject = 'Subject',
}

export enum TimelineActivityType {
  /** Appointment */
  Appointment = 'Appointment',
  /** TimelineNote */
  TimelineNote = 'TimelineNote',
}

export type UpdateAgricultureSearchProfileInput = {
  /** Property categories */
  agriculturePropertyCategories?: InputMaybe<Array<AgriculturePropertyTypeCategory>>;
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The globally unique Id */
  id: Scalars['ID']['input'];
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateAppointmentActivityInputType = {
  customer?: InputMaybe<AppointmentActivityCustomerInputType>;
  endDatetime: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  startDatetime: Scalars['DateTime']['input'];
};

export type UpdateBusinessSearchProfileInput = {
  /** Property categories */
  businessPropertyCategories?: InputMaybe<Array<BusinessSearchProfilePropertyCategory>>;
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The globally unique Id */
  id: Scalars['ID']['input'];
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Rate of return from in percent */
  rateOfReturnFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Rate of return to in percent */
  rateOfReturnTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateCustomerAddressInput = {
  /** The customer address */
  address: CreateAddressInput;
  /** The address type */
  addressType: AddressType;
  /** The customer address global id */
  id: Scalars['ID']['input'];
  /** The customer address valid from */
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateCustomerConsentInputType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer consent global id */
  customerConsentId: Scalars['ID']['input'];
};

export type UpdateCustomerEmailInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailType?: InputMaybe<EmailType>;
  id: Scalars['ID']['input'];
  isLoginEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input object for updating a customer. */
export type UpdateCustomerInput = {
  /** The customer address */
  address: CreateCustomerAddressInput;
  email?: InputMaybe<CustomerEmailInputType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<CustomerPhoneInputType>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerPhoneInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<PhoneType>;
};

export type UpdateFavoriteCaseInput = {
  /** The id of the case */
  caseId: Scalars['ID']['input'];
};

export type UpdateLeadInputType = {
  leadIdGlobal: Scalars['ID']['input'];
  statusType?: InputMaybe<LeadStatusTypeEnum>;
};

export type UpdateNeighborhoodPinInputType = {
  /** The category of pins that a pin belongs to */
  category?: InputMaybe<NeighborhoodPinCategory>;
  /** A detailed description of the pin */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier for the neighborhood pin */
  id: Scalars['ID']['input'];
  /** The latitude component of the GPS-coordinate for the pin */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** The longtitude component of the GPS-coordinate for the pin */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** The short title of the pin */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSearchProfileInput = {
  /** Can be contacted */
  canBeContacted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Can be contacted by phone */
  canBeContactedByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  contactShopId?: InputMaybe<Scalars['ID']['input']>;
  /** Cumulative number of days on market (from) */
  cumulativeDaysOnMarketFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Cumulative number of days on market (to) */
  cumulativeDaysOnMarketTo?: InputMaybe<Scalars['Int']['input']>;
  /** Date of deletion */
  deletionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Distance to coast in meters (from) */
  distanceCoastFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to coast in meters (to) */
  distanceCoastTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to daycare in meters (from) */
  distanceDaycareFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to daycare in meters (to) */
  distanceDaycareTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to public transportation (bus, metro, S train, train) in meters (from) */
  distancePublicTransportationFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to public transportation (bus, metro, S train, train) in meters (to) */
  distancePublicTransportationTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to school in meters (from) */
  distanceSchoolFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to school in meters (to) */
  distanceSchoolTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to supermarket in meters (from) */
  distanceSupermarketFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Distance to supermarket in meters (to) */
  distanceSupermarketTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The number of floors from */
  floorCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of floors to */
  floorCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number from */
  floorNumberFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The floor number to */
  floorNumberTo?: InputMaybe<Scalars['Int']['input']>;
  /** House area from */
  houseAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** House area to */
  houseAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** The globally unique Id */
  id: Scalars['ID']['input'];
  /** The number of living rooms from */
  livingRoomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of living rooms to */
  livingRoomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Multi polygon (lat,lon) */
  multiPolygon?: InputMaybe<Scalars['String']['input']>;
  /** Multi polygon (lon,lat) */
  multiPolygonLonLat?: InputMaybe<Scalars['String']['input']>;
  /** Name of search profile */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerCostFrom?: InputMaybe<Scalars['Decimal']['input']>;
  ownerCostTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area from */
  plotAreaFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Plot area to */
  plotAreaTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Predefined Areas */
  predefinedAreasIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Price from */
  priceFrom?: InputMaybe<Scalars['Decimal']['input']>;
  /** Price to */
  priceTo?: InputMaybe<Scalars['Decimal']['input']>;
  /** Property categories */
  propertyCategories?: InputMaybe<Array<PropertyCategory>>;
  /** Room count from */
  roomCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Room count to */
  roomCountTo?: InputMaybe<Scalars['Int']['input']>;
  /** Energy mark severities */
  searchProfileEnergyMarkSeverities?: InputMaybe<Array<InputMaybe<EnergyMarkSeverityEnum>>>;
  /** Number of the searchprofile */
  searchProfileNo?: InputMaybe<Scalars['String']['input']>;
  /** Search profile status */
  searchProfileStatus?: InputMaybe<SearchProfileStatus>;
  /** Search profile words */
  searchProfileWords?: InputMaybe<Array<InputMaybe<SearchProfileWord>>>;
  /** Customer special wishes */
  specialWishes?: InputMaybe<Scalars['String']['input']>;
  /** The number of toilets from */
  toiletCountFrom?: InputMaybe<Scalars['Int']['input']>;
  /** The number of toilets to */
  toiletCountTo?: InputMaybe<Scalars['Int']['input']>;
  totalCommercialAreaFrom?: InputMaybe<Scalars['Float']['input']>;
  totalCommercialAreaTo?: InputMaybe<Scalars['Float']['input']>;
  /** Profile valid to date */
  validToDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Year built from */
  yearBuiltFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Year built to */
  yearBuiltTo?: InputMaybe<Scalars['Int']['input']>;
  /** Zip codes */
  zipCodesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateSearchProfileMarketDataMatchedCaseInput = {
  /** The globally unique Id of matched case */
  id: Scalars['ID']['input'];
  /** The status of matched case */
  status: MatchProfileStatus;
};

export type UpdateSearchProfileMatchedCaseInput = {
  /** The globally unique Id of matched case */
  id: Scalars['ID']['input'];
  /** The status of matched case */
  status: MatchProfileStatus;
};

export type UploadOfferDocumentInputType = {
  documentTypeId?: InputMaybe<Scalars['ID']['input']>;
  offerId: Scalars['ID']['input'];
};

export enum ValuationCaseStatus {
  /** RequiresValuation */
  RequiresValuation = 'RequiresValuation',
  /** UnderValuation */
  UnderValuation = 'UnderValuation',
  /** ValuationCompleted */
  ValuationCompleted = 'ValuationCompleted',
}

export type WebPushSubscriptionInput = {
  auth: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  p256Dh: Scalars['String']['input'];
};

import { ChangeEvent, FC, FocusEvent, Fragment, ReactNode } from 'react';
import { getDateWithSelectedTime } from '../self-sale-functions';
import { TimerPicker } from 'components/molecules/timepicker/timerpicker';
import '../choose-activity-modal.scss';

export const AppointmentTimePicker: FC<{
  labelStart: ReactNode;
  labelEnd: ReactNode;
  handleChangeTimeStart: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimeEnd: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlurTimeStart: (timeDif: number, timeInMs: number, e: ChangeEvent<HTMLInputElement>) => void;
  handleBlurTimeEnd: (timeDif: number, timeInMs: number, e: ChangeEvent<HTMLInputElement>) => void;
  timeStart: Date;
  timeEnd: Date;
}> = ({
  labelStart,
  labelEnd,
  handleChangeTimeStart,
  handleChangeTimeEnd,
  handleBlurTimeStart,
  handleBlurTimeEnd,
  timeStart,
  timeEnd,
}) => {
  const handleTimeBlurStart = (e: FocusEvent<HTMLInputElement>) => {
    const time = getDateWithSelectedTime(e, timeStart);

    const timeStartMS = time.getTime();
    const timeEndMS = timeEnd.getTime();
    const timeDifferenceInMS = timeEndMS - timeStartMS;
    const timeDifferenceInMinutes = timeDifferenceInMS / (1000 * 60);

    handleBlurTimeStart(timeDifferenceInMinutes, timeStartMS, e);
  };
  const handleTimeBlurEnd = (e: FocusEvent<HTMLInputElement>) => {
    const time = getDateWithSelectedTime(e, timeEnd);

    const timeEndMS = time.getTime();
    const timeStartMS = timeStart.getTime();
    const timeDifferenceInMS = timeEndMS - timeStartMS;
    const timeDifferenceInMinutes = timeDifferenceInMS / (1000 * 60);

    handleBlurTimeEnd(timeDifferenceInMinutes, timeEndMS, e);
  };

  return (
    <Fragment>
      <div className="appointmentTimePickerWrapper">
        {labelStart}
        <TimerPicker handleChange={handleChangeTimeStart} date={timeStart} onBlur={handleTimeBlurStart} />
      </div>
      <div className="appointmentTimePickerWrapper">
        {labelEnd}
        <TimerPicker handleChange={handleChangeTimeEnd} date={timeEnd} onBlur={handleTimeBlurEnd} />
      </div>
    </Fragment>
  );
};

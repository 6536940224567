import classNames from 'classnames';
import './block.scss';
import { FC, ReactNode } from 'react';

export interface BlockProps {
  backgroundColor?: 'white' | 'grey2' | 'grey3' | 'primary' | 'brand';
  borderRadius?: boolean;
  boxShadow?: boolean;
  children?: ReactNode;
  className?: string;
  column?: boolean;
  customPadding?: 'xxs' | 'xs' | 'sm' | 'md' | 'xl';
  flex?: boolean;
  flex1?: boolean;
  flexGutter?: 'xxs' | 'xs' | 'md';
  flexWrap?: boolean;
  justifyRight?: boolean;
  marginBottom?: boolean;
  overflowHidden?: boolean;
  positionRelative?: boolean;
  spaceBetween?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Block: FC<BlockProps> = ({
  children,
  className,
  column,
  borderRadius,
  customPadding,
  marginBottom,
  backgroundColor,
  boxShadow,
  flex,
  flexGutter,
  flexWrap,
  flex1,
  justifyRight,
  overflowHidden,
  positionRelative,
  spaceBetween,
  onClick,
}) => {
  return (
    <div
      className={classNames('block', className, {
        'border-radius': borderRadius,
        boxShadow,
        brand: backgroundColor === 'brand',
        'column-flex': column,
        flex,
        'flex-1': flex1,
        'flex-wrap': flexWrap,
        grey2: backgroundColor === 'grey2',
        grey3: backgroundColor === 'grey3',
        'gutter-md': flexGutter === 'md',
        'gutter-xs': flexGutter === 'xs',
        'gutter-xxs': flexGutter === 'xxs',
        'justify-right': justifyRight,
        marginBottom,
        'overflow-hidden': overflowHidden,
        'padding-md': customPadding === 'md',
        'padding-sm': customPadding === 'sm',
        'padding-xl': customPadding === 'xl',
        'padding-xs': customPadding === 'xs',
        'padding-xxs': customPadding === 'xxs',
        'position-relative': positionRelative,
        primary: backgroundColor === 'primary',
        spaceBetween,
        white: backgroundColor === 'white',
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Block;

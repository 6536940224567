/**
 * @generated SignedSource<<4a083b7784700c8e764692b4ab70340d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPropertyType = "Cattle" | "Cellar" | "Clinic" | "Crop" | "FarmLand" | "ForestProperty" | "FreeLand" | "Garage" | "HotelOrRestaurant" | "HousingAndBusiness" | "HousingCooperative" | "Investment" | "Liberal" | "MinkAndFurAnimals" | "Office" | "Parking" | "Pigs" | "Plot" | "PoultryProduction" | "ProductionFacility" | "ShoppingCenter" | "Showroom" | "SpecialProduction" | "Store" | "WareHouse" | "WareHouseAndProduction" | "Workshop";
export type PropertyType = "AllYearRoundPlot" | "AllotmentHut" | "AllotmentPlot" | "Condo" | "FarmHouseAgriculture" | "FormerFarm" | "FreeLand" | "HobbyAgriculture" | "HousingCooperative" | "IdeelAnpartCondo" | "IdeelAnpartTerracedHouse" | "IdeelAnpartVilla" | "Liebhaveri" | "MixedHousingAndBusiness" | "PartnershipOrLimitedPartnershipCondo" | "PartnershipOrLimitedPartnershipTerracedHouse" | "PartnershipOrLimitedPartnershipVilla" | "TerracedHouse" | "TerracedHouseWithCondoStatus" | "TwoFamilyProperty" | "VacationCondo" | "VacationHousing" | "VacationPlot" | "Villa" | "VillaApartment" | "VillaWithCondoStatus";
import { FragmentRefs } from "relay-runtime";
export type contactModuleOffmarket_offmarketCase$data = {
  readonly businessPropertyType: BusinessPropertyType | null | undefined;
  readonly caseDescriptionHeading: string | null | undefined;
  readonly caseId: string;
  readonly id: string;
  readonly mainOfferEmployees: ReadonlyArray<{
    readonly employee: {
      readonly fullName: string | null | undefined;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"contactCard_employee">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly propertyType: PropertyType | null | undefined;
  readonly " $fragmentType": "contactModuleOffmarket_offmarketCase";
};
export type contactModuleOffmarket_offmarketCase$key = {
  readonly " $data"?: contactModuleOffmarket_offmarketCase$data;
  readonly " $fragmentSpreads": FragmentRefs<"contactModuleOffmarket_offmarketCase">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contactModuleOffmarket_offmarketCase",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caseDescriptionHeading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessPropertyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmployeeOffer",
      "kind": "LinkedField",
      "name": "mainOfferEmployees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Employee",
          "kind": "LinkedField",
          "name": "employee",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "contactCard_employee"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OffmarketCase",
  "abstractKey": null
};
})();

(node as any).hash = "df2a6e123d3a71af039b31d49dab7bb6";

export default node;

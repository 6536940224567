import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Bowser from 'bowser';

type Props = {
  children: ReactNode;
};

type DisplayMode = 'browser' | 'standalone';

interface InfoType extends Bowser.Parser.ParsedResult {
  isRunningPWA: boolean;
}

const UserDeviceContext = createContext<null | InfoType>(null);

const UserDeviceProvider = (providerProps: Props) => {
  const [displayMode, setDisplayMode] = useState<'browser' | 'standalone'>('browser');

  useEffect(() => {
    let displayMode: DisplayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
    if ('standalone' in navigator || window.matchMedia(mqStandAlone).matches) {
      displayMode = 'standalone';
    }
    setDisplayMode(displayMode);

    window.matchMedia('(display-mode: standalone)').addEventListener('change', evt => {
      let displayMode: DisplayMode = 'browser';
      if ('standalone' in navigator || evt.matches) {
        displayMode = 'standalone';
      }
      setDisplayMode(displayMode);
      // Log display mode change to analytics
      console.log('DISPLAY_MODE_CHANGED', displayMode);
    });
  }, []);

  const browserInfo = Bowser.parse(navigator.userAgent);
  const info = {
    ...browserInfo,
    isRunningPWA: displayMode === 'standalone',
  };

  return <UserDeviceContext.Provider value={info}>{providerProps.children}</UserDeviceContext.Provider>;
};

export default UserDeviceProvider;

export const useUserDevice = () => useContext(UserDeviceContext);

import classNames from 'classnames';
import Button from 'components/atoms/button/button';
import FocusTrap from 'focus-trap-react';
import { FC, KeyboardEvent, ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './popup-overlay.scss';

export interface PopupOverlayProps {
  children: ReactNode;
  className?: string;
  focusTrapOptions?: FocusTrap.Props['focusTrapOptions'];
  onClose: () => void;
}

const PopupOverlay: FC<PopupOverlayProps> = props => {
  const { onClose, children, focusTrapOptions, className } = props;

  useEffect(() => {
    document.body.classList.add('lock-scroll');
  }, []);

  const handleClosePopup = () => {
    document.body.classList.remove('lock-scroll');
    onClose();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Escape') {
      handleClosePopup();
    }
  };

  return (
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div className={classNames('popup-overlay', className)} onKeyDown={handleKeyDown}>
        <div className="popup-overlay__close">
          <Button hideText iconHeight={32} iconStart="Cross" iconWidth={32} onClick={handleClosePopup} version="link">
            <FormattedMessage defaultMessage="Close" id="Popup.close" />
          </Button>
        </div>
        {children}
      </div>
    </FocusTrap>
  );
};

export default PopupOverlay;

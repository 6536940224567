import { authenticationService } from 'services/authentication/authentication-service';
import { appSettings } from '../../../src/setup/setup-app-settings';

// generate string of <length> hex numbers, e.g. "a4ed1db61c38"
function getErrorId(hexLength = 6) {
  const random = window.crypto.getRandomValues(new Uint8Array(hexLength));

  let id = '';
  for (const r of random) {
    id += r.toString(16).padStart(2, '0');
  }

  return id;
}

async function send(body: string) {
  const token = await authenticationService.tokenAsync();
  return fetch(appSettings.settings.ENDPOINT_LOGPROXY, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body,
    method: 'POST',
    credentials: 'include',
  })
    .then(response => response.text())
    .catch(error => {
      if (error) console.error(error);
    });
}

interface ErrorInfo {
  message: string;
  stack?: string;
  componentStack?: string;
}

async function sendErrorToServer({ message, stack, componentStack }: ErrorInfo) {
  return await _sendToServer({
    logLevel: 'Error',
    message,
    stack,
    componentStack,
  });
}

async function _sendToServer({ logLevel, message, stack = '', componentStack = '' }: ErrorInfo & { logLevel: string }) {
  const errorId = getErrorId();

  const body = JSON.stringify({
    clientId: appSettings.settings.AUTH_CLIENT_ID,
    clientVersion: appSettings.settings.CLIENT_VERSION,
    componentStack,
    errorId,
    gitBranch: appSettings.settings.CI_COMMIT_REF_NAME,
    gitSha: appSettings.settings.CI_COMMIT_SHA,
    gitTag: appSettings.settings.CI_COMMIT_TAG,
    message,
    stack,
    timestamp: new Date().toISOString(),
    url: window.location.href,
    userAgent: window.navigator.userAgent,
    logLevel: logLevel,
  });

  await send(body);

  return errorId;
}

export { sendErrorToServer };

/**
 * @generated SignedSource<<91f0956d065fa9a5724792ea77bb0807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadDialogContent$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"leadContactDialogContentFragment" | "leadOpenHouseDialogContent" | "leadPresentationDialogContent">;
  readonly " $fragmentType": "leadDialogContent";
};
export type leadDialogContent$key = {
  readonly " $data"?: leadDialogContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadDialogContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadDialogContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadContactDialogContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadPresentationDialogContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadOpenHouseDialogContent"
    }
  ],
  "type": "LeadInterface",
  "abstractKey": "__isLeadInterface"
};

(node as any).hash = "723777cb940ae0f527733610403de611";

export default node;

import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';
import { ChangeEvent, ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { FileInputInterface } from 'components/molecules/chat-actions/chat-actions';
import './file-input.scss';

export interface FileInputProps extends ComponentPropsWithoutRef<'input'> {
  children?: ReactNode;
  label?: string | JSX.Element;
  labelColor?: 'Link' | 'Secondary';
  onFileSelectError: (error: string) => void;
  onFileSelectSuccess: (file: FileInputInterface) => void;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { children, label, onFileSelectSuccess, onFileSelectError, labelColor = 'Link', className, ...rest } = props;

  //Todo: it should be possible to upload multiple files
  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return onFileSelectError('Somthing went wrong.');
    }
    const file = files[0] as FileInputInterface;
    file.id = Date.now().toString();
    onFileSelectSuccess(file);
    e.target.value = ''; // reset file input value
  };

  return (
    <div className={classNames('file-input', className)}>
      <input ref={ref} className="file-input-field" type="file" {...rest} onChange={handleFileInput} />
      {children ? (
        children
      ) : (
        <Typography
          className={classNames('file-input-label', {
            'file-input-label__link': labelColor === 'Link',
            'file-input-label__secondary': labelColor === 'Secondary',
          })}
          htmlFor={rest.id}
          tag="label"
          tagStyle="bodyMedium"
        >
          {label}
        </Typography>
      )}
    </div>
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;

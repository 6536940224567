/**
 * @generated SignedSource<<659692c2350ebf89e75394622a46656d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStatusTypeEnum = "ConvertedToCustomer" | "InProgress" | "MarkAsSpam" | "NotConvertedToCustomer" | "Processed" | "Recieved";
import { FragmentRefs } from "relay-runtime";
export type leadNotification_fragment$data = {
  readonly lead: {
    readonly receivedDate: any | null | undefined;
    readonly statusType: LeadStatusTypeEnum | null | undefined;
    readonly type: {
      readonly displayName: string | null | undefined;
    } | null | undefined;
  };
  readonly read: boolean;
  readonly " $fragmentType": "leadNotification_fragment";
};
export type leadNotification_fragment$key = {
  readonly " $data"?: leadNotification_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadNotification_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadNotification_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadTypeGraphType",
          "kind": "LinkedField",
          "name": "type",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "receivedDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LeadNotification",
  "abstractKey": null
};

(node as any).hash = "8a7602e0d1ed18971917e7749f059158";

export default node;

import classNames from 'classnames';
import { forwardRef, HTMLAttributes, useMemo } from 'react';
import './column.scss';

export type ColumnsType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

interface ColumnBreakPoints {
  desktop?: ColumnsType;
  mobile?: ColumnsType;
  tablet?: ColumnsType;
}

export interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
  fullHeight?: boolean;
  marginBottom?: boolean;
  noMargin?: boolean;
  noPaddingOnMobile?: boolean;
  offset?: ColumnBreakPoints;
  order?: ColumnBreakPoints;
  width?: ColumnBreakPoints;
}

const Column = forwardRef<HTMLDivElement, ColumnProps>(
  (
    { children, className, fullHeight, noMargin, offset, order, marginBottom, width, noPaddingOnMobile, ...rest },
    ref,
  ) => {
    const getClass = (name: 'offset' | 'order' | 'component', breakpoint?: ColumnBreakPoints) => {
      if (breakpoint) {
        return classNames(
          typeof breakpoint.mobile === 'number' && `${name}--${breakpoint.mobile}`,
          typeof breakpoint.tablet === 'number' && `tablet:${name}--${breakpoint.tablet}`,
          typeof breakpoint.desktop === 'number' && `desktop:${name}--${breakpoint.desktop}`,
        );
      }
    };
    const columnClasses = useMemo(() => {
      return classNames(getClass('component', width), getClass('offset', offset), getClass('order', order), {
        fullHeight,
        marginBottom,
        noMargin,
        noPaddingOnMobile,
      });
    }, [width, offset, order, fullHeight, marginBottom, noMargin, noPaddingOnMobile]);

    return (
      <div ref={ref} {...rest} className={classNames('column', columnClasses, className)}>
        {children}
      </div>
    );
  },
);

Column.displayName = 'Column';

export default Column;

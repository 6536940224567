import classNames from 'classnames';
import Typography from 'components/atoms/typography/typography';

import './checkbox.scss';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: ReactNode;
  value?: string | number | readonly string[];
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { value, checked, className, disabled, error, label, children, onChange, ...rest } = props;
  return (
    <div
      className={classNames('checkbox', className, {
        hasError: error,
        isChecked: checked,
        isDisabled: disabled,
      })}
    >
      <label className="label">
        <input
          ref={ref}
          checked={checked}
          className="input"
          onChange={e => onChange && onChange(e)}
          type="checkbox"
          value={value}
          {...rest}
        />
        <div className="checkmark" />
        {(label || children) && (
          <div className="text">
            <Typography tagStyle="bodyMedium">
              {label} {children}
            </Typography>
          </div>
        )}
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;

/**
 * @generated SignedSource<<3c5d1f4f68e4e99257c95fb96e23dab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mainNavigation_fragment$data = {
  readonly unreadNotificationsCount: number | null | undefined;
  readonly unreadThreadCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"notifications_fragment">;
  readonly " $fragmentType": "mainNavigation_fragment";
};
export type mainNavigation_fragment$key = {
  readonly " $data"?: mainNavigation_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"mainNavigation_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "mainNavigation_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadNotificationsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadThreadCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "notifications_fragment"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};

(node as any).hash = "cf548b03adb6070223e0e14b5fe64913";

export default node;

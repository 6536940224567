import { useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import type { GraphQLSubscriptionConfig, OperationType } from 'relay-runtime';
import { requestSubscription } from 'relay-runtime';
import { useSignalrConnection } from 'services/relay/signalr-connection-provider';

function useSubscriptionWithReconnect<TSubscriptionPayload extends OperationType>(
  config: GraphQLSubscriptionConfig<TSubscriptionPayload>,
  requestSubscriptionFn?: typeof requestSubscription,
): void {
  // NOTE: this will re-subscribe every render if config or requestSubscriptionFn are not memoized (e.g useMemo). Please do not pass an object defined in-line.
  const actualRequestSubscription = requestSubscriptionFn ?? requestSubscription;

  const environment = useRelayEnvironment();
  const [, connection] = useSignalrConnection();

  useEffect(() => {
    let disposable: { dispose: () => void };
    const reconnection = {
      dispose: () => {
        // no-op
      },
    };
    const connect = () => {
      disposable?.dispose?.();
      disposable = actualRequestSubscription(environment, config);

      reconnection?.dispose?.();
      reconnection.dispose =
        connection?.onReconnected(connect) ??
        (() => {
          // no-op
        });
    };

    connect();

    return () => {
      reconnection?.dispose?.();
      disposable?.dispose?.();
    };
  }, [environment, connection, config, actualRequestSubscription]);
}

export { useSubscriptionWithReconnect };

/**
 * @generated SignedSource<<3b9b13cf3eea54c32f7f410df2841d08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type caseFullscreenGallery_mediaDataConnection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"caseImagesSwiper_mediaDataConnection">;
  readonly " $fragmentType": "caseFullscreenGallery_mediaDataConnection";
};
export type caseFullscreenGallery_mediaDataConnection$key = {
  readonly " $data"?: caseFullscreenGallery_mediaDataConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"caseFullscreenGallery_mediaDataConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "caseFullscreenGallery_mediaDataConnection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "caseImagesSwiper_mediaDataConnection"
    }
  ],
  "type": "MediaDataConnection",
  "abstractKey": null
};

(node as any).hash = "fb8720a7358b2b1b600a9b8af05ccb53";

export default node;

/**
 * @generated SignedSource<<2ccbc12db4f1541ecba005f32c55e8c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MyPageTextSection = "CasePromotion" | "Consent" | "CustomerGDPR" | "EditSearchProfile" | "Favorites" | "FinishedActivties" | "GetStarted" | "Match" | "Messages" | "MyNeighbourhood" | "OtherBrokerFavorites" | "SearchProfileMatch" | "UpcomingActivities" | "UserProfile" | "Welcome" | "WelcomeHeadline";
export type SearchProfileEnabledType = "AgricultureSearchProfile" | "BusinessRentalSearchProfile" | "BusinessSaleSearchProfile" | "PrivateRentalSearchProfile" | "PrivateSaleSearchProfile";
export type SearchProfileWord = "Balcony" | "Elevator" | "Furnished" | "IsProjectCase" | "Liebhaveri" | "ParkingSpace" | "Pets" | "ResidenceRequirement" | "Shareable" | "Terrace";
export type viewerContextQuery$variables = Record<PropertyKey, never>;
export type viewerContextQuery$data = {
  readonly viewer: {
    readonly configurableTextSections: ReadonlyArray<{
      readonly fileDownloadUrl: string | null | undefined;
      readonly myPageTextSection: MyPageTextSection | null | undefined;
      readonly sectionText: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly customer: {
      readonly id: string;
    } | null | undefined;
    readonly featureToggle: {
      readonly isAnalyticReportsEnabled: boolean | null | undefined;
      readonly isCanBeContactedByPhoneEnabled: boolean | null | undefined;
      readonly isMatchExternalMarketCaseEnabled: boolean | null | undefined;
      readonly isMyNeighborhoodEnabled: boolean | null | undefined;
      readonly isPropertyForSaleByOwnerEnabled: boolean | null | undefined;
      readonly isSellerSaysEnabled: boolean | null | undefined;
      readonly isWebPushNotificationsEnabled: boolean | null | undefined;
      readonly rentalSearchProfileEnabled: boolean;
      readonly searchProfileTypes: ReadonlyArray<SearchProfileEnabledType | null | undefined> | null | undefined;
      readonly searchProfileWords: ReadonlyArray<SearchProfileWord | null | undefined> | null | undefined;
      readonly useCumulativeDaysOnMarketInSearchProfile: boolean;
      readonly useDistancesInSearchProfile: boolean;
      readonly useGenerelConsent: boolean | null | undefined;
      readonly useSearchProfileContactShop: boolean | null | undefined;
    };
    readonly vapidPublicKey: string | null | undefined;
  } | null | undefined;
};
export type viewerContextQuery = {
  response: viewerContextQuery$data;
  variables: viewerContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vapidPublicKey",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureToggle",
  "kind": "LinkedField",
  "name": "featureToggle",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAnalyticReportsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSellerSaysEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMyNeighborhoodEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMatchExternalMarketCaseEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWebPushNotificationsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCanBeContactedByPhoneEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchProfileTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPropertyForSaleByOwnerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSearchProfileContactShop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentalSearchProfileEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCumulativeDaysOnMarketInSearchProfile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchProfileWords",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useGenerelConsent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDistancesInSearchProfile",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "myPageTextSections",
    "value": [
      "CasePromotion",
      "Consent",
      "CustomerGDPR",
      "Favorites",
      "GetStarted",
      "Match",
      "MyNeighbourhood",
      "UserProfile",
      "Welcome",
      "WelcomeHeadline",
      "Messages",
      "UpcomingActivities",
      "FinishedActivties",
      "OtherBrokerFavorites",
      "EditSearchProfile",
      "SearchProfileMatch"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionText",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "myPageTextSection",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileDownloadUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "viewerContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ConfigurableTextSection",
            "kind": "LinkedField",
            "name": "configurableTextSections",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": "configurableTextSections(myPageTextSections:[\"CasePromotion\",\"Consent\",\"CustomerGDPR\",\"Favorites\",\"GetStarted\",\"Match\",\"MyNeighbourhood\",\"UserProfile\",\"Welcome\",\"WelcomeHeadline\",\"Messages\",\"UpcomingActivities\",\"FinishedActivties\",\"OtherBrokerFavorites\",\"EditSearchProfile\",\"SearchProfileMatch\"])"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "viewerContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ConfigurableTextSection",
            "kind": "LinkedField",
            "name": "configurableTextSections",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": "configurableTextSections(myPageTextSections:[\"CasePromotion\",\"Consent\",\"CustomerGDPR\",\"Favorites\",\"GetStarted\",\"Match\",\"MyNeighbourhood\",\"UserProfile\",\"Welcome\",\"WelcomeHeadline\",\"Messages\",\"UpcomingActivities\",\"FinishedActivties\",\"OtherBrokerFavorites\",\"EditSearchProfile\",\"SearchProfileMatch\"])"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "891624b5c385d28acfec2c2eb18bba79",
    "id": null,
    "metadata": {},
    "name": "viewerContextQuery",
    "operationKind": "query",
    "text": "query viewerContextQuery {\n  viewer {\n    vapidPublicKey\n    featureToggle {\n      isAnalyticReportsEnabled\n      isSellerSaysEnabled\n      isMyNeighborhoodEnabled\n      isMatchExternalMarketCaseEnabled\n      isWebPushNotificationsEnabled\n      isCanBeContactedByPhoneEnabled\n      searchProfileTypes\n      isPropertyForSaleByOwnerEnabled\n      useSearchProfileContactShop\n      rentalSearchProfileEnabled\n      useCumulativeDaysOnMarketInSearchProfile\n      searchProfileWords\n      useGenerelConsent\n      useDistancesInSearchProfile\n    }\n    customer {\n      id\n    }\n    configurableTextSections(myPageTextSections: [CasePromotion, Consent, CustomerGDPR, Favorites, GetStarted, Match, MyNeighbourhood, UserProfile, Welcome, WelcomeHeadline, Messages, UpcomingActivities, FinishedActivties, OtherBrokerFavorites, EditSearchProfile, SearchProfileMatch]) {\n      sectionText\n      myPageTextSection\n      fileDownloadUrl\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e46501ed86db3b27097987baa7469c80";

export default node;
